import api, { Header } from './base';
import {
  AllegroDeliveryMethod,
  AllegroDeliveryMethods,
  AllegroDeliveryService,
  AllegroDeliveryServices,
  AllegroImpliedWarranties,
  AllegroImpliedWarranty,
  AllegroOrderCarrier,
  AllegroOrderCarriers,
  AllegroReturnPolicies,
  AllegroReturnPolicy,
  AllegroShippingRate,
  AllegroShippingRateDetail,
  AllegroShippingRates,
} from './allegroApiTypes';
import {
  mockGetAllegroDeliveryMethods,
  mockGetAllegroDeliveryServices,
  mockGetAllegroImpliedWarranties,
  mockGetAllegroOrderCarriers,
  mockGetAllegroReturnPolicies,
  mockGetAllegroShippingRate,
  mockGetAllegroShippingRates,
} from './mocks/allegroMock';

const BASE_PATH = 'allegro-api';

export async function getAllegroShippingRates(
  eshopCode: string,
): Promise<AllegroShippingRate[]> {
  const response = await api.get<AllegroShippingRates>(`${BASE_PATH}/sale/shipping-rates`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
  });
  return response.data.shippingRates;
}

api.mock
  .onGet('allegro-api/sale/shipping-rates')
  .reply(200, mockGetAllegroShippingRates);

export async function getAllegroShippingRate(
  eshopCode: string,
  id: string,
): Promise<AllegroShippingRateDetail> {
  const response = await api.get<AllegroShippingRateDetail>(`${BASE_PATH}/sale/shipping-rates/${id}`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
  });
  return response.data;
}

api.mock
  .onGet(/allegro-api\/sale\/shipping-rates\/.+/)
  .reply(200, mockGetAllegroShippingRate);

export async function getAllegroDeliveryMethods(
  eshopCode: string,
  signal?: AbortSignal,
): Promise<AllegroDeliveryMethod[]> {
  const response = await api.get<AllegroDeliveryMethods>(`${BASE_PATH}/sale/delivery-methods`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
    signal,
  });
  return response.data.deliveryMethods;
}

api.mock
  .onGet('allegro-api/sale/delivery-methods')
  .reply(200, mockGetAllegroDeliveryMethods);

export async function getAllegroReturnPolicies(
  eshopCode: string,
): Promise<AllegroReturnPolicy[]> {
  const response = await api.get<AllegroReturnPolicies>(`${BASE_PATH}/after-sales-service-conditions/return-policies`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
  });
  return response.data.returnPolicies;
}

api.mock
  .onGet('allegro-api/after-sales-service-conditions/return-policies')
  .reply(200, mockGetAllegroReturnPolicies);

export async function getAllegroImpliedWarranties(
  eshopCode: string,
): Promise<AllegroImpliedWarranty[]> {
  const response = await api.get<AllegroImpliedWarranties>(`${BASE_PATH}/after-sales-service-conditions/implied-warranties`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
  });
  return response.data.impliedWarranties;
}

api.mock
  .onGet('allegro-api/after-sales-service-conditions/implied-warranties')
  .reply(200, mockGetAllegroImpliedWarranties);

export async function getAllegroOrderCarriers(
  eshopCode: string,
  signal?: AbortSignal,
): Promise<AllegroOrderCarrier[]> {
  const response = await api.get<AllegroOrderCarriers>(`${BASE_PATH}/order/carriers`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
    signal,
  });

  return response.data.carriers;
}

api.mock
  .onGet('allegro-api/order/carriers')
  .reply(200, mockGetAllegroOrderCarriers);

export async function getAllegroDeliveryServices(
  eshopCode: string,
  signal: AbortSignal,
): Promise<AllegroDeliveryService[]> {
  const response = await api.get<AllegroDeliveryServices>(`${BASE_PATH}/parcel-management/delivery-services`, {
    headers: {
      [Header.X_ESHOP_CODE]: eshopCode,
      [Header.ACCEPT]: 'application/vnd.allegro.public.v1+json',
    },
    signal,
  });

  return response.data.deliveryServices;
}

api.mock
  .onGet('allegro-api/parcel-management/delivery-services')
  .reply(200, mockGetAllegroDeliveryServices);
