import React, { useState } from 'react';
import {
  CheckPicker,
  Col, IconButton, Input, InputGroup, Row, SelectPicker, Stack,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { Icon } from '@rsuite/icons';
import { FaFileExport } from 'react-icons/fa';
import PrintLabelOrderTable from './PrintLabelOrderTable';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { mergeLabels } from '../../api/orders';
import { downloadData } from '../../utils/downloadData';
import { DeliveryTypes, MergeFormat } from '../../api/apiTypes';

const deliveryTypes:{ code: DeliveryTypes, name: string }[] = [
  { code: 'HOME_DELIVERY', name: 'Home delivery' },
  { code: 'ALZA_PICKUP', name: 'Customer pickup Alza' },
  { code: 'CUSTOMER_PICKUP_SHOP', name: 'Customer pickup Parcel shop' },
  { code: 'CUSTOMER_PICKUP_BOX', name: 'Customer pickup Parcel box' },
];

const mergeFormats: { code: MergeFormat, name: string }[] = [
  { code: 'A4', name: 'A4' },
  { code: 'CONTINUOUS', name: 'PDF (continuous)' },
];

export default function PrintExistingLabels() {
  const { setFilter } = useStoreActions(actions => actions.printExistingLabels);
  const { setLoading } = useStoreActions(actions => actions.general);

  const {
    currentFilter, selectedOrders, totalPdfPages,
  } = useStoreState(state => state.printExistingLabels);

  const [globalFilter, setGlobalFilter] = useState(currentFilter.filter.globalFilter);
  const [printFormat, setPrintFormat] = useState<MergeFormat>('A4');
  const [selectedTypes, setSelectedTypes] = useState<DeliveryTypes[]>([]);

  const globalSearch = () => {
    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        ...currentFilter.filter,
        globalFilter,
        deliveryTypes: selectedTypes,
      },
    });
  };

  const checkChanges = () => {
    const current = currentFilter.filter.deliveryTypes;
    if (current.length !== selectedTypes.length
        || !current.every(type => selectedTypes.includes(type))) {
      globalSearch();
    }
  };

  const clearTypes = () => {
    setSelectedTypes([]);
    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        ...currentFilter.filter,
        globalFilter,
        deliveryTypes: [],
      },
    });
  };

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const label = await mergeLabels({
        format: printFormat,
        orderIds: selectedOrders,
      });

      downloadData(label.data, 'application/pdf', 'label.pdf');
    } finally {
      setLoading(false);
    }
  };

  let resultingPages = totalPdfPages;
  if (printFormat === 'A4') {
    resultingPages = Math.ceil(resultingPages / 4);
  }

  return (
    <>
      <Row>
        <Col xs={24}>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" className="mt-20">
            <Stack>
              <InputGroup inside>
                <Input
                  style={{ width: 400 }}
                  placeholder="search by marketplace, status, carrier, order number"
                  defaultValue={globalFilter}
                  onChange={setGlobalFilter}
                  onKeyUp={(ev) => {
                    if (ev.key === 'Enter') {
                      globalSearch();
                    }
                  }}
                />
                <InputGroup.Button>
                  <SearchIcon onClick={globalSearch} />
                </InputGroup.Button>
              </InputGroup>
            </Stack>

            <Stack spacing={10}>
              <span>Print as</span>

              <SelectPicker
                style={{ width: 180 }}
                data={mergeFormats}
                labelKey="name"
                valueKey="code"
                searchable={false}
                cleanable={false}
                block
                value={printFormat}
                onChange={(v) => setPrintFormat(v)}
              />
              <span>
                to
                {' '}
                {resultingPages}
                {' '}
                pages
              </span>
              <IconButton
                style={{ width: 140 }}
                onClick={() => downloadPdf()}
                appearance="primary"
                icon={<Icon as={FaFileExport} />}
                placement="right"
                disabled={selectedOrders.length === 0}
              >
                Print
              </IconButton>
            </Stack>
          </Stack>

        </Col>
      </Row>
      <Row className="mt-10">
        <Col xs={24}>
          <Stack spacing={10}>
            Delivery type
            <CheckPicker
              style={{ width: 150 }}
              data={deliveryTypes}
              labelKey="name"
              valueKey="code"
              searchable={false}
              block
              value={selectedTypes}
              onChange={setSelectedTypes}
              onClose={checkChanges}
              onClean={clearTypes}
            />
          </Stack>

        </Col>
      </Row>
      <Row className="print-order-table-container">
        <Col xs={24} className="mt-20 h100p">
          <PrintLabelOrderTable />
        </Col>
      </Row>
    </>
  );
}
