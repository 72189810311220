import React from 'react';
import { Nav, SelectPicker } from 'rsuite';
import { IoMdExit } from 'react-icons/io';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../store/hooks';
import { PROFILE } from '../routes/links';

export default function LoggedUser() {
  const { currentUser, userTokens } = useStoreState(state => state.user);
  const { logout, changePartner } = useStoreActions(actions => actions.user);

  const partners = userTokens
    .map(ut => ({
      value: ut.user.partnerCode,
      label: `${ut.user.partnerName} (${ut.user.partnerCode})`,
    }));

  const onChangePartner = (partnerCode:string) => {
    changePartner(partnerCode);

    window.location.reload(); // TODO do full reload now
  };

  return (
    <Nav pullRight>
      <Nav.Item
        icon={<FaUser />}
        to={PROFILE}
        as={Link}
      >
        {currentUser.name}
      </Nav.Item>
      <Nav.Item>
        <SelectPicker
          size="sm"
          cleanable={false}
          data={partners}
          value={currentUser.partnerCode}
          placement="bottomEnd"
          menuMaxHeight={400}
          onSelect={(v) => onChangePartner(v)}
        />
      </Nav.Item>
      <Nav.Item>
        <IoMdExit size={24} onClick={() => logout()} className="ml-5" />
      </Nav.Item>
    </Nav>
  );
}
