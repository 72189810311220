import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'rsuite';
import { EshopCarrier } from '../api/apiTypes';
import { Eshop, getEshops } from '../api/eshop/eshop';
import { getEshopCarrier } from '../api/carrier';
import CarrierSetupKauflandEdit from '../components/CarrierSetup/Kaufland/CarrierSetupKauflandEdit';
import { ApiGroup } from '../api/model/ApiGroup';
import CarrierSetupAllegroEdit from '../components/CarrierSetup/Allegro/CarrierSetupAllegroEdit';
import CarrierSetupAlzaEdit from '../components/CarrierSetup/Alza/CarrierSetupAlzaEdit';

interface ContextProps {
  apiGroup: ApiGroup;
  eshopCarrier?: EshopCarrier;
  eshops: Eshop[];
}

function Context({ apiGroup, eshopCarrier, eshops }: ContextProps) {
  return (
    <>
      {apiGroup === ApiGroup.KAUFLAND && (
      <CarrierSetupKauflandEdit
        apiGroup={apiGroup}
        eshopCarrier={eshopCarrier}
        eshops={eshops}
      />
      )}
      { apiGroup === ApiGroup.ALZA && (
      <CarrierSetupAlzaEdit
        apiGroup={apiGroup}
        eshopCarrier={eshopCarrier}
        eshops={eshops}
      />
      ) }
      { apiGroup === ApiGroup.MALL_GROUP && <p>TODO: MALL_GROUP</p> }
      { apiGroup === ApiGroup.ALLEGRO && (
      <CarrierSetupAllegroEdit
        apiGroup={apiGroup}
        eshopCarrier={eshopCarrier}
        eshops={eshops}
      />
      ) }
    </>
  );
}

Context.defaultProps = {
  eshopCarrier: undefined,
};

interface State {
  isLoading: boolean;
  eshopCarrier?: EshopCarrier;
  eshops: Eshop[];
}

const initialState: State = {
  isLoading: false,
  eshopCarrier: undefined,
  eshops: [],
};

interface LoadingSuccessfulPayload {
  eshopCarrier?: EshopCarrier,
  eshops: Eshop[],
}

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload: LoadingSuccessfulPayload };
type Action = LoadingAction | LoadingSuccessfulAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: true,
      };
    case 'loadingSuccessful':
      return {
        ...state,
        isLoading: false,
        eshopCarrier: action.payload.eshopCarrier,
        eshops: action.payload.eshops,
      };
    default:
      return state;
  }
}

export default function CarrierSetupEdit() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { apiGroup, eshopCarrierId } = useParams();

  async function fetchData(): Promise<LoadingSuccessfulPayload> {
    dispatch({ type: 'loading' });
    const eshops = await getEshops().then((response) => response.data);

    if (!eshopCarrierId) {
      dispatch({ type: 'loadingSuccessful', payload: { eshops } });
      return { eshops };
    }

    const eshopCarrier = await getEshopCarrier(parseInt(eshopCarrierId, 10));
    return { eshopCarrier, eshops };
  }

  useEffect(() => {
    fetchData()
      .then((payload) => dispatch({ type: 'loadingSuccessful', payload }));
  }, []);

  if (!apiGroup || !(apiGroup in ApiGroup)) {
    return null;
  }

  return (
    <div className="m-10" style={{ marginBottom: 150 }}>
      { state.isLoading ? <Loader /> : (
        <Context
          apiGroup={apiGroup as ApiGroup}
          eshopCarrier={state.eshopCarrier}
          eshops={state.eshops}
        />
      )}
    </div>
  );
}

CarrierSetupEdit.defaultProps = {
  eshopCarrierId: undefined,
};
