import React, { useEffect, useState } from 'react';
import Upload from 'rc-upload';
import {
  Button, Checkbox, Tree, Progress,
} from 'rsuite';
import { ItemDataType } from 'rsuite/esm/@types/common';
import { RcFile } from 'rc-upload/es/interface';

const Saxophone = require('saxophone');

export default function FeedsEdit() {
  const [schema, setSchema] = useState<Record<string, any>>({});
  const [treeData, setTreeData] = useState<ItemDataType[]>([]);
  const [uploadedFile, setUploadedFile] = useState<RcFile>();
  const [processedContentPreview, setProcessedContentPreviewPreview] = useState<string | undefined>('');
  const [progressOfProcessing, setProgressOfProcessing] = useState<number>(0);
  const [progressOfAnalysis, setProgressOfAnalysis] = useState<number>(0);

  function mapToTreeStructure(obj: Record<string, any>, parentKey = ''): ItemDataType[] {
    return Object.keys(obj).map(key => {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      return {
        label: key,
        value: newKey,
        preserve: true,
        children: mapToTreeStructure(obj[key], newKey),
      };
    });
  }

  useEffect(() => {
    setTreeData(mapToTreeStructure(schema));
  }, [schema]);

  function getExpandValues(obj: ItemDataType): any[] {
    if (!obj) return [];
    if (obj.children) {
      return [obj.value, ...obj.children
        .flatMap((children: ItemDataType) => getExpandValues(children))];
    }
    return [obj.value];
  }

  function findNodeWithValue(node: ItemDataType, value: string): ItemDataType | null {
    if (node.value === value) {
      return node;
    }

    if (!node.children) {
      return null;
    }

    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      const result = findNodeWithValue(child, value);
      if (result) {
        return result;
      }
    }

    return null;
  }

  function getRules(tree: ItemDataType, result: Record<string, any> = {}) {
    if (tree.value) {
      result[tree.value] = { preserve: tree.preserve };
    }
    if (tree.children) {
      tree.children.forEach(child => getRules(child, result));
    }
    return result;
  }

  return (
    <div>
      <h1>Xml Editor</h1>
      <Upload
        type="drag"
        className="uploader"
        accept=".xml"
        beforeUpload={(file) => {
          setUploadedFile(file);
          const parser = new Saxophone();
          setSchema({});
          const currentPath: Record<string, any>[] = [schema];

          parser.on('tagopen', (tag: { name: any; attrs: any; }) => {
            const currentLeaf = currentPath[currentPath.length - 1];
            if (!currentLeaf[tag.name]) {
              currentLeaf[tag.name] = {};
              setSchema({ ...schema });
            }
            currentPath.push(currentLeaf[tag.name]);
          });
          parser.on('tagclose', () => {
            currentPath.pop();
          });
          const fileReader = new FileReader();
          const chunkSize = 1024 * 1024; // Read in chunks of 1 MB
          let offset = 0;

          const readSlice = () => {
            const slice = file.slice(offset, offset + chunkSize);
            fileReader.readAsText(slice);
            offset += chunkSize;
          };

          fileReader.onloadend = () => {
            const chunk = fileReader.result;
            parser.write(chunk);

            if (offset < file.size) {
              readSlice();
              setProgressOfAnalysis(Math.round((offset / file.size) * 100));
            } else {
              parser.end();
              setProgressOfAnalysis(100);
            }
          };

          readSlice();
          return false;
        }}
      >
        <div>Click or Drag files to this area to upload</div>
      </Upload>
      <Progress.Line
        percent={progressOfAnalysis}
        strokeColor={progressOfAnalysis === 100 ? '#52c41a' : '#3385ff'}
        status={progressOfAnalysis === 100 ? 'success' : undefined}
      />
      <Tree
        data={treeData}
        expandItemValues={getExpandValues(treeData[0])}
        renderTreeNode={nodeData => (
          <div>
            {nodeData.label}
            <Checkbox
              checked={nodeData.preserve}
              onChange={() => {
                const node = findNodeWithValue(treeData[0], nodeData.value as string);
                if (node) {
                  node.preserve = !node.preserve;
                }
                setTreeData([...treeData]);
              }}
            >
              Preserve
            </Checkbox>
          </div>
        )}
      />
      <Button onClick={async () => {
        const parser = new Saxophone();
        const rules = getRules(treeData[0]);
        let result = '';
        const currentPath: string[] = [];
        let tagWasPreserved: boolean = true;

        parser.on('tagopen', (tag: { name: any; attrs: any; }) => {
          currentPath.push(tag.name);
          const pathString = currentPath.join('.');
          if (rules[pathString]?.preserve) {
            result += `<${tag.name}>`;
          } else {
            tagWasPreserved = false;
          }
        });
        parser.on('text', (text: { contents: string }) => {
          if (tagWasPreserved) {
            result += text.contents;
          } else {
            tagWasPreserved = true;
          }
        });
        parser.on('tagclose', (tag: { name: any; attrs: any; }) => {
          const pathString = currentPath.join('.');
          if (rules[pathString]?.preserve) {
            result += `</${tag.name}>`;
          } else {
            tagWasPreserved = false;
          }
          currentPath.pop();
        });
        parser.on('finish', () => {
          setProcessedContentPreviewPreview(result.substring(0, 2000));
          const blob = new Blob([result], { type: 'text/xml' });
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = `processed_${uploadedFile?.name}`;
          downloadLink.click();
          URL.revokeObjectURL(url);
        });

        if (uploadedFile == null) return;

        const fileReader = new FileReader();
        const chunkSize = 1024 * 1024; // Read in chunks of 1 MB
        let offset = 0;

        const readSlice = () => {
          const slice = uploadedFile.slice(offset, offset + chunkSize);
          fileReader.readAsText(slice);
          offset += chunkSize;
        };

        fileReader.onloadend = () => {
          const chunk = fileReader.result;
          parser.write(chunk);

          if (offset < uploadedFile.size) {
            readSlice();
            setProgressOfProcessing(Math.round((offset / uploadedFile.size) * 100));
          } else {
            parser.end();
            setProgressOfProcessing(100);
          }
        };

        readSlice();
      }}
      >
        Process
      </Button>
      <Progress.Line
        percent={progressOfProcessing}
        strokeColor={progressOfProcessing === 100 ? '#52c41a' : '#3385ff'}
        status={progressOfProcessing === 100 ? 'success' : undefined}
      />
      <pre lang="xml">{ processedContentPreview }</pre>
    </div>
  );
}
