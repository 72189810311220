import React, { useRef } from 'react';
import { Button } from 'rsuite';
import RecalculateAlzaBranchIdsExecution, {
  RecalculateAlzaBranchIdsRefType,
} from './RecalculateAlzaBranchIdsExecution';

interface RecalculateAlzaBranchIdsProps {
  dynamicBranchId: boolean | undefined
}

function RecalculateAlzaBranchIds({ dynamicBranchId }: RecalculateAlzaBranchIdsProps) {
  const syncExecutionRef = useRef<RecalculateAlzaBranchIdsRefType>(null);

  function recalculateBranchIds() {
    syncExecutionRef.current?.synchronize();
  }

  return (
    <>
      <Button appearance="primary" onClick={() => recalculateBranchIds()} disabled={!dynamicBranchId}>Recalculate branch Ids</Button>
      <RecalculateAlzaBranchIdsExecution ref={syncExecutionRef} />
    </>
  );
}

export default RecalculateAlzaBranchIds;
