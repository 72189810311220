export const stateMappings = [
  {
    mpiStatusName: 'OPEN',
    blStatusName: 'Nowe zamowienia',
    blStatusId: 23882,
  },
  {
    mpiStatusName: 'SHIPPING',
    blStatusName: 'Do wyslania',
    blStatusId: 23883,
  },
];

export const basicInformation = {
  inventoryId: 21444,
  priceGroupId: '26354',
  internalWarehouseIds: ['bl_28023', 'bl_59806'],
  externalWarehouseId: null,
  orderSourceId: '0',
};

export const customFields = {
  marketplaceOrderId: '69242',
  labelUrl: null,
  numberOfPackages: '69243',
};

export const eshopCodesUsingDefaultConfig = ['MALL.CZ', 'MALL.HU'];
