import React, { useEffect, useReducer, useRef } from 'react';
import { Form, Loader } from 'rsuite';
import _ from 'lodash';
import { v4 } from 'uuid';
import { OrderListItem } from '../../api/apiTypes';
import { getCrossDockTrackings, saveCrossDockTrackings } from '../../api/crossdocktracking/crossDockTracking';
import { packagePreview } from '../../api/orders';
import { CrossDockTracking, ParcelTracking } from '../../api/crossdocktracking/crossDockTrackingTypes';
import showSuccessMessage from '../../utils/message';
import CrossDockTrackingRow from './CrossDockTrackingRow';
import CrossDockTrackingAction from './CrossDockTrackingAction';

interface Props {
  order?: OrderListItem;
  onCancel: () => void;
}

interface Tracking {
  id: string;
  trackingNumber: string;
  trackingUrl: string;
}

interface State {
  trackings: Tracking[];
  status: 'ready' | 'loading' | 'saving';
}

const defaultState: State = {
  trackings: [],
  status: 'ready',
};

type LoadAction = { type: 'load' };
type SaveAction = { type: 'save', payload: boolean };
type TrackingsSetAction = { type: 'trackings/set', payload: Tracking[] };
type TrackingsAddAction = { type: 'trackings/add' };
type TrackingsEditAction = { type: 'trackings/edit', payload: Tracking };
type Action = LoadAction
| SaveAction
| TrackingsSetAction
| TrackingsAddAction
| TrackingsEditAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load':
      return { ...state, status: 'loading' };
    case 'save':
      return { ...state, status: action.payload ? 'saving' : 'ready' };
    case 'trackings/set':
      return { ...state, status: 'ready', trackings: action.payload };
    case 'trackings/add':
      return { ...state, trackings: [...state.trackings, { id: v4(), trackingNumber: '', trackingUrl: '' }] };
    case 'trackings/edit':
      return {
        ...state,
        trackings: state.trackings.map((tracking) => {
          if (tracking.id !== action.payload.id) {
            return tracking;
          }

          return action.payload;
        }),
      };
    default:
      return state;
  }
}

function generateTrackings(
  parcelTrackings: ParcelTracking[],
  numberOfPackages: number,
): Tracking[] {
  const trackings: Tracking[] = parcelTrackings.map(parcelTracking => ({
    id: v4(),
    trackingNumber: parcelTracking.parcelTrackingNumber,
    trackingUrl: parcelTracking.parcelTrackingUrl ?? '',
  }));
  const difference = numberOfPackages - trackings.length;

  if (difference <= 0) {
    return trackings;
  }

  const generated = _.times(difference, () => ({ id: v4(), trackingNumber: '', trackingUrl: '' }));

  return [...trackings, ...generated];
}

export default function CrossDockTrackingEdit({ order, onCancel }: Props) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const formRef = useRef<any>();

  async function fetchData(orderId: number, signal: AbortSignal) {
    dispatch({ type: 'load' });

    const [crossDockTrackings, numberOfPackages] = await Promise.all([
      getCrossDockTrackings(orderId, signal),
      packagePreview(orderId, signal).then(response => response.data.numberOfPackages),
    ]);

    const generatedTrackings = generateTrackings(
      crossDockTrackings.parcelTracking,
      numberOfPackages,
    );

    dispatch({ type: 'trackings/set', payload: generatedTrackings });
  }

  async function saveData(orderId: number, crossDockTracking: CrossDockTracking) {
    try {
      dispatch({ type: 'save', payload: true });
      await saveCrossDockTrackings(orderId, crossDockTracking);
      showSuccessMessage('Cross dock trackings successfully saved');
    } finally {
      dispatch({ type: 'save', payload: false });
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    if (order) {
      fetchData(order.id, abortController.signal).then();
    }

    return () => { abortController.abort(); };
  }, [order]);

  if (state.status === 'loading') {
    return <Loader />;
  }

  function handleAddPackage() {
    dispatch({ type: 'trackings/add' });
  }

  function handleTrackingChange(tracking: Tracking) {
    dispatch({ type: 'trackings/edit', payload: tracking });
  }

  function handleSave() {
    if (!order) {
      return;
    }

    if (!formRef.current?.check()) {
      return;
    }

    const trackings = state.trackings.filter(tracking => tracking.trackingNumber);
    const crossDockTracking: CrossDockTracking = {
      parcelTracking: trackings.map(tracking => ({
        parcelTrackingNumber: tracking.trackingNumber,
        parcelTrackingUrl: tracking.trackingUrl,
      })),
    };

    saveData(order.id, crossDockTracking).then();
  }

  function handleCancel() {
    onCancel();
  }

  return (
    <Form layout="horizontal" ref={formRef}>
      {state.trackings.map((tracking, index) => (
        <CrossDockTrackingRow
          key={tracking.id}
          index={index}
          tracking={tracking}
          onChange={(changed) => handleTrackingChange(changed)}
        />
      ))}
      <CrossDockTrackingAction
        isSaving={state.status === 'saving'}
        onAddPackage={() => handleAddPackage()}
        onSave={() => handleSave()}
        onCancel={() => handleCancel()}
      />
    </Form>
  );
}

CrossDockTrackingEdit.defaultProps = {
  order: undefined,
};
