import React from 'react';
import { Stack } from 'rsuite';

export default function Copyright() {
  return (
    <Stack justifyContent="center" className="copyright">
      Copyright ©
      <a href="https://www.salesconnect.cz/">
          &nbsp;salesconnect
      </a>
      &nbsp;
      {new Date().getFullYear()}
      .
    </Stack>
  );
}
