import {
  Button,
  ButtonToolbar,
  Form,
  Message,
  Schema,
  SelectPicker,
  Toggle,
  useToaster,
} from 'rsuite';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchOrderStatuses } from './BaselinkerClient';
import {
  deleteStatusMappings,
  getStatusMappings,
  saveStatusMappings,
} from '../api/baselinker/baselinker';
import { StatusMapping } from '../api/baselinker/apiTypes';

function countOccurrences(arr: string[]) {
  return arr.reduce((acc: Record<string, number>, val: string) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
}

function hasAnyDefinedProperties(obj: any): boolean {
  return Object.values(obj).some(value => value !== undefined);
}

interface OrderStatusesFormProps {
  blLinked: boolean;
  blApiToken: string | undefined;
  eshopCode?: string;
}

const mpiStatuses = ['OPEN', 'SHIPPING', 'SHIPPED', 'RETURNED', 'CANCELLED', 'DELIVERED', 'API_ERROR'];

export default function OrderStatusesForm(
  { blLinked, blApiToken, eshopCode } : OrderStatusesFormProps,
) {
  const navigate = useNavigate();
  const toaster = useToaster();
  const formRef = useRef<any>();
  const [orderStatuses, setOrderStatuses] = useState<Record<string, any>[]>([]);
  const [formValue, setFormValue] = useState<Record<string, any>>({});
  const [useDifferentSetup, setUseDifferentSetup] = useState<boolean>(false);

  useEffect(() => {
    if (blLinked && blApiToken) {
      fetchOrderStatuses(blApiToken).then(data => setOrderStatuses(data));
    }
  }, [blLinked]);

  useEffect(() => {
    getStatusMappings(eshopCode).then(setUpstateMappings => {
      const states: Record<string, any> = {};
      setUpstateMappings.forEach(stateMapping => {
        states[stateMapping.mpiStatusName] = stateMapping.blStatusId;
      });
      setFormValue(prevState => ({
        ...prevState,
        ...states,
      }));
      if (eshopCode && hasAnyDefinedProperties(setUpstateMappings)) {
        setUseDifferentSetup(true);
      } else {
        setUseDifferentSetup(false);
      }
    });
  }, [eshopCode]);

  const orderStatusesRule = Schema.Types.NumberType()
    .isRequired('This field is required.')
    .addRule((value: any, data: any) => {
      const occurrence = countOccurrences(mpiStatuses.map((status: string) => data[status]));
      return occurrence[value] <= 1;
    }, 'Value can be present only once.')
    .addRule((value) => orderStatuses.filter(x => x.value === value).length === 1, 'Select this value');

  function onSubmit() {
    if (eshopCode && !useDifferentSetup) {
      deleteStatusMappings(eshopCode).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>{`Custom fields for ${eshopCode} deleted`}</Message>);
      });
      return;
    }

    if (formRef.current?.check()) {
      const statusMappingsToSave: StatusMapping[] = mpiStatuses.map(status => ({
        mpiStatusName: status,
        blStatusId: formValue[status],
      }));

      saveStatusMappings(statusMappingsToSave, eshopCode).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Status mappings saved</Message>);
      });
    } else {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Please check the form there are validation errors.</Message>);
    }
  }

  return (
    <>
      {eshopCode && (
        <Form layout="horizontal">
          <Form.Group>
            <Form.ControlLabel>{`Use different setup for ${eshopCode}`}</Form.ControlLabel>
            <Toggle checked={useDifferentSetup} onChange={setUseDifferentSetup} />
          </Form.Group>
        </Form>
      )}
      {(!eshopCode || useDifferentSetup) && (
        <Form ref={formRef} formValue={formValue} layout="horizontal" onChange={setFormValue}>
          {mpiStatuses.map(status => (
            <Form.Group key={status} controlId={status}>
              <Form.ControlLabel>{status}</Form.ControlLabel>
              <Form.Control
                name={status}
                accepter={SelectPicker}
                data={orderStatuses}
                rule={orderStatusesRule}
              />
              <Form.HelpText tooltip>Required</Form.HelpText>
            </Form.Group>
          ))}
        </Form>
      )}
      <Form layout="horizontal">
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
}

OrderStatusesForm.defaultProps = {
  eshopCode: undefined,
};
