import React, { useEffect, useReducer } from 'react';
import {
  Button, Message, Stack, Table, toaster,
} from 'rsuite';
import { v4 } from 'uuid';
import { AxiosError } from 'axios';
import { Eshop } from '../../../api/eshop/eshop';
import { KauflandExternalWarehouse } from '../../../api/apiTypes';
import { WarehouseLocality, WarehouseSynchronizationResult } from '../../../api/warehouse/warehouseApiTypes';
import { getWarehouseLocalities, synchronizeWarehouseLocalities } from '../../../api/warehouse/warehouse';
import { getKauflandExternalWarehouses } from '../../../api/kaufland';
import { useStoreState } from '../../../store/hooks';

interface Props {
  eshop: Eshop
}

interface Warehouse {
  id: number;
  city?: string;
  street?: string;
  country?: string;
  postcode?: string;
  company?: string;
  isDefault: boolean;
  source: 'mpi' | 'kaufland';
}

interface State {
  isLoading: boolean;
  isSynchronizing: boolean;
  warehouses: Warehouse[];
  synchronizationResult?: WarehouseSynchronizationResult;
  error?: string
}

const initialState: State = {
  isLoading: false,
  isSynchronizing: false,
  warehouses: [],
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessAction = { type: 'loadingSuccess', payload: Warehouse[] };
type LoadingFailureAction = { type: 'loadingFailure', payload: string };
type SynchronizeAction = { type: 'synchronize' };
type SynchronizeSuccessAction = { type: 'synchronizeSuccess', payload: WarehouseSynchronizationResult };
type SynchronizeFailureAction = { type: 'synchronizeFailure', payload: string };
type Action = LoadingAction
| LoadingSuccessAction
| LoadingFailureAction
| SynchronizeAction
| SynchronizeSuccessAction
| SynchronizeFailureAction;

function mapKauflandWarehouse(kauflandWarehouse: KauflandExternalWarehouse): Warehouse {
  return {
    id: kauflandWarehouse.warehouseId,
    city: kauflandWarehouse.address.city,
    street: `${kauflandWarehouse.address.street}, ${kauflandWarehouse.address.houseNumber}`,
    country: kauflandWarehouse.address.country,
    postcode: kauflandWarehouse.address.postcode,
    isDefault: kauflandWarehouse.isDefault,
    source: 'kaufland',
  };
}

function mapWarehouseLocality(warehouseLocality: WarehouseLocality): Warehouse {
  return {
    id: warehouseLocality.id,
    city: warehouseLocality.city,
    street: warehouseLocality.street,
    country: warehouseLocality.countryCode,
    postcode: warehouseLocality.postcode,
    company: warehouseLocality.company,
    isDefault: warehouseLocality.isDefault,
    source: 'mpi',
  };
}

function mapWarehouseResponse(
  warehouses: [WarehouseLocality[], KauflandExternalWarehouse[]],
): Warehouse[] {
  return [
    ...warehouses[0].map(mapWarehouseLocality),
    ...warehouses[1].map(mapKauflandWarehouse),
  ];
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true, warehouses: [] };
    case 'loadingSuccess':
      return { ...state, isLoading: false, warehouses: action.payload };
    case 'loadingFailure':
      return { ...state, isLoading: false, error: action.payload };
    case 'synchronize':
      return { ...state, isSynchronizing: true, synchronizationResult: undefined };
    case 'synchronizeSuccess':
      return {
        ...state,
        isSynchronizing: false,
        synchronizationResult: action.payload,
        warehouses: [
          ...[
            ...action.payload.savedWarehouseLocalities,
            ...action.payload.notDeletedWarehouseLocalities
              .map(notDeleted => notDeleted.warehouseLocality),
          ]
            .map(mapWarehouseLocality),
          ...state.warehouses.filter(warehouse => warehouse.source === 'kaufland'),
        ],
      };
    case 'synchronizeFailure':
      return { ...state, isSynchronizing: false, error: action.payload };
    default:
      return state;
  }
}

export default function EshopSetupKauflandWarehouses({ eshop }: Props) {
  const { currentUser } = useStoreState(state => state.user);
  const [state, dispatch] = useReducer(reducer, initialState);

  function showErrorMessage(message: string): void {
    toaster.push(<Message type="error" closable showIcon duration={2000}>{message}</Message>);
  }

  function showSuccessMessage() {
    toaster.push(<Message type="success" closable showIcon duration={2000}>Warehouses successfully synchronized.</Message>);
  }

  function showDependingMessage(synchronizationResult: WarehouseSynchronizationResult): void {
    if (synchronizationResult.notDeletedWarehouseLocalities.length === 0) {
      showSuccessMessage();
      return;
    }

    const { notDeletedWarehouseLocalities } = synchronizationResult;
    const messages = notDeletedWarehouseLocalities
      .map(notDeleted => `Cannot delete warehouse with id ${notDeleted.warehouseLocality.id}. 
      It is used in ${notDeleted.numberOfAssignedProducts} products.`);

    toaster.push(
      <Message type="warning" closable showIcon duration={0}>
        {messages.map(message => (<p key={v4()}>{message}</p>))}
      </Message>,
    );
  }

  function fetchWarehouseLocalities(): Promise<WarehouseLocality[]> {
    return getWarehouseLocalities(eshop.code)
      .catch((error: AxiosError) => {
        if (error.response?.status !== 400) {
          return Promise.reject(error);
        }
        return [];
      });
  }

  function fetchKauflandWarehouses(): Promise<KauflandExternalWarehouse[]> {
    return getKauflandExternalWarehouses(currentUser.partnerCode)
      .catch(() => []);
  }

  function loadWarehouses(): void {
    dispatch({ type: 'loading' });
    Promise
      .all([
        fetchWarehouseLocalities(),
        fetchKauflandWarehouses(),
      ])
      .then((response) => {
        const warehouses = mapWarehouseResponse(response);
        dispatch({ type: 'loadingSuccess', payload: warehouses });
      })
      .catch((error: AxiosError) => {
        dispatch({ type: 'loadingFailure', payload: error.response?.data.details });
        showErrorMessage(error.response?.data.details);
      });
  }

  useEffect(() => {
    loadWarehouses();
  }, [eshop]);

  function synchronizeWarehouses(): void {
    dispatch({ type: 'synchronize' });
    synchronizeWarehouseLocalities(eshop.code)
      .then(response => {
        dispatch({ type: 'synchronizeSuccess', payload: response });
        showDependingMessage(response);
      })
      .catch((error: AxiosError) => {
        dispatch({ type: 'synchronizeFailure', payload: error.response?.data.details });
        showErrorMessage(error.response?.data.details);
      });
  }

  return (
    <Stack spacing={15} direction="column" alignItems="stretch" justifyContent="flex-start">
      <Button
        appearance="primary"
        onClick={() => synchronizeWarehouses()}
        loading={state.isSynchronizing}
      >
        Synchronize warehouse data from Kaufland
      </Button>
      <Table
        autoHeight
        bordered
        data={state.warehouses}
        loading={state.isLoading}
      >
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Warehouse Id</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>City</Table.HeaderCell>
          <Table.Cell dataKey="city" />
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>Street</Table.HeaderCell>
          <Table.Cell dataKey="street" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.Cell dataKey="country" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Postcode</Table.HeaderCell>
          <Table.Cell dataKey="postcode" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.Cell dataKey="company" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.Cell>
            {
              (warehouse: Warehouse) => (
                <p>
                  {warehouse.source === 'mpi' ? 'MPI' : 'KAUFLAND' }
                </p>
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>{}</Table.HeaderCell>
          <Table.Cell>
            {
              (warehouse: Warehouse) => (
                <p>
                  {warehouse.isDefault ? '(DEFAULT)' : null}
                </p>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Stack>
  );
}
