import React, { useEffect, useReducer, useRef } from 'react';
import {
  Button, ButtonToolbar, Form, InputNumber, Loader, Schema, Stack,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { useIsMounted } from 'rsuite/utils';
import {
  createWarehouseLocality,
  getWarehouseLocality,
  updateWarehouseLocality,
} from '../../../api/warehouse/warehouse';
import { WarehouseLocality } from '../../../api/warehouse/warehouseApiTypes';
import showSuccessMessage from '../../../utils/message';

interface Props {
  warehouseLocalityId?: number;
  eshopCode: string;
}

interface State {
  edit: Edit;
  warehouseLocality?: WarehouseLocality;
  status: 'ready' | 'loading' | 'saving';
}

interface Edit {
  code?: string;
  expeditionShiftByDPlus?: number;
  maxHandlingTime?: number;
}

const initialState: State = {
  edit: {
    code: '',
  },
  warehouseLocality: undefined,
  status: 'ready',
};

const validationModel = Schema.Model({
  code: Schema.Types.StringType().isRequired('Branch code is required.'),
  expeditionShiftByDPlus: Schema.Types.NumberType().min(0, 'Expedition shift by D+ must be equal or greater than 0'),
  maxHandlingTime: Schema.Types.NumberType().min(0, 'Max handling time must be equal or greater than 0'),
});

type LoadAction = { type: 'load' };
type SetWarehouseLocalityAction = { type: 'setWarehouseLocality', payload: WarehouseLocality };
type SetEditAction = { type: 'setEdit', payload: Edit };
type SaveAction = { type: 'save', payload: boolean };
type Action = LoadAction | SetWarehouseLocalityAction | SetEditAction | SaveAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load':
      return { ...state, status: 'loading' };
    case 'setWarehouseLocality':
      return {
        ...state,
        status: 'ready',
        warehouseLocality: action.payload,
        edit: {
          code: action.payload.code,
          expeditionShiftByDPlus: action.payload?.expeditionShiftByDPlus,
          maxHandlingTime: action.payload?.maxHandlingTime,
        },
      };
    case 'setEdit':
      return {
        ...state,
        edit: {
          code: action.payload.code,
          expeditionShiftByDPlus: typeof action.payload?.expeditionShiftByDPlus !== 'undefined'
            ? Math.floor(action.payload?.expeditionShiftByDPlus) : undefined,
          maxHandlingTime: typeof action.payload?.maxHandlingTime !== 'undefined'
            ? Math.floor(action.payload?.maxHandlingTime) : undefined,
        },
      };
    case 'save':
      return { ...state, status: action.payload ? 'saving' : 'ready' };
    default:
      return state;
  }
}

export default function EshopSetupAlzaBranchEdit({
  warehouseLocalityId,
  eshopCode,
}: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const formRef = useRef<any>();
  const abortControllerRef = useRef(new AbortController());
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  function fetchWarehouseLocality(id: number, signal: AbortSignal): void {
    dispatch({ type: 'load' });
    getWarehouseLocality(id, signal)
      .then((response) => dispatch({ type: 'setWarehouseLocality', payload: response }));
  }

  async function saveWarehouseLocality(): Promise<null | number> {
    dispatch({ type: 'save', payload: true });

    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    const { signal } = abortControllerRef.current;
    const warehouseLocality: Partial<WarehouseLocality> = {
      code: state.edit.code,
      expeditionShiftByDPlus: state.edit.expeditionShiftByDPlus,
      maxHandlingTime: state.edit.maxHandlingTime,
      eshopCode,
    };

    if (!warehouseLocalityId) {
      return createWarehouseLocality(warehouseLocality, signal);
    }

    return updateWarehouseLocality(warehouseLocalityId, warehouseLocality, signal);
  }

  function handleChange(edit: Edit): void {
    dispatch({ type: 'setEdit', payload: edit });
  }

  function handleSave(): void {
    if (!formRef.current?.check()) {
      return;
    }

    saveWarehouseLocality()
      .then((id) => id && navigate(`${id}`, { replace: true }))
      .then(() => showSuccessMessage('Carrier mapping successfully saved.'))
      .finally(() => isMounted() && dispatch({ type: 'save', payload: false }));
  }

  function handleCancel(): void {
    navigate(-1);
  }

  useEffect(() => {
    if (!warehouseLocalityId) {
      return () => {
      };
    }

    const abortController = new AbortController();
    fetchWarehouseLocality(warehouseLocalityId, abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [warehouseLocalityId]);

  useEffect(() => () => {
    abortControllerRef.current.abort();
  }, [abortControllerRef.current]);

  if (state.status === 'loading') {
    return <Loader />;
  }

  return (
    <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
      <Form
        layout="horizontal"
        formValue={state.edit}
        ref={formRef}
        model={validationModel}
        onChange={(edit) => handleChange(edit)}
        fluid
      >
        <Form.Group>
          <Form.ControlLabel>
            Branch code
          </Form.ControlLabel>
          <Form.Control
            name="code"
            value={state.edit.code ?? null}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Posunutí expedice o D+
          </Form.ControlLabel>
          <Form.Control
            name="expeditionShiftByDPlus"
            accepter={InputNumber}
            value={state.edit.expeditionShiftByDPlus ?? null}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Max handling time
          </Form.ControlLabel>
          <Form.Control
            name="maxHandlingTime"
            accepter={InputNumber}
            value={state.edit.maxHandlingTime ?? null}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => handleSave()}
              type="submit"
              loading={state.status === 'saving'}
            >
              Save
            </Button>
            <Button
              appearance="ghost"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Stack>
  );
}

EshopSetupAlzaBranchEdit.defaultProps = {
  warehouseLocalityId: undefined,
};
