import React, { createContext, useEffect, useState } from 'react';
import { Eshop, getEshops } from '../api/eshop/eshop';

export const EshopContext = createContext<Eshop[] | undefined>(undefined);

export function EshopContextProvider({ children }: any) {
  const [eshops, setEshops] = useState<Eshop[]>();

  useEffect(() => {
    getEshops().then(response => {
      setEshops(response.data);
    });
  }, []);

  return (
    <EshopContext.Provider value={eshops}>
      {children}
    </EshopContext.Provider>
  );
}
