export type StatusMapping = {
  mpiStatusName: string,
  blStatusId: number
};

export enum WarehouseType {
  INTERNAL,
  EXTERNAL,
}

export type BasicInformation = {
  inventoryId: string | null,
  priceGroupId: string | undefined | null,
  internalWarehouseIds: string[] | null,
  externalWarehouseId: string | null,
  orderSourceId: string | null
};

export type CustomFields = {
  marketplaceOrderId: string,
  suggestedExpedition: string,
  numberOfPackages: string,
  labelUrl: string,
};
