import React from 'react';
import { Container } from 'rsuite';
import Copyright from '../components/Copyright';

export default function YouAreNotAuthorized() {
  return (
    <Container>
      <h1>
        You are not authorized to access this page.
      </h1>

      <Copyright />
    </Container>
  );
}
