import {
  Button, Form, IconButton, Stack,
} from 'rsuite';
import { Plus } from '@rsuite/icons';
import React from 'react';

interface CrossDockTrackingActionProps {
  isSaving: boolean;
  onAddPackage: () => void;
  onSave: () => void;
  onCancel: () => void;
}

export default function CrossDockTrackingAction({
  isSaving,
  onAddPackage,
  onSave,
  onCancel,
} : CrossDockTrackingActionProps) {
  function handleAddPackage() {
    onAddPackage();
  }

  function handleSave() {
    onSave();
  }

  function handleCancel() {
    onCancel();
  }

  return (
    <Form.Group style={{ marginTop: '20px' }}>
      <Stack justifyContent="space-between">
        <IconButton
          appearance="subtle"
          icon={<Plus />}
          onClick={() => handleAddPackage()}
        >
          Add package
        </IconButton>
        <Stack spacing={12}>
          <Button
            appearance="primary"
            type="submit"
            onClick={() => handleSave()}
            loading={isSaving}
          >
            Save
          </Button>
          <Button
            appearance="ghost"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form.Group>
  );
}
