import React from 'react';
import {
  Button, Message, Panel, Stack,
} from 'rsuite';
import { ProductImportResponse } from '../api/apiTypes';
import { downloadData } from '../utils/downloadData';

export default function ProductUploadResult({ result }: { result: ProductImportResponse }) {
  const successSum = (result.createdProducts ?? 0) + (result.updatedProducts ?? 0)
      + (result.createdVariants ?? 0) + (result.updatedVariants ?? 0);

  let successInfo = null;
  let errorInfo = null;
  if (successSum > 0) {
    successInfo = (
      <Message showIcon type="success" header={`Successfully imported ${successSum} products/variants`}>
        {`Newly created ${result.createdProducts} products and ${result.createdVariants} variants`}
        <br />
        {`Updated ${result.updatedProducts} products and ${result.updatedVariants} variants`}
        <br />

        {(result.draftCount ?? 0) > 0 && (
        <Message type="info" showIcon>
          {` ${result.draftCount} products/variants was saved as DRAFT due to incomplete information`}
        </Message>
        )}

      </Message>
    );
  }

  if ((result.errorCount && result.errorCount > 0) || result.errorCsv) {
    const header = result.errorCount && result.errorCount > 0 ? `There was problem with ${result.errorCount} products/variants`
      : 'There was some problems';

    errorInfo = (
      <Message showIcon type="error" header={header}>
        <Stack className="mt-10" direction="row" spacing={10}>
          {result.errorCsv && (
          <Button
            appearance="primary"
            onClick={() => downloadData(result.errorCsv, 'text/plain', 'import-errors.csv')}
          >
            Download report
          </Button>
          )}
          {result.returnCsv && (
          <Button
            appearance="primary"
            onClick={() => downloadData(result.returnCsv, 'text/plain', 're-import.csv')}
          >
            Download products for re-import
          </Button>
          )}
        </Stack>
      </Message>
    );
  }

  return (
    <Panel className="mt-20" header={`Uploaded products/variants: ${result.productCount}`} bordered>
      <Stack spacing={10} justifyContent="flex-start" alignItems="flex-start">
        {successInfo}

        {errorInfo}
      </Stack>
    </Panel>
  );
}
