import React, { useRef } from 'react';
import {
  Button, ButtonToolbar, Form, Loader, Schema, SelectPicker,
} from 'rsuite';
import _ from 'lodash';
import { Eshop } from '../../../api/eshop/eshop';
import { Carrier, MpiDeliveryTypeLabels } from '../../../api/apiTypes';

const DELIVERY_TYPES = _.map(MpiDeliveryTypeLabels, (label, value) => ({ label, value }));

interface Props {
  eshops: Eshop[];
  carriers: (Omit<Carrier, 'id'> & { id: string })[];
  edit: Edit;
  isLoading: boolean;
  isSaving: boolean;
  onChange: (edit: Edit) => void;
  onSave: () => void;
  onCancel: () => void;
}

interface Edit {
  eshopCode?: string;
  eshopCarrierCode?: string;
  eshopCarrierType?: string;
  name?: string;
  carrierId?: string;
  deliveryType?: string;
  portalName?: string;
}

const validationModel = Schema.Model({
  eshopCode: Schema.Types.StringType().isRequired('Marketplace is required.'),
  eshopCarrierCode: Schema.Types.StringType().isRequired('Carrier code required.'),
  eshopCarrierType: Schema.Types.StringType().isRequired('Carrier delivery type is required.'),
  name: Schema.Types.StringType().isRequired('Delivery name is required.'),
  carrierId: Schema.Types.StringType().isRequired('MPI carrier is required.'),
  deliveryType: Schema.Types.StringType().isRequired('Delivery type is required.'),
});

export default function CarrierSetupAlzaEditForm({
  edit, eshops, carriers, isLoading, isSaving, onChange, onSave, onCancel,
}: Props) {
  const formRef = useRef<any>();

  function handleChange(value: Edit) {
    onChange(value);
  }

  function handleSave() {
    if (!formRef.current?.check()) {
      return;
    }

    onSave();
  }

  function handleCancel() {
    onCancel();
  }

  return (
    <Form
      layout="horizontal"
      formValue={edit}
      ref={formRef}
      model={validationModel}
      onChange={(value) => handleChange(value)}
      fluid
    >
      <Form.Group>
        <Form.ControlLabel>
          Marketplace
        </Form.ControlLabel>
        <Form.Control
          name="eshopCode"
          accepter={SelectPicker}
          data={eshops}
          value={edit.eshopCode ?? null}
          labelKey="name"
          valueKey="code"
          searchable={false}
          cleanable={false}
        />
        <Form.HelpText>Required</Form.HelpText>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Carrier code
        </Form.ControlLabel>
        <Form.Control name="eshopCarrierCode" />
        <Form.HelpText>Required</Form.HelpText>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Carrier delivery type
        </Form.ControlLabel>
        <Form.Control name="eshopCarrierType" />
        <Form.HelpText>Required</Form.HelpText>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Delivery name
        </Form.ControlLabel>
        <Form.Control name="name" />
        <Form.HelpText>Required</Form.HelpText>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          MPI carrier
        </Form.ControlLabel>
        {isLoading ? <Loader /> : (
          <>
            <Form.Control
              name="carrierId"
              accepter={SelectPicker}
              data={carriers}
              value={edit.carrierId ?? null}
              labelKey="identifier"
              valueKey="id"
              cleanable={false}
            />
            <Form.HelpText>Required</Form.HelpText>
          </>
        )}
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Delivery type
        </Form.ControlLabel>
        <Form.Control
          name="deliveryType"
          accepter={SelectPicker}
          data={DELIVERY_TYPES}
          value={edit.deliveryType ?? null}
          searchable={false}
        />
        <Form.HelpText>Required</Form.HelpText>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Portal name
        </Form.ControlLabel>
        <Form.Control name="portalName" />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="primary"
            onClick={() => handleSave()}
            type="submit"
            loading={isSaving}
          >
            Save
          </Button>
          <Button
            appearance="ghost"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}
