import React from 'react';
import {
  Button, Loader, Popover, Stack, Table, Whisper,
} from 'rsuite';
import { format } from 'date-fns';
import { FaExclamationTriangle, FaRegFilePdf } from 'react-icons/fa';
import { AssignedProducts, WarehouseLocality } from '../../../api/warehouse/warehouseApiTypes';
import { downloadData } from '../../../utils/downloadData';

interface FullImportProps {
  warehouseLocality: WarehouseLocality;
  onFullImport: (id: number) => void;
}

function FullImport({ warehouseLocality, onFullImport }: FullImportProps) {
  if (warehouseLocality.isFullImportRunning) {
    return <Loader size="sm" content="Importing" />;
  }

  function handleFullImport() {
    onFullImport(warehouseLocality.id);
  }

  return (
    <Button
      size="sm"
      onClick={(event) => {
        event.stopPropagation();
        handleFullImport();
      }}
    >
      Run full import
    </Button>
  );
}

interface Props {
  warehouseLocalities: WarehouseLocality[];
  assignedProducts: AssignedProducts[];
  isLoading: boolean;
  onSelect: (id: number) => void;
  onFullImport: (id: number) => void;
}

function parseDate(dateString?: string): string {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  return format(date, 'yyyy-MM-dd hh:mm:ss');
}

function ErrorCell({ message } : { message: string | undefined }) {
  if (!message) {
    return null;
  }

  return (
    <Whisper
      trigger="hover"
      placement="autoVerticalStart"
      preventOverflow
      speaker={(
        <Popover
          className="error-message-tooltip"
          arrow={false}
        >
          {message && (
            <div>
              <h3 className="rs-popover-title">Message</h3>
              <p>
                {message}
              </p>
            </div>
          )}
        </Popover>
          )}
    >
      <span>
        <FaExclamationTriangle color="red" cursor="pointer" />
      </span>
    </Whisper>
  );
}

function DownloadProductCodesInLastFullImport(
  { lastFullImportProductCodes } : { lastFullImportProductCodes: string | undefined },
) {
  if (!lastFullImportProductCodes) {
    return null;
  }
  const download = () => downloadData(
    lastFullImportProductCodes,
    'csv',
    'fullimport_file.csv',
  );
  return (
    <FaRegFilePdf
      title="Download product codes in last fullimport"
      cursor="pointer"
      onClick={(event) => {
        download();
        event.stopPropagation();
      }}
    />
  );
}

export default function EshopSetupAlzaBranchesTable({
  warehouseLocalities,
  assignedProducts,
  isLoading,
  onSelect,
  onFullImport,
}: Props) {
  const data = warehouseLocalities
    .map((warehouseLocality) => ({
      ...warehouseLocality,
      numberOfProducts: assignedProducts
        .find((assignedProduct) => assignedProduct.warehouseLocalityId === warehouseLocality.id)
        ?.numberOfProducts,
    }));

  function handleSelect(id: number) {
    onSelect(id);
  }

  function handleFullImport(id: number) {
    onFullImport(id);
  }

  return (
    <Table
      autoHeight
      bordered
      data={data}
      loading={isLoading}
      onRowClick={(value) => handleSelect(value.id)}
    >
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Branch ID</Table.HeaderCell>
        <Table.Cell dataKey="code" />
      </Table.Column>
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Number of products assigned</Table.HeaderCell>
        <Table.Cell dataKey="numberOfProducts" />
      </Table.Column>
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Last successful import</Table.HeaderCell>
        <Table.Cell>
          {(warehouseLocality: WarehouseLocality) => parseDate(warehouseLocality.lastFullImport)}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Products in last full import</Table.HeaderCell>
        <Table.Cell dataKey="lastFullImportProducts" />
      </Table.Column>
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Max handling time</Table.HeaderCell>
        <Table.Cell dataKey="maxHandlingTime" />
      </Table.Column>
      <Table.Column flexGrow={1} align="center" verticalAlign="middle">
        <Table.HeaderCell>{}</Table.HeaderCell>
        <Table.Cell style={{ padding: '0px' }}>
          {(warehouseLocality: WarehouseLocality) => (
            <FullImport
              warehouseLocality={warehouseLocality}
              onFullImport={(value) => handleFullImport(value)}
            />
          )}
        </Table.Cell>
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell>{}</Table.HeaderCell>
        <Table.Cell>
          {(warehouseLocality: WarehouseLocality) => (
            <Stack>
              <ErrorCell message={warehouseLocality.fullImportFailedMessage} />
              <DownloadProductCodesInLastFullImport
                lastFullImportProductCodes={warehouseLocality.lastFullImportProductCodes}
              />
            </Stack>
          )}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
}
