import api from '../base';
import { AssignedProducts, WarehouseLocality, WarehouseSynchronizationResult } from './warehouseApiTypes';
import {
  mockGetAssignedProducts,
  mockGetWarehouseLocality,
  mockWarehouseLocalities,
  mockWarehouseSynchronizationResult,
} from './warehouseMock';

const BASE_PATH = 'management/warehouse-localities';

export async function getWarehouseLocalities(
  eshopCode: string,
  signal?: AbortSignal,
): Promise<WarehouseLocality[]> {
  return api.get<WarehouseLocality[]>(`${BASE_PATH}`, {
    params: {
      eshopCode,
    },
    signal,
  })
    .then(response => response.data);
}

api.mock
  .onGet('management/warehouse-localities')
  .reply(200, mockWarehouseLocalities);

export async function synchronizeWarehouseLocalities(
  eshopCode: string,
): Promise<WarehouseSynchronizationResult> {
  return api.post<WarehouseSynchronizationResult>(`${BASE_PATH}/synchronize`, undefined, {
    params: {
      eshopCode,
    },
  })
    .then(response => response.data);
}

api.mock
  .onPost(/management\/warehouse-localities\/synchronize/)
  .reply(200, mockWarehouseSynchronizationResult);

export async function setDefaultWarehouseLocality(
  id: number,
): Promise<null> {
  const response = await api.post(`${BASE_PATH}/${id}/set-default`);
  return response.data;
}

api.mock
  .onPost(/management\/warehouse-localities\/.+\/set-default/)
  .reply(204, null);

export async function getWarehouseLocality(
  id: number,
  signal?: AbortSignal,
): Promise<WarehouseLocality> {
  const response = await api.get<WarehouseLocality>(`${BASE_PATH}/${id}`, { signal });
  return response.data;
}

api.mock
  .onGet(/management\/warehouse-localities\/.+/)
  .reply(200, mockGetWarehouseLocality);

export async function createWarehouseLocality(
  warehouseLocality: Partial<WarehouseLocality>,
  signal?: AbortSignal,
): Promise<number> {
  const response = await api.post<number>(
    BASE_PATH,
    warehouseLocality,
    {
      transformResponse: (_, headers) => Number(headers?.['X-Entity-Id'.toLocaleLowerCase()]),
      signal,
    },
  );

  return response.data;
}

api.mock
  .onPost('management/warehouse-localities')
  .reply(201, null, { 'X-Entity-Id': '1' });

export async function updateWarehouseLocality(
  id: number,
  warehouseLocality: Partial<WarehouseLocality>,
  signal?: AbortSignal,
): Promise<null> {
  const response = await api.post<null>(`${BASE_PATH}/${id}`, warehouseLocality, { signal });
  return response.data;
}

api.mock
  .onPost(/management\/warehouse-localities\/\d+/)
  .reply(204, null);

export async function getAssignedProducts(
  ids: number[],
  signal?: AbortSignal,
): Promise<AssignedProducts[]> {
  const response = await api.post<AssignedProducts[]>(
    `${BASE_PATH}/assigned-products`,
    { warehouseLocalityIds: ids },
    { signal },
  );
  return response.data;
}

api.mock
  .onPost('management/warehouse-localities/assigned-products')
  .reply(200, mockGetAssignedProducts);

export async function runFullImport(
  id: number,
  signal?: AbortSignal,
): Promise<null> {
  const response = await api.post<null>(
    `${BASE_PATH}/${id}/run-full-import`,
    undefined,
    { signal },
  );
  return response.data;
}

api.mock
  .onPost(/management\/warehouse-localities\/.+\/run-full-import/)
  .reply(204, null);
