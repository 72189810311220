import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  CheckPicker,
  Form,
  Input,
  InputGroup,
  Message,
  Modal,
  Panel,
  Stack,
  Table,
  toaster,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { adminChangePassword, getUsers, updateUserPartners } from '../api/user';
import { AdminUpdatePasswordRequest, UserPartnerEntity, UserWithPartners } from '../api/apiTypes';
import { getPartners, Partner } from '../api/partner';
import { USER_SETUP_CREATE } from '../routes/links';

export default function UsersTable() {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserWithPartners[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<UserWithPartners[]>([]);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState<AdminUpdatePasswordRequest>();

  useEffect(() => {
    getPartners()
      .then(response => {
        setPartners(response.data);
      });
    getUsers()
      .then(response => {
        setUsers(response.data);
        setFilteredUsers(response.data);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  function search() {
    const result = users.filter((user) => {
      const values = [user.id, user.username, user.name];
      const filter = (filterValue.toLowerCase());
      return values
        .map(value => String(value).toLowerCase())
        .some(value => value.includes(filter));
    });

    setFilteredUsers(result);
  }

  function openDialog(id: number) {
    setDialogData({ userId: id, newPassword: '' });
    setShowDialog(true);
  }

  function handleUserPartnerChange(user: UserWithPartners, selectedPartnerCodes: string[]) {
    const userPartnerEntities = partners.filter(p => selectedPartnerCodes
      .includes(p.code)).map(partner => ({
      partnerCode: partner.code,
      partnerName: partner.name,
    }));

    const updatedUsers = users.map(u => {
      if (u.id === user.id) {
        u.partners = userPartnerEntities;
        return u;
      }
      return u;
    });

    setUsers(updatedUsers);
  }

  function updatePartners(user: UserWithPartners) {
    const request = { userId: user.id, partners: user.partners };
    updateUserPartners(request)
      .then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Partners updated!</Message>);
      })
      .catch(() => {
        toaster.push(<Message type="error" closable showIcon duration={2000}>Partners update failed!</Message>);
      });
  }

  function updatePassword(updatePasswordRequest: AdminUpdatePasswordRequest) {
    setShowDialog(false);
    adminChangePassword(updatePasswordRequest)
      .then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Password updated!</Message>);
      })
      .catch(() => {
        toaster.push(<Message type="error" closable showIcon duration={2000}>Password update failed!</Message>);
      });
  }

  const navigate = useNavigate();

  function gotToCreateUser() {
    navigate(USER_SETUP_CREATE);
  }

  const tableCellStyle: React.CSSProperties = {
    overflowX: 'auto',
    overflowY: 'hidden',
    textOverflow: 'clip',
    maxWidth: '250px',
  };

  return (
    <div>
      <Modal open={showDialog}>
        <Form layout="horizontal">
          <Form.Group controlId="partnerCode">
            <Form.ControlLabel>New password</Form.ControlLabel>
            <Form.Control type="password" onChange={(value: string) => setDialogData({ userId: dialogData!.userId, newPassword: value })} name="newPassword" />
          </Form.Group>
          <Form.Group>
            <ButtonToolbar>
              <Button appearance="primary" onClick={() => updatePassword(dialogData!)}>Submit</Button>
              <Button appearance="ghost" onClick={() => setShowDialog(false)}>Cancel</Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </Modal>

      <Panel className="h100p">
        <Stack direction="row" alignItems="flex-start" spacing="30px">
          <InputGroup inside>
            <Input
              style={{ width: 400 }}
              placeholder="search by id, username, name"
              defaultValue={filterValue}
              onChange={setFilterValue}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  search();
                }
              }}
            />
            <InputGroup.Button>
              <SearchIcon onClick={() => search} />
            </InputGroup.Button>
          </InputGroup>

          <Button appearance="primary" onClick={() => gotToCreateUser()}>
            Create new user
          </Button>
        </Stack>
        <Table
          autoHeight
          bordered
          data={filteredUsers}
          loading={loading}
          className="mt-20"
          rowHeight={60}
          width={910}
        >
          <Table.Column width={50} align="center" verticalAlign="middle">
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.Cell dataKey="id" align="center" />
          </Table.Column>
          <Table.Column width={250} align="center" verticalAlign="middle">
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.Cell style={tableCellStyle} dataKey="username" />
          </Table.Column>
          <Table.Column width={250} align="center" verticalAlign="middle">
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell style={tableCellStyle} dataKey="name" />
          </Table.Column>
          <Table.Column width={280} align="center" verticalAlign="middle">
            <Table.HeaderCell>Partners</Table.HeaderCell>
            <Table.Cell style={{ maxWidth: 280 }}>
              {(rowData: UserWithPartners) => (
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>
                  <CheckPicker
                    data={partners}
                    labelKey="name"
                    valueKey="code"
                    block
                    value={rowData.partners.map((p: UserPartnerEntity) => p.partnerCode)}
                    // eslint-disable-next-line max-len
                    onChange={(selectedPartners) => handleUserPartnerChange(rowData, selectedPartners)}
                    onClose={() => updatePartners(rowData)}
                    renderMenu={(menu) => {
                      const allChecked = rowData.partners.length === partners.length;
                      return (
                        <div>
                          <label htmlFor="selectAllPartners" style={{ paddingLeft: '13px' }}>
                            <input
                              id="selectAllPartners"
                              type="checkbox"
                              checked={allChecked}
                              style={{ marginRight: '7px' }}
                              onChange={() => {
                                if (allChecked) {
                                  handleUserPartnerChange(rowData, []);
                                } else {
                                  handleUserPartnerChange(rowData, partners.map(p => p.code));
                                }
                              }}
                            />
                            Select All
                          </label>
                          {menu}
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={80} align="center" verticalAlign="middle">
            <Table.HeaderCell>Password</Table.HeaderCell>
            <Table.Cell>
              {rowData => (
                <FontAwesomeIcon
                  icon={faKey}
                  onClick={() => openDialog(rowData.id)}
                  style={{ width: 20, height: 20 }}
                />
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      </Panel>
    </div>
  );
}
