import React, { ReactNode, useReducer } from 'react';
import { OrderImportResult } from '../../api/apiTypes';
import globalHandleError from '../../api/apiErrorHandle';
import OrderImportUploadResultDialog from './OrderImportUploadResultDialog';
import FileUpload from '../FileUpload';

interface Props {
  children: ReactNode;
}

interface State {
  result?: OrderImportResult;
  state: 'ready' | 'loading' | 'success';
}

const defaultState: State = {
  result: undefined,
  state: 'ready',
};

type ResetAction = { type: 'reset' };
type ImportAction = { type: 'import' };
type ResultSetAction = { type: 'result/set', payload: OrderImportResult };
type Action = ResetAction | ImportAction | ResultSetAction ;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'reset':
      return { ...state, state: 'ready', result: undefined };
    case 'import':
      return { ...state, state: 'loading' };
    case 'result/set':
      return { ...state, state: 'success', result: action.payload };
    default:
      return state;
  }
}

export default function OrderImportUpload({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  function handleError(error: Error) {
    globalHandleError(error);
    dispatch({ type: 'reset' });
  }

  function handleSuccess(result: OrderImportResult) {
    dispatch({ type: 'result/set', payload: result });
  }

  function handleClose() {
    dispatch({ type: 'reset' });
  }

  return (
    <>
      <FileUpload
        path="management/orders/import"
        onError={(error) => handleError(error)}
        onSuccess={(response: OrderImportResult) => handleSuccess(response)}
      >
        {children}
      </FileUpload>
      <OrderImportUploadResultDialog
        result={state.result}
        onClose={() => handleClose()}
      />
    </>
  );
}
