import countries from '../resources/countries.json';
import provinces from '../resources/provinces.json';

export interface Country {
  name: string;
  code: string;
}

export interface Provinces {
  code: string;
  values: string[],
}

export const ALL_COUNTRIES: Country[] = countries;

export function getProvinces(countryCode: string): Provinces | undefined {
  return provinces.find((province) => province.code === countryCode);
}
