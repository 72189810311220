import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Stack } from 'rsuite';
import { createLabelSimple } from '../../api/orders';
import { notificationSuccess } from '../../services/notificationActions';
import { downloadData } from '../../utils/downloadData';

interface SimpleLabelSubmitProps {
  orderId: number,
  afterGenerate: (url: string)=>void
}

function SimpleLabelSubmit({ orderId, afterGenerate }: SimpleLabelSubmitProps) {
  const [loading, setLoading] = useState(false);
  const [numberOfPackages, setNumberOfPackages] = useState(1);

  const invalidPackages = !numberOfPackages || numberOfPackages === 0;

  const handleNumberOfPackagesChange = (
    value: number | string,
  ) => {
    setNumberOfPackages(+value);
  };

  const handleGenerate = async () => {
    setLoading(true);

    try {
      const label = await createLabelSimple(orderId, numberOfPackages);
      downloadData(label.data, 'application/pdf', `${orderId}.pdf`);
      notificationSuccess('Label generated');
      afterGenerate(label.headers.location);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { setNumberOfPackages(1); }, [orderId]);

  return (
    <Stack spacing={2} className="mt-5">
      Number of packages
      <InputNumber
        min={1}
        value={numberOfPackages}
        onChange={handleNumberOfPackagesChange}
      />
      <Button
        loading={loading}
        disabled={invalidPackages}
        appearance="primary"
        onClick={handleGenerate}
      >
        Generate
      </Button>
    </Stack>
  );
}

export default SimpleLabelSubmit;
