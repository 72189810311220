import styled from '@emotion/styled';
import { Button } from 'rsuite';

const CardActionImportGuide = styled(Button)(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 200,
    height: 75,
    padding: '10px 0px',
    textWrap: 'wrap',
  },
);

export default CardActionImportGuide;
