import { AllegroLinkingInfo, UserWithPartners } from '../apiTypes';

export const mockUserData: String[] = [
  'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoxLCJ1c2VybmFtZSI6InNhbGVzY29ubmVjdC1hZG1pbmlzdHJhdG9yIiwibmFtZSI6IlNhbGVzY29uZWN0IEFkbWluaXN0cmF0b3IiLCJyb2xlcyI6WyJTQUxFU0NPTk5FQ1RfQURNSU4iXSwicGFydG5lckNvZGUiOiJQYXJ0bmVyIiwicGFydG5lck5hbWUiOiJQYXJ0bmVyIn19.CDiWkrZmh1IFLrfXQfDsfNdYUtJKELCdbtz9t3CvlYA',
  'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoyLCJ1c2VybmFtZSI6InBhcnRuZXItYWRtaW4iLCJuYW1lIjoiUGFydG5lciBBZG1pbiIsInJvbGVzIjpbIlBBUlRORVJfQURNSU4iXSwicGFydG5lckNvZGUiOiIwMDAxIiwicGFydG5lck5hbWUiOiJNZWdhUGFydG5lciJ9fQ.DjdaT2lFRILPXZeG-EFPAiKe09deX6DeS906TtoHz_Y',
  'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjozLCJ1c2VybmFtZSI6InBhcnRuZXItdXNlciIsIm5hbWUiOiJQYXJ0bmVyIFVzZXIiLCJyb2xlcyI6WyJQQVJUTkVSX1VTRVIiXSwicGFydG5lckNvZGUiOiIwMDAyIiwicGFydG5lck5hbWUiOiJBbm90aGVyIFBhcnRuZXIifX0.U71gCzP3zCs_rAGBXAGY0ja11Yy67CIt_GIWjft7uNA',
  'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjozLCJ1c2VybmFtZSI6InBhcnRuZXItd2FyZWhvdXNlLW9wZXJhdGlvbnMiLCJuYW1lIjoiUGFydG5lciBXYXJlaG91c2UgT3BlcmF0aW9ucyIsInJvbGVzIjpbIlBBUlRORVJfV0FSRUhPVVNFX09QRVJBVElPTlMiXSwicGFydG5lckNvZGUiOiIwMDA0IiwicGFydG5lck5hbWUiOiJQYXJ0bmVyIDQifX0.IM_K-RjAnS5Y1D8QeHEnXD0AyoSNpDgOG7AwNHxfIWk',
];

export const mockUserDataDecoded: UserWithPartners[] = [
  {
    id: 1,
    username: 'admin',
    name: 'Administrator',
    roles: [
      'SALESCONNECT_ADMIN',
      'PARTNER_ADMIN',
    ],
    partners: [
      {
        partnerCode: 'Partner',
        partnerName: 'Partner',
      },
      {
        partnerCode: '0002',
        partnerName: 'Shumee',
      },
    ],
  },
  {
    id: 2,
    username: 'user',
    name: 'Nice Name',
    roles: [
      'partner',
    ],
    partners: [
      {
        partnerCode: '0002',
        partnerName: 'Shumee',
      },
    ],
  },
];

export const allegroLinkResponseMock: AllegroLinkingInfo[] = [
  {
    linked: false,
    linkUrl: 'http://url-to-allegro.com/auth/oauth/authorize?response_type=code&client_id=client-id&redirect_uri=https://mpi-test2.duckdns.org/v1/allegro/account-link/Partner1/ALLEGRO.CZ',
    eshopCode: 'ALLEGRO.CZ',
    allegroUser: null,
  },
  {
    linked: true,
    linkUrl: null,
    eshopCode: 'ALLEGRO.PL',
    allegroUser: {
      id: '123456',
      login: 'FerdaM',
      firstName: 'Ferda',
      lastName: 'Mravenec',
      email: 'ferda.mravenec@codingbear.cz',
      company: {
        name: 'Mraveniste, s.r.o',
        tax: '121345645',
      },
      baseMarketplace: {
        id: 'allegero-pl',
      },
    },
  },
];
