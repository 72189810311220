import React, {
  forwardRef, Ref, useEffect, useImperativeHandle, useReducer, useState,
} from 'react';
import {
  Button, Loader, Message, Modal, Nav, Stack, toaster,
} from 'rsuite';
import { AxiosResponse } from 'axios';
import { useStoreState } from '../store/hooks';
import { SyncPlatformIdResponse, syncPlatformIdsForEshop } from '../api/baselinker/baselinker';
import { downloadData } from '../utils/downloadData';

// Result
interface ResultProps {
  responses: SyncPlatformIdResponse[]
}

function Result({ responses }: ResultProps) {
  const [active, setActive] = useState<SyncPlatformIdResponse>();

  useEffect(() => {
    if (!responses[0]) {
      return;
    }
    setActive(responses[0]);
  }, [responses]);

  function splitResponses(): SyncPlatformIdResponse[][] {
    const split: SyncPlatformIdResponse[][] = [];
    const size = 5;
    for (let index = 0; index < responses.length; index += size) {
      split.push(responses.slice(index, index + size));
    }
    return split;
  }

  function downloadReport() {
    if (!active) {
      return;
    }
    const { csvResponse, eshopCode } = active;
    downloadData(csvResponse, 'text/csv', `${eshopCode}SyncIdsReport.csv`);
  }

  function numberOfProducts() {
    return active?.productsToSync ?? 0;
  }

  function numberOfFoundProducts() {
    return numberOfProducts() - (active?.productsNotFoundInBl ?? 0);
  }

  function responseTabItem(response: SyncPlatformIdResponse) {
    return (
      <Nav.Item
        onSelect={() => setActive(response)}
        eventKey={response.eshopCode}
        key={response.eshopCode}
      >
        {response.eshopCode}
      </Nav.Item>
    );
  }

  function responsesTabs(split: SyncPlatformIdResponse[], index: number) {
    return (
      <Nav appearance="subtle" activeKey={active?.eshopCode} key={index}>
        {split.map(responseTabItem)}
      </Nav>
    );
  }

  function responsesNav() {
    const split = splitResponses();
    return (
      <Stack direction="column" alignItems="flex-start">
        {split.map(responsesTabs)}
      </Stack>
    );
  }

  function responseContent(response: SyncPlatformIdResponse) {
    return (
      response.error
        ? <Message type="error">{response.error}</Message>
        : (
          <Stack direction="column" spacing={12}>
            <span>{`${numberOfFoundProducts()} from ${numberOfProducts()} products were found`}</span>
            <Button appearance="ghost" onClick={() => downloadReport()}>Download full report</Button>
          </Stack>
        )
    );
  }

  return (
    <Stack direction="column" spacing={12}>
      {responsesNav()}
      {active && responseContent(active)}
    </Stack>
  );
}

// Progress
function Progress() {
  return (
    <Stack spacing={12} direction="column">
      Synchronizing BaseLinker identifiers
      <Loader size="md" />
    </Stack>
  );
}

// Component
interface PlatformIdSyncProps {
  eshopCodes: string[];
}

export type PlatformIdSyncRefType = {
  synchronize: (remapRequired: boolean) => void;
};

interface State {
  isOpen: boolean,
  isSynchronizing: boolean;
  responses: SyncPlatformIdResponse[];
  error?: string
}

const initialState: State = {
  isOpen: false,
  isSynchronizing: false,
  responses: [],
};

type SynchronizeAction = { type: 'synchronize' };
type SynchronizeSuccessAction = { type: 'synchronizeSuccess', payload: SyncPlatformIdResponse[] };
type SynchronizeFailureAction = { type: 'synchronizeFailure', payload: string };
type CloseAction = { type: 'close' };
type Action = SynchronizeAction | SynchronizeSuccessAction | SynchronizeFailureAction | CloseAction;

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'synchronize':
      return {
        ...state,
        isOpen: true,
        isSynchronizing: true,
        responses: [],
        error: undefined,
      };
    case 'synchronizeSuccess':
      return {
        ...state,
        isSynchronizing: false,
        responses: action.payload,
      };
    case 'synchronizeFailure':
      toaster.push(<Message type="error" closable showIcon duration={4000}>{action.payload}</Message>);
      return {
        ...state,
        isOpen: false,
        isSynchronizing: false,
        error: action.payload,
      };
    case 'close':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

function Component({ eshopCodes }: PlatformIdSyncProps, ref: Ref<PlatformIdSyncRefType>) {
  const { currentUser } = useStoreState(state => state.user);
  const [state, dispatch] = useReducer(reducer, initialState);

  function syncProductPlatformIds(
    eshopCode: string,
    remapRequired: boolean,
  ): Promise<AxiosResponse<SyncPlatformIdResponse>> {
    return syncPlatformIdsForEshop({
      partnerCode: currentUser.partnerCode,
      inventoryId: null,
      eshopCode: eshopCode as string,
      remapRequired,
    });
  }

  function syncAllProductPlatformIds(remapRequired: boolean) {
    dispatch({ type: 'synchronize' });
    Promise
      .all(eshopCodes.map((eshopCode) => syncProductPlatformIds(eshopCode, remapRequired)))
      .then(responses => dispatch({ type: 'synchronizeSuccess', payload: responses.map((response) => response.data) }))
      .catch(error => dispatch({ type: 'synchronizeFailure', payload: error.response?.data?.details }));
  }

  useImperativeHandle(ref, () => ({ synchronize: syncAllProductPlatformIds }));

  return (
    <Modal open={state.isOpen} size="sm">
      {
        state.isSynchronizing
          ? <Progress />
          : (
            <Stack direction="column" spacing={12}>
              <Result responses={state.responses} />
              <Button appearance="primary" onClick={() => dispatch({ type: 'close' })}>Close</Button>
            </Stack>
          )
      }
    </Modal>
  );
}

const PlatformIdSyncExecution = forwardRef<PlatformIdSyncRefType, PlatformIdSyncProps>(Component);
export default PlatformIdSyncExecution;
