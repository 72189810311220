import React, { useEffect, useState } from 'react';
import {
  Col, DatePicker, Grid, Panel, Row, Stack, Table,
} from 'rsuite';
import { FaFileContract } from 'react-icons/fa';
import api from '../api/base';
import { ORDERS_URL } from '../api/orders';
import { toDateApiFormat } from '../services/dateUtils';

type Shipment = {
  branch: string,
  carrier: string,
  deliveryTypes: string[]
  shippingListId: number,
  numberOfOrders: number,
  shippingListUrl: string,
};

function getShipmentsMock() {
  const shipmentsMock: Shipment[] = JSON.parse('[\n'
      + '  {\n'
      + '    "branch": "1170",\n'
      + '    "carrier": "GEISPARCEL",\n'
      + '    "deliveryTypes": [\n'
      + '      "B2C",\n'
      + '      "B2B"\n'
      + '    ],\n'
      + '    "shippingListId": 210070,\n'
      + '    "numberOfOrders": 2,\n'
      + '    "shippingListUrl": "https://alza.com/shiping-list/210070"\n'
      + '  },\n'
      + '  {\n'
      + '    "branch": "1238",\n'
      + '    "carrier": "GEISPARCEL",\n'
      + '    "deliveryTypes": [\n'
      + '      "Branch"\n'
      + '    ],\n'
      + '    "shippingListId": 210071,\n'
      + '    "numberOfOrders": 1,\n'
      + '    "shippingListUrl": "https://alza.com/shiping-list/210071"\n'
      + '  }\n'
      + ']');
  return shipmentsMock;
}

export default function Shipments() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [shipments, setShipments] = useState<Shipment[]>([]);

  function fetchShipments() {
    api.get<Shipment[]>(`${ORDERS_URL}/shipments/${toDateApiFormat(selectedDate)}`).then(response => {
      setShipments(response.data);
    });
  }

  useEffect(() => {
    api.mock.onGet(`${ORDERS_URL}/shipments/${toDateApiFormat(selectedDate)}`).reply(200, getShipmentsMock());
  }, []);

  useEffect(() => {
    fetchShipments();
  }, [selectedDate]);

  return (
    <Panel className="h100p">
      <Grid fluid className="h100p">
        <Row>
          <Col xs={24}>
            <Stack spacing={6}>
              Shipments happening on:
              <DatePicker format="yyyy-MM-dd" size="lg" value={selectedDate} onSelect={setSelectedDate} ranges={[]} />
            </Stack>
          </Col>
        </Row>
        <Row className="table-container">
          <Col className="mt-20 h100p" xs={24}>
            <Table data={shipments} height={400} fillHeight>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Branch</Table.HeaderCell>
                <Table.Cell dataKey="branch" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Carrier</Table.HeaderCell>
                <Table.Cell>
                  {rowData => {
                    const { carrier, deliveryTypes } = rowData as Shipment;
                    return `${carrier} (${deliveryTypes.join(', ')})`;
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Shipping list id</Table.HeaderCell>
                <Table.Cell dataKey="shippingListId" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Number of orders</Table.HeaderCell>
                <Table.Cell dataKey="numberOfOrders" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Shipping list URL</Table.HeaderCell>
                <Table.Cell>
                  {rowData => {
                    const { shippingListUrl } = rowData;
                    return shippingListUrl && (
                    <FaFileContract
                      title="Download shipping list"
                      cursor="pointer"
                      onClick={() => window.open(shippingListUrl)}
                    />
                    );
                  }}
                </Table.Cell>
              </Table.Column>
            </Table>
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
