import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, CheckPicker, Col, FlexboxGrid, Input, InputNumber, Stack,
} from 'rsuite';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { HoldStatus, ProductStates } from '../../api/apiTypes';

function getHoldStatusesData(): Record<string, any>[] {
  const labels: { [key in HoldStatus]: string } = {
    [HoldStatus.ON_HOLD]: 'On hold',
    [HoldStatus.NOT_ON_HOLD]: 'Not on hold',
  };

  return Object.values(HoldStatus)
    .sort((a, b) => a.localeCompare(b))
    .map(status => ({ value: status, label: labels[status] }));
}

export default function ProductFilter({ visible }:{ visible: boolean }) {
  const {
    fetchEshops,
    fetchCategories, fetchBrands,
  } = useStoreActions(actions => actions.catalogs);
  const { setFilter } = useStoreActions(actions => actions.products);
  const eshops = useStoreState(state => state.catalogs.eshops);
  const categories = useStoreState(state => state.catalogs.categories);
  const brands = useStoreState(state => state.catalogs.brands);

  const currentFilter = useStoreState(state => state.products.currentFilter);
  const selectedApiGroup = useStoreState(state => state.products.apiGroup);

  const { filter } = currentFilter;
  const [selectedStates, setSelectedStates] = useState<ProductStates[]>(filter.states);
  const [selectedEshops, setSelectedEshops] = useState<number[]>(filter.eshopIds);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(filter.categories);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(filter.brands);
  const [priceFrom, setPriceFrom] = useState<number | string>(filter.priceRange?.from ?? '');
  const [priceTo, setPriceTo] = useState<number | string>(filter.priceRange?.to ?? '');
  const [stockFrom, setStockFrom] = useState<number | string>(filter.stockRange?.from ?? '');
  const [stockTo, setStockTo] = useState<number | string>(filter.stockRange?.to ?? '');
  const [weightFrom, setWeightFrom] = useState<number | string>(filter.weightRange?.from ?? '');
  const [weightTo, setWeightTo] = useState<number | string>(filter.weightRange?.to ?? '');
  const [sku, setSku] = useState(filter.productCode ?? '');
  const [ean, setEan] = useState(filter.ean ?? '');
  const [title, setTitle] = useState(filter.title ?? '');
  const [onlyErrors, setOnlyErrors] = useState(filter.onlyErrors ?? false);
  const [handlingTimeFrom, setHandlingTimeFrom] = useState<number | string>(filter?.handlingTimeRange?.from ?? '');
  const [handlingTimeTo, setHandlingTimeTo] = useState<number | string>(filter?.handlingTimeRange?.to ?? '');
  const [onlyForcedPrice, setOnlyForcedPrice] = useState<boolean>(filter.onlyForcedPrice ?? false);
  const [holdStatuses, setHoldStatuses] = useState<HoldStatus[]>(filter.holdStatuses);

  useEffect(() => {
    fetchEshops();
    fetchBrands();
    fetchCategories();
  }, [fetchEshops, fetchBrands, fetchCategories]);

  useEffect(() => {
    setSelectedEshops([]);
  }, [selectedApiGroup]);

  const states = Object.values(ProductStates)
    .sort((a, b) => a.localeCompare(b))
    .map(s => ({ value: s, label: s }));

  const applyFilters = () => {
    const global = filter.globalFilter;

    const newFilter = {
      ...currentFilter,
      page: 1,
      filter: {
        globalFilter: global,
        states: selectedStates,
        eshopIds: selectedEshops,
        categories: selectedCategories,
        brands: selectedBrands,
        ean,
        productCode: sku,
        title,
        priceRange: {
          from: priceFrom !== '' ? +priceFrom : null,
          to: priceTo !== '' ? +priceTo : null,
        },
        stockRange: {
          from: stockFrom !== '' ? +stockFrom : null,
          to: stockTo !== '' ? +stockTo : null,
        },
        weightRange: {
          from: weightFrom !== '' ? +weightFrom : null,
          to: weightTo !== '' ? +weightTo : null,
        },
        onlyErrors,
        handlingTimeRange: {
          from: handlingTimeFrom !== '' ? +handlingTimeFrom : null,
          to: handlingTimeTo !== '' ? +handlingTimeTo : null,
        },
        onlyForcedPrice,
        holdStatuses,
      },
    };
    setFilter(newFilter);
  };

  const clearFilters = () => {
    setSelectedEshops([]);
    setSelectedBrands([]);
    setSelectedCategories([]);
    setSelectedStates([]);
    setEan('');
    setSku('');
    setTitle('');
    setPriceFrom('');
    setPriceTo('');
    setStockFrom('');
    setStockTo('');
    setWeightFrom('');
    setWeightTo('');
    setOnlyErrors(false);
    setHandlingTimeFrom('');
    setHandlingTimeTo('');
    setOnlyForcedPrice(false);
    setHoldStatuses([]);

    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        globalFilter: filter.globalFilter,
        states: [],
        eshopIds: [],
        categories: [],
        brands: [],
        ean: '',
        productCode: '',
        title: '',
        priceRange: undefined,
        stockRange: undefined,
        weightRange: undefined,
        onlyErrors: false,
        handlingTimeRange: undefined,
        onlyForcedPrice: false,
        holdStatuses: [],
      },
    });
  };

  if (!visible) {
    return null;
  }

  return (
    <FlexboxGrid align="middle" justify="start" className="flex-box-filters mt-10">
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Marketplace
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          data={eshops.filter(e => e.apiGroup === selectedApiGroup)}
          labelKey="name"
          valueKey="id"
          searchable={false}
          block
          value={selectedEshops}
          onChange={setSelectedEshops}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        SKU
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Input
          value={sku}
          onChange={setSku}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Category
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          style={{ width: 250 }}
          data={categories}
          virtualized
          labelKey="externalId"
          valueKey="externalId"
          value={selectedCategories}
          onChange={setSelectedCategories}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Price
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Stack spacing={5}>
          <InputNumber value={priceFrom} onChange={setPriceFrom} min={0} />
          <span>-</span>
          <InputNumber value={priceTo} onChange={setPriceTo} min={0} />
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Status
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          searchable={false}
          data={states}
          block
          value={selectedStates}
          onChange={setSelectedStates}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        EAN
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Input
          value={ean}
          onChange={setEan}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Brand
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          style={{ width: 250 }}
          data={brands}
          virtualized
          labelKey="externalId"
          valueKey="externalId"
          value={selectedBrands}
          onChange={setSelectedBrands}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8} />
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Checkbox
          onChange={(_, checked) => setOnlyForcedPrice(checked)}
          checked={onlyForcedPrice}
        >
          Only products with forced price
        </Checkbox>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Hold
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          searchable={false}
          data={getHoldStatusesData()}
          block
          value={holdStatuses}
          onChange={setHoldStatuses}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Title
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Input value={title} onChange={setTitle} />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8} />
      <FlexboxGrid.Item as={Col} lg={4} colspan={16} />
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Stock
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Stack spacing={5}>
          <InputNumber value={stockFrom} onChange={setStockFrom} min={0} />
          <span>-</span>
          <InputNumber value={stockTo} onChange={setStockTo} min={0} />
        </Stack>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} lg={4} colspan={24}>
        <Checkbox
          onChange={(v, checked) => setOnlyErrors(checked)}
          checked={onlyErrors}
        >
          Only products with error
        </Checkbox>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={6} colspan={24}>
        <Stack spacing={10} alignItems="flex-end" justifyContent="flex-end">
          <Button appearance="primary" style={{ width: 80 }} onClick={applyFilters}>Apply</Button>
          <Button appearance="primary" style={{ width: 80 }} onClick={clearFilters}>Clear</Button>
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={8} />
      <FlexboxGrid.Item as={Col} lg={4} colspan={16} />
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Weight
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Stack spacing={5}>
          <InputNumber value={weightFrom} onChange={setWeightFrom} min={0} />
          <span>-</span>
          <InputNumber value={weightTo} onChange={setWeightTo} min={0} />
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} />
      <FlexboxGrid.Item as={Col} lg={16} />
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Handling time
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Stack spacing={5}>
          <InputNumber value={handlingTimeFrom} onChange={setHandlingTimeFrom} min={0} />
          <span>-</span>
          <InputNumber value={handlingTimeTo} onChange={setHandlingTimeTo} min={0} />
        </Stack>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
