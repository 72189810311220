import React from 'react';
import { Divider } from 'rsuite';
import EshopSetupDetail, { Input, Labels } from '../EshopSetupDetail';
import { Eshop } from '../../../api/eshop/eshop';
import EshopSetupAlzaBranches from './EshopSetupAlzaBranches';
import EshopSetupCrossDock from '../EshopSetupCrossDock';

interface Props {
  eshop: Eshop;
  onUpdate: () => void
}

const labels: Labels = {
  clientKey: 'Supplier ID',
  clientSecret: 'Secret Key',
  stockThreshold: 'Stock threshold',
  expeditionDays: 'Expedition days',
  fixedFee: '',
  dynamicBranchId: 'Dynamic change of branch ID',
  substitutedPhoneNumber: 'Substitute for an empty phone number',
};

const inputs: Input[] = [
  Input.SYNCHRONIZATION,
  Input.CLIENT_KEY,
  Input.CLIENT_SECRET,
  Input.STOCK_THRESHOLD,
  Input.EXPEDITION_DAYS,
  Input.DYNAMIC_BRANCH_ID,
  Input.SUBSTITUTED_PHONE_NUMBER,
];

export default function EshopSetupAlza({ eshop, onUpdate }: Props): JSX.Element {
  return (
    <>
      <EshopSetupDetail
        eshop={eshop}
        labels={labels}
        inputs={inputs}
        onSuccessfulSave={onUpdate}
      />
      <Divider>Branches</Divider>
      <EshopSetupAlzaBranches eshop={eshop} />
      <Divider>Cross dock</Divider>
      <EshopSetupCrossDock eshop={eshop} />
    </>
  );
}
