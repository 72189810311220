import React from 'react';
import { Divider } from 'rsuite';
import { Eshop } from '../../../api/eshop/eshop';
import EshopSetupDetail, { Labels } from '../EshopSetupDetail';
import EshopSetupKauflandTransportMapping from './EshopSetupKauflandTransportMapping';
import EshopSetupKauflandWarehouses from './EshopSetupKauflandWarehouses';
import EshopSetupCrossDock from '../EshopSetupCrossDock';

interface Props {
  eshop: Eshop;
  onUpdate: () => void
}

const labels: Labels = {
  clientKey: 'Client key',
  clientSecret: 'Client secret',
  stockThreshold: 'Stock threshold',
  expeditionDays: 'Expedition days',
  fixedFee: '',
  dynamicBranchId: '',
  substitutedPhoneNumber: 'Substitute for an empty phone number',
};

export default function EshopSetupKaufland({ eshop, onUpdate }: Props) {
  return (
    <>
      <EshopSetupDetail eshop={eshop} labels={labels} onSuccessfulSave={onUpdate} />
      <Divider>Warehouses</Divider>
      <EshopSetupKauflandWarehouses eshop={eshop} />
      <Divider>Shipping groups</Divider>
      <EshopSetupKauflandTransportMapping eshop={eshop} />
      <Divider>Cross dock</Divider>
      <EshopSetupCrossDock eshop={eshop} />
    </>
  );
}
