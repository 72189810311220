import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from 'rsuite';
import EshopSetupAllegroAddressEdit from '../components/EshopSetup/Allegro/EshopSetupAllegroAddressEdit';
import { ApiGroup } from '../api/model/ApiGroup';
import EshopSetupAlzaBranchEdit from '../components/EshopSetup/Alza/EshopSetupAlzaBranchEdit';

interface ContextProps {
  apiGroup: ApiGroup;
  eshopCode: string;
  warehouseLocalityId?: number;
}

function Context(
  { apiGroup, eshopCode, warehouseLocalityId }: ContextProps,
): JSX.Element | null {
  switch (apiGroup) {
    case ApiGroup.ALLEGRO:
      return (
        <EshopSetupAllegroAddressEdit
          warehouseLocalityId={warehouseLocalityId}
          eshopCode={eshopCode}
        />
      );
    case ApiGroup.ALZA:
      return (
        <EshopSetupAlzaBranchEdit
          warehouseLocalityId={warehouseLocalityId}
          eshopCode={eshopCode}
        />
      );
    default:
      return null;
  }
}

Context.defaultProps = {
  warehouseLocalityId: undefined,
};

export default function WarehouseLocalityEdit(): JSX.Element | null {
  const { apiGroup, eshopCode, warehouseLocalityId } = useParams();
  const parsedWarehouseLocalityId = warehouseLocalityId ? Number(warehouseLocalityId) : undefined;

  if (!eshopCode || !apiGroup || !(apiGroup in ApiGroup)) {
    return null;
  }

  let title = `${parsedWarehouseLocalityId ? 'Edit' : 'Add'}`;
  if (apiGroup === ApiGroup.ALLEGRO) {
    title += ` dispatch address for ${eshopCode}`;
  }
  if (apiGroup === ApiGroup.ALZA) {
    title += ` branch for ${eshopCode}`;
  }

  return (
    <div className="m-10" style={{ marginBottom: 150 }}>
      <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
        <h4>{title}</h4>
        <Context
          apiGroup={apiGroup as ApiGroup}
          eshopCode={eshopCode}
          warehouseLocalityId={parsedWarehouseLocalityId}
        />
      </Stack>
    </div>
  );
}
