import { FaCopy } from 'react-icons/fa';
import React, { ForwardedRef, useRef } from 'react';
import { Tooltip, Whisper, WhisperInstance } from 'rsuite';

const RefWhisper = React.forwardRef((
  { onCopy }: { onCopy: any },
  ref : ForwardedRef<WhisperInstance>,
) => (
  <Whisper
    ref={ref}
    trigger="none"
    placement="right"
    speaker={<Tooltip className="success-tooltip" arrow={false}>Copied to clipboard.</Tooltip>}
  >
    <span>
      <FaCopy
        cursor="pointer"
        onClick={(event) => {
          event.stopPropagation();
          onCopy();
        }}
      />
    </span>
  </Whisper>
));

export default function CopyToClipboardIcon({ text }: { text: string | undefined }) {
  const whisperRef = useRef<WhisperInstance>(null);

  const copyToClipboard = () => {
    if (!text) {
      return;
    }

    navigator.clipboard.writeText(text).then(() => {
      whisperRef.current?.open();
      whisperRef.current?.close(750);
    });
  };

  if (!text || text.trim() === '') {
    return <span />;
  }

  return <RefWhisper ref={whisperRef} onCopy={copyToClipboard} />;
}
