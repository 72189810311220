import dayjs from 'dayjs';
import { DateRange } from 'rsuite/cjs/DateRangePicker';
import { ApiDateRange } from '../api/apiTypes';

function toDate(str: string | undefined | null) {
  if (!str) {
    return null;
  }

  return dayjs(str, 'YYYY-MM-DD');
}

export function toDateFormat(str: string | undefined | null) {
  if (!str) {
    return '';
  }
  return dayjs(str, 'YYYY-MM-DD').format('DD.MM.YYYY');
}

export function toDateApiFormat(date: Date) {
  if (!date) {
    return '';
  }
  return dayjs(date).format('YYYY-MM-DD');
}

export function toDateTimeFormat(str: string | undefined | null) {
  if (!str) {
    return '';
  }
  return dayjs(str).format('DD.MM.YYYY HH:mm');
}

export function lastWeekRange() :DateRange {
  const dayOfWeek = dayjs().day();
  const start = dayjs().subtract(dayOfWeek + 6, 'day');
  const end = start.add(6, 'day');

  return [start.toDate(), end.toDate()];
}

export function lastMonthRange() :DateRange {
  const start = dayjs().subtract(1, 'month').date(1);
  const end = start.add(1, 'month').subtract(1, 'day');

  return [start.toDate(), end.toDate()];
}

export function toApiDateRange(range: DateRange | null | undefined) : ApiDateRange | undefined {
  if (!range) {
    return undefined;
  }

  return {
    from: dayjs(range[0]).format('YYYY-MM-DD'),
    to: dayjs(range[1]).format('YYYY-MM-DD'),
  };
}

export function fromApiDateRange(apiRange: ApiDateRange | null | undefined) : DateRange | null {
  if (!apiRange) {
    return null;
  }
  const from = toDate(apiRange.from);
  const to = toDate(apiRange.to);

  if (from && to) {
    return [from.toDate(), to.toDate()];
  }
  return null;
}
