import React, { useState } from 'react';

import {
  Col, Grid, Nav, Panel, Row,
} from 'rsuite';
import CreateLabel from './CreateLabel';
import PrintExistingLabels from '../components/Labels/PrintExistingLabels';

function TabContent({ activeKey } : { activeKey:string }) {
  if (activeKey === 'tabRequest') {
    return <div />;
  }
  if (activeKey === 'tabPrintExisting') {
    return <PrintExistingLabels />;
  }

  if (activeKey === 'tabCreateOne') {
    return <CreateLabel />;
  }

  return null;
}

export default function ShippingLabels() {
  const [activeTab, setActiveTab] = useState('tabPrintExisting');

  return (
    <Panel className="h100p">
      <Grid fluid className="h100p">
        <Row>
          <Col xs={24}>
            <Nav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
              <Nav.Item eventKey="tabRequest" disabled>Request labels</Nav.Item>
              <Nav.Item eventKey="tabPrintExisting">Print existing labels</Nav.Item>
              <Nav.Item eventKey="tabCreateOne">Create single label</Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="print-order-container">
            <TabContent activeKey={activeTab} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
