import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ButtonToolbar, Divider, Form, Message, Schema, Stack, useToaster,
} from 'rsuite';
import { Icon } from '@rsuite/icons';
import ReloadIcon from '@rsuite/icons/Reload';
import { useNavigate } from 'react-router-dom';
import { getInventories } from './BaselinkerClient';
import { getEshopCodesUsingBaselinkerDefaultConfig } from '../api/baselinker/baselinker';
import { WarehouseType } from '../api/baselinker/apiTypes';
import { getIntegrationInfo, saveIntegrationInfo } from '../api/integrationInfo';
import CustomFieldsForm from './CustomFieldsForm';
import BasicInformationForm from './BasicInformationForm';
import OrderStatusesForm from './OrderStatusesForm';
import PlatformIdSync from './PlatformIdSync';

export default function BaseLinkerIntegrationForm({ onSave }: any) {
  const navigate = useNavigate();
  const toaster = useToaster();
  const formRef = useRef<any>();
  const [blLinked, setBlLinked] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<Record<string, any>>({
    blApiToken: '',
    warehouseType: WarehouseType.INTERNAL,
  });
  const [eshopCodes, setEshopCodes] = useState<string[]>([]);

  function linkBl() {
    getInventories(formValue.blApiToken)
      .then(() => {
        setBlLinked(true);
        toaster.push(<Message type="success" closable showIcon duration={2000}>Connected to BaseLinker</Message>);
      })
      .catch((error: any) => {
        setBlLinked(false);
        toaster.push(<Message type="error" closable showIcon duration={3500}>{error.message}</Message>);
      });
  }

  useEffect(() => {
    formRef.current?.checkForField('blApiToken');
  }, [blLinked]);

  useEffect(() => {
    getIntegrationInfo().then(info => {
      setFormValue(prevState => ({
        ...prevState,
        blApiToken: info.baseLinkerToken,
      }));
      if (info.baseLinkerToken) {
        formValue.blApiToken = info.baseLinkerToken;
        linkBl();
      }
    });
    getEshopCodesUsingBaselinkerDefaultConfig()
      .then((data) => setEshopCodes(data));
  }, []);

  const apiTokenRule = Schema.Types.StringType()
    .isRequired('This field is required.')
    .addRule(() => !!blLinked, 'Click the button to link BaseLinker account');

  function onSubmit() {
    if (formRef.current?.check()) {
      saveIntegrationInfo({ baseLinkerToken: formValue.blApiToken }).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>BaseLinker token saved</Message>);
        onSave(formValue.blApiToken);
      });
    } else {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Please check the form there are validation errors.</Message>);
    }
  }

  return (
    <div>
      <br />
      <Form ref={formRef} formValue={formValue} layout="horizontal" onChange={setFormValue}>
        <Form.Group controlId="blApiToken">
          <Form.ControlLabel>BL API token</Form.ControlLabel>
          <Stack spacing={15}>
            <Form.Control name="blApiToken" rule={apiTokenRule} />
            <Button appearance="primary" onClick={() => linkBl()} disabled={!formValue.blApiToken}>
              Link
              {' '}
              <Icon as={ReloadIcon} hidden={!blLinked} />
            </Button>
          </Stack>
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel />
          {eshopCodes.length > 0 && (
          <PlatformIdSync
            show
            eshopCodes={eshopCodes}
          />
          )}
        </Form.Group>
      </Form>
      <Divider>Custom Fields</Divider>
      <CustomFieldsForm blLinked={blLinked} blApiToken={formValue.blApiToken} />
      <Divider>Basic information</Divider>
      <BasicInformationForm blLinked={blLinked} blApiToken={formValue.blApiToken} />
      <Divider>Order statuses</Divider>
      <OrderStatusesForm blLinked={blLinked} blApiToken={formValue.blApiToken} />
    </div>
  );
}
