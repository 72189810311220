import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ButtonToolbar, Form, InputNumber, Message, Schema, SelectPicker, Stack, toaster, Toggle,
} from 'rsuite';
import { AxiosError } from 'axios';
import { ItemDataType } from 'rsuite/esm/@types/common';
import {
  Eshop, EshopSetup, getEshopSetup, saveEshopSetup,
} from '../../api/eshop/eshop';
import { utils } from '../../utils/utils';
import RecalculateAlzaBranchIds from '../alza/RecalculateAlzaBranchIds';
import EshopSetupDetailBaseMarketplace from './EshopSetupDetailBaseMarketplace';

export enum Input {
  BASE_MARKETPLACE,
  SYNCHRONIZATION,
  CLIENT_KEY,
  CLIENT_SECRET,
  STOCK_THRESHOLD,
  EXPEDITION_DAYS,
  FIXED_FEE,
  SUBSTITUTED_PHONE_NUMBER,
  DYNAMIC_BRANCH_ID,
}

export interface Labels {
  clientKey: string;
  clientSecret: string;
  stockThreshold: string;
  expeditionDays: string;
  fixedFee: string;
  dynamicBranchId: string;
  substitutedPhoneNumber: string;
}

interface Props {
  credentials?: JSX.Element;
  eshop: Eshop;
  inputs?: Input[];
  labels: Labels;
  baseMarketplaceCodes?: string[];
  onSuccessfulSave: () => void;
  onIsBaseMarketplaceUpdate?: (value: boolean) => void;
}

interface EshopEdit {
  isBaseMarketplace: boolean;
  clientKey: string;
  clientSecret: string;
  stockThreshold: string;
  expeditionDays: string;
  fixedFee: string;
  isEnabled: boolean;
  dynamicBranchId?: boolean;
  substitutedPhoneNumber: string;
  baseMarketplaceCode: string;
}

const defaultEshopEdit: EshopEdit = {
  isBaseMarketplace: true,
  clientKey: '',
  clientSecret: '',
  stockThreshold: '',
  expeditionDays: '',
  fixedFee: '',
  isEnabled: false,
  substitutedPhoneNumber: '',
  baseMarketplaceCode: '',
};

const minimumThreshold = 0;
const minimumExpeditionDays = 0;

const { StringType, NumberType } = Schema.Types;

function buildValidationModel(labels: Labels, inputs?: Input[]) {
  return Schema.Model({
    baseMarketplaceCode: inputs?.includes(Input.BASE_MARKETPLACE)
      ? Schema.Types.StringType().addRule((value, eshopEdit) => {
        if (eshopEdit.isBaseMarketplace) {
          return true;
        }

        return !!value;
      }, 'Base marketplace is required.', true)
      : StringType(),
    clientKey: inputs?.includes(Input.CLIENT_KEY)
      ? StringType().isRequired(`${labels.clientKey} is required.`)
      : StringType(),
    clientSecret: inputs?.includes(Input.CLIENT_SECRET)
      ? StringType().isRequired(`${labels.clientSecret} is required.`)
      : StringType(),
    stockThreshold: inputs?.includes(Input.STOCK_THRESHOLD)
      ? NumberType()
        .min(minimumThreshold, `${labels.stockThreshold} must be equal or greater than ${minimumThreshold}`)
      : NumberType(),
    expeditionDays: inputs?.includes(Input.EXPEDITION_DAYS)
      ? NumberType()
        .min(minimumExpeditionDays, `${labels.expeditionDays} must be equal or greater than ${minimumExpeditionDays}`)
        .isRequired(`${labels.expeditionDays} are required.`)
      : NumberType(),
    fixedFee: NumberType(),
  });
}

function mapEshopSetup(eshopSetup: EshopSetup | undefined): EshopEdit {
  if (!eshopSetup) {
    return defaultEshopEdit;
  }
  return {
    isBaseMarketplace: !eshopSetup.baseMarketplaceCode,
    clientKey: eshopSetup.id,
    clientSecret: eshopSetup.clientId,
    stockThreshold: eshopSetup.stockThreshold?.toString(10) ?? '',
    expeditionDays: eshopSetup.expeditionDays?.toString(10) ?? '',
    fixedFee: eshopSetup.fixedFee?.toString(10) ?? '',
    substitutedPhoneNumber: eshopSetup?.substitutedPhoneNumber ?? '',
    isEnabled: eshopSetup.enableSyncWithEshop,
    dynamicBranchId: eshopSetup.dynamicBranchId,
    baseMarketplaceCode: eshopSetup.baseMarketplaceCode ?? '',
  };
}

function mapEshopEditRecord(record: Record<string, any>): EshopSetup {
  const eshopEdit = record as EshopEdit;
  return {
    enableSyncWithEshop: eshopEdit.isBaseMarketplace && eshopEdit.isEnabled,
    id: eshopEdit.clientKey ?? '',
    clientId: eshopEdit.clientSecret ?? '',
    stockThreshold: +eshopEdit.stockThreshold,
    expeditionDays: +eshopEdit.expeditionDays,
    fixedFee: +eshopEdit.fixedFee,
    dynamicBranchId: eshopEdit.dynamicBranchId,
    substitutedPhoneNumber: utils.defaultToUndefined(eshopEdit.substitutedPhoneNumber),
    baseMarketplaceCode: eshopEdit.baseMarketplaceCode || undefined,
  };
}

function mapBaseMarketplaceCodes(baseMarketplaceCodes?: string[]): ItemDataType[] {
  return baseMarketplaceCodes
    ?.map(baseMarketplaceCode => ({
      label: baseMarketplaceCode,
      value: baseMarketplaceCode,
    })) ?? [];
}

export default function EshopSetupDetail({
  credentials,
  eshop,
  inputs,
  labels,
  baseMarketplaceCodes,
  onSuccessfulSave,
  onIsBaseMarketplaceUpdate,
}: Props) {
  const [eshopSetup, setEshopSetup] = useState<EshopSetup>();
  const [
    eshopEdit,
    setEshopEdit,
  ] = useState<Record<string, any>>(defaultEshopEdit);
  const formRef = useRef<any>();
  const validationModel = buildValidationModel(labels, inputs);

  function showSuccessMessage() {
    toaster.push(
      <Message
        type="success"
        closable
        showIcon
        duration={2000}
      >
        Eshop settings successfully saved.
      </Message>,
    );
  }

  function fetchEshopSetup() {
    getEshopSetup(eshop.code)
      .catch((error: AxiosError) => {
        if (error.response?.status !== 404) {
          return Promise.reject(error);
        }
        return Promise.resolve(undefined);
      })
      .then(response => {
        setEshopSetup(response);
        setEshopEdit(mapEshopSetup(response));
      });
  }

  useEffect(() => {
    formRef.current?.cleanErrors();
    fetchEshopSetup();
  }, [eshop]);

  useEffect(() => {
    onIsBaseMarketplaceUpdate?.(eshopEdit.isBaseMarketplace);
  }, [eshopEdit.isBaseMarketplace]);

  function onSave(): void {
    if (!formRef.current?.check()) {
      return;
    }

    const updatedEshopSetup = mapEshopEditRecord(eshopEdit);
    saveEshopSetup(eshop.code, { ...eshopSetup, ...updatedEshopSetup })
      .then(() => {
        showSuccessMessage();
        onSuccessfulSave();
      });
  }

  function onCancel(): void {
    setEshopEdit(mapEshopSetup(eshopSetup));
  }

  return (
    <Stack>
      <Form
        layout="horizontal"
        formValue={eshopEdit}
        onChange={setEshopEdit}
        model={validationModel}
        ref={formRef}
        fluid
      >
        {
          inputs?.includes(Input.BASE_MARKETPLACE) && (
          <EshopSetupDetailBaseMarketplace
            isBaseMarketplace={eshopEdit.isBaseMarketplace}
            onUpdate={(value) => setEshopEdit({
              ...eshopEdit,
              isBaseMarketplace: value,
              baseMarketplaceCode: value ? '' : eshopEdit.baseMarketplaceCode,
            })}
          />
          )
        }
        {
          inputs?.includes(Input.BASE_MARKETPLACE) && !eshopEdit.isBaseMarketplace && (
          <Form.Group>
            <Form.ControlLabel>
              Base marketplace
            </Form.ControlLabel>
            <Form.Control
              name="baseMarketplaceCode"
              accepter={SelectPicker}
              value={eshopEdit.baseMarketplaceCode}
              data={mapBaseMarketplaceCodes(baseMarketplaceCodes)}
            />
          </Form.Group>
          )
        }
        {
              inputs?.includes(Input.SYNCHRONIZATION) && (
              <Form.Group>
                <Form.ControlLabel>
                  Enable sync with
                  {' '}
                  {eshop.code}
                </Form.ControlLabel>
                <Toggle
                  checked={eshopEdit.isEnabled}
                  onChange={(value) => setEshopEdit({ ...eshopEdit, isEnabled: value })}
                />
              </Form.Group>
              )
          }
        {
            eshopEdit.isBaseMarketplace && credentials && (
              <Form.Group>
                {credentials}
              </Form.Group>
            )
          }
        {
          inputs?.includes(Input.CLIENT_KEY) && (
          <Form.Group>
            <Form.ControlLabel>
              {labels.clientKey}
            </Form.ControlLabel>
            <Form.Control name="clientKey" type="text" autoComplete="off" />
            <Form.HelpText>Required</Form.HelpText>
          </Form.Group>
          )
        }
        {
          inputs?.includes(Input.CLIENT_SECRET) && (
          <Form.Group>
            <Form.ControlLabel>
              {labels.clientSecret}
            </Form.ControlLabel>
            <Form.Control name="clientSecret" type="text" autoComplete="off" />
            <Form.HelpText>Required</Form.HelpText>
          </Form.Group>
          )
        }
        {
          inputs?.includes(Input.STOCK_THRESHOLD) && (
          <Form.Group>
            <Form.ControlLabel>
              {labels.stockThreshold}
            </Form.ControlLabel>
            <Form.Control name="stockThreshold" accepter={InputNumber} />
          </Form.Group>
          )
        }
        {
          inputs?.includes(Input.EXPEDITION_DAYS) && (
          <Form.Group>
            <Form.ControlLabel>
              {labels.expeditionDays}
            </Form.ControlLabel>
            <Form.Control name="expeditionDays" accepter={InputNumber} />
          </Form.Group>
          )
        }
        {
          inputs?.includes(Input.FIXED_FEE) && (
          <Form.Group>
            <Form.ControlLabel>
              {labels.fixedFee}
            </Form.ControlLabel>
            <Form.Control name="fixedFee" accepter={InputNumber} />
          </Form.Group>
          )
        }
        {
            inputs?.includes(Input.DYNAMIC_BRANCH_ID) && (
            <Form.Group>
              <Form.ControlLabel>
                {labels.dynamicBranchId}
              </Form.ControlLabel>
              <Stack spacing={10}>
                <Toggle
                  checked={eshopEdit.dynamicBranchId}
                  onChange={(value) => setEshopEdit({ ...eshopEdit, dynamicBranchId: value })}
                />
                <RecalculateAlzaBranchIds dynamicBranchId={eshopSetup?.dynamicBranchId} />
              </Stack>
            </Form.Group>
            )
        }
        {
            inputs?.includes(Input.SUBSTITUTED_PHONE_NUMBER) && (
            <Form.Group>
              <Form.ControlLabel>
                {labels.substitutedPhoneNumber}
              </Form.ControlLabel>
              <Form.Control name="substitutedPhoneNumber" />
              <Form.HelpText>Optional</Form.HelpText>
            </Form.Group>
            )
        }
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSave()} type="submit">Save</Button>
            <Button appearance="ghost" onClick={() => onCancel()}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Stack>
  );
}

EshopSetupDetail.defaultProps = {
  credentials: undefined,
  inputs: [
    Input.SYNCHRONIZATION,
    Input.CLIENT_KEY,
    Input.CLIENT_SECRET,
    Input.STOCK_THRESHOLD,
    Input.EXPEDITION_DAYS,
    Input.SUBSTITUTED_PHONE_NUMBER,
  ],
  baseMarketplaceCodes: [],
  onIsBaseMarketplaceUpdate: undefined,
};
