import React from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton, Nav, Sidebar, Sidenav, Stack,
} from 'rsuite';
import { Icon } from '@rsuite/icons';
import {
  RiArrowUpCircleLine,
  RiArrowUpLine,
  RiBarcodeBoxLine,
  RiHome4Line,
  RiRefreshLine,
  RiSettings5Fill,
  RiShoppingCartLine,
} from 'react-icons/ri';

import {
  FaBars, FaBoxes, FaBoxOpen, FaTruck, FaUser,
} from 'react-icons/fa';

import { AiOutlineShop } from 'react-icons/ai';
import { PiBookOpenTextBold, PiTruckFill } from 'react-icons/pi';
import { VscLaw } from 'react-icons/vsc';
import { LiaFileContractSolid } from 'react-icons/lia';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  ALLEGRO_IMPORT,
  BATCH_UPDATE,
  CARRIER_SETUP,
  ESHOP_SETUP,
  ESHOP_SETUP_OVERVIEW,
  KAUF_IMPORT,
  LABEL_CREATION,
  ORDERS,
  PARTNER_SETUP,
  PARTNER_SETUP_OVERVIEW,
  PRODUCT_IMPORT,
  PRODUCTS,
  SHIPMENTS,
  UPDATE_STOCKS_AND_PRICES,
  USER_SETUP,
} from './links';
import KaufIcon from '../assets/KaufIcon';
import { useStoreState } from '../store/hooks';
import { Permission } from '../store/user/types';
import AllegroIcon from '../assets/AllegroIcon';

export default function DrawerList() {
  const [activeKey, setActiveKey] = React.useState('1');
  const [expanded, setExpanded] = React.useState(true);
  const currentUser = useStoreState(state => state.user);

  const partnersSetupLink = currentUser.hasPermission(Permission.PartnerSetupOverview)
    ? PARTNER_SETUP_OVERVIEW
    : PARTNER_SETUP;

  const eshopSetupLink = currentUser.hasPermission(Permission.EshopSetupOverview)
    ? ESHOP_SETUP_OVERVIEW
    : ESHOP_SETUP;

  const usersSetupLink = USER_SETUP;

  return (
    <Sidebar className={expanded ? undefined : 'sidebar-collapsed'}>
      <Sidenav defaultOpenKeys={['2', '3', '7']} expanded={expanded}>
        <Sidenav.Header>
          <Stack>
            <IconButton
              className="hide-menu-button"
              appearance="subtle"
              circle
              onClick={() => setExpanded(!expanded)}
              icon={<FaBars />}
            />
            { expanded && <span className="sidenav-header-logo">salesconnect portal</span>}
          </Stack>
        </Sidenav.Header>
        <Nav onSelect={setActiveKey} activeKey={activeKey}>
          { currentUser.hasPermission(Permission.ProductImport) && (
          <Nav.Menu
            eventKey="2"
            title="Product import"
            icon={<Icon as={RiShoppingCartLine} style={{ width: 20, height: 20 }} />}
          >
            { currentUser.hasPermission(Permission.ProductImport) && (
            <Nav.Item
              eventKey="2-1"
              icon={<Icon as={RiArrowUpCircleLine} style={{ width: 20, height: 20 }} />}
              as={Link}
              to={PRODUCT_IMPORT}
            >
              List new products
            </Nav.Item>
            )}

            { currentUser.hasPermission(Permission.KauflandImport) && (
            <Nav.Item
              eventKey="2-2"
              as={Link}
              to={KAUF_IMPORT}
            >
              <span style={{ marginRight: '7px' }}>
                <KaufIcon />
              </span>
              List Kaufland offers
            </Nav.Item>
            )}

            { currentUser.hasPermission(Permission.AllegroImport) && (
            <Nav.Item
              eventKey="2-3"
              as={Link}
              to={ALLEGRO_IMPORT}
            >
              <span style={{ marginRight: '7px' }}>
                <AllegroIcon />
              </span>
              List Allegro offers
            </Nav.Item>
            )}

            { currentUser.hasPermission(Permission.BatchUpdate) && (
            <Nav.Item
              eventKey="2-4"
              icon={<Icon as={RiArrowUpLine} style={{ width: 20, height: 20 }} />}
              as={Link}
              to={BATCH_UPDATE}
            >
              Change existing products
            </Nav.Item>
            )}
          </Nav.Menu>
          )}

          { currentUser.hasPermission(Permission.Products) && (
          <Nav.Item
            eventKey="3"
            title="Product catalogue"
            icon={(
              <Icon as={FaBoxes} style={{ width: 20, height: 20 }} />)}
            as={Link}
            to={PRODUCTS}
          >
            Product catalogue
          </Nav.Item>
          )}

          { currentUser.hasPermission(Permission.LabelCreation) && (
          <Nav.Item
            eventKey="4"
            icon={<Icon as={RiBarcodeBoxLine} style={{ width: 20, height: 20 }} />}
            as={Link}
            to={LABEL_CREATION}
          >
            Label Creation
          </Nav.Item>
          )}

          { currentUser.hasPermission(Permission.UpdateStocksAndPrices) && (
          <Nav.Item
            eventKey="5"
            icon={<Icon as={RiRefreshLine} style={{ width: 20, height: 20 }} />}
            as={Link}
            to={UPDATE_STOCKS_AND_PRICES}
          >
            Update stock and prices
          </Nav.Item>
          )}

          { currentUser.hasPermission(Permission.Orders) && (
          <Nav.Item
            eventKey="6"
            icon={<Icon as={FaBoxOpen} style={{ width: 20, height: 20 }} />}
            as={Link}
            to={`${ORDERS}?view=all`}
          >
            Orders
          </Nav.Item>
          )}

          { currentUser.hasPermission(Permission.Shipments) && (
          <Nav.Item
            eventKey="7"
            icon={<Icon as={FaTruck} style={{ width: 20, height: 20 }} />}
            as={Link}
            to={SHIPMENTS}
          >
            Shipments
          </Nav.Item>
          )}

          { currentUser.hasPermission(Permission.PartnerSetup) && (
          <Nav.Menu
            eventKey="7"
            title="Admin"
            icon={<Icon as={RiSettings5Fill} style={{ width: 20, height: 20 }} />}
          >
            {currentUser.hasPermission(Permission.PartnerSetup) && (
            <Nav.Item
              eventKey="7.2"
              icon={<Icon as={RiHome4Line} style={{ width: 20, height: 20 }} />}
              as={Link}
              to={partnersSetupLink}
            >
              Partner setup
            </Nav.Item>
            )}
            {currentUser.hasPermission(Permission.EshopSetup) && (
            <Nav.Item
              eventKey="7.3"
              icon={<Icon as={AiOutlineShop} style={{ width: 20, height: 20 }} />}
              as={Link}
              to={eshopSetupLink}
            >
              Eshop setup
            </Nav.Item>
            )}
            {currentUser.hasPermission(Permission.UserSetup) && (
              <Nav.Item
                eventKey="7.4"
                icon={<Icon as={FaUser} style={{ width: 20, height: 20 }} />}
                as={Link}
                to={usersSetupLink}
              >
                User setup
              </Nav.Item>
            )}
            {currentUser.hasPermission(Permission.CarrierSetup) && (
            <Nav.Item
              eventKey="7.5"
              icon={<Icon as={PiTruckFill} style={{ width: 20, height: 20 }} />}
              as={Link}
              to={CARRIER_SETUP}
            >
              Carrier setup
            </Nav.Item>
            )}
          </Nav.Menu>
          )}
          <Nav.Menu
            eventKey="8"
            title="Sources"
            icon={<Icon as={IoInformationCircleOutline} style={{ width: 20, height: 20 }} />}
          >
            {
                (process.env.REACT_APP_KNOWLEDGE_BASE_VISIBLE === 'true')
                && (
                <Nav.Item
                  eventKey="8.1"
                  icon={<Icon as={PiBookOpenTextBold} style={{ width: 20, height: 20 }} />}
                  href={process.env.REACT_APP_KNOWLEDGE_BASE_URL ?? ''}
                  target="_blank"
                >
                  Knowledge base
                </Nav.Item>
                )
            }
            <Nav.Item
              eventKey="8.2"
              icon={<Icon as={LiaFileContractSolid} style={{ width: 20, height: 20 }} />}
              href="https://www.salesconnect.cz/vop"
              target="_blank"
            >
              VOP
            </Nav.Item>
            <Nav.Item
              eventKey="8.3"
              icon={<Icon as={VscLaw} style={{ width: 20, height: 20 }} />}
              href="https://www.salesconnect.cz/gdpr"
              target="_blank"
            >
              GDPR
            </Nav.Item>
          </Nav.Menu>
        </Nav>
      </Sidenav>
    </Sidebar>
  );
}
