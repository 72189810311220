import React from 'react';
import styled from '@emotion/styled';

interface Props {
  size?: number;
}

// eslint-disable-next-line react/require-default-props
function AlzaIcon({ className } :{ className?: string }) {
  return <span className={`${className} alza-icon`} />;
}

export default styled(AlzaIcon)<Props>({
  width: 20,
  height: 20,
}, props => ({
  width: props.size,
  height: props.size,
}));
