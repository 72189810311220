import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Message,
  Panel,
  Row,
  Schema,
} from 'rsuite';
import { FaUser } from 'react-icons/fa';
import { useStoreActions, useStoreState } from '../store/hooks';
import { notificationSuccess } from '../services/notificationActions';
import { getAllegroLinkedInfo } from '../api/user';
import { AllegroLinkingInfo } from '../api/apiTypes';

export default function UserProfile() {
  const { currentUser } = useStoreState(state => state.user);
  const { logout, changePassword } = useStoreActions(actions => actions.user);

  const [saving, setSaving] = useState(false);

  const [formData, setFormData] = useState({ password: '', newPassword: '', verifyPassword: '' });

  const [allegroInfos, setAllegroInfos] = useState<AllegroLinkingInfo[] | null>();

  const handleChange = (formValue: any) => {
    setFormData(formValue);
  };

  const handleSubmit = async (checkStatus: boolean) => {
    if (checkStatus) {
      setSaving(true);
      try {
        await changePassword({ ...formData, username: currentUser.username });
        notificationSuccess('Password was successfully changed.');
        logout();
      } finally {
        setSaving(false);
      }
    }
  };

  useEffect(() => {
    getAllegroLinkedInfo().then(info => setAllegroInfos(info.data));
  }, []);

  const model = Schema.Model({
    password: Schema.Types.StringType().isRequired('Current password is required.'),
    newPassword: Schema.Types.StringType().isRequired('New password is required.'),
    verifyPassword: Schema.Types.StringType()
      .addRule((value, data) => {
        if (value !== data.newPassword) {
          return false;
        }
        return true;
      }, 'The two passwords do not match')
      .isRequired('Verify password is required.'),
  });

  function getAllegroUserName(allegroInfo: AllegroLinkingInfo) {
    if (allegroInfo) {
      const name = allegroInfo.allegroUser?.login;
      let userName = '';
      if (allegroInfo.allegroUser?.firstName) {
        userName = allegroInfo.allegroUser.firstName;
      }
      if (allegroInfo.allegroUser?.lastName) {
        userName += ` ${allegroInfo.allegroUser.lastName}`;
      }

      if (name && userName) {
        return `${name} (${userName})`;
      }
      if (name) {
        return <b>{name}</b>;
      }
      if (userName) {
        return userName;
      }
    }

    return null;
  }

  return (
    <Panel className="h100p">
      <Grid fluid className="h100p">
        <Row>
          <Col xs={24}>
            <h4>
              <FaUser size={24} />
              {' '}
              {currentUser.name}
            </h4>
            <div className="mt-10">
              Username:
              {' '}
              <b>
                {currentUser.username}
              </b>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <h5>Change password</h5>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col>
            <Form
              model={model}
              formValue={formData}
              onSubmit={handleSubmit}
              onChange={handleChange}
              layout="horizontal"
            >
              <Form.Group controlId="password">
                <Form.ControlLabel>Current password</Form.ControlLabel>
                <Form.Control name="password" type="password" autoComplete="off" />
              </Form.Group>
              <Form.Group controlId="newPassword">
                <Form.ControlLabel>New password</Form.ControlLabel>
                <Form.Control name="newPassword" type="password" autoComplete="off" />
              </Form.Group>
              <Form.Group controlId="verifyPassword">
                <Form.ControlLabel>Verify password</Form.ControlLabel>
                <Form.Control name="verifyPassword" type="password" autoComplete="off" />
              </Form.Group>
              <Form.Group>
                <ButtonToolbar>
                  <Button type="submit" appearance="primary" block disabled={saving}>Change password</Button>
                </ButtonToolbar>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <h5>
              Settings for
              {' '}
              {currentUser.partnerName}
            </h5>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col xs={24}>
            <h6>
              Allegro integration
            </h6>
            {allegroInfos?.map(allegroInfo => allegroInfo?.linked && (
              <Message type="success" className="mt-5">
                {`Your account is linked to ${allegroInfo.eshopCode} account:`}
                {' '}
                <b>{getAllegroUserName(allegroInfo)}</b>
              </Message>
            ))}

            {allegroInfos?.map(allegroInfo => !allegroInfo?.linked && (
            <div className="mt-5">
              <Button
                appearance="link"
                color="blue"
                href={allegroInfo?.linkUrl ?? undefined}
              >
                {`Link with ${allegroInfo.eshopCode} account`}
              </Button>
            </div>
            ))}
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
