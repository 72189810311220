import api from './base';
import {
  ApiCarrier,
  BrandResponse,
  CategoriesResponse,
  PackageSizes,
  PartnerEshopResponse,
} from './apiTypes';
import {
  mockGetBrands,
  mockGetCancelReasons,
  mockGetCarriers,
  mockGetCategories,
  mockGetPackageSizes,
  mockGetPartnerEshops,
} from './mocks/catalogsMock';

const CATEGORIES_URL = 'management/products/categories';
const BRANDS_URL = 'management/products/brands';
const PARTNER_ESHOPS_URL = 'management/partner-eshops';
const CARRIERS_URL = 'management/orders/carriers';
const CANCEL_REASONS_URL = 'management/orders/catalogs/cancel-reasons';
const PACKAGE_SIZES = 'management/orders/catalogs/package-sizes';

export async function getCategories() {
  return api.get<CategoriesResponse>(CATEGORIES_URL);
}

api.mock.onGet(CATEGORIES_URL).reply(200, mockGetCategories);

export async function getBrands() {
  return api.get<BrandResponse>(BRANDS_URL);
}

api.mock.onGet(BRANDS_URL).reply(200, mockGetBrands);

export async function getPartnerEshops() {
  return api.get<PartnerEshopResponse>(PARTNER_ESHOPS_URL);
}

api.mock.onGet(PARTNER_ESHOPS_URL).reply(200, mockGetPartnerEshops);

export async function getCarriers() {
  return api.get<ApiCarrier[]>(CARRIERS_URL);
}

api.mock.onGet(CARRIERS_URL).reply(200, mockGetCarriers);

export async function getCancelReasons() {
  return api.get<String[]>(CANCEL_REASONS_URL);
}

api.mock.onGet(CANCEL_REASONS_URL).reply(200, mockGetCancelReasons);

export async function getPackageSizes() {
  return api.get<PackageSizes[]>(PACKAGE_SIZES);
}

api.mock.onGet(PACKAGE_SIZES).reply(200, mockGetPackageSizes);
