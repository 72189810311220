import React, { useEffect, useReducer } from 'react';
import {
  IconButton, Input, InputGroup, Stack, Table,
} from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { getEshopCarriers } from '../../../api/carrier';
import { ApiGroup } from '../../../api/model/ApiGroup';
import { EshopCarrier, MpiDeliveryTypeLabels } from '../../../api/apiTypes';
import AllegroIcon from '../../../assets/AllegroIcon';
import CopyToClipboardIcon from '../../CopyToClipboardIcon';
import { CARRIER_SETUP_EDIT } from '../../../routes/links';
import { utils } from '../../../utils/utils';
import Pagination from '../../Pagination';
import usePagination from '../../../hooks/paginationHook';

const FILTER_KEYS: (keyof EshopCarrier)[] = [
  'eshopCode',
  'eshopCarrierCode',
  'name',
  'eshopCarrierType',
  'defaultMpiDeliveryType',
  'identifier',
  'portalName',
];

interface State {
  eshopCarriers: EshopCarrier[];
  status: 'loading' | 'ready';
}

const initialState: State = {
  eshopCarriers: [],
  status: 'ready',
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessful = { type: 'loadingSuccessful', payload: EshopCarrier[] };
type Action = LoadingAction | LoadingSuccessful;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, status: 'loading' };
    case 'loadingSuccessful':
      return { ...state, status: 'ready', eshopCarriers: action.payload };
    default:
      return state;
  }
}

export default function CarrierSetupAllegroTable(): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pagination, setPagination] = usePagination();
  const navigate = useNavigate();

  const { eshopCarriers, status } = state;
  const filteredEshopCarriers = utils.fulltextFiltered(
    eshopCarriers,
    pagination.filter,
    FILTER_KEYS,
  );
  const paginatedEshopCarriers = utils.paginated(
    filteredEshopCarriers,
    pagination,
  );

  function handleFilterChange(filter: string): void {
    setPagination({ ...pagination, filter });
  }

  function handleChangeLimit(limit: number): void {
    setPagination({ ...pagination, limit });
  }

  function handleChangePage(page: number): void {
    setPagination({ ...pagination, page });
  }

  function handleEdit(id: number): void {
    navigate(`${CARRIER_SETUP_EDIT}/${id}`);
  }

  function handleAddNew() {
    navigate(CARRIER_SETUP_EDIT);
  }

  function fetchEshopCarriers(signal: AbortSignal): void {
    dispatch({ type: 'loading' });
    getEshopCarriers({ apiGroup: ApiGroup.ALLEGRO }, signal)
      .then((response) => dispatch({ type: 'loadingSuccessful', payload: response }));
  }

  useEffect(() => {
    const controller = new AbortController();
    fetchEshopCarriers(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Stack
      spacing={15}
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <InputGroup style={{ width: 400 }}>
        <Input
          placeholder="search by marketplace, delivery method name, etc..."
          value={pagination.filter}
          onChange={(value) => handleFilterChange(value)}
        />
        <InputGroup.Button onClick={() => handleFilterChange('')}>
          <CloseIcon />
        </InputGroup.Button>
      </InputGroup>
      <Table
        autoHeight
        bordered
        data={paginatedEshopCarriers}
        loading={status === 'loading'}
        onRowClick={(item) => handleEdit(item.id)}
      >
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>Marketplace</Table.HeaderCell>
          <Table.Cell dataKey="eshopCode" />
        </Table.Column>
        <Table.Column flexGrow={4} align="center">
          <Table.HeaderCell>Delivery method ID</Table.HeaderCell>
          <Table.Cell>
            {
            (eshopCarrier: EshopCarrier) => (
              <>
                {eshopCarrier.eshopCarrierCode}
                {' '}
                <CopyToClipboardIcon
                  text={eshopCarrier.eshopCarrierCode}
                />
              </>
            )
          }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>Delivery method name</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell>{}</Table.HeaderCell>
          <Table.Cell>
            {
              (eshopCarrier: EshopCarrier) => (
                eshopCarrier.managedByPartner
                  ? null
                  : <AllegroIcon />
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>MPI delivery type</Table.HeaderCell>
          <Table.Cell>
            {
              (eshopCarrier: EshopCarrier) => (eshopCarrier.defaultMpiDeliveryType
                ? MpiDeliveryTypeLabels[eshopCarrier.defaultMpiDeliveryType]
                : null)
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Allegro carrier ID</Table.HeaderCell>
          <Table.Cell>
            {
              (eshopCarrier: EshopCarrier) => (
                eshopCarrier.managedByPartner
                  ? eshopCarrier.eshopCarrierType
                  : '-'
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>MPI carrier</Table.HeaderCell>
          <Table.Cell dataKey="identifier" />
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>Portal name</Table.HeaderCell>
          <Table.Cell dataKey="portalName" />
        </Table.Column>
      </Table>
      <div className="mt-10">
        <Pagination
          total={filteredEshopCarriers.length}
          limit={pagination.limit}
          activePage={pagination.page}
          onChangePage={(value) => handleChangePage(value)}
          onChangeLimit={(value) => handleChangeLimit(value)}
        />
      </div>
      <IconButton
        size="md"
        appearance="subtle"
        icon={<BsPlusCircle size={20} />}
        onClick={() => handleAddNew()}
      />
    </Stack>
  );
}
