import React, { ReactNode } from 'react';
import Upload from 'rc-upload';
import { getCurrentUserToken } from '../services/authorization';

interface Props<T> {
  children?: ReactNode;
  path: string;
  additionalHeaders?: Record<string, string>;
  accept?: string;
  name?: string;
  onStart?: () => void;
  onError?: (error: Error) => void;
  onSuccess?: (response: T) => void;
}

export default function FileUpload<T>(
  {
    children,
    path,
    additionalHeaders,
    accept,
    name,
    onStart,
    onError,
    onSuccess,
  }: Props<T>,
) {
  const token = getCurrentUserToken();
  const url = `${process.env.REACT_APP_API_URL}${path}`;
  const headers = {
    ...additionalHeaders,
    Authorization: `BEARER ${token}`,
  };

  function handleStart(): void {
    onStart?.();
  }

  function handleError(error: Error): void {
    onError?.(error);
  }

  function handleSuccess(response: Record<string, unknown>): void {
    onSuccess?.(response as T);
  }

  return (
    <Upload
      action={url}
      headers={headers}
      accept={accept}
      name={name}
      onStart={() => handleStart()}
      onError={(error) => handleError(error)}
      onSuccess={(response) => handleSuccess(response)}
    >
      {children}
    </Upload>
  );
}

FileUpload.defaultProps = {
  children: undefined,
  additionalHeaders: undefined,
  accept: '.csv',
  name: 'attachment',
  onStart: undefined,
  onError: undefined,
  onSuccess: undefined,
};
