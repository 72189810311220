import React from 'react';
import { Button, Modal } from 'rsuite';

interface Props {
  children: JSX.Element;
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export default function Alert({
  children, title, isOpen, isLoading, onConfirm, onCancel, onClose,
}: Props) {
  function handleCancel() {
    if (onCancel) {
      onCancel();
      return;
    }

    onClose?.();
  }

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={isOpen}
      onClose={() => onClose?.()}
      size="xs"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onConfirm()}
          appearance="primary"
          loading={isLoading}
        >
          Confirm
        </Button>
        <Button
          onClick={() => handleCancel()}
          appearance="subtle"
          disabled={isLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

Alert.defaultProps = {
  isLoading: false,
  onCancel: undefined,
  onClose: undefined,
};
