import React from 'react';
import styled from '@emotion/styled';
import { ImCross } from 'react-icons/im';
import AlzaIcon from '../../assets/AlzaIcon';
import MallIcon from '../../assets/MallIcon';
import AllegroIcon from '../../assets/AllegroIcon';

interface Props {
  carrier: string;
  managedByPartner: boolean;
  apiGroup: string;
  crossDockDelivery: boolean;
}

const Container = styled.div`
  display: flex;
  
  div:first-of-type {
    flex: 1 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

function BrandIcon({ apiGroup }: { apiGroup: string }): React.ReactElement | null {
  switch (apiGroup) {
    case 'ALZA':
      return <AlzaIcon />;
    case 'MALL_GROUP':
      return <MallIcon />;
    case 'ALLEGRO':
      return <AllegroIcon />;
    default:
      return null;
  }
}

export default function CarrierCell({
  carrier,
  managedByPartner,
  apiGroup,
  crossDockDelivery,
}: Props) {
  return (
    <Container>
      <div>{carrier}</div>
      <div>
        {!managedByPartner && <BrandIcon apiGroup={apiGroup} />}
        {crossDockDelivery && <ImCross />}
      </div>
    </Container>
  );
}
