import React, { useEffect, useReducer } from 'react';
import { IconButton, Stack } from 'rsuite';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { WAREHOUSE_LOCALITY_EDIT } from '../../../routes/links';
import EshopSetupAlzaBranchesTable from './EshopSetupAlzaBranchesTable';
import { Eshop } from '../../../api/eshop/eshop';
import { AssignedProducts, WarehouseLocality } from '../../../api/warehouse/warehouseApiTypes';
import Alert from '../../Alert';
import { getAssignedProducts, getWarehouseLocalities, runFullImport } from '../../../api/warehouse/warehouse';

const POLLING_INTERVAL = 10_000; // 10 seconds

interface Props {
  eshop: Eshop;
}

interface State {
  data: Data;
  fullImportAlert: [boolean, number?];
  status: 'ready' | 'loading';
}

interface Data {
  warehouseLocalities: WarehouseLocality[];
  assignedProducts: AssignedProducts[];
}

const initialState: State = {
  data: {
    warehouseLocalities: [],
    assignedProducts: [],
  },
  fullImportAlert: [false, undefined],
  status: 'ready',
};

type LoadAction = { type: 'load' };
type SetDataAction = { type: 'setData', payload: Data };
type ShowFullImportAlertAction = { type: 'showFullImportAlert', payload: [boolean, number] };
type CloseFullImportAlertAction = { type: 'closeFullImportAlert' };
type SetFullImportRunningAction = { type: 'setFullImportRunning', payload: number };
type Action = LoadAction
| SetDataAction
| ShowFullImportAlertAction
| CloseFullImportAlertAction
| SetFullImportRunningAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load':
      return { ...state, status: 'loading' };
    case 'setData':
      return { ...state, status: 'ready', data: action.payload };
    case 'showFullImportAlert':
      return { ...state, fullImportAlert: action.payload };
    case 'closeFullImportAlert':
      return { ...state, fullImportAlert: [false, state.fullImportAlert[1]] };
    case 'setFullImportRunning':
      return {
        ...state,
        data: {
          ...state.data,
          warehouseLocalities: state.data.warehouseLocalities
            .map((warehouseLocality) => {
              if (warehouseLocality.id === action.payload) {
                warehouseLocality.isFullImportRunning = true;
              }

              return warehouseLocality;
            }),
        },
      };
    default:
      return state;
  }
}

export default function EshopSetupAlzaBranches({ eshop }: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  async function fetchData(isPolling: boolean, signal: AbortSignal): Promise<Data> {
    if (!isPolling) {
      dispatch({ type: 'load' });
    }

    try {
      const warehouseLocalities = await getWarehouseLocalities(eshop.code, signal);
      const warehouseLocalityIds = warehouseLocalities
        .map((warehouseLocality) => warehouseLocality.id);
      const assignedProducts = await getAssignedProducts(warehouseLocalityIds, signal);
      return { warehouseLocalities, assignedProducts };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 400) {
        throw error;
      }

      return { warehouseLocalities: [], assignedProducts: [] };
    }
  }

  function startFullImport(id: number) {
    runFullImport(id)
      .then(() => dispatch({ type: 'setFullImportRunning', payload: id }));
  }

  useEffect(() => {
    const abortController = new AbortController();
    const fetch = (isPolling: boolean) => {
      fetchData(isPolling, abortController.signal)
        .then((data) => dispatch({ type: 'setData', payload: data }));
    };

    fetch(false);
    const interval = setInterval(() => fetch(true), POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
      abortController.abort();
    };
  }, [eshop]);

  function handleSelect(id: number) {
    navigate(`${WAREHOUSE_LOCALITY_EDIT}/${id}`);
  }

  function handleAdd(): void {
    navigate(WAREHOUSE_LOCALITY_EDIT);
  }

  function handleFullImport(id: number) {
    dispatch({ type: 'showFullImportAlert', payload: [true, id] });
  }

  function handleFullImportConfirm() {
    dispatch({ type: 'closeFullImportAlert' });
    const [, id] = state.fullImportAlert;

    if (!id) {
      return;
    }

    startFullImport(id);
  }

  function handleFullImportClose() {
    dispatch({ type: 'closeFullImportAlert' });
  }

  return (
    <Stack spacing={15} direction="column" alignItems="stretch" justifyContent="flex-start">
      <EshopSetupAlzaBranchesTable
        warehouseLocalities={state.data.warehouseLocalities}
        assignedProducts={state.data.assignedProducts}
        isLoading={state.status === 'loading'}
        onSelect={(value) => handleSelect(value)}
        onFullImport={(value) => handleFullImport(value)}
      />
      <IconButton
        size="md"
        appearance="subtle"
        icon={<BsPlusCircle size={20} />}
        onClick={() => handleAdd()}
      />
      <Alert
        isOpen={state.fullImportAlert[0]}
        onConfirm={() => handleFullImportConfirm()}
        onClose={() => handleFullImportClose()}
        title="Confirmation"
      >
        <p>
          Do you really want to run full import on branch
          {' '}
          <strong>
            {
              state.data.warehouseLocalities
                .find((warehouseLocality) => warehouseLocality.id === state.fullImportAlert[1])
                ?.code ?? ''
            }
          </strong>
          {' '}
          ?
        </p>
      </Alert>
    </Stack>
  );
}
