import React, { useEffect, useReducer } from 'react';
import {
  Button, ButtonToolbar, Form, Loader, Message, Stack, toaster,
} from 'rsuite';
import axios from 'axios';
import { Eshop, getPartnerEshopBankDetails, savePartnerEshopBankDetails } from '../../api/eshop/eshop';
import { PartnerEshopBankDetails as BankDetails } from '../../api/apiTypes';
import { utils } from '../../utils/utils';

interface Props {
  eshop: Eshop;
}

interface BankEdit {
  bankAccount?: string;
  bankAccountName?: string;
}

interface State {
  isLoading: boolean;
  isSaving: boolean;
  bankDetails?: BankDetails,
  bankEdit: BankEdit;
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  bankDetails: undefined,
  bankEdit: {
    bankAccount: '',
    bankAccountName: '',
  },
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload?: BankDetails };
type EditAction = { type: 'edit', payload: BankEdit };
type SavingAction = { type: 'saving', payload: boolean };
type ResetAction = { type: 'reset' };
type Action = LoadingAction | LoadingSuccessfulAction | EditAction | SavingAction | ResetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return {
        ...state,
        isLoading: false,
        bankDetails: action.payload,
        bankEdit: {
          bankAccount: action.payload?.bankAccount ?? '',
          bankAccountName: action.payload?.bankAccountName ?? '',
        },
      };
    case 'edit':
      return { ...state, bankEdit: action.payload };
    case 'saving':
      return { ...state, isSaving: action.payload };
    case 'reset':
      return {
        ...state,
        bankEdit: {
          bankAccount: state.bankDetails?.bankAccount ?? '',
          bankAccountName: state.bankDetails?.bankAccountName ?? '',
        },
      };
    default:
      return state;
  }
}

export default function EshopSetupBankDetails({ eshop }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchBankDetails(): Promise<BankDetails | undefined> {
    dispatch({ type: 'loading' });
    try {
      return await getPartnerEshopBankDetails(eshop.code);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        throw error;
      }
      return undefined;
    }
  }

  async function saveBankDetails(): Promise<void> {
    dispatch({ type: 'saving', payload: true });
    const { bankAccount, bankAccountName } = state.bankEdit;
    const bankDetails: BankDetails = {
      bankAccount: utils.defaultToUndefined(bankAccount),
      bankAccountName: utils.defaultToUndefined(bankAccountName),
    };
    try {
      await savePartnerEshopBankDetails(eshop.code, bankDetails);
    } finally {
      dispatch({ type: 'saving', payload: false });
    }
  }

  function showSuccessMessage(): void {
    toaster.push(
      <Message
        type="success"
        closable
        showIcon
        duration={2000}
      >
        Bank details successfully saved.
      </Message>,
    );
  }

  useEffect(() => {
    fetchBankDetails()
      .then((response) => dispatch({ type: 'loadingSuccessful', payload: response }));
  }, [eshop]);

  function onSave(): void {
    saveBankDetails()
      .then(() => showSuccessMessage());
  }

  function onCancel(): void {
    dispatch({ type: 'reset' });
  }

  return state.isLoading ? <Loader /> : (
    <Stack>
      <Form
        layout="horizontal"
        formValue={state.bankEdit}
        onChange={(bankEdit) => dispatch({ type: 'edit', payload: bankEdit })}
      >
        <Form.Group>
          <Form.ControlLabel>
            Bank account
          </Form.ControlLabel>
          <Form.Control
            name="bankAccount"
            type="text"
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Account name
          </Form.ControlLabel>
          <Form.Control
            name="bankAccountName"
            type="text"
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => onSave()}
              type="submit"
              loading={state.isSaving}
            >
              Save
            </Button>
            <Button
              appearance="ghost"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Stack>
  );
}
