import React from 'react';
import { AllegroLinkingInfo } from '../../../../api/apiTypes';
import EshopSetupAllegroCredentialsLinked from './EshopSetupAllegroCredentialsLinked';
import EshopSetupAllegroCredentialsUnlinked from './EshopSetupAllegroCredentialsUnlinked';

interface Props {
  linkingInfo: AllegroLinkingInfo,
  onSuccessfulUnlink: () => void,
}

export default function EshopSetupAllegroCredentialsLink(
  { linkingInfo, onSuccessfulUnlink } : Props,
): JSX.Element | null {
  const { linked } = linkingInfo;

  if (linked) {
    return (
      <EshopSetupAllegroCredentialsLinked
        linkingInfo={linkingInfo}
        onSuccessfulUnlink={onSuccessfulUnlink}
      />
    );
  }

  return (
    <EshopSetupAllegroCredentialsUnlinked
      linkingInfo={linkingInfo}
    />
  );
}
