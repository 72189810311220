import React from 'react';
import { FaPauseCircle } from 'react-icons/fa';
import { Popover, Whisper } from 'rsuite';
import styled from '@emotion/styled';

interface Props {
  stockQuantity: number;
  isOnHold: boolean;
  marketplace: string;
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export default function ProductTableStockQuantity({ stockQuantity, isOnHold, marketplace }: Props) {
  const inStockQuantity = stockQuantity > 99 ? '99+' : stockQuantity;
  const message = `Offer is on hold and hidden from ${marketplace}`;

  return (
    <Container>
      <span title={stockQuantity.toString()}>{inStockQuantity}</span>
      {isOnHold && (
      <Whisper
        speaker={<Popover arrow={false}>{message}</Popover>}
        trigger="hover"
        placement="bottomStart"
        preventOverflow
      >
        <span>
          <FaPauseCircle color="orange" size={20} />
        </span>
      </Whisper>
      )}
    </Container>
  );
}
