import React, { useEffect } from 'react';
import { Checkbox, Pagination, Table } from 'rsuite';

import { SortType } from 'rsuite-table';
import Enumerable from 'linq';
import { toDateTimeFormat } from '../../services/dateUtils';

import { useStoreActions, useStoreState } from '../../store/hooks';
import CarrierCell from '../orders/CarrierCell';

function PrintLabelOrderTable() {
  const {
    fetchOrders,
    setFilter, selectOrders,
  } = useStoreActions(actions => actions.printExistingLabels);
  const {
    orders, loading, currentFilter, total, selectedOrders,
  } = useStoreState(state => state.printExistingLabels);

  const handleSortColumn = (column:string, type?:SortType) => {
    setFilter({
      ...currentFilter,
      orderBy: column,
      orderDirection: type ?? 'asc',
    });
  };

  const changePage = (page:number) => {
    setFilter({
      ...currentFilter,
      page,
    });
  };

  const changeLimit = (limit: number) => {
    setFilter({
      ...currentFilter,
      pageSize: limit,
    });
  };

  const handleCheckAll = (value:any, checked:boolean) => {
    const pageIds = orders.map(item => item.id);

    const selection = Enumerable.from(selectedOrders)
      .merge(pageIds)
      .distinct()
      .toArray();

    const keys = checked
      ? selection
      : selectedOrders.filter(item => pageIds.indexOf(item) < 0);
    selectOrders(keys);
  };
  const handleCheck = (value:any, checked:boolean) => {
    const keys = checked
      ? [...selectedOrders, value]
      : selectedOrders.filter(item => item !== value);
    selectOrders(keys);
  };

  let checked = false;
  let indeterminate = false;

  const pageOrders = orders.map(item => item.id);
  const selectedPageOrders = selectedOrders.filter(id => pageOrders.indexOf(id) >= 0);

  if (selectedPageOrders.length === orders.length) {
    checked = true;
  } else if (selectedPageOrders.length === 0) {
    checked = false;
  } else if (selectedPageOrders.length > 0 && selectedPageOrders.length < orders.length) {
    indeterminate = true;
  }

  useEffect(() => {
    fetchOrders({
      ...currentFilter,
      filter: {
        ...currentFilter.filter,
      },
    });
  }, [currentFilter]);

  useEffect(() => {
    selectOrders([]);
  }, [currentFilter.filter]);

  return (
    <div className="h100p">
      <Table
        height={350}
        fillHeight
        bordered
        data={orders}
        rowKey="id"
        loading={loading}
        onSortColumn={handleSortColumn}
        sortColumn={currentFilter.orderBy}
        sortType={currentFilter.orderDirection}
        shouldUpdateScroll={false}
      >
        <Table.Column width={50} align="center">
          <Table.HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: '40px' }}>
              <Checkbox
                className="table-header-checkbox"
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </div>
          </Table.HeaderCell>
          <Table.Cell
            style={{ padding: 0 }}
            dataKey="id"
          >
            {rowData => (
              <div style={{ lineHeight: '46px' }}>
                <Checkbox
                  value={rowData.id}
                  inline
                  onChange={handleCheck}
                  checked={selectedOrders.some(item => item === rowData.id)}
                />
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Marketplace</Table.HeaderCell>
          <Table.Cell dataKey="marketplace" />
        </Table.Column>
        <Table.Column width={150} align="center" sortable>
          <Table.HeaderCell>Carrier</Table.HeaderCell>
          <Table.Cell dataKey="carrier">
            {rowData => (
              <CarrierCell
                carrier={rowData.carrier}
                apiGroup={rowData.apiGroup}
                managedByPartner={rowData.managedByPartner}
                crossDockDelivery={rowData.crossDockDelivery}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={160} align="center" sortable>
          <Table.HeaderCell>Suggested expedition</Table.HeaderCell>
          <Table.Cell dataKey="suggestedExpedition">
            {rowData => toDateTimeFormat(rowData.suggestedExpedition)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200} align="center" sortable>
          <Table.HeaderCell>Order Number (MP)</Table.HeaderCell>
          <Table.Cell dataKey="orderNumber" />
        </Table.Column>
        <Table.Column width={220} align="center" sortable>
          <Table.HeaderCell>Order Number (Partner)</Table.HeaderCell>
          <Table.Cell dataKey="partnerId" />
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Number of pages</Table.HeaderCell>
          <Table.Cell dataKey="pagesCount" />
        </Table.Column>
      </Table>

      <div className="mt-10">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={total}
          limitOptions={[25, 50, 100]}
          limit={currentFilter.pageSize}
          activePage={currentFilter.page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>

    </div>
  );
}

export default React.memo(PrintLabelOrderTable);
