import { action, thunk } from 'easy-peasy';
import Enumerable from 'linq';
import { LabelPackage, LabelPackageItem, LabelStoreModel } from './labelStoreTypes';

import { DetailedLabelRequest, OrderForLabelPrint } from '../../api/apiTypes';
import { createLabelDetailed, packagePreview } from '../../api/orders';
import { notificationSuccess } from '../../services/notificationActions';
import { downloadData } from '../../utils/downloadData';
import { ApiGroup } from '../../api/model/ApiGroup';

function checkPackages(order: OrderForLabelPrint, packages: LabelPackage[])
  : { valid: boolean; allItems: boolean } {
  let valid = true;
  let allItems = true;

  if (packages.length === 0) {
    valid = false;
  }

  const itemsCount : { [itemId: string] : number } = {};

  for (let i = 0; i < packages.length; i++) {
    // check size and weight
    const p = packages[i];
    if (p.volumeMode !== 'simple') {
      if (!p.proportions.weight) {
        valid = false;
      }
    }

    if (p.volumeMode === 'dimensions') {
      if (!p.proportions.dimension1 || !p.proportions.dimension2 || !p.proportions.dimension3) {
        valid = false;
      }
    } else if (p.volumeMode === 'volume') {
      if (!p.proportions.volume) {
        valid = false;
      }
    } else if (p.volumeMode === 'simple') {
      if (!p.size) {
        valid = false;
      }
    }

    if (p.items.length === 0) {
      valid = false;
    }

    // compute items count
    for (let j = 0; j < p.items.length; j++) {
      const item = p.items[j];
      if (!itemsCount[item.id]) {
        itemsCount[item.id] = item.count;
      } else {
        itemsCount[item.id] += item.count;
      }
    }
  }

  // check items count against order
  for (let i = 0; i < order.items.length; i++) {
    const orderItem = order.items[i];
    if (!itemsCount[orderItem.id] || itemsCount[orderItem.id] !== orderItem.count) {
      valid = false;
      allItems = false;
    }
  }

  return { valid, allItems };
}

const labelStore: LabelStoreModel = {
  validity: { valid: false, allItems: true },
  generating: false,
  order: {
    id: 0,
    items: [],
    originalId: '',
    shippingLabelsPrintMethod: 'DETAILED',
    source: '',
    parcelShopBranchId: undefined,
    managedByPartner: false,
    apiGroup: ApiGroup.ALZA,
  },
  packages: [],
  initPackages: action((state, order) => {
    state.order = order;

    state.packages = [{
      packageId: 1,
      proportions: {
        weight: undefined,
        dimension1: undefined,
        dimension2: undefined,
        dimension3: undefined,
        volume: undefined,
      },
      items: order.items.map(item => ({
        id: item.id,
        count: item.count,
        maxCount: item.count,
      })),
      volumeMode: 'simple',
      isSplit: false,
    }];

    state.validity = { valid: false, allItems: true };
  }),
  packagePreview: thunk(async (actions, orderId) => {
    const { data } = await packagePreview(orderId);
    const packages: LabelPackage[] = data.packages.map((onePackage) => ({
      packageId: onePackage.packageId,
      proportions: {
        weight: onePackage.weight,
        dimension1: onePackage.dimension1,
        dimension2: onePackage.dimension2,
        dimension3: onePackage.dimension3,
        volume: onePackage.volume,
      },
      items: onePackage.items.map(item => ({
        id: item.id,
        count: item.count,
        maxCount: item.count,
      })),
      volumeMode: 'dimensions',
      isSplit: onePackage.items.some(item => item.count === 0),
    }));
    actions.setPackages(packages);
  }),
  addPackage: action((state) => {
    let items: LabelPackageItem[] = [];

    const usedItems = Enumerable.from(state.packages)
      .selectMany(p => p.items)
      .groupBy(i => i.id, element => element, (key, group) => (
        {
          itemId: key,
          count: group.sum(g => g.count),
        }))
      .toDictionary(k => k.itemId, v => v.count);

    items = state.order.items
      .filter(item => !usedItems.contains(item.id) || usedItems.get(item.id) < item.count)
      .map(item => ({
        id: item.id,
        count: item.count - (usedItems.get(item.id) ?? 0),
        maxCount: item.count - (usedItems.get(item.id) ?? 0),
      }));

    state.packages.push({
      packageId: state.packages.length + 1,
      proportions: {
        weight: undefined,
        dimension1: undefined,
        dimension2: undefined,
        dimension3: undefined,
        volume: undefined,
      },
      volumeMode: 'simple',
      isSplit: false,
      items,
    });

    state.validity = checkPackages(state.order, state.packages);
  }),
  setPackages: action((state, packages) => {
    state.packages = packages;
    state.validity = checkPackages(state.order, state.packages);
  }),
  removePackage: action((state, packageId) => {
    state.packages = state.packages
      .filter((p) => p.packageId !== packageId)
      .map((p, i) => ({ ...p, packageId: i + 1 }));

    state.validity = checkPackages(state.order, state.packages);
  }),
  splitItem: action((state, { labelPackage, itemId }) => {
    const lp = state.packages.find(p => p.packageId === labelPackage.packageId)!!;

    const newPackage: LabelPackage = {
      packageId: state.packages.length + 1,
      proportions: {
        weight: lp.proportions.weight,
        dimension1: lp.proportions.dimension1,
        dimension2: lp.proportions.dimension2,
        dimension3: lp.proportions.dimension3,
        volume: lp.proportions.volume,
      },
      items: [{
        id: itemId,
        count: 0,
        maxCount: 0,
      }],
      volumeMode: lp.volumeMode,
      isSplit: true,
      size: lp.size,
    };

    state.packages.push(newPackage);
    state.validity = checkPackages(state.order, state.packages);
  }),
  removeItem: action((state, { labelPackage, itemId }) => {
    const lp = state.packages.find(p => p.packageId === labelPackage.packageId)!!;

    lp.items = lp.items.filter(item => item.id !== itemId);
    state.validity = checkPackages(state.order, state.packages);
  }),
  updateItemCount: action((state, { labelPackage, itemId, value }) => {
    const lp = state.packages.find(p => p.packageId === labelPackage.packageId)!!;
    const item = lp.items.find(i => i.id === itemId);

    if (item) {
      item.count = value;
    }

    state.validity = checkPackages(state.order, state.packages);
  }),
  changeProportions: action((state, { labelPackage, proportions, size }) => {
    const lp = state.packages.find(p => p.packageId === labelPackage.packageId)!!;
    if (proportions) {
      lp.proportions = { ...lp.proportions, ...proportions };
    }
    if (size) {
      lp.size = size;
    }

    state.validity = checkPackages(state.order, state.packages);
  }),
  changeVolumeMode: action((state, { labelPackage, mode }) => {
    const lp = state.packages.find(p => p.packageId === labelPackage.packageId)!!;
    lp.volumeMode = mode;

    state.validity = checkPackages(state.order, state.packages);
  }),
  saveLabels: thunk(async (actions, payload, { getState }) => {
    const { packages, order } = getState();

    const request: DetailedLabelRequest = {
      numberOfPackages: packages.length,
      packages: packages.map((p) => ({
        packageId: packages.indexOf(p) + 1,
        weight: p.volumeMode !== 'simple' ? p.proportions.weight : undefined,
        dimension1: p.volumeMode === 'dimensions' ? p.proportions.dimension1 : undefined,
        dimension2: p.volumeMode === 'dimensions' ? p.proportions.dimension2 : undefined,
        dimension3: p.volumeMode === 'dimensions' ? p.proportions.dimension3 : undefined,
        volume: p.volumeMode === 'volume' ? p.proportions.volume : undefined,
        size: p.volumeMode === 'simple' ? p.size : undefined,
        items: p.items.map((i) => ({
          id: i.id,
          count: i.count,
        })),
      })),
    };

    let labelUrl = '';
    try {
      actions.setGenerating(true);
      const label = await createLabelDetailed(order.id, request);
      if (label.status === 202) {
        notificationSuccess('The request to print the label has been submitted for processing', 10000);
      } else {
        labelUrl = label.headers.location;

        downloadData(label.data, 'application/pdf', `${order.id}.pdf`);
        notificationSuccess('Label generated');
      }
    } finally {
      actions.setGenerating(false);
    }

    return labelUrl;
  }),
  setGenerating: action((state, payload) => {
    state.generating = payload;
  }),
};

export default labelStore;
