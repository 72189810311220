// eslint-disable-next-line import/no-cycle
import api from './base';
import { allegroLinkResponseMock, mockUserData, mockUserDataDecoded } from './mocks/userMock';
import {
  AdminUpdatePasswordRequest,
  AllegroLinkingInfo,
  ChangePasswordRequest,
  CreateUserRequest,
  UpdateUserPartnersRequest,
  UserWithPartners,
} from './apiTypes';

export const USER_URL = 'user';

export default async function getUser(loginPayload: { username: string; password: string; }) {
  return api.get<string[]>(USER_URL, {
    auth: {
      username: loginPayload.username,
      password: loginPayload.password,
    },
  });
}

export async function createUser(request: CreateUserRequest) {
  return api.post('user/create', request);
}

api.mock.onPost('user/create').reply(204);

const errorResponse = {
  timestamp: '2024-01-03T09:44:30.679+00:00',
  path: '/v1/user',
  status: 401,
  error: 'Unauthorized',
  message: 'Authentication was not done or is invalid.',
  requestId: 'cc777b25-9',
};

let useErrorResponseOnce = true;

api.mock.onGet(USER_URL).reply(() => {
  if (useErrorResponseOnce) {
    useErrorResponseOnce = false;
    return [401, JSON.stringify(errorResponse)];
  }

  return [200, JSON.stringify(mockUserData)];
});

export async function changePassword(request: ChangePasswordRequest) {
  return api.post('user/change-password', request);
}

api.mock.onPost('user/change-password').reply(204);

export async function adminChangePassword(request: AdminUpdatePasswordRequest) {
  return api.post('user/change-password/admin', request);
}

api.mock.onPost('user/change-password/admin').reply(204);

export async function getAllegroLinkedInfo() {
  return api.get<AllegroLinkingInfo[]>('management/allegro/linked-accounts');
}

api.mock.onGet('management/allegro/linked-accounts').reply(200, allegroLinkResponseMock);

export async function postAllegroUnlinkAccount(
  partnerCode: string,
  eshopCode: string,
): Promise<null> {
  return api
    .post<null>(`/allegro/account-unlink/${partnerCode}/${eshopCode}`)
    .then((response) => response.data);
}

api.mock.onPost(/\/v1\/allegro\/account-unlink\/.+\/.+/).reply(204);

export async function getUsers() {
  return api.get<UserWithPartners[]>('user/all');
}

api.mock.onGet('user/all').reply(200, mockUserDataDecoded);

export async function updateUserPartners(updateRequest: UpdateUserPartnersRequest) {
  return api.put<null>('user/partners', updateRequest);
}

api.mock.onPut('user/partners').reply(204, null);
