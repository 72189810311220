import React, { useEffect, useReducer } from 'react';
import {
  Button, ButtonToolbar, Form, Loader, Message, SelectPicker, Stack, toaster,
} from 'rsuite';
import axios from 'axios';
import _ from 'lodash';
import { Eshop, getPartnerEshopDefaults, savePartnerEshopDefaults } from '../../../api/eshop/eshop';
import { AllegroImpliedWarranty, AllegroReturnPolicy, AllegroShippingRate } from '../../../api/allegroApiTypes';
import { PartnerEshopDefaults as Defaults } from '../../../api/apiTypes';
import { getAllegroImpliedWarranties, getAllegroReturnPolicies, getAllegroShippingRates } from '../../../api/allegro';
import EshopSetupAllegroMappedDeliveryMethods from './EshopSetupAllegroMappedDeliveryMethods';
import { utils } from '../../../utils/utils';

interface Props {
  eshop: Eshop;
}

interface State {
  isLoading: boolean;
  isSaving: boolean;
  data: Data;
  defaultsEdit: Defaults;
}

interface Data {
  defaults?: Defaults;
  shippingRates: AllegroShippingRate[];
  returnPolicies: AllegroReturnPolicy[];
  impliedWarranties: AllegroImpliedWarranty[];
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  data: {
    defaults: undefined,
    shippingRates: [],
    returnPolicies: [],
    impliedWarranties: [],
  },
  defaultsEdit: {
    defaultShippingRateId: '',
    defaultReturnPolicyId: '',
    defaultWarrantyId: '',
  },
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload: Data };
type EditAction = { type: 'edit', payload: Defaults };
type ResetAction = { type: 'reset' };
type SavingAction = { type: 'saving', payload: boolean };
type Action = LoadingAction
| LoadingSuccessfulAction
| EditAction
| ResetAction
| SavingAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        defaultsEdit: {
          defaultShippingRateId: _.defaultTo(action.payload.defaults?.defaultShippingRateId, ''),
          defaultReturnPolicyId: _.defaultTo(action.payload.defaults?.defaultReturnPolicyId, ''),
          defaultWarrantyId: _.defaultTo(action.payload.defaults?.defaultWarrantyId, ''),
        },
      };
    case 'edit':
      return { ...state, defaultsEdit: action.payload };
    case 'reset':
      return {
        ...state,
        defaultsEdit: {
          defaultShippingRateId: _.defaultTo(state.data.defaults?.defaultShippingRateId, ''),
          defaultReturnPolicyId: _.defaultTo(state.data.defaults?.defaultReturnPolicyId, ''),
          defaultWarrantyId: _.defaultTo(state.data.defaults?.defaultWarrantyId, ''),
        },
      };
    case 'saving':
      return { ...state, isSaving: action.payload };
    default:
      return state;
  }
}

function renderItem(value: any, item?: any): React.ReactNode {
  return item ? (
    <p>
      <strong>{item.name}</strong>
      {' '}
      {`(${item.id})`}
    </p>
  ) : value;
}

export default function EshopSetupAllegroDefaults({ eshop }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchDefaults(): Promise<Defaults | undefined> {
    try {
      return await getPartnerEshopDefaults(eshop.code);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        throw error;
      }
      return undefined;
    }
  }

  async function fetchAllegro(): Promise<[
    AllegroShippingRate[],
    AllegroReturnPolicy[],
    AllegroImpliedWarranty[],
  ]> {
    try {
      return await Promise.all([
        getAllegroShippingRates(eshop.code),
        getAllegroReturnPolicies(eshop.code),
        getAllegroImpliedWarranties(eshop.code),
      ]);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 401) {
        throw error;
      }
      return [[], [], []];
    }
  }

  async function fetchData(): Promise<Data> {
    dispatch({ type: 'loading' });
    const [defaults, [shippingRates, returnPolicies, impliedWarranties]] = await Promise.all([
      fetchDefaults(),
      fetchAllegro(),
    ]);
    return {
      defaults,
      shippingRates,
      returnPolicies,
      impliedWarranties,
    };
  }

  async function saveDefaults(): Promise<void> {
    dispatch({ type: 'saving', payload: true });
    const {
      defaultShippingRateId,
      defaultReturnPolicyId,
      defaultWarrantyId,
    } = state.defaultsEdit;
    const defaults: Defaults = {
      defaultShippingRateId: utils.defaultToUndefined(defaultShippingRateId),
      defaultReturnPolicyId: utils.defaultToUndefined(defaultReturnPolicyId),
      defaultWarrantyId: utils.defaultToUndefined(defaultWarrantyId),
    };
    try {
      await savePartnerEshopDefaults(eshop.code, defaults);
    } finally {
      dispatch({ type: 'saving', payload: false });
    }
  }

  useEffect(() => {
    fetchData()
      .then((response) => dispatch({ type: 'loadingSuccessful', payload: response }));
  }, [eshop]);

  function showSuccessMessage(): void {
    toaster.push(
      <Message
        type="success"
        closable
        showIcon
        duration={2000}
      >
        Defaults successfully saved.
      </Message>,
    );
  }

  function onSave() {
    saveDefaults()
      .then(() => showSuccessMessage());
  }

  function onCancel() {
    dispatch({ type: 'reset' });
  }

  return state.isLoading ? <Loader /> : (
    <Form
      layout="horizontal"
      formValue={state.defaultsEdit}
      onChange={(defaultsEdit) => dispatch({ type: 'edit', payload: defaultsEdit })}
    >
      <Form.Group>
        <Stack>
          <Form.ControlLabel>Shipping price list</Form.ControlLabel>
          <Form.Control
            name="defaultShippingRateId"
            accepter={SelectPicker}
            data={state.data.shippingRates}
            value={state.defaultsEdit.defaultShippingRateId ?? null}
            valueKey="id"
            labelKey="name"
            renderMenuItem={renderItem}
            renderValue={renderItem}
            cleanable
          />
          <div style={{ marginLeft: 20 }}>
            <EshopSetupAllegroMappedDeliveryMethods
              eshopCode={eshop.code}
              shippingRateId={state.defaultsEdit.defaultShippingRateId}
            />
          </div>
        </Stack>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Returns terms</Form.ControlLabel>
        <Form.Control
          name="defaultReturnPolicyId"
          accepter={SelectPicker}
          data={state.data.returnPolicies}
          value={state.defaultsEdit.defaultReturnPolicyId ?? null}
          valueKey="id"
          labelKey="name"
          renderMenuItem={renderItem}
          renderValue={renderItem}
          cleanable
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Complaints terms</Form.ControlLabel>
        <Form.Control
          name="defaultWarrantyId"
          accepter={SelectPicker}
          data={state.data.impliedWarranties}
          value={state.defaultsEdit.defaultWarrantyId ?? null}
          valueKey="id"
          labelKey="name"
          renderMenuItem={renderItem}
          renderValue={renderItem}
          cleanable
        />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="primary"
            onClick={() => onSave()}
            loading={state.isSaving}
            type="submit"
          >
            Save
          </Button>
          <Button
            appearance="ghost"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}
