import React from 'react';
import { Stack } from 'rsuite';
import { FaBoxOpen, FaPrint } from 'react-icons/fa';
import { OrderListItem, OrderStates } from '../../api/apiTypes';

interface Props {
  order: OrderListItem;
  onCreateShippingLabel?: (order: OrderListItem) => void;
  onAddCrossDockTracking?: (order: OrderListItem) => void;
}

export default function OrdersTableAction({
  order,
  onCreateShippingLabel,
  onAddCrossDockTracking,
}: Props) {
  if (order.managedByPartner) {
    return null;
  }

  const isCreateShippingLabelVisible = order.status === OrderStates.SHIPPING && order.updateStatus === 'UP_TO_DATE';
  const isCrossDockTrackingFilled = (order.numberOfCrossDockTrackings ?? 0) > 0;
  const crossDockTrackingColor = isCrossDockTrackingFilled ? '#4caf50' : 'var(--rs-gray-600)';

  function handleCreateShippingLabel() {
    onCreateShippingLabel?.(order);
  }

  function handleAddCrossDockTracking() {
    onAddCrossDockTracking?.(order);
  }

  return (
    <Stack spacing={10}>
      {isCreateShippingLabelVisible && (
      <FaPrint
        title="Create shipping label"
        cursor="pointer"
        onClick={() => handleCreateShippingLabel()}
      />
      )}
      <FaBoxOpen
        title="Add tracking info"
        cursor="pointer"
        color={crossDockTrackingColor}
        onClick={() => handleAddCrossDockTracking()}
      />
    </Stack>
  );
}

OrdersTableAction.defaultProps = {
  onCreateShippingLabel: undefined,
  onAddCrossDockTracking: undefined,
};
