import React, { useEffect, useState } from 'react';
import {
  Button, ButtonToolbar, Col, Grid, Input, InputPicker, Message, Row, Stack, useToaster,
} from 'rsuite';
import _ from 'lodash';
import { v4 } from 'uuid';
import { Eshop } from '../../../api/eshop/eshop';
import {
  getKauflandExternalShippingGroups,
  getKauflandTransportMappings,
  saveKauflandTransportMappings,
} from '../../../api/kaufland';
import { useStoreState } from '../../../store/hooks';
import { EshopCarrier, KauflandExternalShippingGroup, KauflandTransportMapping } from '../../../api/apiTypes';
import { getEshopCarriers } from '../../../api/carrier';

interface Props {
  eshop: Eshop
}

interface TransportMappingEdit {
  uuid: string;
  shippingGroup: KauflandExternalShippingGroup;
  eshopCarrier?: EshopCarrier;
}

interface TransportMappingRowProps {
  transportMappingEdit: TransportMappingEdit;
  eshopCarriers: EshopCarrier[];
  onChange: (eshopCarrier?: EshopCarrier) => void
}

function TransportMappingRow({
  transportMappingEdit, eshopCarriers, onChange,
}: TransportMappingRowProps) {
  function onEshopCarrierChange(id?: number): void {
    onChange(eshopCarriers.find((eshopCarrier) => eshopCarrier.id === id));
  }

  return (
    <Row gutter={15} style={{ paddingTop: 15 }}>
      <Col xs={12}>
        <Input
          value={`${transportMappingEdit.shippingGroup.name} (${transportMappingEdit.shippingGroup.shippingGroupId})`}
          readOnly
        />
      </Col>
      <Col xs={12}>
        <InputPicker
          data={eshopCarriers.map(eshopCarrier => ({
            label: eshopCarrier.name ?? eshopCarrier.identifier,
            value: eshopCarrier.id,
          }))}
          value={transportMappingEdit.eshopCarrier?.id}
          onChange={(id) => onEshopCarrierChange(id)}
          onClean={() => onEshopCarrierChange()}
          cleanable
        />
      </Col>
    </Row>
  );
}

function mapTransportMappingEdits(
  eshopCarriers: EshopCarrier[],
  shippingGroups: KauflandExternalShippingGroup[],
  transportMappings: KauflandTransportMapping[],
): TransportMappingEdit[] {
  return shippingGroups
    .map(shippingGroup => {
      const transportMapping = _.find(
        transportMappings,
        (value) => value.shippingGroupId === shippingGroup.shippingGroupId.toString(10),
      );
      const eshopCarrier = _.find(
        eshopCarriers,
        (value) => value.id === transportMapping?.eshopCarrierId,
      );

      return {
        uuid: v4(),
        shippingGroup,
        eshopCarrier,
      };
    });
}

function mapTransportMapping(
  transportMappingEdit: TransportMappingEdit,
  partnerCode: string,
): KauflandTransportMapping | undefined {
  if (!transportMappingEdit.eshopCarrier) {
    return undefined;
  }

  return {
    partnerCode,
    shippingGroupId: transportMappingEdit.shippingGroup.shippingGroupId.toString(10),
    eshopCarrierId: transportMappingEdit.eshopCarrier.id,
  };
}

export default function EshopSetupKauflandTransportMapping({ eshop }: Props) {
  const { currentUser } = useStoreState(state => state.user);
  const [transportMappingEdits, setTransportMappingEdits] = useState<TransportMappingEdit[]>([]);
  const [eshopCarriers, setEshopCarriers] = useState<EshopCarrier[]>([]);
  const [shippingGroups, setShippingGroups] = useState<KauflandExternalShippingGroup[]>([]);
  const [transportMappings, setTransportMappings] = useState<KauflandTransportMapping[]>([]);
  const toaster = useToaster();

  function showSuccessMessage() {
    toaster.push(
      <Message
        type="success"
        closable
        showIcon
        duration={2000}
      >
        Transport mapping successfully saved.
      </Message>,
    );
  }

  function showWarning() {
    toaster.push(
      <Message
        type="error"
        closable
        showIcon
        duration={2000}
      >
        All Kaufland shipping groups must be mapped to carriers.
      </Message>,
    );
  }

  function loadData() {
    const { partnerCode } = currentUser;
    const storefront = _.lowerCase(_.last(eshop.code.split('.')) ?? '');
    Promise
      .all(
        [
          getEshopCarriers({ eshopCode: eshop.code }),
          getKauflandExternalShippingGroups(partnerCode, storefront).catch(() => []),
          getKauflandTransportMappings(partnerCode),
        ],
      )
      .then((response) => ({
        eshopCarriers: response[0],
        shippingGroups: response[1],
        transportMappings: response[2],
      }))
      .then((response) => {
        setEshopCarriers(response.eshopCarriers);
        setShippingGroups(response.shippingGroups);
        setTransportMappings(response.transportMappings);
        setTransportMappingEdits(
          mapTransportMappingEdits(
            response.eshopCarriers,
            response.shippingGroups,
            response.transportMappings,
          ),
        );
      });
  }

  function saveTransportMappings() {
    const updatedTransportMappings = _.compact(transportMappingEdits
      .map(transportMappingEdit => mapTransportMapping(
        transportMappingEdit,
        currentUser.partnerCode,
      )));

    saveKauflandTransportMappings(updatedTransportMappings)
      .then(() => showSuccessMessage());
  }

  useEffect(() => {
    loadData();
  }, [eshop]);

  function onSave(): void {
    const areAllEshopCarriersFilled = transportMappingEdits
      .filter(transportMappingEdit => !transportMappingEdit.eshopCarrier)
      .length === 0;

    if (!areAllEshopCarriersFilled) {
      showWarning();
      return;
    }

    saveTransportMappings();
  }

  function onCancel(): void {
    setTransportMappingEdits([
      ...mapTransportMappingEdits(eshopCarriers, shippingGroups, transportMappings),
    ]);
  }

  function updateEshopCarrier(
    transportMappingEdit: TransportMappingEdit,
    eshopCarrier?: EshopCarrier,
  ): void {
    transportMappingEdit.eshopCarrier = eshopCarrier;
    setTransportMappingEdits([...transportMappingEdits]);
  }

  return (
    <Stack direction="column" alignItems="flex-start" spacing={15}>
      <Grid fluid>
        <Row gutter={15}>
          <Col xs={12}>Shipping Group</Col>
          <Col xs={12}>Carrier</Col>
        </Row>
        {
          transportMappingEdits.map(transportMappingEdit => (
            <TransportMappingRow
              key={transportMappingEdit.uuid}
              transportMappingEdit={transportMappingEdit}
              eshopCarriers={eshopCarriers}
              onChange={(eshopCarrier) => updateEshopCarrier(transportMappingEdit, eshopCarrier)}
            />
          ))
        }
      </Grid>
      <ButtonToolbar>
        <Button appearance="primary" onClick={() => onSave()} type="submit">Save</Button>
        <Button appearance="ghost" onClick={() => onCancel()}>Cancel</Button>
      </ButtonToolbar>
    </Stack>
  );
}
