import React, { useEffect, useRef, useState } from 'react';
import { Button, Message, Stack } from 'rsuite';
import { getPlatformIdSyncStatusForEshop, PlatformISyncStatus } from '../api/eshop/eshop';
import PlatformIdSyncExecution, { PlatformIdSyncRefType } from './PlatformIdSyncExecution';

interface PlatformIdSyncProps {
  show: boolean;
  eshopCodes: string[];
}

function PlatformIdSync({ show, eshopCodes }: PlatformIdSyncProps) {
  const syncExecutionRef = useRef<PlatformIdSyncRefType>(null);
  function syncProductPlatformIds() {
    syncExecutionRef.current?.synchronize(false);
  }

  const [platformIdSyncStatus, setPlatformIdSyncStatus] = useState<PlatformISyncStatus>();

  useEffect(() => {
    const eshopCode = eshopCodes[0];
    if (!eshopCode || eshopCodes.length > 1) {
      return;
    }

    getPlatformIdSyncStatusForEshop(eshopCode).then((data) => {
      setPlatformIdSyncStatus(data);
    });
  }, [eshopCodes]);

  function getPlatformIdSyncStatusMessage() {
    if (!platformIdSyncStatus) {
      return undefined;
    }

    const {
      numberOfProductsWithoutPlatformId: synced,
      numberOfProductsAssignedToEshop: total,
      eshopCode,
    } = platformIdSyncStatus;
    if (synced === total) {
      return <Message type="success">{`All ${total} ${eshopCode} products have BL product code assigned!`}</Message>;
    }
    return <Message type="warning">{`${synced} of ${total} products assigned to ${eshopCode} does not have BL product codes assigned.`}</Message>;
  }

  return (
    <>
      {show && (
      <Stack spacing={platformIdSyncStatus ? 6 : 0}>
        {getPlatformIdSyncStatusMessage()}
        <Button appearance="primary" onClick={() => syncProductPlatformIds()}>Sync BL product identifiers</Button>
      </Stack>
      )}
      <PlatformIdSyncExecution eshopCodes={eshopCodes} ref={syncExecutionRef} />
    </>
  );
}

export default PlatformIdSync;
