import {
  Col, Drawer, Grid, Row,
} from 'rsuite';
import React from 'react';
import styled from '@emotion/styled';
import { OrderListItem } from '../../api/apiTypes';
import CrossDockTrackingEdit from './CrossDockTrackingEdit';

interface Props {
  order?: OrderListItem;
  onClose: () => void;
}

const StyledText = styled.span`
  color: var(--rs-text-secondary);
  font-size: 14px;
`;

export default function CrossDockTrackingDrawer({ order, onClose }: Props) {
  function handleClose() {
    onClose();
  }

  return (
    <Drawer
      backdrop
      placement="bottom"
      size="lg"
      open={!!order}
      onClose={() => handleClose()}
    >
      <Drawer.Header>
        <Drawer.Title>
          <p>
            <strong>Cross dock tracking</strong>
            {' '}
            <StyledText>
              {order?.apiGroup ?? ''}
              {' '}
              -
              {' '}
              {order?.orderNumber}
              {' '}
              (
              {order?.id}
              )
            </StyledText>
          </p>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid>
          <Row>
            <Col md={14} mdOffset={3}>
              <CrossDockTrackingEdit
                order={order}
                onCancel={() => handleClose()}
              />
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
    </Drawer>
  );
}

CrossDockTrackingDrawer.defaultProps = {
  order: undefined,
};
