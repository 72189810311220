import React from 'react';
import { Container } from 'rsuite';
import Copyright from '../components/Copyright';

export default function PageNotFound() {
  return (
    <Container>
      <h1>
        Page not found
      </h1>

      <Copyright />
    </Container>
  );
}
