import { sample } from 'lodash';
import {
  BackendExceptionResponse,
  DetailedLabelRequest,
  LabelsMergeRequest,
  OrderForLabelPrint,
  OrderForPrintListItem,
  OrderItem,
  OrderListFilter,
  OrderListItem,
  OrderPreview,
  OrderStateUpdateEntity,
  OrderUpdateStatus,
  PagedRequest,
  PagedResponse,
  PrintExistingOrdersFilter,
  RevertedOrderStatus,
  SerialNumber,
  SimpleOrder,
  UpdateSerialNumbers,
} from './apiTypes';
import api from './base';
import {
  dummyPdf,
  mockGetOrderData1,
  mockGetOrderData2,
  mockGetOrderData3,
  mockGetOrderItems,
  mockGetOrderPreview,
  mockGetOrdersData,
  mockGetOrdersForPrintLists,
  mockGetOrdersLists,
  mockGetOrderStatusReverted,
  mockGetPackagePreviewOrder1,
  mockOrdersExport,
  orderListItemTemplate,
} from './mocks/ordersMock';

export const ORDERS_URL = 'management/orders';

export async function getOrdersList(request: PagedRequest<OrderListFilter>) {
  return api.post<PagedResponse<OrderListItem>>(`${ORDERS_URL}`, request);
}
api.mock.onPost(`${ORDERS_URL}`).reply(200, mockGetOrdersLists);

export async function updateState(request: OrderStateUpdateEntity) {
  return api.patch<OrderListItem>(`${ORDERS_URL}/${request.id}`, request);
}

api.mock.onPatch(/\/management\/orders\/\d+(?!\/)/).reply(200, orderListItemTemplate);

export async function fetchOrderUpdateStatus(orderId: number) {
  return api.get<OrderUpdateStatus>(`${ORDERS_URL}/${orderId}/update-status`);
}

export async function getOrderPreview(id: number) {
  return api.get<OrderPreview>(`${ORDERS_URL}/${id}/preview`);
}

api.mock.onGet(/\/management\/orders\/\d+\/preview/).reply(200, mockGetOrderPreview);

export async function getOrdersForLabel() {
  return api.get<SimpleOrder[]>(`${ORDERS_URL}/labels`);
}

api.mock.onGet(`${ORDERS_URL}/labels`).reply(200, mockGetOrdersData);

export async function getOrderForLabel(id: number) {
  return api.get<OrderForLabelPrint>(`${ORDERS_URL}/labels/${id}`);
}

api.mock.onGet(`${ORDERS_URL}/labels/111`).reply(200, mockGetOrderData1);
api.mock.onGet(`${ORDERS_URL}/labels/222`).reply(200, mockGetOrderData2);
api.mock.onGet(`${ORDERS_URL}/labels/333`).reply(200, mockGetOrderData3);
api.mock.onGet(/\/management\/orders\/labels\/\d+/).reply(
  200,
  sample([
    mockGetOrderData1,
    mockGetOrderData2,
    mockGetOrderData3]),
);

export async function createLabelSimple(orderId: number, numberOfPackages: number) {
  return api.post<File>(
    `${ORDERS_URL}/labels/${orderId}`,
    { numberOfPackages },
    {
      responseType: 'arraybuffer',
    },
  );
}

export async function createLabelDetailed(orderId: number, labelsRequest: DetailedLabelRequest) {
  return api.post<File>(
    `${ORDERS_URL}/labels/${orderId}`,
    labelsRequest,
    {
      responseType: 'arraybuffer',
    },
  );
}

export async function packagePreview(orderId: number, signal?: AbortSignal) {
  return api.get<DetailedLabelRequest>(
    `${ORDERS_URL}/labels/package-preview/${orderId}`,
    { signal },
  );
}

api.mock.onGet(`${ORDERS_URL}/labels/package-preview/111`).reply(200, mockGetPackagePreviewOrder1);
api.mock.onGet(/management\/orders\/labels\/package-preview\/\.*/).reply(200, mockGetPackagePreviewOrder1);

api.mock.onPost(`${ORDERS_URL}/labels/111`).reply(200, dummyPdf, { location: 'url.to.pdf' });
api.mock.onPost(`${ORDERS_URL}/labels/222`).reply(200, dummyPdf, { location: 'url.to.pdf' });
api.mock.onPost(`${ORDERS_URL}/labels/333`).reply(200, dummyPdf, { location: 'url.to.pdf' });

export async function exportOrderList(
  request: PagedRequest<OrderListFilter>,
) {
  return api.post<string | BackendExceptionResponse>('management/orders/export', request);
}

api.mock.onPost('management/orders/export').reply(200, mockOrdersExport);

export async function getOrdersForMultiplePrint(request: PagedRequest<PrintExistingOrdersFilter>) {
  return api.post<PagedResponse<OrderForPrintListItem>>(`${ORDERS_URL}/labels-for-merge`, request);
}
api.mock.onPost(`${ORDERS_URL}/labels-for-merge`).reply(200, mockGetOrdersForPrintLists);

export async function mergeLabels(request: LabelsMergeRequest) {
  return api.post<File>(`${ORDERS_URL}/labels-merge`, request, {
    responseType: 'arraybuffer',
  });
}
api.mock.onPost(`${ORDERS_URL}/labels-merge`).reply(200, dummyPdf);

export async function getOrderItems(orderId: number): Promise<OrderItem[]> {
  return api.get<OrderItem[]>(`${ORDERS_URL}/${orderId}/items`)
    .then(response => response.data);
}

api.mock
  .onGet(/\/management\/orders\/\d+\/items/)
  .reply(200, mockGetOrderItems);

export async function updateSerialNumbers(
  orderId: number,
  serialNumbers: SerialNumber[],
): Promise<null> {
  const request: UpdateSerialNumbers = {
    serialNumbers,
  };
  return api.patch<null>(`${ORDERS_URL}/${orderId}/serial-numbers`, request)
    .then((response) => response.data);
}

api.mock
  .onPatch(/\/management\/orders\/\d+\/serial-numbers/)
  .reply(204);

export async function revertOrderStatus(id: number) {
  return api.patch<RevertedOrderStatus>(`${ORDERS_URL}/${id}/revert-status`);
}

api.mock
  .onPatch(/\/management\/orders\/\d+\/revert-status/)
  .reply(config => {
    const id = parseInt(config!.url!.match(/\d+/)![0], 10);
    return [200, { ...mockGetOrderStatusReverted, orderId: id }];
  });

export async function updateParcelShopBranchId(
  orderId: number,
  parcelShopBranchId: string,
  signal?: AbortSignal,
): Promise<null> {
  return api.patch<null>(
    `${ORDERS_URL}/${orderId}/parcel-shop-branch-id/${parcelShopBranchId}`,
    { signal },
  )
    .then(response => response.data);
}

api.mock
  .onPatch(/\/management\/orders\/\d+\/parcel-shop-branch-id\/d+/)
  .reply(204);
