import React, { useEffect, useState } from 'react';
import {
  Button,
  CheckPicker,
  CheckTreePicker,
  Col,
  DateRangePicker,
  FlexboxGrid,
  Input,
  InputNumber,
  Stack,
} from 'rsuite';
import { DateRange, RangeType } from 'rsuite/esm/DateRangePicker/types';
import dayjs from 'dayjs';
import { ItemDataType } from 'rsuite/esm/@types/common';
import { CarrierFilter, OrderStates } from '../../api/apiTypes';
import { useStoreActions, useStoreState } from '../../store/hooks';
import {
  fromApiDateRange,
  lastMonthRange,
  lastWeekRange,
  toApiDateRange,
} from '../../services/dateUtils';
import { getFilterStates } from './OrdersTable';
import { Carrier } from '../../store/catalogs/types';
import AlzaIcon from '../../assets/AlzaIcon';
import AllegroIcon from '../../assets/AllegroIcon';
import MallIcon from '../../assets/MallIcon';

const ranges: RangeType[] = [
  {
    label: 'Yesterday',
    value: [dayjs().subtract(1, 'day').toDate(), dayjs().subtract(1, 'day').toDate()],
  },
  {
    label: 'Today',
    value: [new Date(), new Date()],
  },
  {
    label: 'Last 7 days',
    value: [dayjs().subtract(6, 'day').toDate(), dayjs().toDate()],
  },
  {
    label: 'Last week',
    value: lastWeekRange(),
  },
  {
    label: 'Last month',
    value: lastMonthRange(),
  },
];

const flags: { label: string, value: string }[] = [
  {
    label: 'Cross dock delivery',
    value: 'crossDockDelivery',
  },
];

function mapToDeliveryGroupName(group: string) {
  switch (group) {
    case 'ALLEGRO': return 'SHIP WITH ALLEGRO';
    case 'ALZA': return 'ALZA DELIVERY';
    case 'MALL_GROUP': return 'MALL DELIVERY';
    case 'MPI': return 'MPI DELIVERY';
    case 'PRIVATE': return 'PRIVATE TRANSPORT';
    default: return group;
  }
}

function mapCarriersToCheckTreePickerData(carriers: Carrier[]): ItemDataType<string>[] {
  const groupedCarriers = carriers.reduce<Record<string, ItemDataType<string>[]>>((acc, item) => {
    if (!acc[item.group]) {
      acc[item.group] = [];
    }
    acc[item.group].push({ value: item.id, label: item.name });
    return acc;
  }, {});

  const data: ItemDataType<string>[] = [];
  Object.keys(groupedCarriers).forEach(group => {
    data.push({
      value: group,
      label: mapToDeliveryGroupName(group),
      children: groupedCarriers[group],
    });
  });

  return data;
}

function getDeliveryIcon(group: string) {
  switch (group) {
    case 'ALLEGRO': return <AllegroIcon />;
    case 'ALZA': return <AlzaIcon />;
    case 'MALL_GROUP': return <MallIcon />;
    default: return undefined;
  }
}

export default function OrderFilter({ view, visible }:{ view:string, visible: boolean }) {
  const { fetchEshops, fetchCarriers } = useStoreActions(actions => actions.catalogs);
  const { setFilter } = useStoreActions(actions => actions.orders);
  const eshops = useStoreState(state => state.catalogs.eshops);
  const carriers = useStoreState(state => state.catalogs.carriers);
  const currentFilter = useStoreState(state => state.orders.currentFilter);
  const [isStatusLocked, setStatusLocked] = useState(view !== 'all');

  const { filter } = currentFilter;
  const [selectedStates, setSelectedStates] = useState<OrderStates[]>(filter.states);
  const [selectedEshops, setSelectedEshops] = useState<string[]>(filter.eshops ?? []);

  const filterCarriers = filter.carriers.map(c => `${c.apiGroup ?? 'PRIVATE'};${c.carrier}`);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>(filterCarriers);
  const [expeditionDate, setExpeditionDate] = useState<DateRange | null>(
    fromApiDateRange(filter.expedition),
  );
  const [createdDate, setCreatedDate] = useState<DateRange | null>(
    fromApiDateRange(filter.created),
  );
  const [deliveredDate, setDeliveredDate] = useState<DateRange | null>(
    fromApiDateRange(filter.delivered),
  );
  const [shippingDate, setShippingDate] = useState<DateRange | null>(
    fromApiDateRange(filter.shipping),
  );
  const [returnedDate, setReturnedDate] = useState<DateRange | null>(
    fromApiDateRange(filter.returned),
  );
  const [shippedDate, setShippedDate] = useState<DateRange | null>(
    fromApiDateRange(filter.shipped),
  );
  const [cancelledDate, setCancelledDate] = useState<DateRange | null>(
    fromApiDateRange(filter.cancelled),
  );

  const [orderValueFrom, setOrderValueFrom] = useState<number | string>(filter.orderValue?.from ?? '');
  const [orderValueTo, setOrderValueTo] = useState<number | string>(filter.orderValue?.to ?? '');

  const [originalOrderId, setOriginalOrderId] = useState(filter.originalOrderId ?? '');
  const [partnerOrderId, setPartnerOrderId] = useState(filter.partnerOrderId ?? '');

  const [selectedFlags, setSelectedFlags] = useState<string[]>([]);

  useEffect(() => {
    fetchEshops();
    fetchCarriers();
  }, [fetchEshops, fetchCarriers]);

  useEffect(() => {
    setStatusLocked(view !== 'all');
    if (view === 'all') {
      setSelectedStates([]);
    } else {
      setSelectedStates(getFilterStates(view));
    }
  }, [view]);

  const states = Object.values(OrderStates)
    .filter(s => s !== OrderStates.RESERVATION)
    .sort((a, b) => a.localeCompare(b))
    .map(s => ({ value: s, label: s }));

  const applyFilters = () => {
    const global = currentFilter.filter.globalFilter;

    function parseCarriers(ids: string[]): CarrierFilter[] {
      const result: CarrierFilter[] = [];
      ids.forEach(id => {
        const split = id.split(';');
        const group = split[0];
        const carrier = split[1];

        let carrierIds = [carrier];
        if (!carrier) {
          carrierIds = carriers.filter(c => c.group === group)
            .map(c => c.name);
        }
        carrierIds.forEach(carrierId => {
          result.push({
            managedByPartner: group === 'PRIVATE',
            apiGroup: group === 'PRIVATE' ? undefined : group,
            carrier: carrierId,
          });
        });
      });
      return result;
    }

    const newFilter = {
      ...currentFilter,
      page: 1,
      filter: {
        globalFilter: global,
        states: selectedStates,
        eshops: selectedEshops,
        expedition: toApiDateRange(expeditionDate),
        created: toApiDateRange(createdDate),
        delivered: toApiDateRange(deliveredDate),
        shipping: toApiDateRange(shippingDate),
        shipped: toApiDateRange(shippedDate),
        returned: toApiDateRange(returnedDate),
        cancelled: toApiDateRange(cancelledDate),
        originalOrderId,
        orderValue: {
          from: orderValueFrom !== '' ? +orderValueFrom : null,
          to: orderValueTo !== '' ? +orderValueTo : null,
        },
        partnerOrderId,
        carriers: parseCarriers(selectedCarriers),
        crossDockDelivery: selectedFlags.includes('crossDockDelivery') || undefined,
      },
    };
    setFilter(newFilter);
  };

  const clearFilters = () => {
    if (!isStatusLocked) {
      setSelectedStates([]);
    }

    setSelectedEshops([]);
    setExpeditionDate(null);
    setCreatedDate(null);
    setDeliveredDate(null);
    setShippingDate(null);
    setReturnedDate(null);
    setShippedDate(null);
    setCancelledDate(null);
    setOriginalOrderId('');
    setPartnerOrderId('');
    setOrderValueFrom('');
    setOrderValueTo('');
    setSelectedCarriers([]);
    setSelectedFlags([]);

    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        globalFilter: currentFilter.filter.globalFilter,
        states: [],
        eshops: [],
        expedition: undefined,
        created: undefined,
        delivered: undefined,
        shipping: undefined,
        shipped: undefined,
        returned: undefined,
        cancelled: undefined,
        originalOrderId: '',
        orderValue: undefined,
        partnerOrderId: '',
        carriers: [],
        crossDockDelivery: undefined,
      },
    });
  };

  if (!visible) {
    return null;
  }

  return (
    <FlexboxGrid align="middle" justify="start" className="flex-box-filters mt-10">
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Marketplace
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          data={eshops}
          labelKey="name"
          valueKey="code"
          searchable={false}
          block
          value={selectedEshops}
          onChange={setSelectedEshops}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Suggested expedition
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          ranges={ranges}
          value={expeditionDate}
          onChange={setExpeditionDate}
          placement="bottomEnd"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Created
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          value={createdDate}
          onChange={setCreatedDate}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Delivered
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          value={deliveredDate}
          onChange={setDeliveredDate}
          ranges={ranges}
          placement="bottomEnd"
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Status
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          searchable={false}
          data={states}
          block
          disabled={isStatusLocked}
          value={selectedStates}
          onChange={setSelectedStates}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Order value
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Stack spacing={5}>
          <InputNumber value={orderValueFrom} onChange={setOrderValueFrom} min={0} />
          <span>-</span>
          <InputNumber value={orderValueTo} onChange={setOrderValueTo} min={0} />
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Shipping
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          value={shippingDate}
          onChange={setShippingDate}
          ranges={ranges}
          placement="bottomEnd"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Returned
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          value={returnedDate}
          onChange={setReturnedDate}
          ranges={ranges}
          placement="bottomEnd"
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Order Number (MP)
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Input
          value={originalOrderId}
          onChange={setOriginalOrderId}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Order Number (EXT)
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <Input value={partnerOrderId} onChange={setPartnerOrderId} />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Shipped
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          value={shippedDate}
          onChange={setShippedDate}
          ranges={ranges}
          placement="bottomEnd"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Cancelled
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <DateRangePicker
          isoWeek
          placeholder="Select"
          block
          value={cancelledDate}
          onChange={setCancelledDate}
          ranges={ranges}
          placement="bottomEnd"
        />
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Carrier
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckTreePicker
          searchable={false}
          data={mapCarriersToCheckTreePickerData(carriers)}
          value={selectedCarriers}
          block
          onChange={(values) => setSelectedCarriers(values as string[])}
          renderTreeNode={nodeData => (
            <span>
              {getDeliveryIcon(nodeData.value as string)}
              {' '}
              {nodeData.label}
            </span>
          )}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
        Flags
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
        <CheckPicker
          searchable
          block
          data={flags}
          valueKey="value"
          labelKey="label"
          value={selectedFlags}
          onChange={setSelectedFlags}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} lg={6} colspan={24}>
        <Stack spacing={10} alignItems="flex-end" justifyContent="flex-end">
          <Button appearance="primary" style={{ width: 80 }} onClick={applyFilters}>Apply</Button>
          <Button appearance="primary" style={{ width: 80 }} onClick={clearFilters}>Clear</Button>
        </Stack>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
