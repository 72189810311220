import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack } from 'rsuite';

export default function AccountLinkingStatus() {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);
  const status = params.get('status');
  const errorDesc = params.get('description');

  const message = status === 'ok'
    ? 'Your account was successfully linked with allegro.'
    : 'There were some problems during account linking.';

  function handleNavigation() {
    navigate(-1);
  }

  return (
    <div>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={10}
        direction="column"
        className="pt-20"
      >

        <h4>
          {message}
        </h4>

        {status !== 'ok' && errorDesc && (
          <div>
            Reason send from allegro:
            {' '}
            {errorDesc}
          </div>
        )}

        <Button
          appearance="link"
          color="blue"
          onClick={() => handleNavigation()}
        >
          Return back to application
        </Button>

      </Stack>
    </div>
  );
}
