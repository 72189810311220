import { action, thunk } from 'easy-peasy';
import { ProductStoreModel } from './types';
import { getProductList } from '../../api/products';

const productStore: ProductStoreModel = {
  loading: false,
  products: [],
  total: 0,
  apiGroup: undefined,
  currentFilter: {
    pageSize: 50,
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      brands: [],
      states: [],
      categories: [],
      eshopIds: [],
      holdStatuses: [],
    },
  },

  fetchProducts: thunk(async (actions, payload) => {
    actions.setLoading(true);
    const { data } = await getProductList(payload.apiGroup, payload.request);

    actions.setProducts({ total: data.total, products: data.data });
    actions.setLoading(false);
  }),
  setProducts: action((state, payload) => {
    state.total = payload.total;
    state.products = payload.products;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setFilter: action((state, payload) => {
    state.currentFilter = payload;
  }),
  setApiGroup: action((state, payload) => {
    state.apiGroup = payload;
  }),
};

export default productStore;
