import handleError from '../api/apiErrorHandle';

export default function registerUnhandledRejection() {
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    const { reason: error } = event;
    handleError(error);
    if (process.env.NODE_ENV === 'production') {
      event.preventDefault();
    }
  });
}
