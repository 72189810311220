import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  Message,
  Schema,
  SelectPicker,
  Toggle,
  useToaster,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { fetchCustomFields } from './BaselinkerClient';
import { deleteCustomFields, getCustomFields, saveCustomFields } from '../api/baselinker/baselinker';
import { CustomFields } from '../api/baselinker/apiTypes';

function countOccurrences(arr: string[]) {
  return arr.reduce((acc: Record<string, number>, val: string) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
}

function hasAnyDefinedProperties(obj: any): boolean {
  return Object.values(obj).some(value => value !== undefined);
}

interface CustomFieldsFormProps {
  blLinked: boolean;
  blApiToken: string | undefined;
  eshopCode?: string;
}

export default function CustomFieldsForm(
  { blLinked, blApiToken, eshopCode } : CustomFieldsFormProps,
) {
  const navigate = useNavigate();
  const toaster = useToaster();
  const [customFields, setCustomFields] = useState<Record<string, any>[]>([]);
  const formRef = useRef<any>();
  const [formValue, setFormValue] = useState<Record<string, any>>({});
  const [useDifferentSetup, setUseDifferentSetup] = useState<boolean>(false);

  useEffect(() => {
    if (blLinked && blApiToken) {
      fetchCustomFields(blApiToken).then((data) => setCustomFields(data));
    }
  }, [blLinked]);

  const customFieldRule = Schema.Types.NumberType()
    .isRequired('This field is required.')
    .addRule((value: any, data: any) => {
      const occurrence = countOccurrences([
        data.marketplaceOrderId,
        data.labelUrl,
        data.numberOfPackages,
        data.suggestedExpedition,
      ]);
      return occurrence[value] <= 1;
    }, 'Value can be present only once.')
    .addRule((value) => customFields.filter(x => x.value === value).length === 1, 'Select this value');

  useEffect(() => {
    getCustomFields(eshopCode).then(mpiCustomFields => {
      setFormValue(prevState => ({
        ...prevState,
        marketplaceOrderId: Number(mpiCustomFields.marketplaceOrderId),
        labelUrl: Number(mpiCustomFields.labelUrl),
        numberOfPackages: Number(mpiCustomFields.numberOfPackages),
        suggestedExpedition: Number(mpiCustomFields.suggestedExpedition),
      }));
      if (eshopCode && hasAnyDefinedProperties(mpiCustomFields)) {
        setUseDifferentSetup(true);
      } else {
        setUseDifferentSetup(false);
      }
    });
  }, [eshopCode]);

  function onSubmit() {
    if (eshopCode && !useDifferentSetup) {
      deleteCustomFields(eshopCode).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>{`Custom fields for ${eshopCode} deleted`}</Message>);
      });
      return;
    }

    if (formRef.current?.check()) {
      const customFieldsToSave: CustomFields = {
        numberOfPackages: formValue.numberOfPackages,
        suggestedExpedition: formValue.suggestedExpedition,
        labelUrl: formValue.labelUrl,
        marketplaceOrderId: formValue.marketplaceOrderId,
      };
      saveCustomFields(customFieldsToSave, eshopCode).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Custom fields saved</Message>);
      });
    } else {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Please check the form there are validation errors.</Message>);
    }
  }

  return (
    <>
      {eshopCode && (
      <Form layout="horizontal">
        <Form.Group>
          <Form.ControlLabel>{`Use different setup for ${eshopCode}`}</Form.ControlLabel>
          <Toggle checked={useDifferentSetup} onChange={setUseDifferentSetup} />
        </Form.Group>
      </Form>
      )}
      {(!eshopCode || useDifferentSetup) && (
      <Form ref={formRef} formValue={formValue} layout="horizontal" onChange={setFormValue}>
        <Form.Group controlId="marketplaceOrderId">
          <Form.ControlLabel>Marketplace Order Id</Form.ControlLabel>
          <Form.Control name="marketplaceOrderId" accepter={SelectPicker} data={customFields} rule={customFieldRule} />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
        <Form.Group controlId="labelUrl">
          <Form.ControlLabel>Label URL</Form.ControlLabel>
          <Form.Control name="labelUrl" accepter={SelectPicker} data={customFields} rule={customFieldRule} />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
        <Form.Group controlId="numberOfPackages">
          <Form.ControlLabel>Number of packages</Form.ControlLabel>
          <Form.Control name="numberOfPackages" accepter={SelectPicker} data={customFields} rule={customFieldRule} />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
        <Form.Group controlId="suggestedExpedition">
          <Form.ControlLabel>Suggested expedition</Form.ControlLabel>
          <Form.Control name="suggestedExpedition" accepter={SelectPicker} data={customFields} rule={customFieldRule} />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
      </Form>
      )}
      <Form layout="horizontal">
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
}

CustomFieldsForm.defaultProps = {
  eshopCode: undefined,
};
