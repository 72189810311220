import React from 'react';
import { Eshop } from '../../../../api/eshop/eshop';
import { AllegroLinkingInfo } from '../../../../api/apiTypes';
import EshopSetupAllegroCredentialsLink from './EshopSetupAllegroCredentialsLink';

interface Props {
  eshop: Eshop;
  linkingInfos: AllegroLinkingInfo[],
  onUpdate: () => void
}

export default function EshopSetupAllegroCredentials({
  eshop,
  linkingInfos,
  onUpdate,
}: Props) {
  const linkingInfo = linkingInfos.find(value => value.eshopCode === eshop?.code);

  if (!linkingInfo) {
    return null;
  }

  return (
    <EshopSetupAllegroCredentialsLink
      linkingInfo={linkingInfo}
      onSuccessfulUnlink={onUpdate}
    />
  );
}
