import React, { useEffect, useState } from 'react';
import {
  Button, ButtonToolbar, CheckPicker, Form, Message, useToaster,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import {
  Partner, getPartners,
} from '../api/partner';
import { UserPartnerEntity } from '../api/apiTypes';
import { createUser } from '../api/user';

export default function PartnerSetup() {
  const navigate = useNavigate();
  const toaster = useToaster();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [newUsername, setUsername] = useState<string>();
  const [newName, setName] = useState<string>();
  const [newPassword, setPassword] = useState<string>();
  const [selectedPartners, setSelectedPartners] = useState<UserPartnerEntity[]>([]);

  useEffect(() => {
    getPartners()
      .then(response => {
        setPartners(response.data);
      });
  }, []);

  function handleUserPartnerChange(selectedPartnerCodes: string[]) {
    const userPartnerEntities = partners.filter(p => selectedPartnerCodes
      .includes(p.code)).map(partner => ({
      partnerCode: partner.code,
      partnerName: partner.name,
    }));
    setSelectedPartners(userPartnerEntities);
  }

  function onSubmit() {
    const request = {
      username: newUsername!,
      name: newName!,
      password: newPassword!,
      partners: selectedPartners,
    };

    navigate(-1);
    createUser(request)
      .then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>User created!</Message>);
      })
      .catch(() => {
        toaster.push(<Message type="error" closable showIcon duration={2000}>User creation failed!</Message>);
      });
  }

  return (
    <div className="m-10">
      <h4>Create new user</h4>
      <Form layout="horizontal">
        <Form.Group controlId="username">
          <Form.ControlLabel>Username</Form.ControlLabel>
          <Form.Control onChange={(value: string) => setUsername(value)} name="username" />
        </Form.Group>

        <Form.Group controlId="name">
          <Form.ControlLabel>Name</Form.ControlLabel>
          <Form.Control onChange={(value: string) => setName(value)} name="name" />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.ControlLabel>Password</Form.ControlLabel>
          <Form.Control type="password" onChange={(value: string) => setPassword(value)} name="password" />
        </Form.Group>

        <Form.Group controlId="partners">
          <Form.ControlLabel>Partners</Form.ControlLabel>
          <CheckPicker
            data={partners}
            labelKey="name"
            valueKey="code"
            value={selectedPartners.map((p: UserPartnerEntity) => p.partnerCode)}
            onChange={(partnerCodes) => handleUserPartnerChange(partnerCodes)}
          />
        </Form.Group>

        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
}
