import { Form, Schema, Stack } from 'rsuite';
import React from 'react';

interface Props {
  index: number;
  tracking: Tracking;
  onChange: (tracking: Tracking) => void;
}

interface Tracking {
  id: string;
  trackingNumber: string;
  trackingUrl: string;
}

export default function CrossDockTrackingRow({ index, tracking, onChange }: Props) {
  function handleTrackingNumberChange(value: string) {
    onChange({ ...tracking, trackingNumber: value });
  }

  function handleTrackingUrlChange(value: string) {
    onChange({ ...tracking, trackingUrl: value });
  }

  return (
    <Stack direction="column" alignItems="flex-start" spacing={10}>
      <h6>
        Package
        {' '}
        {index + 1}
      </h6>
      <Stack>
        <Form.Group>
          <Form.ControlLabel>
            Tracking number
          </Form.ControlLabel>
          <Form.Control
            name={`trackingNumber.${tracking.id}`}
            type="text"
            value={tracking.trackingNumber}
            onChange={(value: string) => handleTrackingNumberChange(value)}
            rule={Schema.Types.StringType().addRule(() => {
              if (!tracking.trackingUrl) {
                return true;
              }

              return tracking.trackingNumber.length > 0;
            }, 'Tracking number is required.', true)}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Tracking url
          </Form.ControlLabel>
          <Form.Control
            name={`trackingUrl.${tracking.id}`}
            type="text"
            value={tracking.trackingUrl}
            onChange={(value: string) => handleTrackingUrlChange(value)}
          />
        </Form.Group>
      </Stack>
    </Stack>
  );
}
