import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button, ButtonToolbar, Divider, Form, InputNumber, Message, Nav, Schema, Toggle, useToaster,
} from 'rsuite';
import { PARTNER_SETUP } from '../routes/links';
import {
  Eshop, EshopSetup, getEshopSetup, saveEshopSetup,
} from '../api/eshop/eshop';
import PlatformIdSync from './PlatformIdSync';
import CustomFieldsForm from './CustomFieldsForm';
import OrderStatusesForm from './OrderStatusesForm';
import BasicInformationForm from './BasicInformationForm';
import { EshopContext } from './EshopContextProvider';

interface BaselinkerIntegrationEshopFormProps {
  blApiToken: string | undefined
}

export default function BaselinkerIntegrationEshopForm(
  { blApiToken }: BaselinkerIntegrationEshopFormProps,
) {
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const toaster = useToaster();
  const eshops = useContext<Eshop[] | undefined>(EshopContext);
  const mallEshops: string[] = eshops ? eshops?.filter(eshop => eshop.apiGroup === 'MALL_GROUP').map(eshop => eshop.code) : [];
  const [eshopAlreadyConfigured, setEshopAlreadyConfigured] = useState<boolean>(false);
  // eslint-disable-next-line prefer-const
  let { apiGroup, eshopCode } = useParams();
  eshopCode = eshopCode || mallEshops[0];

  const initialState = {
    enableSyncWithEshop: true,
    id: '',
    clientId: '',
    stockThreshold: '',
    expeditionDays: '1',
  };
  const [formValue, setFormValue] = useState<Record<string, any>>(initialState);

  const idMandatoryRule = Schema.Types.StringType().isRequired('This field is required');
  const clientIdRule = Schema.Types.StringType().isRequired('This field is required');

  function onSubmit() {
    if (formRef.current?.check()) {
      const eshopSetup: EshopSetup = {
        enableSyncWithEshop: formValue.enableSyncWithEshop,
        id: formValue.id,
        clientId: formValue.clientId,
        stockThreshold: formValue.stockThreshold ? Number(formValue.stockThreshold) : null,
        expeditionDays: Number(formValue.expeditionDays),
      };
      saveEshopSetup(eshopCode as string, eshopSetup).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Saved!</Message>);
        setEshopAlreadyConfigured(true);
      });
    } else {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Please check the form there are validation errors.</Message>);
    }
  }

  useEffect(() => {
    getEshopSetup(eshopCode as string).then((responseData: EshopSetup) => {
      if (responseData) {
        setFormValue(() => ({
          enableSyncWithEshop: responseData.enableSyncWithEshop,
          id: responseData.id,
          clientId: responseData.clientId,
          stockThreshold: responseData.stockThreshold ? String(responseData.stockThreshold) : '',
          expeditionDays: responseData.expeditionDays ? String(responseData.expeditionDays) : '',
        }));
        setEshopAlreadyConfigured(true);
      } else {
        setFormValue(initialState);
        setEshopAlreadyConfigured(false);
      }
    });
  }, [eshopCode]);

  return (
    <div>
      <Nav className="nav-large" appearance="subtle" activeKey={eshopCode}>
        {mallEshops.map(code => <Nav.Item key={code} eventKey={code} as={Link} to={`${PARTNER_SETUP}/${apiGroup}/${code}`}>{code}</Nav.Item>)}
      </Nav>
      <br />
      <Form ref={formRef} layout="horizontal" formValue={formValue} onChange={setFormValue}>
        <Form.Group controlId="enableSyncWithEshop">
          <Form.ControlLabel>{`Enable sync with ${eshopCode}`}</Form.ControlLabel>
          <Form.Control name="enableSyncWithEshop" checked={formValue.enableSyncWithEshop} accepter={Toggle} />
        </Form.Group>
        <Form.Group controlId="id">
          <Form.ControlLabel>ID</Form.ControlLabel>
          <Form.Control name="id" rule={idMandatoryRule} />
        </Form.Group>
        <Form.Group controlId="clientId">
          <Form.ControlLabel>Client ID</Form.ControlLabel>
          <Form.Control name="clientId" rule={clientIdRule} />
        </Form.Group>
        <Form.Group controlId="stockThreshold">
          <Form.ControlLabel>Stock threshold</Form.ControlLabel>
          <Form.Control name="stockThreshold" accepter={InputNumber} />
        </Form.Group>
        <Form.Group controlId="expeditionDays">
          <Form.ControlLabel>Expedition days</Form.ControlLabel>
          <Form.Control name="expeditionDays" accepter={InputNumber} rule={idMandatoryRule} />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      <br />
      <PlatformIdSync show={eshopAlreadyConfigured} eshopCodes={[eshopCode]} />
      <Divider>Custom Fields</Divider>
      <CustomFieldsForm blLinked blApiToken={blApiToken} eshopCode={eshopCode} />
      <Divider>Basic information</Divider>
      <BasicInformationForm blLinked blApiToken={blApiToken} eshopCode={eshopCode} />
      <Divider>Order statuses</Divider>
      <OrderStatusesForm blLinked blApiToken={blApiToken} eshopCode={eshopCode} />
    </div>
  );
}
