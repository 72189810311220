import React from 'react';
import FormGroup from 'rsuite/FormGroup';
import { Radio, RadioGroup } from 'rsuite';
import { ValueType } from 'rsuite/Radio';

interface Props {
  isBaseMarketplace: boolean;
  onUpdate: (isBaseMarketplace: boolean) => void;
}

export default function EshopSetupDetailBaseMarketplace(
  { isBaseMarketplace, onUpdate }: Props,
) {
  const radioValue = isBaseMarketplace ? 'base' : 'additional';

  function handleChange(value: ValueType) {
    onUpdate(value === 'base');
  }

  return (
    <FormGroup>
      <RadioGroup inline value={radioValue} onChange={(value) => handleChange(value)}>
        <Radio value="base">Base marketplace</Radio>
        <Radio value="additional">Additional marketplace</Radio>
      </RadioGroup>
    </FormGroup>
  );
}
