import React, { useState } from 'react';
import { Button, Modal } from 'rsuite';
import {
  FaQuestionCircle,
} from 'react-icons/fa';
import uploadGuidelines from '../assets/uploadGuidelines';
import CardActionImportGuide from './CardActionImportGuide';

export default function Guidelines() {
  const [guidelinesDialogOpen, setGuidelinesDialogOpen] = useState(false);

  return (
    <>
      <CardActionImportGuide onClick={() => setGuidelinesDialogOpen(true)}>
        <FaQuestionCircle />
        <span>Upload guidelines</span>
      </CardActionImportGuide>
      <Modal
        open={guidelinesDialogOpen}
        size="full"
        onClose={() => setGuidelinesDialogOpen(false)}
      >
        <Modal.Header>Upload guidelines</Modal.Header>
        <Modal.Body>
          {uploadGuidelines.split('\n').map((paragraph) => <p key={paragraph}>{paragraph}</p>)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setGuidelinesDialogOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
