import { action, thunk } from 'easy-peasy';
import Enumerable from 'linq';
import { CatalogStoreModel } from './types';
import {
  getBrands,
  getCancelReasons,
  getCarriers,
  getCategories,
  getPackageSizes,
  getPartnerEshops,
} from '../../api/catalogs';

const catalogStore : CatalogStoreModel = {
  brands: [],
  categories: [],
  eshops: [],
  apiGroups: [],
  carriers: [],
  cancelReasons: [],
  packageSizes: [],
  categoryFetched: false,
  brandsFetched: false,
  eshopsFetched: false,
  carriersFetched: false,
  cancelReasonsFetched: false,
  packageSizesFetched: false,

  fetchBrands: thunk(async (actions, _, { getState }) => {
    if (getState().brandsFetched) {
      return;
    }

    const { data } = await getBrands();

    actions.setBrands(data.brands);
    actions.setBrandsFetched(true);
  }),
  fetchCategories: thunk(async (actions, _, { getState }) => {
    if (getState().categoryFetched) {
      return;
    }
    const { data } = await getCategories();

    actions.setCategories(data.categories);
    actions.setCategoriesFetched(true);
  }),
  fetchEshops: thunk(async (actions, _, { getState }) => {
    if (getState().eshopsFetched) {
      return getState().eshops;
    }
    const { data } = await getPartnerEshops();
    const eshops = data.partners
      .flatMap(p => p.apiGroups
        .flatMap(group => group.eshops.map(e => ({ ...e, apiGroup: group.code }))));

    eshops.sort((a, b) => a.name.localeCompare(b.name));

    const apiGroups = data.partners.flatMap(p => p.apiGroups.flatMap(g => g.code));
    const groups = Enumerable.from(apiGroups).distinct().toArray();

    actions.setEshops(eshops);
    actions.setApiGroups(groups);
    actions.setEshopsFetched(true);

    return eshops;
  }),
  fetchCarriers: thunk(async (actions, _, { getState }) => {
    if (getState().carriersFetched) {
      return;
    }

    const { data } = await getCarriers();
    const carriers = data.map(c => ({
      group: c.group,
      groupName: c.group === 'PRIVATE' ? 'Private transport' : c.group,
      name: c.identifier,
      id: `${c.group};${c.identifier}`,
    }));

    actions.setCarriers(carriers);
    actions.setCarriersFetched(true);
  }),
  fetchCancelReasons: thunk(async (actions, _, { getState }) => {
    if (getState().cancelReasonsFetched) {
      return;
    }

    const { data } = await getCancelReasons();

    actions.setCancelReasons(data);
    actions.setCancelReasonsFetched(true);
  }),
  fetchPackageSizes: thunk(async (actions, _, { getState }) => {
    if (getState().packageSizesFetched) {
      return getState().packageSizes;
    }

    const { data } = await getPackageSizes();
    actions.setPackageSizes(data);
    actions.setPackageSizesFetched(true);

    return data;
  }),
  setBrands: action((state, payload) => {
    state.brands = payload;
  }),
  setCategories: action((state, payload) => {
    state.categories = payload;
  }),
  setEshops: action((state, payload) => {
    state.eshops = payload;
  }),
  setApiGroups: action((state, payload) => {
    state.apiGroups = payload;
  }),
  setEshopsFetched: action((state, payload) => {
    state.eshopsFetched = payload;
  }),
  setCarriers: action((state, payload) => {
    state.carriers = payload;
  }),
  setCancelReasons: action((state, payload) => {
    state.cancelReasons = payload;
  }),
  setPackageSizes: action((state, payload) => {
    state.packageSizes = payload;
  }),
  setBrandsFetched: action((state, payload) => {
    state.brandsFetched = payload;
  }),
  setCategoriesFetched: action((state, payload) => {
    state.categoryFetched = payload;
  }),
  setCarriersFetched: action((state, payload) => {
    state.carriersFetched = payload;
  }),
  setCancelReasonsFetched: action((state, payload) => {
    state.cancelReasonsFetched = payload;
  }),
  setPackageSizesFetched: action((state, payload) => {
    state.packageSizesFetched = payload;
  }),
};

export default catalogStore;
