import React, { useEffect, useState } from 'react';
import { Input, Stack } from 'rsuite';
import { OrderItem, OrderWithItems, SerialNumber } from '../../api/apiTypes';

interface SerialNumberRowProps {
  serialNumber: SerialNumber;
  onChange: () => void;
}

function SerialNumberRow({ serialNumber, onChange }: SerialNumberRowProps): JSX.Element {
  const [text, setText] = useState<string>(serialNumber.serialNumber ?? '');

  function handleOnChange(value: string): void {
    setText(value);
    serialNumber.serialNumber = value;
    onChange();
  }

  return (
    <tr>
      <td><b>Serial number</b></td>
      <td colSpan={2}>
        <Input
          placeholder="Required"
          value={text}
          onChange={(value) => handleOnChange(value)}
        />
      </td>
    </tr>
  );
}

interface OrderItemRowProps {
  orderItem: OrderItem;
  onChange: () => void;
}

function OrderItemRow({ orderItem, onChange }: OrderItemRowProps): JSX.Element | null {
  const {
    partnerProductCode, ean, orderProductCode, title, serialNumbers,
  } = orderItem;

  function handleOnChange(): void {
    onChange();
  }

  return serialNumbers.length > 0 ? (
    <>
      <tr>
        <td><b>{partnerProductCode}</b></td>
        <td><b>{ean}</b></td>
        <td><b>{orderProductCode}</b></td>
        <td><b>{title}</b></td>
      </tr>
      {serialNumbers.map((serialNumber) => (
        <SerialNumberRow
          key={serialNumber.id}
          serialNumber={serialNumber}
          onChange={() => handleOnChange()}
        />
      ))}
      <tr>
        <td colSpan={4}>
          <div style={{ background: '#78ABC6', height: '2px' }} />
        </td>
      </tr>
    </>
  ) : null;
}

interface OrdersSerialNumbersFormProps {
  ordersWithItems: OrderWithItems[];
  onChange: (ordersWithItems: OrderWithItems[]) => void;
  onValidation: (isValid: boolean) => void;
}

export default function OrdersSerialNumbersForm(
  { ordersWithItems, onChange, onValidation }: OrdersSerialNumbersFormProps,
): JSX.Element | null {
  function filterOrdersWithItems(): OrderWithItems[] {
    return ordersWithItems
      .filter((orderWithItems) => {
        const { items: orderItems } = orderWithItems;
        const serialNumbers = orderItems
          .map((orderItem) => orderItem.serialNumbers)
          .flat();
        return orderItems.length > 0 && serialNumbers.length > 0;
      });
  }

  const filteredOrdersWithItems = filterOrdersWithItems();
  const orderItems = filteredOrdersWithItems.map((orderWithItems) => orderWithItems.items).flat();

  function areSerialNumbersValid(): boolean {
    return filteredOrdersWithItems
      .map((orderWithItems) => orderWithItems.items)
      .flat()
      .map((orderItem) => orderItem.serialNumbers)
      .flat()
      .filter((serialNumber) => (!serialNumber.serialNumber?.length))
      .length === 0;
  }

  function handleOnChange(): void {
    onChange([...ordersWithItems]);
    onValidation(areSerialNumbersValid());
  }

  useEffect(() => {
    onValidation(areSerialNumbersValid());
  }, [ordersWithItems]);

  return orderItems.length > 0 ? (
    <Stack>
      <table className="order-serial-numbers-table">
        <thead>
          <tr>
            <th><b>SKU</b></th>
            <th><b>EAN</b></th>
            <th><b>Product id (order)</b></th>
            <th><b>Title</b></th>
          </tr>
        </thead>
        <tbody>
          {filteredOrdersWithItems
            .map((orderWithItems) => orderWithItems.items.map((orderItem) => (
              <OrderItemRow
                orderItem={orderItem}
                key={orderItem.id}
                onChange={() => handleOnChange()}
              />
            ))) }
        </tbody>
      </table>
    </Stack>
  ) : null;
}
