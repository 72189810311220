import React, { useEffect, useReducer, useRef } from 'react';
import {
  Button, ButtonToolbar, Form, Message, Schema, SelectPicker, Stack, toaster,
} from 'rsuite';
import _ from 'lodash';
import axios from 'axios';
import {
  Carrier,
  EshopCarrier,
  KauflandCarrier,
  KauflandExternalCarrier,
  MpiDeliveryType,
  MpiDeliveryTypeLabels,
} from '../../../api/apiTypes';
import { getCarriers, postEshopCarrier } from '../../../api/carrier';
import { getKauflandCarrier, getKauflandExternalCarriers } from '../../../api/kaufland';
import { Eshop } from '../../../api/eshop/eshop';
import { ApiGroup } from '../../../api/model/ApiGroup';

interface Props {
  apiGroup: ApiGroup;
  eshopCarrier?: EshopCarrier;
  eshops: Eshop[];
}

interface State {
  isLoading: boolean;
  isSaving: boolean;
  eshopCarrier?: EshopCarrier;
  kauflandCarrier?: KauflandCarrier;
  carriers: Carrier[];
  kauflandExternalCarriers: KauflandExternalCarrier[];
  eshops: Eshop[];
  carrierEdit: CarrierEdit
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  eshopCarrier: undefined,
  kauflandCarrier: undefined,
  carriers: [],
  kauflandExternalCarriers: [],
  eshops: [],
  carrierEdit: {
    portalName: '',
  },
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = {
  type: 'loadingSuccessful',
  payload: {
    kauflandCarrier?: KauflandCarrier;
    carriers: Carrier[],
    kauflandExternalCarriers: KauflandExternalCarrier[]
  }
};
type EditAction = { type: 'edit', payload: CarrierEdit };
type SavingAction = { type: 'saving' };
type SavingSuccessfulAction = { type: 'savingSuccessful' };
type ResetAction = { type: 'reset' };
type Action = LoadingAction
| LoadingSuccessfulAction
| EditAction
| SavingAction
| SavingSuccessfulAction
| ResetAction;

interface CarrierEdit {
  eshopCode?: string;
  kauflandExternalCarrierName?: string;
  carrierId?: number;
  deliveryType?: MpiDeliveryType;
  portalName?: string;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return {
        ...state,
        isLoading: false,
        kauflandCarrier: action.payload.kauflandCarrier,
        carriers: action.payload.carriers,
        kauflandExternalCarriers: action.payload.kauflandExternalCarriers,
        carrierEdit: {
          eshopCode: state.eshopCarrier?.eshopCode,
          kauflandExternalCarrierName: action.payload.kauflandCarrier?.carrierCode,
          carrierId: state.eshopCarrier?.carrierId,
          deliveryType: state.eshopCarrier?.defaultMpiDeliveryType,
          portalName: state.eshopCarrier?.portalName ?? '',
        },
      };
    case 'edit':
      return {
        ...state,
        carrierEdit: action.payload,
      };
    case 'saving':
      return {
        ...state,
        isSaving: true,
      };
    case 'savingSuccessful': {
      return {
        ...state,
        isSaving: false,
      };
    }
    case 'reset':
      return {
        ...state,
        carrierEdit: {
          eshopCode: state.eshopCarrier?.eshopCode,
          kauflandExternalCarrierName: state.kauflandCarrier?.carrierCode,
          carrierId: state.eshopCarrier?.carrierId,
          deliveryType: state.eshopCarrier?.defaultMpiDeliveryType,
          portalName: state.eshopCarrier?.portalName ?? '',
        },
      };
    default:
      return state;
  }
}

const deliveryTypes = _.map(MpiDeliveryTypeLabels, (label, value) => ({ label, value }));

const validationModel = Schema.Model({
  eshopCode: Schema.Types.StringType()
    .isRequired('Marketplace is required.'),
  kauflandExternalCarrierName: Schema.Types.StringType()
    .isRequired('Carrier code is required.'),
  carrierId: Schema.Types.NumberType()
    .isRequired('MPI carrier is required.'),
});

function filterEshops(eshops: Eshop[]) {
  return eshops
    .filter((eshop) => eshop.apiGroup === ApiGroup.KAUFLAND);
}

export default function CarrierSetupKauflandEdit({ apiGroup, eshopCarrier, eshops }: Props) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    (initial) => ({ ...initial, eshopCarrier, eshops: filterEshops(eshops) }),
  );
  const formRef = useRef<any>();

  function showSuccessMessage() {
    toaster.push(
      <Message
        type="success"
        closable
        showIcon
        duration={2000}
      >
        Carrier mapping successfully saved.
      </Message>,
    );
  }

  async function fetchKauflandCarrier(): Promise<KauflandCarrier | undefined> {
    if (!state.eshopCarrier?.id) {
      return undefined;
    }

    try {
      return await getKauflandCarrier(state.eshopCarrier.id);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        throw error;
      }
      return undefined;
    }
  }

  function fetchData() {
    dispatch({ type: 'loading' });
    Promise
      .all([
        fetchKauflandCarrier(),
        getCarriers(),
        getKauflandExternalCarriers(),
      ])
      .then((response) => dispatch({
        type: 'loadingSuccessful',
        payload: {
          kauflandCarrier: response[0],
          carriers: response[1],
          kauflandExternalCarriers: response[2],
        },
      }));
  }

  function saveCarrier() {
    dispatch({ type: 'saving' });
    const { carrierEdit } = state;
    postEshopCarrier({
      ...state.eshopCarrier,
      eshopCode: carrierEdit.eshopCode,
      eshopCarrierCode: carrierEdit.kauflandExternalCarrierName,
      carrierId: carrierEdit.carrierId,
      defaultMpiDeliveryType: carrierEdit.deliveryType,
      portalName: _.isEmpty(carrierEdit.portalName) ? undefined : carrierEdit.portalName,
    })
      .then(() => dispatch({ type: 'savingSuccessful' }))
      .then(() => showSuccessMessage());
  }

  useEffect(() => {
    fetchData();
    formRef.current?.cleanErrors();
  }, []);

  function onSave() {
    if (!formRef.current?.check()) {
      return;
    }

    saveCarrier();
  }

  function onCancel() {
    dispatch({ type: 'reset' });
  }

  return (
    <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
      <h4>
        {apiGroup}
        {' '}
        { state.eshopCarrier ? '(edit carrier mapping)' : '(new carrier mapping)'}
      </h4>
      <Form
        layout="horizontal"
        formValue={state.carrierEdit}
        model={validationModel}
        ref={formRef}
        onChange={(carrierEdit) => dispatch({ type: 'edit', payload: carrierEdit })}
        fluid
      >
        <Form.Group>
          <Form.ControlLabel>
            Marketplace
          </Form.ControlLabel>
          <Form.Control
            name="eshopCode"
            accepter={SelectPicker}
            data={state.eshops}
            value={state.carrierEdit.eshopCode ?? null}
            labelKey="name"
            valueKey="code"
            searchable={false}
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Carrier code
          </Form.ControlLabel>
          <Form.Control
            name="kauflandExternalCarrierName"
            accepter={SelectPicker}
            data={state.kauflandExternalCarriers}
            value={state.carrierEdit.kauflandExternalCarrierName ?? null}
            labelKey="name"
            valueKey="name"
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            MPI carrier
          </Form.ControlLabel>
          <Form.Control
            name="carrierId"
            accepter={SelectPicker}
            data={state.carriers}
            value={state.carrierEdit.carrierId ?? null}
            labelKey="identifier"
            valueKey="id"
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Delivery type
          </Form.ControlLabel>
          <Form.Control
            name="deliveryType"
            accepter={SelectPicker}
            data={deliveryTypes}
            value={state.carrierEdit.deliveryType ?? null}
            searchable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Portal name
          </Form.ControlLabel>
          <Form.Control name="portalName" />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSave()} type="submit" loading={state.isSaving}>Save</Button>
            <Button appearance="ghost" onClick={() => onCancel()}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Stack>
  );
}

CarrierSetupKauflandEdit.defaultProps = {
  eshopCarrier: undefined,
};
