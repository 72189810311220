import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { useStoreState } from '../store/hooks';
import { Permission } from '../store/user/types';
import { YOU_ARE_NOT_AUTHORIZED } from './links';

interface PermissionProtectedRouteProps {
  requiredPermission: Permission;
  element: any;
}

export default function PermissionProtectedRoute({
  requiredPermission, element,
}: PermissionProtectedRouteProps) {
  const currentUser = useStoreState(state => state.user);

  if (!currentUser.hasPermission(requiredPermission)) {
    return <Navigate to={YOU_ARE_NOT_AUTHORIZED} replace />;
  }
  return element || <Outlet />;
}
