import React, { useEffect, useState } from 'react';
import {
  Col, Drawer, Grid, Loader, Row,
} from 'rsuite';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { getOrderForLabel } from '../../api/orders';
import { LabelType } from '../../api/apiTypes';
import SimpleLabelSubmit from './SimpleLabelSubmit';
import DetailedLabelSubmit from './DetailedLabelSubmit/DetailedLabelSubmit';
import ParcelShopBranchIdEdit from '../orders/ParcelShopBranchIdEdit';

interface Props {
  orderId: number | undefined;
  onClose: ()=>void;
}

export default function LabelSubmitDrawer({ orderId, onClose }:Props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [labelType, setLabelType] = useState<LabelType>();
  const [loading, setLoading] = useState(false);
  const selectedOrder = useStoreState((state) => state.labels.order);
  const { initPackages } = useStoreActions((state) => state.labels);
  const { updateAfterLabelPrinted } = useStoreActions((state) => state.orders);

  async function loadOrder() {
    try {
      const response = await getOrderForLabel(orderId!);
      const order = response.data;
      initPackages(order);
      setLabelType(order.shippingLabelsPrintMethod);
    } finally {
      setLoading(false);
    }
  }

  useEffect(
    () => {
      if (orderId) {
        setDrawerOpen(true);
        setLoading(true);
        loadOrder();
      } else {
        setDrawerOpen(false);
      }
    },
    [orderId],
  );

  const afterGenerate = (url:string) => {
    if (url) {
      updateAfterLabelPrinted({ orderId: orderId!, url });
    }
    onClose();
  };

  let body;

  if (loading) {
    body = <Loader size="lg" center />;
  } else if (labelType === 'SIMPLE') {
    body = <SimpleLabelSubmit orderId={selectedOrder?.id} afterGenerate={afterGenerate} />;
  } else {
    body = (
      <DetailedLabelSubmit orderId={selectedOrder?.id} afterGenerate={afterGenerate} />
    );
  }

  return (
    <Drawer
      backdrop
      placement="bottom"
      size="lg"
      open={drawerOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Drawer.Header>
        <Drawer.Title>
          Create shipping label for order
          {' '}
          {selectedOrder?.originalId}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid>
          {selectedOrder && (
          <Row>
            <Col md={14} mdOffset={3}>
              <ParcelShopBranchIdEdit order={selectedOrder} />
            </Col>
          </Row>
          )}
          <Row>
            <Col md={14} mdOffset={3}>
              {body}
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
    </Drawer>
  );
}
