import { Popover, Whisper } from 'rsuite';
import { FaExclamationTriangle } from 'react-icons/fa';
import React from 'react';

interface Props {
  title?: string;
  message: string;
}

export default function OrdersTableErrorPopover({ title, message } : Props) {
  if (!message) {
    return null;
  }

  const speaker = (
    <Popover
      title={title}
      className="error-message-tooltip"
      arrow={false}
      style={{ maxWidth: '400px' }}
    >
      <p>
        {message}
      </p>
    </Popover>
  );

  return (
    <Whisper
      trigger="click"
      placement="autoVerticalStart"
      preventOverflow
      speaker={speaker}
    >
      <span>
        <FaExclamationTriangle color="red" cursor="pointer" />
      </span>
    </Whisper>
  );
}

OrdersTableErrorPopover.defaultProps = {
  title: undefined,
};
