import axios, { AxiosError, AxiosResponse } from 'axios';
import { notificationError, validationError } from '../services/notificationActions';
import { removeUseTokens } from '../services/authorization';

function processUnauthorizedError(error: AxiosError) {
  notificationError(`Unauthorized: ${error.response?.data?.message}`);
  if (error.config.url === 'user') {
    return;
  }

  removeUseTokens();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl === '/') {
    window.location.href = `${window.location.origin}/`;
  } else {
    window.location.href = `${window.location.origin}${baseUrl}/`;
  }
}

function processResponseError(response: AxiosResponse | undefined) {
  if (!response) {
    return;
  }
  const { data } = response;

  const isArrayBuffer = response.config.responseType === 'arraybuffer';

  function toJson(responseData: any) {
    if (isArrayBuffer) {
      const decoder = new TextDecoder();
      const decoded = decoder.decode(data);
      return JSON.parse(decoded);
    }

    return responseData;
  }

  let handled = false;
  if (response.status === 400 && data.code === 'VALIDATION_ERROR') {
    const d = toJson(data);
    if (!d.validationErrors || d.validationErrors.length === 0) {
      validationError(d.details);
      handled = true;
    }
  }

  if (response.status === 401) {
    notificationError('Invalid authorization');
    handled = true;
  }

  if (!handled) {
    const d = toJson(data);

    notificationError(d?.details || 'Unknown error');
  }
}

export default function handleError(
  error: unknown,
) {
  if (axios.isCancel(error)) {
    return;
  }

  if (!axios.isAxiosError(error)) {
    notificationError('Unknown error');
    return;
  }

  if (error.response?.status === 401) {
    processUnauthorizedError(error);
    return;
  }

  processResponseError(error.response);
}
