import React, { useEffect, useReducer } from 'react';
import _ from 'lodash';
import {
  Loader, Message, Popover, Whisper,
} from 'rsuite';
import axios from 'axios';
import { useIsMounted } from 'rsuite/utils';
import { EshopCarrier } from '../../../api/apiTypes';
import { getAllegroDeliveryMethods, getAllegroShippingRate } from '../../../api/allegro';
import { getEshopCarriers } from '../../../api/carrier';
import { AllegroDeliveryMethod, AllegroShippingRateDetail as AllegroShippingRate } from '../../../api/allegroApiTypes';

interface StatusProps {
  deliveryMethods: AllegroDeliveryMethod[];
}

function Status({ deliveryMethods }: StatusProps) {
  function isSuccess() {
    return deliveryMethods.length === 0;
  }

  function getType(): 'success' | 'warning' {
    return isSuccess() ? 'success' : 'warning';
  }

  function getText(): string {
    return isSuccess() ? 'All delivery methods are mapped.' : 'Show unmapped delivery methods.';
  }

  function getTrigger(): 'none' | 'click' {
    return isSuccess() ? 'none' : 'click';
  }

  function methods(): JSX.Element {
    return (
      <Popover>
        {deliveryMethods.map(deliveryMethod => (
          <p key={deliveryMethod.id}>
            <strong>{deliveryMethod.name}</strong>
            {' '}
            {`(${deliveryMethod.id})`}
          </p>
        ))}
      </Popover>
    );
  }

  return (
    <Whisper
      speaker={methods()}
      trigger={getTrigger()}
    >
      <Message
        id="allegro-mapped-delivery-methods-message"
        type={getType()}
      >
        <p className={isSuccess() ? undefined : 'rs-btn-link'}>{getText()}</p>
      </Message>
    </Whisper>
  );
}

interface Props {
  eshopCode: string;
  shippingRateId?: string;
}

interface State {
  isLoading: boolean;
  data: Data;
}

interface Data {
  shippingRate?: AllegroShippingRate;
  eshopCarriers: EshopCarrier[];
  unmappedDeliveryMethods: AllegroDeliveryMethod[];
}

const initialState: State = {
  isLoading: false,
  data: {
    shippingRate: undefined,
    eshopCarriers: [],
    unmappedDeliveryMethods: [],
  },
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload: Data };
type Action = LoadingAction | LoadingSuccessfulAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return { ...state, isLoading: false, data: action.payload };
    default:
      return state;
  }
}

function getUnmappedDeliveryMethodIds(
  shippingRate: AllegroShippingRate,
  eshopCarriers: EshopCarrier[],
): string[] {
  const deliveryMethodIds = shippingRate.rates.map((rate) => rate.deliveryMethod.id);
  const mappedDeliveryMethodIds = eshopCarriers.map((carrier) => carrier.eshopCarrierCode);
  return _.difference(deliveryMethodIds, mappedDeliveryMethodIds);
}

function getUnmappedDeliveryMethods(
  deliveryMethodIds: string[],
  deliveryMethods: AllegroDeliveryMethod[],
): AllegroDeliveryMethod[] {
  return deliveryMethods
    .filter((deliveryMethod) => deliveryMethodIds.includes(deliveryMethod.id));
}

export default function EshopSetupAllegroMappedDeliveryMethods(
  { eshopCode, shippingRateId }: Props,
) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useIsMounted();

  async function fetchData(id: string): Promise<Data> {
    dispatch({ type: 'loading' });
    const [shippingRate, eshopCarriers] = await Promise.all([
      getAllegroShippingRate(eshopCode, id),
      getEshopCarriers({ eshopCode }),
    ]);

    const unmappedDeliveryMethodsIds = getUnmappedDeliveryMethodIds(shippingRate, eshopCarriers);
    if (unmappedDeliveryMethodsIds.length === 0) {
      return {
        shippingRate,
        eshopCarriers,
        unmappedDeliveryMethods: [],
      };
    }

    const deliveryMethods = await getAllegroDeliveryMethods(eshopCode);
    const unmappedDeliveryMethods = getUnmappedDeliveryMethods(
      unmappedDeliveryMethodsIds,
      deliveryMethods,
    );
    return {
      shippingRate,
      eshopCarriers,
      unmappedDeliveryMethods,
    };
  }

  useEffect(() => {
    if (!shippingRateId) {
      return;
    }

    fetchData(shippingRateId)
      .catch((error) => {
        if (axios.isAxiosError(error) && error.response?.status !== 401) {
          return Promise.reject(error);
        }
        return Promise.resolve({
          shippingRate: undefined,
          eshopCarriers: [],
          unmappedDeliveryMethods: [],
        });
      })
      .then((response) => {
        if (!isMounted()) {
          return;
        }
        dispatch({ type: 'loadingSuccessful', payload: response });
      });
  }, [eshopCode, shippingRateId]);

  if (!shippingRateId) {
    return null;
  }

  return (state.isLoading ? <Loader /> : (
    <Status
      deliveryMethods={state.data.unmappedDeliveryMethods}
    />
  ));
}

EshopSetupAllegroMappedDeliveryMethods.defaultProps = {
  shippingRateId: undefined,
};
