import React from 'react';
import RoutesConfig from './routes/Routes';

import './styles/styles.css';
import registerUnhandledRejection from './utils/error';

registerUnhandledRejection();

export default function App() {
  return (
    <RoutesConfig />
  );
}
