import React from 'react';
import {
  Button, ButtonToolbar, Message, Modal,
} from 'rsuite';
import { OrderImportResult } from '../../api/apiTypes';
import { downloadData } from '../../utils/downloadData';

interface Props {
  result?: OrderImportResult;
  onClose?: () => void;
}

function getErrorMessage(result: OrderImportResult): string | undefined {
  if (result.failedCount < 1) {
    return undefined;
  }

  return `There was problem with ${result.failedCount} orders. Please check the report.`;
}

function getWarningMessage(result: OrderImportResult): string | undefined {
  if (result.warningCount < 1) {
    return undefined;
  }

  return `There were ${result.warningCount} warnings. Please check the report.`;
}

function getSuccessMessage(result: OrderImportResult): string | undefined {
  if (result.updatedCount < 1) {
    return undefined;
  }

  return `${result.updatedCount} from ${result.totalCount} orders was successfully updated.`;
}

export default function OrderImportUploadResultDialog({ result, onClose }: Props) {
  if (!result) {
    return null;
  }

  const isDownloadReportVisible = !!result.errorReport;
  const isDownloadReimportVisible = !!result.reimportReport;

  const errorMessage = getErrorMessage(result);
  const warningMessage = getWarningMessage(result);
  const successMessage = getSuccessMessage(result);

  function handleClose() {
    onClose?.();
  }

  function handleDownloadReport() {
    if (!result?.errorReport) {
      return;
    }

    downloadData(result.errorReport, 'text/plain', 'import-errors.csv');
  }

  function handleDownloadReimport() {
    if (!result?.reimportReport) {
      return;
    }

    downloadData(result.reimportReport, 'text/plain', 're-import.csv');
  }

  return (
    <Modal
      open
      onClose={() => handleClose()}
    >
      <Modal.Header
        closeButton={false}
      >
        Uploaded orders:
        {' '}
        {result.totalCount}
      </Modal.Header>
      <Modal.Body>
        {successMessage && (
        <Message showIcon type="success" header={successMessage}>
          {warningMessage && (
          <>
            <br />
            <Message showIcon type="warning" header={warningMessage} />
          </>
          )}
        </Message>
        )}
        {errorMessage && (
        <Message showIcon type="error" header={errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          {isDownloadReportVisible && (
          <Button onClick={() => handleDownloadReport()}>
            Download report
          </Button>
          )}
          {isDownloadReimportVisible && (
          <Button onClick={() => handleDownloadReimport()}>
            Download orders for re-import
          </Button>
          )}
          <Button
            appearance="primary"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}

OrderImportUploadResultDialog.defaultProps = {
  result: undefined,
  onClose: undefined,
};
