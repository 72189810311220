import { FaRegFilePdf } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { Panel, SelectPicker } from 'rsuite';
import { LabelType, OrderForLabelPrint, SimpleOrder } from '../api/apiTypes';
import { getOrderForLabel, getOrdersForLabel } from '../api/orders';
import DetailedLabelSubmit from '../components/LabelSubmit/DetailedLabelSubmit/DetailedLabelSubmit';
import SimpleLabelSubmit from '../components/LabelSubmit/SimpleLabelSubmit';
import { useStoreActions } from '../store/hooks';
import ParcelShopBranchIdEdit from '../components/orders/ParcelShopBranchIdEdit';

export default function CreateLabel() {
  const [orders, setOrders] = useState<SimpleOrder[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<OrderForLabelPrint>();
  const [labelType, setLabelType] = useState<LabelType>();
  const setLoading = useStoreActions(actions => actions.general.setLoading);

  const { initPackages } = useStoreActions((state) => state.labels);

  useEffect(() => {
    async function fetchOrders() {
      setLoading(true);

      try {
        const response = await getOrdersForLabel();
        setOrders(response.data);
      } finally {
        setLoading(false);
      }
    }

    fetchOrders();
  }, []);

  const handleSelectOrder = (value: any) => {
    async function fetchOrder(orderToFetch: SimpleOrder) {
      setLoading(true);
      try {
        const response = await getOrderForLabel(orderToFetch.id);
        const order = response.data;
        initPackages(order);
        setSelectedOrder(order);
        setLabelType(order.shippingLabelsPrintMethod);
      } finally {
        setLoading(false);
      }
    }

    if (value) {
      const order = orders.find((o) => o.id === value);
      if (order) {
        fetchOrder(order);
      }
    } else {
      setLabelType(undefined);
    }
  };

  const orderOptions = orders.filter((id) => id)
    .map((o) => ({
      value: o.id, label: `${o.partnerId == null ? '' : o.partnerId} (Original ID ${o.originalId}, ${o.source})`, hasLabels: o.hasLabels,
    }));

  const customItemRenderer = (label: any, item: any) => {
    if (item.hasLabels) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{label}</span>
          <FaRegFilePdf
            style={{ marginLeft: '10px' }}
            title="Labels generated"
          />
        </div>
      );
    }
    return <div>{label}</div>;
  };

  return (
    <Panel>
      <SelectPicker
        placeholder="Order Id"
        onChange={handleSelectOrder}
        data={orderOptions}
        disabled={!orderOptions}
        renderMenuItem={customItemRenderer}
        renderValue={(value, meta) => customItemRenderer(
          (
            orderOptions.find((option) => option.value === value)!.label
          ), meta,
        )}
        style={{ width: 500 }}
      />
      {selectedOrder && (
      <ParcelShopBranchIdEdit order={selectedOrder} />
      )}
      {labelType === 'SIMPLE' && selectedOrder && (
      <SimpleLabelSubmit orderId={selectedOrder.id} afterGenerate={() => {}} />
      )}
      {labelType === 'DETAILED' && selectedOrder && (
      <DetailedLabelSubmit orderId={selectedOrder.id} afterGenerate={() => {}} />
      )}
    </Panel>
  );
}
