import React, { useEffect, useReducer, useRef } from 'react';
import axios from 'axios';
import {
  Button, Form, Loader, Schema, SelectPicker,
} from 'rsuite';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { createWarehouseLocality, getWarehouseLocality, updateWarehouseLocality } from '../../../api/warehouse/warehouse';
import { WarehouseLocality } from '../../../api/warehouse/warehouseApiTypes';
import { utils } from '../../../utils/utils';
import showSuccessMessage from '../../../utils/message';
import { ALL_COUNTRIES, getProvinces } from '../../../utils/countries';

const PL_COUNTRY_CODE = 'PL';
const PL_PROVINCES: string[] = getProvinces(PL_COUNTRY_CODE)?.values ?? [];

interface Props {
  eshopCode: string;
  warehouseLocalityId?: number;
}

interface State {
  isLoading: boolean;
  isSaving: boolean;
  data: Data;
  edit: Edit;
}

interface Data {
  warehouseLocality?: WarehouseLocality;
}

interface Edit {
  code?: string;
  street?: string;
  city?: string;
  countryCode?: string;
  province?: string;
  postcode?: string;
  company?: string;
  name?: string;
  email?: string;
  phone?: string;
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  data: {
    warehouseLocality: undefined,
  },
  edit: {
    code: '',
    street: '',
    city: '',
    countryCode: '',
    province: '',
    postcode: '',
    company: '',
    name: '',
    email: '',
    phone: '',
  },
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload: Data };
type EditAction = { type: 'edit', payload: Edit };
type SavingAction = { type: 'saving', payload: boolean };
type ResetAction = { type: 'reset' };
type Action = LoadingAction | LoadingSuccessfulAction | EditAction | SavingAction | ResetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        edit: {
          code: utils.defaultToEmpty(action.payload.warehouseLocality?.code),
          street: utils.defaultToEmpty(action.payload.warehouseLocality?.street),
          city: utils.defaultToEmpty(action.payload.warehouseLocality?.city),
          countryCode: utils.defaultToEmpty(action.payload.warehouseLocality?.countryCode),
          province: utils.defaultToEmpty(action.payload.warehouseLocality?.province),
          postcode: utils.defaultToEmpty(action.payload.warehouseLocality?.postcode),
          company: utils.defaultToEmpty(action.payload.warehouseLocality?.company),
          name: utils.defaultToEmpty(action.payload.warehouseLocality?.name),
          email: utils.defaultToEmpty(action.payload.warehouseLocality?.email),
          phone: utils.defaultToEmpty(action.payload.warehouseLocality?.phone),
        },
      };
    case 'edit':
      return { ...state, edit: action.payload };
    case 'saving':
      return { ...state, isSaving: action.payload };
    case 'reset':
      return {
        ...state,
        edit: {
          code: utils.defaultToEmpty(state.data.warehouseLocality?.code),
          street: utils.defaultToEmpty(state.data.warehouseLocality?.street),
          city: utils.defaultToEmpty(state.data.warehouseLocality?.city),
          countryCode: utils.defaultToEmpty(state.data.warehouseLocality?.countryCode),
          province: utils.defaultToEmpty(state.data.warehouseLocality?.province),
          postcode: utils.defaultToEmpty(state.data.warehouseLocality?.postcode),
          company: utils.defaultToEmpty(state.data.warehouseLocality?.company),
          name: utils.defaultToEmpty(state.data.warehouseLocality?.name),
          email: utils.defaultToEmpty(state.data.warehouseLocality?.email),
          phone: utils.defaultToEmpty(state.data.warehouseLocality?.phone),
        },
      };
    default:
      return state;
  }
}

const { StringType } = Schema.Types;
const validationModel = Schema.Model({
  code: StringType().isRequired('Address code is required.'),
  street: StringType().isRequired('Street is required.'),
  city: StringType().isRequired('City is required.'),
  countryCode: StringType().isRequired('Country is required.'),
  province: StringType().addRule(
    (value, edit: Edit) => (edit.countryCode !== PL_COUNTRY_CODE
      ? true
      : !_.isEmpty(value)),
    'Province is required.',
    true,
  )
    .addRule(
      (value, edit: Edit) => (edit.countryCode !== PL_COUNTRY_CODE
        ? true
        : _.includes(PL_PROVINCES, value)),
      'Invalid province.',
      true,
    ),
  company: StringType().addRule(
    (value, edit: Edit) => !(_.isEmpty(value) && _.isEmpty(edit.name)),
    'Company is required if name is empty.',
    true,
  ),
  name: StringType().addRule(
    (value, edit: Edit) => !(_.isEmpty(value) && _.isEmpty(edit.company)),
    'Name is required if company is empty.',
    true,
  ),
  email: StringType().isRequired('Email is required.'),
  phone: StringType().isRequired('Phone is required.'),
});

export default function EshopSetupAllegroAddressEdit(
  { eshopCode, warehouseLocalityId }: Props,
) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const formRef = useRef<any>();

  async function fetchWarehouseLocality(id: number): Promise<WarehouseLocality | undefined> {
    dispatch({ type: 'loading' });

    try {
      return await getWarehouseLocality(id);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        throw error;
      }

      return undefined;
    }
  }

  async function saveWarehouseLocality(): Promise<number | null> {
    dispatch({ type: 'saving', payload: true });

    const warehouseLocality: Partial<WarehouseLocality> = _.mapValues(
      state.edit,
      utils.defaultToUndefined,
    );

    const id = state.data.warehouseLocality?.id;
    if (!id) {
      return createWarehouseLocality({ ...warehouseLocality, eshopCode });
    }

    return updateWarehouseLocality(id, warehouseLocality);
  }

  useEffect(() => {
    formRef.current?.cleanErrors();

    if (!warehouseLocalityId) {
      return;
    }

    fetchWarehouseLocality(warehouseLocalityId)
      .then((warehouseLocality) => dispatch({ type: 'loadingSuccessful', payload: { warehouseLocality } }));
  }, [warehouseLocalityId]);

  function handleSave() {
    if (!formRef.current?.check()) {
      return;
    }

    saveWarehouseLocality()
      .then((id) => {
        showSuccessMessage('Address successfully saved.');

        if (id) {
          navigate(`${id}`);
        }
      })
      .finally(() => dispatch({ type: 'saving', payload: false }));
  }

  function handleCancel() {
    dispatch({ type: 'reset' });
  }

  return state.isLoading ? <Loader /> : (
    <Form
      fluid
      formValue={state.edit}
      onChange={(edit) => dispatch({ type: 'edit', payload: edit })}
      model={validationModel}
      layout="vertical"
      ref={formRef}
    >
      <div style={{ display: 'inline-grid', gridGap: '0px 20px', gridTemplateColumns: 'auto auto' }}>
        <Form.Group style={{ gridColumnStart: 1, gridColumnEnd: 3, width: 'fit-content' }}>
          <Form.ControlLabel>Address code</Form.ControlLabel>
          <Form.Control name="code" type="text" />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Street address</Form.ControlLabel>
          <Form.Control name="street" type="text" />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Company</Form.ControlLabel>
          <Form.Control name="company" type="text" />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>City</Form.ControlLabel>
          <Form.Control name="city" type="text" />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Name</Form.ControlLabel>
          <Form.Control name="name" type="text" />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Country</Form.ControlLabel>
          <Form.Control
            name="countryCode"
            accepter={SelectPicker}
            data={ALL_COUNTRIES}
            labelKey="name"
            valueKey="code"
            block
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>E-mail</Form.ControlLabel>
          <Form.Control name="email" type="email" />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Province</Form.ControlLabel>
          {state.edit.countryCode === PL_COUNTRY_CODE
            ? (
              <Form.Control
                name="province"
                accepter={SelectPicker}
                data={PL_PROVINCES.map((province) => ({ label: province, value: province }))}
                block
              />
            )
            : <Form.Control name="province" type="text" />}
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Phone</Form.ControlLabel>
          <Form.Control name="phone" type="tel" />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group style={{ gridColumnStart: 1, gridColumnEnd: 3, width: 'fit-content' }}>
          <Form.ControlLabel>Postcode</Form.ControlLabel>
          <Form.Control name="postcode" type="text" />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Button
          style={{ width: 'fit-content', justifySelf: 'end' }}
          appearance="primary"
          onClick={() => handleSave()}
          type="submit"
          loading={state.isSaving}
        >
          Save
        </Button>
        <Button
          style={{ width: 'fit-content', justifySelf: 'start' }}
          appearance="ghost"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}

EshopSetupAllegroAddressEdit.defaultProps = {
  warehouseLocalityId: undefined,
};
