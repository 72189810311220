const SELECTED_USER = 'SELECTED_USER';
const USER_TOKENS = 'USER_TOKENS';

export function getUserTokens(): string[] {
  const tokens = localStorage.getItem(USER_TOKENS);

  if (!tokens) {
    return [];
  }

  return JSON.parse(tokens);
}

export function getCurrentIndex() {
  return +(localStorage.getItem(SELECTED_USER) ?? 0);
}

export function getCurrentUserToken():string {
  const userIndex = getCurrentIndex();
  const tokens = getUserTokens();
  return tokens[userIndex];
}

export function setCurrentIndex(index:number) {
  localStorage.setItem(SELECTED_USER, String(index));
}

export function removeUseTokens() {
  localStorage.removeItem(USER_TOKENS);
  localStorage.removeItem(SELECTED_USER);
}

export function setUserTokens(tokens:string[], index: number) {
  localStorage.setItem(SELECTED_USER, String(index));
  localStorage.setItem(USER_TOKENS, JSON.stringify(tokens));
}

export function userTokenStored() {
  return localStorage.getItem(USER_TOKENS) !== null && localStorage.getItem(SELECTED_USER) !== null;
}
