import React from 'react';
import styled from '@emotion/styled';

interface FromBoxRowProps {
  label: string
}

interface FormBoxProps {
  // eslint-disable-next-line react/require-default-props
  className?: string,
  children: React.ReactElement<FromBoxRowProps> | React.ReactElement<FromBoxRowProps>[];
}

interface FormBoxStyledProps {
  labelWidth?: number;
}

// eslint-disable-next-line react/function-component-definition
const FormBoxRow: React.FC<FromBoxRowProps> = ({ label, children }) => (
  <div>
    <div className="form-box-label">{label}</div>
    <div className="form-box-content">{children}</div>
  </div>
);

// eslint-disable-next-line react/function-component-definition
const FormBoxDef: React.FC<FormBoxProps> = ({ className, children }) => (
  <div className={`${className} form-box`}>
    {children}
  </div>
);

const FormBox = styled(FormBoxDef)<FormBoxStyledProps>(
  {
    // '& > div > span': {
    //   width: 170,
    // },
  },
  props => ({
    '& > div > .form-box-label': {
      flexBasis: props.labelWidth,
      width: props.labelWidth,
    },
  }),
);

export { FormBox, FormBoxRow };
