import {
  KauflandCarrier,
  KauflandExternalCarrier,
  KauflandExternalShippingGroup,
  KauflandExternalWarehouse,
  KauflandTransportMapping,
} from '../apiTypes';

export const mockKauflandExternalShippingGroups: KauflandExternalShippingGroup[] = [
  {
    currency: 'CZK',
    shippingGroupId: 1,
    storefront: 'CZ',
    name: 'SHIPPING GROUP 1',
    type: 'normal',
    isDefault: false,
    regions: [],
  },
  {
    currency: 'PLN',
    shippingGroupId: 2,
    storefront: 'CZ',
    name: 'SHIPPING GROUP 2',
    type: 'normal',
    isDefault: true,
    regions: [],
  },
  {
    currency: 'CZK',
    shippingGroupId: 3,
    storefront: 'CZ',
    name: 'SHIPPING GROUP 3',
    type: 'normal',
    isDefault: true,
    regions: [],
  },
];

export const mockKauflandExternalWarehouses: KauflandExternalWarehouse[] = [
  {
    warehouseId: 1,
    name: 'Warehouse 1',
    address: {
      street: 'street',
      city: 'city',
      houseNumber: 'houseNumber',
      postcode: 'postcode',
      country: 'country',
      phone: 'phone',
    },
    isDefault: false,
    type: 'normal',
  },
  {
    warehouseId: 2,
    name: 'Warehouse 2',
    address: {
      street: 'street',
      city: 'city',
      houseNumber: 'houseNumber',
      postcode: 'postcode',
      country: 'country',
      phone: 'phone',
    },
    isDefault: true,
    type: 'normal',
  },
];

export const mockKauflandExternalCarriers: KauflandExternalCarrier[] = [{
  name: 'DPD',
  isHauler: false,
},
{
  name: 'PPL',
  isHauler: false,
},
{
  name: 'GLS',
  isHauler: false,
},
{
  name: 'UPS',
  isHauler: false,
}];

export const mockKauflandTransportMappings: KauflandTransportMapping[] = [
  {
    partnerCode: '0001',
    shippingGroupId: '1',
    eshopCarrierId: 1,
  },
  {
    partnerCode: '0001',
    shippingGroupId: '2',
    eshopCarrierId: 2,
  },
];

export const mockKauflandCarrier: KauflandCarrier = {
  eshopCarrierId: 1,
  carrierCode: 'GLS',
};

export const mockKauflandCarriers: KauflandCarrier[] = [
  {
    eshopCarrierId: 1,
    carrierCode: 'GLS',
  },
  {
    eshopCarrierId: 2,
    carrierCode: 'DPD',
  },
  {
    eshopCarrierId: 3,
    carrierCode: 'UPS',
  },
];
