import React from 'react';
import { Pagination as RsuitePagination } from 'rsuite';

type LayoutType = 'total' | '-' | 'pager' | '|' | 'limit' | 'skip';

const LAYOUT: LayoutType[] = ['total', '-', 'limit', '|', 'pager', 'skip'];
const LIMIT_OPTIONS = [25, 50, 100];

interface Props {
  total: number;
  limit: number;
  activePage: number;
  onChangePage: (page: number) => void;
  onChangeLimit: (limit: number) => void;
}

export default function Pagination({
  total, limit, activePage, onChangePage, onChangeLimit,
}: Props): JSX.Element {
  function handleChangePage(value: number): void {
    onChangePage(value);
  }

  function handleChangeLimit(value: number): void {
    onChangeLimit(value);
  }

  return (
    <RsuitePagination
      prev
      next
      first
      last
      ellipsis
      boundaryLinks
      maxButtons={3}
      size="sm"
      layout={LAYOUT}
      total={total}
      limitOptions={LIMIT_OPTIONS}
      limit={limit}
      activePage={activePage}
      onChangePage={(value) => handleChangePage(value)}
      onChangeLimit={(value) => handleChangeLimit(value)}
    />
  );
}
