import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  ButtonToolbar,
  CheckPicker,
  Form,
  Message,
  Modal,
  Radio,
  RadioGroup,
  Schema,
  SelectPicker,
  Stack,
  Toggle,
  useToaster,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import { BasicInformation, WarehouseType } from '../api/baselinker/apiTypes';
import {
  fetchExternalWarehouses,
  fetchInternalWarehouse,
  fetchInventoryPriceGroups,
  fetchOrderSources,
  getInventories,
} from './BaselinkerClient';
import {
  getBasicInformation,
  getEshopCodesUsingBaselinkerDefaultConfig,
  saveBasicInformation,
} from '../api/baselinker/baselinker';
import { EshopContext } from './EshopContextProvider';
import PlatformIdSyncExecution, { PlatformIdSyncRefType } from './PlatformIdSyncExecution';

function hasAnyDefinedProperties(obj: any): boolean {
  return !!(obj.orderSourceId
      || obj.priceGroupId
      || obj.inventoryId
      || (obj.internalWarehouseIds?.length > 0)
      || obj.externalWarehouseId);
}

interface BasicInformationFormProps {
  blLinked: boolean;
  blApiToken: string | undefined;
  eshopCode?: string;
}

export default function BasicInformationForm(
  { blLinked, blApiToken, eshopCode } : BasicInformationFormProps,
) {
  const navigate = useNavigate();
  const toaster = useToaster();
  const availableEshops = useContext(EshopContext);
  const [inventories, setInventories] = useState<Record<string, any>[]>([]);
  const [internalWarehouses, setInternalWarehouses] = useState<Record<string, any>[]>([]);
  const [externalWarehouses, setExternalWarehouses] = useState<Record<string, any>[]>([]);
  const [previousInventoryId, setPreviousInventoryId] = useState<string | null>(null);
  const [originalInventoryId, setOriginalInventoryId] = useState<string | null>(null);
  const [openInventoryWarnDialog, setOpenInventoryWarnDialog] = useState<boolean>(false);
  const [orderSources, setOrderSources] = useState<Record<string, any>[]>([]);
  const [enableInventory, setEnableInventory] = useState(true);
  const formRef = useRef<any>();
  const priceGroupFormRef = useRef<any>();
  const [formValue, setFormValue] = useState<Record<string, any>>({
    warehouseType: WarehouseType.INTERNAL,
  });
  const [useDifferentSetup, setUseDifferentSetup] = useState<boolean>(false);
  const [priceGroupIds, setPriceGroupIds] = useState<Record<string, any>[]>([]);
  const [priceGroupFormValue, setPriceGroupFormValue] = useState<Record<string, any>>({});
  const platformIdSyncExecutionRef = useRef<PlatformIdSyncRefType>(null);
  const [eshopCodes, setEshopCodes] = useState<string[]>([]);

  useEffect(() => {
    function setDefaultPriceGroup(data: Record<string, any>[]) {
      if (!priceGroupFormValue.priceGroupId) {
        const currentEshopCurrency = availableEshops?.find(e => e.code === eshopCode)?.currency;
        let priceGroup = data?.find(e => e.currency === currentEshopCurrency);
        if (!priceGroup) {
          priceGroup = data?.find(e => e.isDefault === true);
        }
        if (priceGroup) {
          setPriceGroupFormValue({ priceGroupId: priceGroup.value });
        }
      }
    }

    if (blLinked && blApiToken) {
      getInventories(blApiToken).then(inventoriesData => setInventories(inventoriesData));
      fetchInternalWarehouse(blApiToken).then(data => setInternalWarehouses(data));
      fetchExternalWarehouses(blApiToken).then(data => setExternalWarehouses(data));
      fetchOrderSources(blApiToken).then(data => setOrderSources(data));
      if (eshopCode) {
        fetchInventoryPriceGroups(blApiToken).then(data => {
          setPriceGroupIds(data);
          setDefaultPriceGroup(data);
        });
      }
    }
  }, [blLinked]);

  useEffect(() => {
    getBasicInformation(eshopCode).then(basicInformation => {
      setFormValue(prevState => ({
        ...prevState,
        inventory: basicInformation.inventoryId,
        warehouseType: basicInformation.externalWarehouseId
          ? WarehouseType.EXTERNAL
          : WarehouseType.INTERNAL,
        internalWarehouses: basicInformation.internalWarehouseIds,
        externalWarehouse: basicInformation.externalWarehouseId,
        orderSource: basicInformation.orderSourceId,
      }));
      if (basicInformation.priceGroupId) {
        setPriceGroupFormValue({ priceGroupId: basicInformation.priceGroupId });
      } else {
        setPriceGroupFormValue({ priceGroupId: null });
      }
      setEnableInventory(!!basicInformation.inventoryId);
      setPreviousInventoryId(basicInformation.inventoryId);
      setOriginalInventoryId(basicInformation.inventoryId);
      if (eshopCode && hasAnyDefinedProperties(basicInformation)) {
        setUseDifferentSetup(true);
      } else {
        setUseDifferentSetup(false);
      }
    });
    getEshopCodesUsingBaselinkerDefaultConfig()
      .then((data) => setEshopCodes(data));
  }, [eshopCode]);

  const inventoryRule = Schema.Types.NumberType()
    .isRequired('This field is required.')
    .addRule((value) => inventories.filter(x => x.value === value).length === 1, 'Select this value');

  const internalWarehouseRule = Schema.Types.ArrayType()
    .minLength(1, 'You must select at least one warehouse')
    .unrepeatable('You can not select one value multiple times.')
    .addRule((value: string[]) => {
      const warehousesId: string[] = internalWarehouses.map(x => x.value);
      return value.filter((warehouseId: string) => !warehousesId.includes(warehouseId))
        .length === 0;
    }, 'Select this value');

  const externalWarehouseRule = Schema.Types.StringType()
    .isRequired('This field is required.')
    .addRule((value) => externalWarehouses.filter(x => x.value === value).length === 1, 'Select this value');

  const orderSourceRule = Schema.Types.NumberType()
    .isRequired('This field is required.')
    .addRule((value) => orderSources.filter(x => x.value === value).length === 1, 'Select this value');

  const priceGroupRule = Schema.Types.NumberType().isRequired('This field is required.')
    .addRule((value) => priceGroupIds.filter(x => x.value === value).length === 1, 'Select this value');

  function filterOutInternalWarehousesThatDoesNotExistInBL() {
    if (!formValue.internalWarehouses) {
      return;
    }

    formValue.internalWarehouses = formValue.internalWarehouses
      .filter((warehouseId: string) => internalWarehouses.map(x => x.value).includes(warehouseId));
  }

  function onSubmit() {
    function showCheckFormMessage() {
      toaster.push(
        <Message type="error" closable showIcon duration={2000}>
          Please check the form
          there are validation errors.
        </Message>,
      );
    }

    function saveBasicInfo() {
      let basicInfo: BasicInformation = {
        inventoryId: !enableInventory ? null : formValue.inventory,
        priceGroupId: eshopCode ? priceGroupFormValue.priceGroupId : null,
        internalWarehouseIds: !enableInventory || formValue.warehouseType !== WarehouseType.INTERNAL
          ? null : formValue.internalWarehouses,
        externalWarehouseId: !enableInventory || formValue.warehouseType !== WarehouseType.EXTERNAL
          ? null : formValue.externalWarehouse,
        orderSourceId: formValue.orderSource,
      };

      if (eshopCode && !useDifferentSetup) {
        basicInfo = {
          inventoryId: null,
          priceGroupId: priceGroupFormValue.priceGroupId,
          internalWarehouseIds: null,
          externalWarehouseId: null,
          orderSourceId: null,
        };
      }

      saveBasicInformation(basicInfo, eshopCode)
        .then(() => {
          toaster.push(
            <Message type="success" closable showIcon duration={2000}>
              Basic information saved
            </Message>,
          );
          setOriginalInventoryId(formValue.inventory);
        })
        .then(() => {
          if (originalInventoryId !== formValue.inventory) {
            platformIdSyncExecutionRef.current?.synchronize(true);
          }
        });
    }

    filterOutInternalWarehousesThatDoesNotExistInBL();
    if (eshopCode) {
      const priceGroupValid = priceGroupFormRef.current?.check();
      const restOfFieldsValid = useDifferentSetup ? formRef.current?.check() : true;
      if (priceGroupValid && restOfFieldsValid) {
        saveBasicInfo();
      } else {
        showCheckFormMessage();
      }
    } else if (formRef.current?.check()) {
      saveBasicInfo();
    } else {
      showCheckFormMessage();
    }
  }

  const warnAboutInventoryChange = (value: any) => {
    if (previousInventoryId) {
      setOpenInventoryWarnDialog(true);
    } else {
      setPreviousInventoryId(value);
    }
  };

  const handleBack = () => {
    setFormValue((prevState => ({
      ...prevState,
      inventory: previousInventoryId,
    })));
    setOpenInventoryWarnDialog(false);
  };

  const handleOk = () => {
    setPreviousInventoryId(formValue.inventory);
    setOpenInventoryWarnDialog(false);
  };

  return (
    <>
      {eshopCode && (
      <>
        <Form ref={priceGroupFormRef} formValue={priceGroupFormValue} onChange={setPriceGroupFormValue} layout="horizontal">
          <Form.Group>
            <Form.ControlLabel>Price group id</Form.ControlLabel>
            <Form.Control
              name="priceGroupId"
              accepter={SelectPicker}
              data={priceGroupIds}
              rule={priceGroupRule}
            />
          </Form.Group>
        </Form>
        <br />
        <Form layout="horizontal">
          <Form.Group>
            <Form.ControlLabel>{`Use different setup for ${eshopCode}`}</Form.ControlLabel>
            <Toggle checked={useDifferentSetup} onChange={setUseDifferentSetup} />
          </Form.Group>
        </Form>
      </>
      )}
      {(!eshopCode || useDifferentSetup) && (
      <Form ref={formRef} formValue={formValue} layout="horizontal" onChange={setFormValue}>
        <Form.Group controlId="disableInventoryId">
          <Stack spacing={15}>
            <Form.ControlLabel>Use BL inventory for price/stock sync</Form.ControlLabel>
            <Form.Control
              name="disableInventoryId"
              checked={enableInventory}
              accepter={Toggle}
              onChange={(value: boolean) => setEnableInventory(value)}
            />
          </Stack>
        </Form.Group>
        {enableInventory && (
          <>
            <Form.Group controlId="inventory">
              <Form.ControlLabel>Inventory</Form.ControlLabel>
              <Form.Control name="inventory" accepter={SelectPicker} onChange={warnAboutInventoryChange} data={inventories} rule={inventoryRule} />
            </Form.Group>
            <Form.Group controlId="warehouseType">
              <Form.ControlLabel>Warehouse</Form.ControlLabel>
              <Form.Control inline name="warehouseType" accepter={RadioGroup}>
                <Radio value={WarehouseType.INTERNAL}>Internal</Radio>
                <Radio value={WarehouseType.EXTERNAL}>External</Radio>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="warehouse">
              <Form.ControlLabel />
              {formValue.warehouseType === WarehouseType.INTERNAL && (
              <div>
                <Form.Control
                  name="internalWarehouses"
                  accepter={CheckPicker}
                  data={internalWarehouses}
                  rule={internalWarehouseRule}
                />
                <Form.HelpText tooltip>
                  Required.
                  <br />
                  System will
                  {' '}
                  <b>sum</b>
                  {' '}
                  product stocks from all selected warehouses.
                </Form.HelpText>
              </div>
              )}
              {formValue.warehouseType === WarehouseType.EXTERNAL && (
              <div>
                <Form.Control
                  name="externalWarehouse"
                  accepter={SelectPicker}
                  data={externalWarehouses}
                  rule={externalWarehouseRule}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </div>
              )}
            </Form.Group>
          </>
        )}
        <Form.Group controlId="orderSource">
          <Form.ControlLabel>Order source</Form.ControlLabel>
          <Form.Control name="orderSource" accepter={SelectPicker} data={orderSources} rule={orderSourceRule} />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
      </Form>
      )}
      <Form layout="horizontal">
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      <PlatformIdSyncExecution
        eshopCodes={eshopCode ? [eshopCode] : eshopCodes}
        ref={platformIdSyncExecutionRef}
      />
      <Modal open={openInventoryWarnDialog}>
        <Modal.Body>
          <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
          Changing inventory will result in synchronization
          {' '}
          of product identifiers from the new inventory.
        </Modal.Body>
        <Modal.Footer>
          <Stack justifyContent="space-around">
            <Button appearance="ghost" onClick={handleBack}>Back</Button>
            <Button appearance="primary" onClick={handleOk}>Ok</Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
}

BasicInformationForm.defaultProps = {
  eshopCode: undefined,
};
