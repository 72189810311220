import React from 'react';
import { Divider, Panel, Stack } from 'rsuite';
import { fullImportOffers, importOffersMissingParameters } from '../api/products';

import DownloadTemplate from '../components/DownloadTemplate';
import Guidelines from '../components/Guidelines';
import FileUploadCsv from '../components/FileUploadCsv';
import DownloadMissingAttributesReport from '../components/DownloadMissingAttributesReport';

interface Props {
  type: 'KAUFLAND' | 'ALLEGRO'
}

const templateFilenames = {
  KAUFLAND: 'templateKauflandOffers.csv',
  ALLEGRO: 'templateAllegroOffers.csv',
};

export default function ImportOffers({ type }: Props) {
  async function uploadFile(file:Blob) {
    const data = new FormData();
    data.append('attachment', file);
    const response = await fullImportOffers(data, type);
    return response.data;
  }

  async function uploadFileMissingParameters(file:Blob) {
    const data = new FormData();
    data.append('attachment', file);
    return importOffersMissingParameters(data);
  }

  return (
    <Panel>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Guidelines />
        <DownloadTemplate filename={templateFilenames[type]} />
        <DownloadMissingAttributesReport />
      </Stack>
      <Divider />
      <h6>import</h6>
      <FileUploadCsv className="mt-5" getUploadResult={(f) => uploadFile(f)} />
      {type === 'ALLEGRO' && (
      <div>
        <Divider />
        <h6>Import missing parameters for offers</h6>
        <FileUploadCsv className="mt-5" getUploadResult={(f) => uploadFileMissingParameters(f)} />
      </div>
      )}
    </Panel>
  );
}
