import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { getCurrentUserToken } from '../services/authorization';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const mock = new MockAdapter(API, { delayResponse: 500 });
if (process.env.REACT_APP_API_MOCK === 'false') {
  mock.restore();
}

API.interceptors.request.use((request) => {
  if (request.url === 'user') {
    // login request uses basic auth
    return request;
  }

  API.defaults.auth = undefined;
  const token = getCurrentUserToken();
  request.headers!.Authorization = `BEARER ${token}`;

  return request;
});

export default {
  get: API.get,
  post: API.post,
  put: API.put,
  delete: API.delete,
  patch: API.patch,
  mock,
};

export enum Header {
  X_ESHOP_CODE = 'X-Eshop-Code',
  ACCEPT = 'Accept',
}
