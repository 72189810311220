import api from '../base';
import { PartnerEshopBankDetails, PartnerEshopCrossDock, PartnerEshopDefaults } from '../apiTypes';
import { mockGetPartnerCrossDock, mockGetPartnerEshopBankDetails, mockGetPartnerEshopDefaults } from '../mocks/eshopMock';

export interface EshopSetup {
  enableSyncWithEshop: boolean,
  id: string,
  clientId: string,
  stockThreshold: number | null | undefined,
  expeditionDays: number,
  fixedFee?: number,
  dynamicBranchId?: boolean,
  substitutedPhoneNumber?: string
  baseMarketplaceCode?: string
}

const mallCzMock : EshopSetup = {
  enableSyncWithEshop: true,
  id: '1234',
  clientId: 'skjhf8a7s5dcjasbldkfjhc8as7d6fasjhdgfcasasdf76vcagskjdfhaksjdhf2',
  stockThreshold: null,
  expeditionDays: 2,
  fixedFee: 150,
  substitutedPhoneNumber: '+491759937822',
};

const alzaCzMock : EshopSetup = {
  enableSyncWithEshop: true,
  id: '197523',
  stockThreshold: 1,
  expeditionDays: 0,
  clientId: 'skjhf8a7s5dcjasblagskjdfhaksjdhf2',
  dynamicBranchId: true,
};

const mockKauflandCz: EshopSetup = {
  enableSyncWithEshop: true,
  id: 'kaufland-cz-client-key',
  clientId: 'kaufland-cz-client-secret',
  stockThreshold: 1,
  expeditionDays: 2,
};

const mockKauflandSk: EshopSetup = {
  enableSyncWithEshop: false,
  id: '',
  clientId: '',
  stockThreshold: null,
  expeditionDays: 1,
};

const mockAllegroCz: EshopSetup = {
  enableSyncWithEshop: true,
  id: '',
  clientId: '',
  stockThreshold: 5,
  expeditionDays: 3,
};

const mockAllegroPl: EshopSetup = {
  enableSyncWithEshop: false,
  id: '',
  clientId: '',
  stockThreshold: null,
  expeditionDays: 1,
};

export function getEshopSetup(eshopCode: string) {
  return api.get<EshopSetup>(`management/partner-eshops/${eshopCode}`).then((response) => response.data);
}

api.mock.onGet('management/partner-eshops/MALL.CZ').reply(200, mallCzMock);
api.mock.onGet('management/partner-eshops/ALZA.CZ').reply(200, alzaCzMock);
api.mock.onGet('management/partner-eshops/KAUFLAND.CZ').reply(200, mockKauflandCz);
api.mock.onGet('management/partner-eshops/KAUFLAND.SK').reply(200, mockKauflandSk);
api.mock.onGet('management/partner-eshops/KAUFLAND.DE').reply(200, null);
api.mock.onGet('management/partner-eshops/MALL.SK').reply(200, null);
api.mock.onGet('management/partner-eshops/ALLEGRO.CZ').reply(200, mockAllegroCz);
api.mock.onGet('management/partner-eshops/ALLEGRO.PL').reply(404, mockAllegroPl);

export function saveEshopSetup(eshopCode: string, eshopSetup: EshopSetup) {
  return api.post<null>(`management/partner-eshops/${eshopCode}`, eshopSetup);
}

api.mock.onPost('management/partner-eshops/MALL.CZ').reply(204, null);
api.mock.onPost('management/partner-eshops/MALL.SK').reply(204, null);
api.mock.onPost('management/partner-eshops/KAUFLAND.CZ').reply(204);
api.mock.onPost('management/partner-eshops/KAUFLAND.SK').reply(204);
api.mock.onPost('management/partner-eshops/KAUFLAND.DE').reply(204);
api.mock.onPost('management/partner-eshops/ALLEGRO.CZ').reply(204);
api.mock.onPost('management/partner-eshops/ALLEGRO.PL').reply(204);

export interface PlatformISyncStatus {
  partnerCode: string,
  eshopCode: string,
  numberOfProductsAssignedToEshop: number,
  numberOfProductsWithoutPlatformId: number,
}

const mallCzIdSyncStatusMock : PlatformISyncStatus = {
  partnerCode: 'Partner',
  eshopCode: 'MALL.CZ',
  numberOfProductsAssignedToEshop: 5,
  numberOfProductsWithoutPlatformId: 0,
};

const mallSkIdSyncStatusMock : PlatformISyncStatus = {
  partnerCode: 'Partner',
  eshopCode: 'MALL.SK',
  numberOfProductsAssignedToEshop: 5,
  numberOfProductsWithoutPlatformId: 5,
};

export function getPlatformIdSyncStatusForEshop(eshopCode: string) {
  return api.get<PlatformISyncStatus>(`management/products/platform-id/sync-status/${eshopCode}`).then((response) => response.data);
}

api.mock.onGet('management/products/platform-id/sync-status/MALL.CZ').reply(200, mallCzIdSyncStatusMock);
api.mock.onGet('management/products/platform-id/sync-status/MALL.SK').reply(200, mallSkIdSyncStatusMock);

export interface RecalculateAlzaBranchIdResponse {
  error?: string,
  numberOfChangedBranchId: number,
  numberOfUnassignedBranchId: number,
}

const recalculateAlzaBranchIdsMockSuccess : RecalculateAlzaBranchIdResponse = {
  numberOfChangedBranchId: 5,
  numberOfUnassignedBranchId: 2,
};

export function recalculateAlzaBranchIds() {
  return api.post<RecalculateAlzaBranchIdResponse>('management/partner-eshops/recalculate-alza-branch-id');
}

api.mock.onPost('management/partner-eshops/recalculate-alza-branch-id').reply(200, recalculateAlzaBranchIdsMockSuccess);

const ESHOPS_URL = 'management/eshops';

export interface Eshop {
  code: string,
  name: string,
  apiGroup: string,
  currency: string,
}

export const mockGetEshops: Eshop[] = [{
  code: 'MALL.CZ',
  name: 'mall.cz',
  apiGroup: 'MALL_GROUP',
  currency: 'CZK',
}, {
  code: 'MALL.SK',
  name: 'mall.sk',
  apiGroup: 'MALL_GROUP',
  currency: 'EUR',
}, {
  code: 'MALL.PL',
  name: 'mall.pl',
  apiGroup: 'MALL_GROUP',
  currency: 'PLN',
}, {
  code: 'ALZA.CZ',
  name: 'alza.cz',
  apiGroup: 'ALZA',
  currency: 'CZK',
},
{
  code: 'KAUFLAND.CZ',
  name: 'kaufland.cz',
  apiGroup: 'KAUFLAND',
  currency: 'CZK',
},
{
  code: 'KAUFLAND.SK',
  name: 'kaufland.sk',
  apiGroup: 'KAUFLAND',
  currency: 'EUR',
},
{
  code: 'KAUFLAND.DE',
  name: 'kaufland.de',
  apiGroup: 'KAUFLAND',
  currency: 'EUR',
},
{
  code: 'ALLEGRO.CZ',
  name: 'allegro.cz',
  apiGroup: 'ALLEGRO',
  currency: 'CZK',
},
{
  code: 'ALLEGRO.PL',
  name: 'allegro.pl',
  apiGroup: 'ALLEGRO',
  currency: 'PLN',
}];

export async function getEshops() {
  return api.get<Eshop[]>(ESHOPS_URL);
}

api.mock.onGet(ESHOPS_URL).reply(200, mockGetEshops);

export async function getPartnerEshopBankDetails(
  eshopCode: string,
): Promise<PartnerEshopBankDetails> {
  const response = await api.get<PartnerEshopBankDetails>(`management/partner-eshops/${eshopCode}/bank-details`);
  return response.data;
}

api.mock.onGet(/management\/partner-eshops\/.+\/bank-details/).reply(200, mockGetPartnerEshopBankDetails);

export async function savePartnerEshopBankDetails(
  eshopCode: string,
  bankDetails: PartnerEshopBankDetails,
): Promise<null> {
  const response = await api.post<null>(`management/partner-eshops/${eshopCode}/bank-details`, bankDetails);
  return response.data;
}

api.mock.onPost(/management\/partner-eshops\/.+\/bank-details/).reply(204, null);

export async function getPartnerEshopDefaults(eshopCode: string): Promise<PartnerEshopDefaults> {
  const response = await api.get<PartnerEshopDefaults>(`management/partner-eshops/${eshopCode}/defaults`);
  return response.data;
}

api.mock.onGet(/management\/partner-eshops\/.+\/defaults/).reply(200, mockGetPartnerEshopDefaults);

export async function savePartnerEshopDefaults(
  eshopCode: string,
  defaults: PartnerEshopDefaults,
): Promise<null> {
  const response = await api.post<null>(`management/partner-eshops/${eshopCode}/defaults`, defaults);
  return response.data;
}

api.mock.onPost(/management\/partner-eshops\/.+\/defaults/).reply(204, null);

export async function getPartnerEshopCrossDock(
  eshopCode: string,
  signal: AbortSignal,
): Promise<PartnerEshopCrossDock> {
  const response = await api.get<PartnerEshopCrossDock>(`management/partner-eshops/${eshopCode}/cross-dock`, { signal });
  return response.data;
}

api.mock.onGet(/management\/partner-eshops\/.+\/cross-dock/).reply(200, mockGetPartnerCrossDock);

export async function savePartnerEshopCrossDock(
  eshopCode: string,
  crossDock: Partial<PartnerEshopCrossDock>,
  signal: AbortSignal,
): Promise<null> {
  const response = await api.post<null>(`management/partner-eshops/${eshopCode}/cross-dock`, crossDock, { signal });
  return response.data;
}

api.mock.onPost(/management\/partner-eshops\/.+\/cross-dock/).reply(204, null);
