import api from './base';
import { PartnerInfo } from './apiTypes';

export async function getPartnerInfo(): Promise<PartnerInfo> {
  return api.get<PartnerInfo>('/partner/info').then(response => response.data);
}

const mockResponse: PartnerInfo = {
  partnerName: 'MegaPartner',
  integration: 'BASELINKER',
};

api.mock.onGet('/partner/info').reply(200, mockResponse);

export async function savePartnerInfo(info: PartnerInfo): Promise<string> {
  return api.post<string>('/partner/info', info).then(response => response.data);
}

api.mock.onPost('/partner/info').reply(202);

type Webhook = {
  useWebhook: boolean;
  webhookUrl: string;
};

export async function getPartnersWebhook(): Promise<Webhook> {
  return api.get('/partner/webhook').then(response => response.data);
}

api.mock.onGet('/partner/webhook').reply(200, {
  useWebhook: true,
  webhookUrl: 'https://www.xemel.cz/liftago/sadf987654654asdf',
});

export async function setPartnerWebhookUrl(webhook: Webhook) {
  return api.patch('/partner/webhook', webhook);
}

api.mock.onPatch('/partner/webhook').reply(204);

export async function generateApiToken() {
  return api.get('/partner/token/generate').then(response => response.data);
}

api.mock.onGet('/partner/token/generate').reply(200, 's6djl3Kf4DasdG9kHJ03');

export async function saveApiToken(apiToken: string) {
  return api.post('/partner/token', apiToken, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

api.mock.onPost('/partner/token').reply(204);

export async function isApiTokenGenerated(): Promise<boolean> {
  return api.get<boolean>('/partner/token').then(response => response.data);
}

export type Partner = {
  id: number,
  code: string,
  name: string,
  integration: string,
  marketplaceCountTotal: number,
  marketplaceCountActive: number,
  webhook: string,
};

const partnersMock: Partner[] = [{
  id: 0,
  code: '0000',
  name: 'ProSales, sro',
  integration: 'MPI',
  marketplaceCountTotal: 1,
  marketplaceCountActive: 1,
  webhook: 'https://prosale.cz/mpi/hook.php',
}, {
  id: 1,
  code: '0002',
  name: 'Shumee',
  integration: 'MPI',
  marketplaceCountTotal: 5,
  marketplaceCountActive: 5,
  webhook: 'https://shum.cz/mpi/hook.php',
},
{
  id: 3,
  code: 'Partner',
  name: 'Partner',
  integration: 'MPI',
  marketplaceCountTotal: 5,
  marketplaceCountActive: 5,
  webhook: 'https://shum.cz/mpi/hook.php',
}];

export async function getPartners() {
  return api.get<Partner[]>('management/partners');
}

api.mock.onGet('management/partners').reply(200, partnersMock);
