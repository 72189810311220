import { action, computed, thunk } from 'easy-peasy';
import jwtDecode from 'jwt-decode';
import {
  Permission, rolePermissions, TokenPayload, UserStoreModel, UserWithToken,
} from './types';

import getUser, { changePassword } from '../../api/user';
import {
  getCurrentIndex,
  getUserTokens,
  removeUseTokens,
  setCurrentIndex,
  setUserTokens,
} from '../../services/authorization';

function parseTokens(data: string[]) {
  return data
    .map(token => {
      const decoded = jwtDecode<TokenPayload>(token);

      return {
        user: {
          name: decoded.user.name,
          username: decoded.user.username,
          roles: decoded.user.roles,
          partnerCode: decoded.user.partnerCode,
          partnerName: decoded.user.partnerName,
        },
        token,
        expires: decoded.exp,
      } as UserWithToken;
    })
    .sort((a, b) => a.user.partnerName.localeCompare(b.user.partnerName));
}

const userStore : UserStoreModel = {
  userTokens: [],
  currentUser: {
    username: '',
    name: '',
    roles: [],
    partnerCode: '',
    partnerName: '',
  },
  login: thunk(async (actions, payload) => {
    const { data } = await getUser(payload);

    if (data.length > 0) {
      const users = parseTokens(data);

      actions.setTokens(users);
      actions.setCurrentUser(users[0].user);

      const orderedTokens = users.map(u => u.token);
      setUserTokens(orderedTokens, 0);
    }
  }),
  logout: action((state) => {
    state.userTokens = [];
    state.currentUser = {
      username: '',
      name: '',
      roles: [],
      partnerCode: '',
      partnerName: '',
    };
    removeUseTokens();
  }),
  changePassword: thunk(async (actions, payload) => {
    await changePassword({
      username: payload.username,
      password: payload.password,
      newPassword: payload.newPassword,
    });
  }),
  restore: action((state) => {
    const tokens = getUserTokens();
    if (tokens.length > 0) {
      const userWithTokens = parseTokens(tokens);

      const expired = userWithTokens.filter(ut => Date.now() >= ut.expires * 1000);
      if (expired.length > 0) {
        removeUseTokens();
        return;
      }

      const index = getCurrentIndex();

      state.userTokens = userWithTokens;
      state.currentUser = userWithTokens[index].user;
    }
  }),
  setTokens: action((state, users) => {
    state.userTokens = users;
  }),
  setCurrentUser: action((state, user) => {
    state.currentUser = user;
  }),
  changePartner: action((state, partnerCode) => {
    for (let i = 0; i < state.userTokens.length; i++) {
      const userToken = state.userTokens[i];

      if (userToken.user.partnerCode === partnerCode) {
        state.currentUser = userToken.user;

        setCurrentIndex(i);
        break;
      }
    }
  }),
  // eslint-disable-next-line max-len
  hasPermission: computed((state) => (requiredPermission: Permission) => state.currentUser.roles?.some(
    role => rolePermissions[role]?.includes(requiredPermission),
  )),
};

export default userStore;
