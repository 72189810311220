import api from '../base';
import { CrossDockTracking } from './crossDockTrackingTypes';
import { mockGetCrossDockTrackings } from './crossDockTrackingMocks';

const BASE_PATH = 'management/cross-dock-trackings';

export async function getCrossDockTrackings(
  orderId: number,
  signal?: AbortSignal,
): Promise<CrossDockTracking> {
  const response = await api.get<CrossDockTracking>(
    BASE_PATH,
    {
      params: { orderId },
      signal,
    },
  );
  return response.data;
}

api.mock
  .onGet('management/cross-dock-trackings')
  .reply(200, mockGetCrossDockTrackings);

export async function saveCrossDockTrackings(
  orderId: number,
  crossDockTracking: CrossDockTracking,
  signal?: AbortSignal,
): Promise<CrossDockTracking> {
  const response = await api.put<CrossDockTracking>(
    BASE_PATH,
    crossDockTracking,
    {
      params: { orderId },
      signal,
    },
  );
  return response.data;
}

api.mock
  .onPut('management/cross-dock-trackings')
  .reply(204);
