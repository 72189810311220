import React, { useRef } from 'react';
import {
  Button, ButtonToolbar, Form, Loader, Radio, RadioGroup, Schema, SelectPicker,
} from 'rsuite';
import _ from 'lodash';
import { Eshop } from '../../../api/eshop/eshop';
import { AllegroDeliveryMethod, AllegroDeliveryService, AllegroOrderCarrier } from '../../../api/allegroApiTypes';
import { Carrier, MpiDeliveryTypeLabels } from '../../../api/apiTypes';

interface Props {
  edit: Edit;
  eshopCodes: Eshop[];
  deliveryMethods: AllegroDeliveryMethod[];
  orderCarriers: AllegroOrderCarrier[];
  deliveryServices: AllegroDeliveryService[];
  carriers: (Omit<Carrier, 'id'> & { id: string })[];
  isLoading: boolean;
  isSaving: boolean;
  onChange: (edit: Edit) => void;
  onSave: () => void;
  onCancel: () => void;
}

interface Edit {
  eshopCode?: string;
  eshopCarrierCode?: string;
  secondaryEshopCarrierCode?: string;
  managedByPartner?: string;
  eshopCarrierType?: string;
  deliveryType?: string;
  carrierId?: string;
  portalName?: string;
}

const deliveryTypes = _.map(MpiDeliveryTypeLabels, (label, value) => ({ label, value }));
const validationModel = Schema.Model({
  eshopCode: Schema.Types.StringType().isRequired('Marketplace is required.'),
  eshopCarrierCode: Schema.Types.StringType().isRequired('Delivery method id is required.'),
  managedByPartner: Schema.Types.StringType().isRequired('Managed by is required.'),
  eshopCarrierType: Schema.Types.StringType()
    .addRule((value, edit: Edit) => {
      if (edit.managedByPartner === 'false') {
        return true;
      }

      return !_.isEmpty(value);
    }, 'Allegro carrier id is required.', true),
  deliveryType: Schema.Types.StringType().isRequired('Delivery type is required.'),
  carrierId: Schema.Types.StringType().isRequired('MPI carrier is required.'),
});

export default function CarrierSetupAllegroEditForm({
  edit,
  eshopCodes,
  deliveryMethods,
  orderCarriers,
  deliveryServices,
  carriers,
  isLoading,
  isSaving,
  onChange,
  onSave,
  onCancel,
}: Props) {
  const formRef = useRef<any>();

  const {
    eshopCode,
    eshopCarrierCode,
    secondaryEshopCarrierCode,
    managedByPartner,
    eshopCarrierType,
    deliveryType,
    carrierId,
    portalName,
  } = edit;

  function handleChange(value: Edit): void {
    onChange(value);
  }

  function handleSave(): void {
    if (!formRef.current?.check()) {
      return;
    }

    onSave();
  }

  function handleCancel(): void {
    onCancel();
  }

  return (
    <Form
      layout="horizontal"
      formValue={edit}
      ref={formRef}
      model={validationModel}
      onChange={(value) => handleChange(value)}
      fluid
    >
      <Form.Group>
        <Form.ControlLabel>
          Marketplace
        </Form.ControlLabel>
        <Form.Control
          name="eshopCode"
          accepter={SelectPicker}
          data={eshopCodes}
          value={eshopCode ?? null}
          labelKey="name"
          valueKey="code"
          searchable={false}
          cleanable={false}
        />
        <Form.HelpText>Required</Form.HelpText>
      </Form.Group>
      { eshopCode && isLoading && <Loader />}
      { eshopCode && !isLoading && (
      <>
        <Form.Group>
          <Form.ControlLabel>
            Delivery method ID
          </Form.ControlLabel>
          <Form.Control
            name="eshopCarrierCode"
            accepter={SelectPicker}
            data={deliveryMethods}
            value={eshopCarrierCode ?? null}
            labelKey="id"
            valueKey="id"
            searchable
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Delivery method name
          </Form.ControlLabel>
          <Form.Control
            name="eshopCarrierCode"
            accepter={SelectPicker}
            data={deliveryMethods}
            value={eshopCarrierCode ?? null}
            labelKey="name"
            valueKey="id"
            searchable
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Managed by</Form.ControlLabel>
          <Form.Control
            name="managedByPartner"
            accepter={RadioGroup}
            value={managedByPartner ?? null}
          >
            <Radio value="false">Ship with Allegro</Radio>
            <Radio value="true">Partner</Radio>
          </Form.Control>
        </Form.Group>
        { managedByPartner === 'true' && (
        <Form.Group>
          <Form.ControlLabel>
            Allegro carrier ID
          </Form.ControlLabel>
          <Form.Control
            name="eshopCarrierType"
            accepter={SelectPicker}
            data={orderCarriers}
            value={eshopCarrierType ?? null}
            labelKey="name"
            valueKey="id"
            searchable
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        )}
        { managedByPartner === 'false' && (
        <Form.Group>
          <Form.ControlLabel>
            Delivery service ID
          </Form.ControlLabel>
          <Form.Control
            name="secondaryEshopCarrierCode"
            accepter={SelectPicker}
            data={deliveryServices}
            value={secondaryEshopCarrierCode ?? null}
            valueKey="id"
            labelKey="id"
            searchable
            cleanable
          />
          <Form.HelpText>Optional</Form.HelpText>
        </Form.Group>
        ) }
        { managedByPartner === 'false' && (
        <Form.Group>
          <Form.ControlLabel>
            Delivery service name
          </Form.ControlLabel>
          <Form.Control
            name="secondaryEshopCarrierCode"
            accepter={SelectPicker}
            data={deliveryServices}
            value={secondaryEshopCarrierCode ?? null}
            valueKey="id"
            labelKey="name"
            searchable
            cleanable
          />
          <Form.HelpText>Optional</Form.HelpText>
        </Form.Group>
        )}
        <Form.Group>
          <Form.ControlLabel>
            Delivery type
          </Form.ControlLabel>
          <Form.Control
            name="deliveryType"
            accepter={SelectPicker}
            data={deliveryTypes}
            value={deliveryType ?? null}
            searchable={false}
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            MPI carrier
          </Form.ControlLabel>
          <Form.Control
            name="carrierId"
            accepter={SelectPicker}
            data={carriers}
            value={carrierId ?? null}
            labelKey="identifier"
            valueKey="id"
            searchable
            cleanable={false}
          />
          <Form.HelpText>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Portal name
          </Form.ControlLabel>
          <Form.Control
            name="portalName"
            value={portalName ?? null}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => handleSave()}
              type="submit"
              loading={isSaving}
            >
              Save
            </Button>
            <Button
              appearance="ghost"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </>
      )}
    </Form>
  );
}
