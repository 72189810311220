import React, { useEffect, useReducer } from 'react';
import _ from 'lodash';
import { v4 } from 'uuid';
import {
  IconButton, Input, InputGroup, Stack, Table,
} from 'rsuite';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@rsuite/icons/Close';
import { EshopCarrier, KauflandCarrier } from '../../../api/apiTypes';
import { getEshopCarriers } from '../../../api/carrier';
import { getKauflandCarriers } from '../../../api/kaufland';
import { CARRIER_SETUP_EDIT } from '../../../routes/links';
import { ApiGroup } from '../../../api/model/ApiGroup';
import { utils } from '../../../utils/utils';
import Pagination from '../../Pagination';
import usePagination from '../../../hooks/paginationHook';

const FILTER_KEYS = [
  'eshopCarrier.eshopCode',
  'eshopCarrier.identifier',
  'kauflandCarrier.carrierCode',
];

interface CarrierItem {
  id: string;
  eshopCarrier: EshopCarrier;
  kauflandCarrier?: KauflandCarrier;
}

function mapCarrierItems(
  eshopCarriers: EshopCarrier[],
  kauflandCarriers: KauflandCarrier[],
): CarrierItem[] {
  return eshopCarriers
    .map((eshopCarrier) => ({
      id: v4(),
      eshopCarrier,
      kauflandCarrier: _.find(
        kauflandCarriers,
        (carrier) => carrier.eshopCarrierId === eshopCarrier.id,
      ),
    }));
}

interface State {
  isLoading: boolean;
  carrierItems: CarrierItem[];
}

const initialState: State = {
  isLoading: false,
  carrierItems: [],
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload: CarrierItem[] };
type Action = LoadingAction | LoadingSuccessfulAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return { ...state, isLoading: false, carrierItems: action.payload };
    default:
      return state;
  }
}

export default function CarrierSetupKauflandTable(): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pagination, setPagination] = usePagination();
  const navigate = useNavigate();

  function fetchData(): void {
    dispatch({ type: 'loading' });
    Promise.all([
      getEshopCarriers({ apiGroup: ApiGroup.KAUFLAND }),
      getKauflandCarriers(),
    ])
      .then(response => mapCarrierItems(
        response[0],
        response[1],
      ))
      .then((carrierItems) => dispatch({
        type: 'loadingSuccessful',
        payload: carrierItems,
      }));
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filteredCarrierItems = utils.fulltextFiltered(
    state.carrierItems,
    pagination.filter,
    FILTER_KEYS,
  );
  const paginatedCarrierItems = utils.paginated(filteredCarrierItems, pagination);

  function handleFilterChange(filter: string): void {
    setPagination({ ...pagination, filter });
  }

  function handleChangeLimit(limit: number): void {
    setPagination({ ...pagination, limit });
  }

  function handleChangePage(page: number): void {
    setPagination({ ...pagination, page });
  }

  function handleEdit(id: number): void {
    navigate(`${CARRIER_SETUP_EDIT}/${id}`);
  }

  function handleAddNew(): void {
    navigate(CARRIER_SETUP_EDIT);
  }

  return (
    <Stack
      spacing={15}
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <InputGroup style={{ width: 400 }}>
        <Input
          placeholder="search by marketplace, kaufland carrier, MPI carrier"
          value={pagination.filter}
          onChange={(value) => handleFilterChange(value)}
        />
        <InputGroup.Button onClick={() => handleFilterChange('')}>
          <CloseIcon />
        </InputGroup.Button>
      </InputGroup>
      <Table
        autoHeight
        bordered
        data={filteredCarrierItems}
        loading={state.isLoading}
        onRowClick={(item) => handleEdit(item.eshopCarrier.id)}
      >
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Marketplace</Table.HeaderCell>
          <Table.Cell dataKey="eshopCarrier.eshopCode" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Kaufland carrier</Table.HeaderCell>
          <Table.Cell dataKey="kauflandCarrier.carrierCode" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>MPI carrier</Table.HeaderCell>
          <Table.Cell dataKey="eshopCarrier.identifier" />
        </Table.Column>
        <Table.Column flexGrow={6} align="center">
          <Table.HeaderCell>{}</Table.HeaderCell>
          <Table.Cell>{}</Table.Cell>
        </Table.Column>
      </Table>
      <div className="mt-10">
        <Pagination
          total={paginatedCarrierItems.length}
          limit={pagination.limit}
          activePage={pagination.page}
          onChangePage={(value) => handleChangePage(value)}
          onChangeLimit={(value) => handleChangeLimit(value)}
        />
      </div>
      <IconButton
        size="md"
        appearance="subtle"
        icon={<BsPlusCircle size={20} />}
        onClick={() => handleAddNew()}
      />
    </Stack>
  );
}
