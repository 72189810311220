import {
  Button, Form, Modal, Panel, PanelGroup, Radio, RadioGroup, Schema, SelectPicker, Stack,
} from 'rsuite';
import React, { useEffect, useState } from 'react';
import { OrderListItem, OrderStates, OrderWithItems } from '../../api/apiTypes';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { getOrderItems, updateSerialNumbers } from '../../api/orders';
import OrdersSerialNumbersForm from './OrdersSerialNumbersForm';
import { ApiGroup } from '../../api/model/ApiGroup';

interface OrderIdentification {
  id: number;
  partnerOrderId: string;
  orderNumber: string;
  carrier: string;
}

interface TrackingInfo {
  orderIdentification: OrderIdentification;
  number: string;
  url: string;
  isValid: () => boolean;
  belongToSameOrderAs: (trackingInfo: TrackingInfo) => boolean;
}

class TrackingInfoImpl implements TrackingInfo {
  orderIdentification: OrderIdentification;

  number: string;

  url: string;

  apiGroup: ApiGroup;

  constructor(
    orderIdentification: OrderIdentification,
    number: string,
    url: string,
    apiGroup: ApiGroup,
  ) {
    this.orderIdentification = orderIdentification;
    this.number = number;
    this.url = url;
    this.apiGroup = apiGroup;
  }

  isValid(): boolean {
    if (!this.number) return false;
    return this.isUrlValid();
  }

  isUrlValid(): boolean {
    if ([ApiGroup.ALLEGRO, ApiGroup.KAUFLAND].includes(this.apiGroup)) {
      return true;
    }

    return !!this.url;
  }

  belongToSameOrderAs(trackingInfo: TrackingInfo) {
    return this.orderIdentification.id === trackingInfo.orderIdentification.id;
  }
}

interface ShipInfoFormProps {
  trackingInfos: TrackingInfo[]
  handleFormChange: (newTrackingInfo: TrackingInfo[]) => void
}

function TrackingInfoForm({ trackingInfos, handleFormChange }: ShipInfoFormProps) {
  function handleFieldChange(oldTrackingInfo: TrackingInfo, event:any) {
    const newTrackingInfo = oldTrackingInfo;

    if (event.name === 'number') {
      newTrackingInfo.number = event.value;
    }
    if (event.name === 'url') {
      newTrackingInfo.url = event.value;
    }

    const newTrackingInfos = [...trackingInfos];
    const changeShipInfoIndex = newTrackingInfos
      .findIndex(currentTrackingInfo => currentTrackingInfo.belongToSameOrderAs(newTrackingInfo));
    newTrackingInfos[changeShipInfoIndex] = newTrackingInfo;

    handleFormChange(newTrackingInfos);
  }

  return (
    <Form layout="horizontal">
      <PanelGroup>
        {trackingInfos.map(trackingInfo => {
          const { carrier, partnerOrderId, orderNumber } = trackingInfo.orderIdentification;
          const partnerIdMessage = partnerOrderId ? `partner ID ${partnerOrderId},` : '';
          const header = `For order carried by ${carrier},
          ${partnerIdMessage} 
          order number ${orderNumber}`;
          return (
            <Panel header={header}>
              <Form.Control
                name="number"
                placeholder="Tracking Number"
                value={trackingInfo.number}
                onChange={(value: any) => handleFieldChange(trackingInfo, { name: 'number', value })}
              />
              <Form.Control
                name="url"
                placeholder="Tracking Url"
                value={trackingInfo.url}
                onChange={(value: any) => handleFieldChange(trackingInfo, { name: 'url', value })}
              />
            </Panel>
          );
        })}
      </PanelGroup>
    </Form>
  );
}
interface ConfirmCancelProps {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  numberOfOrders: number;
}

function ConfirmCancelOrdersDialog({
  open, handleOk, handleCancel, numberOfOrders,
}: ConfirmCancelProps) {
  return (
    <Modal open={open} backdrop="static" onClose={handleCancel}>
      <Modal.Title>
        {`Cancelling ${numberOfOrders} orders`}
      </Modal.Title>
      <Modal.Body>
        {`Are you sure you want to cancel ${numberOfOrders} selected orders? this action cannot be reverted.`}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOk} appearance="primary">
          Ok
        </Button>
        <Button onClick={handleCancel} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface Props {
  open: boolean;
  orders: OrderListItem[];
  close: () => void;
}

export default function OrderStatesChangeDialog({ open, orders, close }: Props) {
  const cancelReasons = useStoreState(state => state.catalogs.cancelReasons);
  const { updateState } = useStoreActions(actions => actions.orders);
  const { fetchCancelReasons } = useStoreActions(actions => actions.catalogs);
  const [selectedState, setSelectedState] = useState<OrderStates>(OrderStates.OPEN);
  const [cancelReasonForm, setCancelReasonForm] = useState<Record<string, any>>({ cancelReason: '' });
  const [trackingInfos, setTrackingInfos] = useState<TrackingInfo[]>([]);
  const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false);
  const [ordersWithItems, setOrdersWithItems] = useState<OrderWithItems[]>([]);
  const [areSerialNumbersValid, setAreSerialNumbersValid] = useState<boolean>(false);

  const confirmedStateChange = () => {
    setOpenConfirmCancelDialog(false);
    function updateOrderState(order: OrderListItem) {
      const newStateRequest = {
        id: order!.id,
        newState: selectedState,
        trackingNumber: '',
        trackingUrl: '',
        cancelReason: '',
      };

      if (selectedState === OrderStates.CANCELLED) {
        newStateRequest.cancelReason = cancelReasonForm.cancelReason;
      }

      const trackingInfo = trackingInfos.find(info => info.orderIdentification.id === order.id);
      if (trackingInfo) {
        newStateRequest.trackingNumber = trackingInfo.number;
        newStateRequest.trackingUrl = trackingInfo.url;
      }

      updateState(newStateRequest);
    }

    orders.forEach(updateOrderState);

    close();
  };

  function getOrdersWithItems() {
    const requests: Promise<OrderWithItems>[] = orders
      .map(order => getOrderItems(order.id).then(items => ({ ...order, items })));
    Promise
      .all(requests)
      .then(items => setOrdersWithItems(items));
  }

  function saveSerialNumbers(onSuccess: () => void) {
    const requests: Promise<null>[] = ordersWithItems
      .map((orderWithItems) => {
        const { id, items } = orderWithItems;
        const serialNumbers = items.map((orderItem) => orderItem.serialNumbers).flat();
        return updateSerialNumbers(id, serialNumbers);
      });
    Promise
      .all(requests)
      .then(() => onSuccess());
  }

  const confirmStateChange = () => {
    switch (selectedState) {
      case OrderStates.CANCELLED:
        setOpenConfirmCancelDialog(true);
        break;
      case OrderStates.SHIPPED:
        saveSerialNumbers(() => confirmedStateChange());
        break;
      default:
        confirmedStateChange();
    }
  };

  const closeConfirmCancelDialog = () => {
    setOpenConfirmCancelDialog(false);
  };

  const countOccurrencesOf = (result: Map<OrderStates, number>, element: OrderStates):
  Map<OrderStates, number> => result.set(element, (result.get(element) || 0) + 1);

  function getIntersectionOfAvailableStatesForSelectedOrders() {
    const states: OrderStates[] = [];
    orders
      .flatMap(order => order.allowedStates)
      .reduce(countOccurrencesOf, new Map())
      .forEach((value, key) => {
        if (value === orders.length) {
          states.push(key);
        }
      });

    return states.sort((a, b) => {
      // push cancelled as last state
      if (a === OrderStates.CANCELLED) {
        return 1;
      }
      if (b === OrderStates.CANCELLED) {
        return -1;
      }

      return a.localeCompare(b);
    });
  }

  const states = getIntersectionOfAvailableStatesForSelectedOrders();

  const showTracking = (order: OrderListItem) => {
    if (!order) {
      return false;
    }
    return selectedState === OrderStates.SHIPPED && order!.managedByPartner;
  };

  function generateEmptyTrackingInfoForOrdersThatRequiresIt() {
    return orders.filter(order => showTracking(order))
      .map(order => {
        const orderIdentification = {
          id: order.id,
          partnerOrderId: order.partnerId,
          orderNumber: order.orderNumber,
          carrier: order.carrier,
        };
        return new TrackingInfoImpl(orderIdentification, '', '', order.apiGroup as ApiGroup);
      });
  }

  useEffect(() => {
    fetchCancelReasons();
    if (states[0]) {
      setSelectedState(states[0]);
    }
    getOrdersWithItems();
  }, [orders]);

  useEffect(() => {
    setTrackingInfos(generateEmptyTrackingInfoForOrdersThatRequiresIt());
  }, [orders, selectedState]);

  const isFormValid = () => {
    if (!orders) {
      return false;
    }

    if (states.length === 0) {
      return false;
    }

    if (selectedState === OrderStates.CANCELLED
        && !cancelReasonForm.cancelReason) {
      return false;
    }

    if (selectedState === OrderStates.SHIPPED && !areSerialNumbersValid) {
      return false;
    }

    const numberOfInvalid = trackingInfos.filter(trackingInfo => !trackingInfo.isValid()).length;
    return numberOfInvalid === 0;
  };
  const selectCancelReasonData = cancelReasons.map(
    item => ({ label: item, value: item }),
  );
  const cancelReasonValidation = Schema.Model({
    cancelReason: Schema.Types.StringType().isRequired(),
  });

  function handleTrackingInfoChange(newTrackingInfos: TrackingInfo[]) {
    setTrackingInfos(newTrackingInfos);
  }

  return (
    <div>
      <Modal
        size="sm"
        open={open}
        onClose={close}
      >
        <Modal.Header>
          <Modal.Title>
            {`State change to ${orders.length} orders`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack justifyContent="flex-start" alignItems="flex-start" direction="column" spacing={15}>
            {states.length === 0 && (
              <p>
                Selected orders can not be changed to a same status.
                Please update your selection.
              </p>
            )}
            <RadioGroup
              name="stateSelect"
              inline
              className="order-status-radio"
              onChange={(v) => setSelectedState(v as OrderStates)}
              value={selectedState}
            >
              {states.map(s => (
                <Radio
                  className={`order-status-${s}`}
                  value={s}
                  key={s}
                >
                  {s}
                </Radio>
              ))}
            </RadioGroup>
            {selectedState === OrderStates.CANCELLED
            && (
            <Form
              model={cancelReasonValidation}
              layout="horizontal"
              onChange={setCancelReasonForm}
              formValue={cancelReasonForm}
            >
              <Form.Group controlId="cancelReason">
                <Form.ControlLabel>Cancel reason</Form.ControlLabel>
                <Form.Control accepter={SelectPicker} data={selectCancelReasonData} name="cancelReason" />
              </Form.Group>
            </Form>
            )}
            {selectedState === OrderStates.SHIPPED
                && (
                <OrdersSerialNumbersForm
                  ordersWithItems={ordersWithItems}
                  onChange={(value) => setOrdersWithItems(value)}
                  onValidation={(value) => setAreSerialNumbersValid(value)}
                />
                )}
            <TrackingInfoForm
              trackingInfos={trackingInfos}
              handleFormChange={(newTrackingInfo) => handleTrackingInfoChange(newTrackingInfo)}
            />
          </Stack>

        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => confirmStateChange()}
            disabled={!isFormValid()}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={close} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>

      </Modal>
      <ConfirmCancelOrdersDialog
        open={openConfirmCancelDialog}
        handleOk={confirmedStateChange}
        handleCancel={closeConfirmCancelDialog}
        numberOfOrders={orders.length}
      />
    </div>
  );
}
