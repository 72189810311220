import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ButtonToolbar, Form, Message, Schema, Toggle, useToaster,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { getPartnersWebhook, setPartnerWebhookUrl } from '../api/partner';
import ApiTokenReset from './ApiTokenReset';

const webhookRule = Schema.Types.StringType().isURL('Must be a valid URL');

export default function MpiIntegrationSetup() {
  const [formValue, setFormValue] = useState<Record<string, any>>({ useWebhook: false, webhookUrl: '' });
  const navigate = useNavigate();
  const toaster = useToaster();
  const formRef = useRef<any>();

  useEffect(() => {
    getPartnersWebhook().then(data => setFormValue({
      ...data,
    }));
  }, []);

  const submit = () => {
    if (formRef.current?.check()) {
      setPartnerWebhookUrl({
        useWebhook: formValue.useWebhook,
        webhookUrl: formValue.webhookUrl,
      }).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Save succeeded</Message>);
      });
    } else {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Please check the form there are validation errors.</Message>);
    }
  };

  return (
    <div>
      <br />
      <h4>MPI Setup</h4>
      <Form ref={formRef} layout="horizontal" formValue={formValue} onChange={setFormValue}>
        <Form.Group>
          <Form.ControlLabel>Use Webhook</Form.ControlLabel>
          <Form.Control checked={formValue.useWebhook} name="useWebhook" accepter={Toggle} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Webhook URL</Form.ControlLabel>
          <Form.Control name="webhookUrl" rule={webhookRule} />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <ApiTokenReset />
          </ButtonToolbar>
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={submit}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
}
