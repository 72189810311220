import _ from 'lodash';

export enum ApiGroup {
  ALZA = 'ALZA',
  KAUFLAND = 'KAUFLAND',
  ALLEGRO = 'ALLEGRO',
  MALL_GROUP = 'MALL_GROUP',
}

const ALZA_ESHOP_CODES: string[] = [
  'ALZA.CZ',
];

const KAUFLAND_ESHOP_CODES: string[] = [
  'KAUFLAND.CZ',
  'KAUFLAND.SK',
  'KAUFLAND.DE',
];

const ALLEGRO_ESHOP_CODES: string[] = [
  'ALLEGRO.PL',
  'ALLEGRO.CZ',
  'ALLEGRO.SK',
];

const MALL_GROUP_ESHOP_CODES: string[] = [
  'MALL.CZ',
  'MALL.SK',
  'MALL.HU',
  'MALL.HR',
  'MALL.PL',
  'MIMOVRSTE.COM',
];

const groups: { [K in ApiGroup]: string[] } = {
  ALZA: ALZA_ESHOP_CODES,
  KAUFLAND: KAUFLAND_ESHOP_CODES,
  ALLEGRO: ALLEGRO_ESHOP_CODES,
  MALL_GROUP: MALL_GROUP_ESHOP_CODES,
};

export function getApiGroup(eshopCode: string): ApiGroup | undefined {
  const key = _.findKey(groups, (value) => value.includes(eshopCode.toUpperCase()));
  return ApiGroup[key as keyof typeof ApiGroup];
}
