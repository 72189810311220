import React, { useEffect } from 'react';
import { Button, Stack, Tag } from 'rsuite';

import LabelPackageForm from '../LabelPackage/LabelPackage';
import { useStoreActions, useStoreState } from '../../../store/hooks';

type Props = { orderId: number; afterGenerate: (url: string)=>void };

function DetailedLabelSubmit({ orderId, afterGenerate } : Props) {
  const { packages, validity, generating } = useStoreState((state) => state.labels);
  const { addPackage, saveLabels, packagePreview } = useStoreActions((actions) => actions.labels);
  const setLoading = useStoreActions(actions => actions.general.setLoading);

  const handleGenerate = async () => {
    saveLabels().then((url: string) => {
      afterGenerate(url);
    });
  };

  useEffect(() => {
    setLoading(true);
    packagePreview(orderId);
    setLoading(false);
  }, [orderId]);

  const handlePackageAddition = () => {
    addPackage();
  };

  return (
    <Stack spacing={2} direction="column" alignItems="flex-start">
      <Stack spacing={15} className="mt-5">
        <Button
          onClick={handlePackageAddition}
          appearance="ghost"
        >
          Add package
        </Button>
        <Button
          disabled={!validity.valid}
          loading={generating}
          appearance="primary"
          onClick={handleGenerate}
        >
          Generate
        </Button>
        {!validity.valid && (
        <Tag color="red">
          Validation not passed
        </Tag>
        )}
        <Tag color={validity.allItems ? 'green' : 'orange'}>
          {validity.allItems ? 'All items listed' : 'All items must be fully included in the packages'}
        </Tag>
      </Stack>
      {packages.map((p) => (
        <LabelPackageForm
          key={`package-${packages.indexOf(p)}`}
          labelPackage={p}
        />
      ))}
    </Stack>
  );
}

export default DetailedLabelSubmit;
