import React from 'react';
import { FaFileCsv } from 'react-icons/fa';
import CardActionImportGuide from './CardActionImportGuide';
import { downloadData } from '../utils/downloadData';
import { createReportNewProductMissingParameters } from '../api/products';

export default function DownloadMissingAttributesReport() {
  const downloadFile = async () => {
    const response = await createReportNewProductMissingParameters();
    downloadData(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'report-new-product-parameters.xlsx');
  };

  return (
    <CardActionImportGuide onClick={downloadFile}>
      <FaFileCsv />
      <span>Download missing attributes report</span>
    </CardActionImportGuide>
  );
}
