import React, { useState } from 'react';
import {
  Panel, Divider, Message, Input, Stack,
} from 'rsuite';

import {
  deleteProducts,
} from '../../api/products';
import FileUploadCsv from '../FileUploadCsv';

export default function DeleteProducts() {
  const [confirmText, setConfirmText] = useState('');

  async function uploadFile(file:Blob) {
    const data = new FormData();
    data.append('attachment', file);

    const response = await deleteProducts(data);
    return response.data;
  }

  return (
    <Panel>
      <Message type="error">
        <Stack alignItems="center" justifyContent="center" direction="column">
          <span>
            You are going to delete ALL products in attached files.
            This action cannot be reverted.
            If you are sure type
            {' '}
            <b>&quot;DELETE&quot;</b>
            {' '}
            into provided field and
            ALL products from attached
            file will be deleted from specified marketplaces.
          </span>

          <Input
            className="mt-20"
            style={{ width: 200 }}
            onChange={setConfirmText}
          />
        </Stack>
      </Message>

      <Divider />
      <h6>delete products</h6>
      <FileUploadCsv
        className="mt-5"
        getUploadResult={(f) => uploadFile(f)}
        disableUploadButton={confirmText !== 'DELETE'}
      />
    </Panel>
  );
}
