import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ButtonToolbar, Form, InputPicker, Message, useToaster,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from '../store/hooks';
import { getPartnerInfo, savePartnerInfo } from '../api/partner';
import BaselinkerIntegrationSetup from '../baselinker/BaselinkerIntegrationSetup';
import MpiIntegrationSetup from '../mpi/MpiIntegrationSetup';
import ShoptetIntegrationSetup from '../shoptet/ShoptetIntegrationSetup';

const integrations = [
  { value: 'MPI', label: 'MPI' },
  { value: 'BASELINKER', label: 'BaseLinker' },
  { value: 'SHOPTET', label: 'Shoptet' },
];

export default function PartnerSetup() {
  const navigate = useNavigate();
  const toaster = useToaster();
  const formRef = useRef<any>();
  const { currentUser } = useStoreState(state => state.user);
  const [formValue, setFormValue] = useState<Record<string, any>>({
    partnerCode: currentUser.partnerCode,
    partnerName: currentUser.partnerName,
    integration: null,
  });

  useEffect(() => {
    getPartnerInfo().then(info => {
      setFormValue(prevState => ({
        ...prevState,
        partnerName: info.partnerName,
        integration: info.integration ? info.integration : 'MPI',
      }));
    });
  }, []);

  function onSubmit() {
    if (formRef.current?.check()) {
      savePartnerInfo({
        partnerName: formValue.partnerName,
        integration: formValue.integration,
      }).then(() => {
        toaster.push(<Message type="success" closable showIcon duration={2000}>Save succeeded</Message>);
      });
    } else {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Please check the form there are validation errors.</Message>);
    }
  }

  return (
    <div className="m-10">
      <h4>{`${formValue.partnerName} (${currentUser.partnerCode}) `}</h4>
      <Form ref={formRef} layout="horizontal" formValue={formValue} onChange={setFormValue}>
        <Form.Group controlId="partnerCode">
          <Form.ControlLabel>Partner code</Form.ControlLabel>
          <Form.Control name="partnerCode" readOnly />
        </Form.Group>
        <Form.Group controlId="partnerName">
          <Form.ControlLabel>Partner name</Form.ControlLabel>
          <Form.Control name="partnerName" />
        </Form.Group>
        <Form.Group controlId="integration">
          <Form.ControlLabel>Integration</Form.ControlLabel>
          <Form.Control name="integration" accepter={InputPicker} data={integrations} />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => onSubmit()}>Submit</Button>
            <Button appearance="ghost" onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      {formValue.integration === 'MPI' && <MpiIntegrationSetup />}
      {formValue.integration === 'BASELINKER' && <BaselinkerIntegrationSetup />}
      {formValue.integration === 'SHOPTET' && <ShoptetIntegrationSetup />}
    </div>
  );
}
