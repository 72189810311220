import React from 'react';
import {
  Button, ButtonToolbar, Loader, Message, Modal,
} from 'rsuite';
import { OffersBulkActionResponse } from '../../../api/apiTypes';
import { downloadData } from '../../../utils/downloadData';

function getSuccessMessage(response: OffersBulkActionResponse): string {
  return `Successfully updated ${response.total - response.failed} offers.`;
}

function getErrorMessage(response: OffersBulkActionResponse): string {
  return `Failed to update ${response.failed} offers.`;
}

interface ContentProps {
  response: OffersBulkActionResponse;
  onClose: () => void;
}

function Content({ response, onClose }: ContentProps): JSX.Element {
  const isDownloadReportVisible = !!response.report;

  function handleClose(): void {
    onClose?.();
  }

  function handleDownloadReport(): void {
    if (!response?.report) {
      return;
    }

    downloadData(response.report, 'text/plain', 'result.csv');
  }

  return (
    <>
      <Modal.Header
        closeButton={false}
      >
        Total number of uploaded offers in CSV file
        {' '}
        {response.total}
        .
      </Modal.Header>
      <Modal.Body>
        {response.total > response.failed && (
        <Message
          showIcon
          type="success"
          header={getSuccessMessage(response)}
        />
        )}
        {!!response.failed && (
        <Message
          showIcon
          type="error"
          header={getErrorMessage(response)}
        />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          {isDownloadReportVisible && (
          <Button onClick={() => handleDownloadReport()}>
            Download report
          </Button>
          )}
          <Button
            appearance="primary"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  );
}

interface Props {
  isLoading: boolean;
  response?: OffersBulkActionResponse;
  onClose?: () => void;
}

export default function ProductBulkActionDialog(
  { isLoading, response, onClose }: Props,
): JSX.Element | null {
  if (!response && !isLoading) {
    return null;
  }

  function handleClose() {
    onClose?.();
  }

  return (
    <Modal
      open
      onClose={() => handleClose()}
    >
      {isLoading && (
      <Modal.Body>
        <Loader />
      </Modal.Body>
      )}
      {response && (
      <Content
        response={response}
        onClose={() => handleClose()}
      />
      )}
    </Modal>
  );
}

ProductBulkActionDialog.defaultProps = {
  response: undefined,
  onClose: undefined,
};
