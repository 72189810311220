import React, { useState } from 'react';
import {
  Button, Input, List, Message, SelectPicker, TagPicker,
} from 'rsuite';

export function XsltMapping() {
  const [xsltContent, setXsltContent] = useState<string>('');
  const [mappingName, setMappingName] = useState<string>('');

  const insertXsltMappingSql = `INSERT INTO products.xslt_mapping (name, content) VALUES ('${mappingName}', '${xsltContent.replaceAll('\'', '\'\'')}');`;

  const mpiStockFeed = `<?xml version="1.0" encoding="UTF-8"?>
<PRODUCTS>
    <PRODUCT>
        <SKU>227</SKU>
        <MARKETPLACES>
            <MARKETPLACE>
                <CODE>MALL.CZ</CODE>
                <STOCK_QTY>2</STOCK_QTY>
                <DELIVERY_DELAY>1</DELIVERY_DELAY>
            </MARKETPLACE>
            <MARKETPLACE>
                <CODE>ALZA.CZ</CODE>
                <STOCK_QTY>2</STOCK_QTY>
                <DELIVERY_DELAY>1</DELIVERY_DELAY>
            </MARKETPLACE>
        </MARKETPLACES>
    </PRODUCT>
</PRODUCTS>`;

  const mpiPriceFeed = `<?xml version="1.0" encoding="UTF-8"?>
<PRODUCTS>
    <PRODUCT>
        <SKU>227</SKU>
        <MARKETPLACES>
            <MARKETPLACE>
                <CODE>MALL.CZ</CODE>
                <PRICE>1290</PRICE>
                <RRP>1990</RRP>
                <VAT>21</VAT>
            </MARKETPLACE>
            <MARKETPLACE>
                <CODE>ALZA.CZ</CODE>
                <PRICE>1290</PRICE>
                <RRP>1990</RRP>
                <VAT>21</VAT>
            </MARKETPLACE>
        </MARKETPLACES>
    </PRODUCT>
</PRODUCTS>`;

  const tecTakeExample = `<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output method="xml" indent="yes"/>
    <xsl:template match="/">
        <PRODUCTS>
            <xsl:for-each select="/AVAILABILITIES/AVAILABILITY">
                <PRODUCT>
                    <SKU><xsl:value-of select="ID"/></SKU>
                    <MARKETPLACES>
                        <MARKETPLACE>
                            <xsl:choose>
                                <xsl:when test="ACTIVE='true'">
                                    <STOCK_QTY><xsl:value-of select="IN_STOCK"/></STOCK_QTY>
                                </xsl:when>
                                <xsl:otherwise>
                                    <STOCK_QTY>0</STOCK_QTY>
                                </xsl:otherwise>
                            </xsl:choose>
                        </MARKETPLACE>
                    </MARKETPLACES>
                </PRODUCT>
            </xsl:for-each>
        </PRODUCTS>
    </xsl:template>
</xsl:stylesheet>`;

  const isNotValid = () => !xsltContent.trim() || !mappingName.trim();
  return (
    <div>
      <h1>Xslt Mapping</h1>
      <ul>
        <li>
          You can run
          <b><code> select * from products.xslt_mapping </code></b>
          to know if the mapping already exists.
        </li>
        <li>
          Open the xml feed in browser, or if it is too large you can download it using
          <a href="https://reqbin.com/req/c-r8g2qivg/how-to-use-curl"> curl </a>
          It should be already installed on MacOS, Windows and Linux.
        </li>
        <li>
          You can reduce the content to minimal list of products,
          it is required only to test the mapping.
        </li>
        <li>
          Prepare xslt mapping configuration.
          <a href="https://www.w3schools.com/xml/xsl_intro.asp"> Xslt Introduction</a>
        </li>
        <li><a href="http://xsltransform.net/">Test it here </a></li>
        <li>Output should be in MPI feed format.</li>
      </ul>
      <p>
        MPI Stock Feed example:
      </p>
      <pre lang="xml">{ mpiStockFeed }</pre>
      <p>
        MPI Price Feed example:
      </p>
      <pre lang="xml">{ mpiPriceFeed }</pre>
      <p>
        TecTake XSLT example example:
      </p>
      <pre lang="xml">{ tecTakeExample }</pre>
      <Input
        as="textarea"
        rows={5}
        placeholder="Xslt content"
        value={xsltContent}
        onChange={setXsltContent}
        required
      />
      <Input placeholder="Mapping name" value={mappingName} onChange={(value) => setMappingName(value.trimStart().replaceAll(/\s+/g, '_').toUpperCase())} />
      { isNotValid() && <Message showIcon type="error">Both values must be set </Message>}
      <h2>SQL Insert for xslt mapping</h2>
      <Input as="textarea" rows={15} value={insertXsltMappingSql} />
    </div>
  );
}

export function FeedRegistration() {
  const [feedUrl, setFeedUrl] = useState<string>('');
  const [partnerId, setPartnerId] = useState<string>('');
  const [selectedFeedType, setSelectedFeedType] = useState<string>('');

  const [transformation, setTransformation] = useState<any>({
    steps: [],
  });

  const feedTypes = ['STOCK', 'PRICE'].map(item => ({ label: item, value: item }));
  const availableCountries = ['CZE', 'SVK', 'HUN', 'HRV', 'POL', 'SVN']
    .map(item => ({ label: item, value: item }));
  const availableEshops = ['MALL.CZ', 'MALL.HU', 'MALL.PL', 'MALL.SK', 'MALL.HR', 'KAUFLAND.DE', 'ALZA.CZ', 'MIMOVRSTE.COM', 'ALLEGRO.PL', 'KAUFLAND.SK', 'KAUFLAND.CZ']
    .map(item => ({ label: item, value: item }));
  const availableTransformations = ['XSLT', 'MPI_STOCK', 'MPI_PRICE', 'COUNTRY', 'ESHOP']
    .map(item => ({ label: item, value: item }));
  const insertFeedSql = `INSERT INTO products.partners_feeds (partner_id, type, url, transformation) VALUES (${partnerId}, '${selectedFeedType}', '${feedUrl}', '${JSON.stringify(transformation, null, 2)}');`;
  const removeFeedSql = `DELETE FROM products.partners_feeds WHERE partner_id = ${partnerId} and "type" = '${selectedFeedType}';`;

  function removeStep(index: number) {
    transformation.steps.splice(index, 1);
    setTransformation({ steps: transformation.steps });
  }

  function renderTransformation(item: any) {
    const argumentsText = item.arguments ? JSON.stringify(item.arguments) : '';
    return `${item.transformation} ${argumentsText}`;
  }

  const [selectedTransformation, setSelectedTransformation] = useState<string>(
    availableTransformations[0].value,
  );

  const [selectedArguments, setSelectedArguments] = useState<any>(null);

  const argumentInput = () => {
    if (selectedTransformation === 'XSLT') {
      return <Input placeholder="Mapping name" value={selectedArguments} onChange={(value) => setSelectedArguments(value.trimStart().replaceAll(/\s+/g, '_').toUpperCase())} />;
    }
    if (selectedTransformation === 'COUNTRY') {
      return (
        <TagPicker
          data={availableCountries}
          value={selectedArguments}
          onChange={(value) => setSelectedArguments(value)}
        />
      );
    }
    if (selectedTransformation === 'ESHOP') {
      return (
        <TagPicker
          data={availableEshops}
          value={selectedArguments}
          onChange={(value) => setSelectedArguments(value)}
        />
      );
    }
    return '';
  };

  function addTransformationStep() {
    const step = {
      transformation: selectedTransformation,
      arguments: selectedArguments,
    };
    transformation.steps.push(step);
    setTransformation({ steps: transformation.steps });
    setSelectedArguments(null);
  }

  function onTransformationStepSelect(value: string) {
    setSelectedTransformation(value);
    setSelectedArguments(null);
  }

  return (
    <div>
      <h1>Feed Registration</h1>
      <Input placeholder="Feed url" value={feedUrl} onChange={setFeedUrl} />
      <Input placeholder="Partner Id" value={partnerId} onChange={setPartnerId} />
      <SelectPicker placeholder="Feed Type" data={feedTypes} onSelect={setSelectedFeedType} />
      <h3>Transformation</h3>
      <ul>
        <li>If the feed is not in MPI Feed format you should start with XSLT transformation.</li>
        <li>XSLT transformation should return MPI Feed format</li>
        <li>
          Then you have to use either MPI_STOCK or MPI_PRICE.
          It will transform data to our data representation.
        </li>
        <li>
          If the feed does not contain data per marketplace e.g.
          Heureka feed format then you can use COUNTRY transformation.
        </li>
        <li>
          COUNTRY transformation takes MARKETPLACE data for each product
          and duplicates it to every eshop registered by partner in given countries.
        </li>
        <li>
          COUNTRY transformation only works if there is only one MARKETPLACE record
          for given product without specified CODE.
        </li>
      </ul>
      <div>
        <SelectPicker
          data={availableTransformations}
          defaultValue={selectedTransformation}
          onSelect={(value) => onTransformationStepSelect(value)}
        />
        {argumentInput()}
        <Button onClick={() => addTransformationStep()}>Add step</Button>
      </div>
      <List>
        {transformation.steps.map((item: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <List.Item key={index} index={index}>
            {renderTransformation(item)}
            <Button onClick={() => removeStep(index)}>Remove</Button>
          </List.Item>
        ))}
      </List>
      <h3>SQL Insert for feed registration</h3>
      <Input as="textarea" rows={15} value={insertFeedSql} />
      <h3>SQL delete feed registration</h3>
      <Input as="textarea" rows={15} value={removeFeedSql} />
    </div>
  );
}
export default function Feeds() {
  return (
    <div>
      <XsltMapping />
      <FeedRegistration />
    </div>
  );
}
