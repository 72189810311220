import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Container, Content, Header, Navbar,
} from 'rsuite';

import DrawerList from '../routes/drawerLinks';
import LoadingBackdrop from './LoadingBackdrop';
import LoggedUser from './LoggedUser';

export default function Layout() {
  return (
    <Container className="main-container">
      <DrawerList />

      <Container className="main-section">
        <Header>
          <Navbar appearance="inverse">
            <LoggedUser />
          </Navbar>
        </Header>
        <Content>
          <Outlet />
          <LoadingBackdrop />
        </Content>
      </Container>
    </Container>
  );
}
