import React from 'react';
import styled from '@emotion/styled';

interface Props {
  size?: number;
}

// eslint-disable-next-line react/require-default-props
function KaufIcon({ className } :{ className?: string }) {
  return <span className={`${className} kauf-icon`} style={{ verticalAlign: 'middle' }} />;
}

export default styled(KaufIcon)<Props>({
  width: 20,
  height: 20,
}, props => ({
  width: props.size,
  height: props.size,
}));
