import {
  Button, Input, InputGroup, Message, Modal, Stack, toaster,
} from 'rsuite';
import React, { useEffect, useState } from 'react';
import { generateApiToken, isApiTokenGenerated, saveApiToken } from '../api/partner';

export default function ApiTokenReset() {
  const [apiTokenGenerated, setApiTokenGenerated] = useState(false);
  const [apiToken, setApiToken] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showTokenDialog, setShowTokenDialog] = useState<boolean>(false);

  useEffect(() => {
    isApiTokenGenerated().then(bool => setApiTokenGenerated(bool));
  }, []);

  const generate = () => {
    generateApiToken().then(token => {
      setApiToken(token);
      setShowWarning(false);
      setShowTokenDialog(true);
    });
  };

  const resetToken = () => {
    if (apiTokenGenerated) {
      setShowWarning(true);
    } else {
      generate();
    }
  };

  const copyToken = () => {
    navigator.clipboard.writeText(apiToken).then(() => {
      toaster.push(<Message duration={3000} type="success">Token copied to clipboard.</Message>);
    });
  };

  const saveToken = () => {
    saveApiToken(apiToken).then(() => {
      setShowTokenDialog(false);
      setApiTokenGenerated(true);
      setApiToken('');
    }).then(() => {
      toaster.push(<Message type="success" closable showIcon duration={2000}>Token saved!</Message>);
    });
  };

  const changedMind = () => {
    setApiToken('');
    setShowTokenDialog(false);
  };

  const getButtonText = () => {
    if (apiTokenGenerated) {
      return 'Reset API token';
    }
    return 'Generate API token';
  };

  return (
    <>
      <Button appearance="primary" onClick={resetToken}>{getButtonText()}</Button>
      <Modal open={showWarning}>
        <Modal.Body>
          Are you sure, you really want to reset the api token?
          This operation is irreversible and will make it impossible
          to use the API with the current token.
        </Modal.Body>
        <Modal.Footer>
          <Stack justifyContent="space-between">
            <Button appearance="ghost" onClick={generate}>I know what I&apos;m doing</Button>
            <Button appearance="primary" onClick={() => setShowWarning(false)}>Abort!</Button>
          </Stack>
        </Modal.Footer>
      </Modal>
      <Modal open={showTokenDialog}>
        <Modal.Body>
          <Stack direction="column" spacing={6}>
            <span>Your new API token is</span>
            <InputGroup>
              <Input readOnly value={apiToken} />
              <InputGroup.Button appearance="primary" onClick={copyToken}>Copy</InputGroup.Button>
            </InputGroup>
            <span>Copy it now, as it cannot be retrieved later</span>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Stack justifyContent="space-between">
            <Button appearance="primary" onClick={changedMind}>I have changed my mind</Button>
            <Button appearance="ghost" onClick={saveToken}>Save</Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
}
