import React, {
  forwardRef, Ref, useImperativeHandle, useReducer,
} from 'react';
import {
  Button, Loader, Message, Modal, Stack, toaster,
} from 'rsuite';
import { RecalculateAlzaBranchIdResponse, recalculateAlzaBranchIds } from '../../api/eshop/eshop';

// Result
interface ResultProps {
  response: RecalculateAlzaBranchIdResponse | null
}

function Result({ response }: ResultProps) {
  return (
    response?.error
      ? <Message type="error">{response.error}</Message>
      : (
        <Stack direction="column" spacing={12}>
          <span>{`Changed branch id for ${response?.numberOfChangedBranchId} products.`}</span>
          <span>{`Branch id unassigned for ${response?.numberOfUnassignedBranchId} products.`}</span>
        </Stack>
      )

  );
}

// Progress
function Progress() {
  return (
    <Stack spacing={12} direction="column">
      Recalculating Alza branch Ids.
      <Loader size="md" />
    </Stack>
  );
}

export type RecalculateAlzaBranchIdsRefType = {
  synchronize: () => void;
};

interface State {
  isOpen: boolean,
  isSynchronizing: boolean;
  response: RecalculateAlzaBranchIdResponse | null;
  error?: string
}

const initialState: State = {
  isOpen: false,
  isSynchronizing: false,
  response: null,
};

type SynchronizeAction = { type: 'synchronize' };
type SynchronizeSuccessAction = { type: 'synchronizeSuccess', payload: RecalculateAlzaBranchIdResponse };
type SynchronizeFailureAction = { type: 'synchronizeFailure', payload: string };
type CloseAction = { type: 'close' };
type Action = SynchronizeAction | SynchronizeSuccessAction | SynchronizeFailureAction | CloseAction;

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'synchronize':
      return {
        ...state,
        isOpen: true,
        isSynchronizing: true,
        response: null,
        error: undefined,
      };
    case 'synchronizeSuccess':
      return {
        ...state,
        isSynchronizing: false,
        response: action.payload,
      };
    case 'synchronizeFailure':
      toaster.push(<Message type="error" closable showIcon duration={4000}>{action.payload}</Message>);
      return {
        ...state,
        isOpen: false,
        isSynchronizing: false,
        error: action.payload,
      };
    case 'close':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

// eslint-disable-next-line no-empty-pattern
function Component({}, ref: Ref<RecalculateAlzaBranchIdsRefType>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function syncAllProductPlatformIds() {
    dispatch({ type: 'synchronize' });
    recalculateAlzaBranchIds()
      .then(response => dispatch({ type: 'synchronizeSuccess', payload: response.data }))
      .catch(error => dispatch({ type: 'synchronizeFailure', payload: error.response?.data?.details }));
  }

  useImperativeHandle(ref, () => ({ synchronize: syncAllProductPlatformIds }));

  return (
    <Modal open={state.isOpen} size="sm">
      {
        state.isSynchronizing
          ? <Progress />
          : (
            <Stack direction="column" spacing={12}>
              <Result response={state.response} />
              <Button appearance="primary" onClick={() => dispatch({ type: 'close' })}>Close</Button>
            </Stack>
          )
      }
    </Modal>
  );
}

const RecalculateAlzaBranchIdsExecution = forwardRef<RecalculateAlzaBranchIdsRefType>(Component);
export default RecalculateAlzaBranchIdsExecution;
