import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import store from './store/store';
import { userTokenStored } from './services/authorization';

if (userTokenStored()) {
  store.getActions().user.restore();
}

const baseName = process.env.REACT_APP_BASE_URL;

ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter basename={baseName}>
      <App />
    </BrowserRouter>
  </StoreProvider>,
  document.querySelector('#root'),
);
