import { action, thunk } from 'easy-peasy';

import { getOrdersForMultiplePrint } from '../../api/orders';

import { PrintExistingLabelStoreModel } from './printExistinglabelStoreTypes';

const printExistingLabels: PrintExistingLabelStoreModel = {
  loading: false,
  orders: [],
  selectedOrders: [],
  totalPdfPages: 0,

  total: 0,
  currentFilter: {
    pageSize: 100,
    page: 1,
    orderBy: 'id',
    orderDirection: 'desc',
    filter: {
      globalFilter: '',
      deliveryTypes: [],
    },
  },

  fetchOrders: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.selectOrders([]);
    const { data } = await getOrdersForMultiplePrint(payload);

    actions.setOrders({ total: data.total, orders: data.data });
    actions.setLoading(false);
  }),
  setOrders: action((state, payload) => {
    state.total = payload.total;
    state.orders = payload.orders;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setFilter: action((state, payload) => {
    state.currentFilter = payload;
  }),
  selectOrders: action((state, payload) => {
    state.selectedOrders = payload;
    const selected = state.orders
      .filter(o => payload.indexOf(o.id) >= 0)
      .map(o => o.pagesCount ?? 0);

    state.totalPdfPages = selected.reduce((sum, value) => sum + value, 0);
  }),
};

export default printExistingLabels;
