export const INDEX: string = '/';
export const PROFILE: string = '/profile';
export const PRODUCT_IMPORT: string = '/import';
export const KAUF_IMPORT: string = '/kauf-import';
export const ALLEGRO_IMPORT: string = '/allegro-import';
export const LABEL_CREATION: string = '/label';
export const UPDATE_STOCKS_AND_PRICES: string = '/stocks-prices';
export const BATCH_UPDATE: string = '/batch-update';
export const ORDERS: string = '/orders';
export const SHIPMENTS: string = '/shipments';
export const PRODUCTS: string = '/products';
export const ACCOUNT_LINKED: string = '/allegro-linked';
export const FEEDS: string = '/feeds';
export const FEEDS_EDIT: string = '/feeds-edit';
export const PARTNER_SETUP_OVERVIEW: string = '/partner-setup/overview';
export const PARTNER_SETUP: string = '/partner-setup';
export const ESHOP_SETUP_OVERVIEW: string = '/eshop-setup/overview';
export const ESHOP_SETUP: string = '/eshop-setup';
export const WAREHOUSE_LOCALITY_EDIT: string = 'warehouse-locality/edit';
export const USER_SETUP: string = '/user-setup';
export const USER_SETUP_CREATE: string = '/user-setup/create';
export const CARRIER_SETUP: string = '/carrier-setup';
export const CARRIER_SETUP_EDIT: string = 'edit';
export const YOU_ARE_NOT_AUTHORIZED: string = '/you-are-not-authorized';
