import React, { useState } from 'react';

import {
  Col, Grid, Nav, Panel, Row,
} from 'rsuite';
import {
  updatePrices,
  updateStocks,
} from '../api/products';

import FileUploadCsv from '../components/FileUploadCsv';

export default function UpdateStocksAndPrices() {
  const [activeTab, setActiveTab] = useState('tabStock');

  async function uploadFile(file:Blob) {
    const data = new FormData();
    data.append('attachment', file);

    if (activeTab === 'tabStock') {
      const response = await updateStocks(data);
      return response.data;
    }

    const response = await updatePrices(data);
    return response.data;
  }

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <Nav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
              <Nav.Item eventKey="tabStock">Stock</Nav.Item>
              <Nav.Item eventKey="tabPrices">Prices</Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <FileUploadCsv className="mt-5" getUploadResult={(f) => uploadFile(f)} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
