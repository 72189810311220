import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from 'rsuite';
import { Eshop, getEshops } from '../api/eshop/eshop';
import EshopSetupNavigation from '../components/EshopSetup/EshopSetupNavigation';
import { CARRIER_SETUP } from '../routes/links';
import CarrierSetupKauflandTable from '../components/CarrierSetup/Kaufland/CarrierSetupKauflandTable';

import { ApiGroup } from '../api/model/ApiGroup';
import CarrierSetupAllegroTable from '../components/CarrierSetup/Allegro/CarrierSetupAllegroTable';
import CarrierSetupAlzaTable from '../components/CarrierSetup/Alza/CarrierSetupAlzaTable';

interface ContextProps {
  apiGroup?: string;
}

function Context({ apiGroup }: ContextProps) {
  return (
    <>
      { apiGroup === ApiGroup.KAUFLAND && <CarrierSetupKauflandTable />}
      { apiGroup === ApiGroup.ALZA && <CarrierSetupAlzaTable />}
      { apiGroup === ApiGroup.MALL_GROUP && <p>TODO: MALL_GROUP</p>}
      { apiGroup === ApiGroup.ALLEGRO && <CarrierSetupAllegroTable />}
    </>
  );
}

Context.defaultProps = {
  apiGroup: undefined,
};

function findEshop(
  eshops: Eshop[],
  apiGroup?: string,
): Eshop | undefined {
  if (apiGroup) {
    return eshops.find(eshop => eshop.apiGroup === apiGroup);
  }

  return eshops[0];
}

export default function CarrierSetup() {
  const [eshops, setEshops] = useState<Eshop[]>([]);
  const [eshop, setEshop] = useState<Eshop>();
  const { apiGroup } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getEshops()
      .then(response => response.data)
      .then(data => {
        setEshops(data);

        const foundEshop = findEshop(data, apiGroup);
        if (!foundEshop) {
          return;
        }
        setEshop(foundEshop);
      });
  }, []);

  useEffect(() => {
    if (!eshop || eshop.apiGroup === apiGroup) {
      return;
    }

    navigate(`${CARRIER_SETUP}/${eshop.apiGroup}`);
  }, [eshop]);

  return (
    <div className="m-10" style={{ marginBottom: 150 }}>
      <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
        <EshopSetupNavigation
          eshops={eshops}
          selected={eshop}
          onSelection={setEshop}
          isSecondTabVisible={false}
        />
        <Context apiGroup={eshop?.apiGroup} />
      </Stack>
    </div>
  );
}
