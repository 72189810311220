import { Action, action } from 'easy-peasy';

export interface GeneralStoreModel {
  loading: boolean;
  setLoading: Action<GeneralStoreModel, boolean>;
}

const generalStore : GeneralStoreModel = {
  loading: false,
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
};

export default generalStore;
