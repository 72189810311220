import React from 'react';
import { Loader } from 'rsuite';
import { useStoreState } from '../store/hooks';

export default function LoadingBackdrop() {
  const loading = useStoreState(state => state.general.loading);

  if (!loading) {
    return null;
  }

  return (
    <Loader
      backdrop
      center
      inverse
      size="lg"
      style={{ zIndex: 999 }}
    />
  );
}
