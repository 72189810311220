import {
  AssignedProducts,
  WarehouseLocality,
  WarehouseSynchronizationResult,
} from './warehouseApiTypes';

export const mockWarehouseLocalities: WarehouseLocality[] = [
  {
    id: 1,
    code: '0001',
    city: 'Prague',
    countryCode: 'CZ',
    street: 'Street 1',
    postcode: '123456',
    company: 'Joe Doe Company',
    expeditionShiftByDPlus: 1,
    maxHandlingTime: 1,
    isDefault: false,
    isFullImportRunning: false,
    lastFullImport: '2023-11-23T11:14:54.919Z',
    lastFullImportProducts: 10,
    fullImportFailedMessage: 'productList[0].code missing',
  },
  {
    id: 2,
    code: '0002',
    city: 'Brno',
    countryCode: 'CZ',
    street: 'Street 2',
    expeditionShiftByDPlus: 2,
    maxHandlingTime: 2,
    isDefault: true,
    isFullImportRunning: false,
    lastFullImport: '2023-11-23T11:14:54.919Z',
    lastFullImportProducts: 50,
    lastFullImportProductCodes: '01\n02',
  },
  {
    id: 3,
    code: '0003',
    city: 'Ostrava',
    countryCode: 'CZ',
    street: 'Street 3',
    isDefault: true,
    isFullImportRunning: false,
    lastFullImport: '2023-11-23T11:14:54.919Z',
    lastFullImportProducts: 100,
  },
];

export const mockWarehouseSynchronizationResult: WarehouseSynchronizationResult = {
  savedWarehouseLocalities: [
    {
      id: 1,
      code: '0001',
      city: 'Prague',
      countryCode: 'CZ',
      street: 'Street 1',
      isDefault: false,
      isFullImportRunning: false,
    },
    {
      id: 2,
      code: '0001',
      city: 'Brno',
      countryCode: 'CZ',
      street: 'Street 2',
      isDefault: true,
      isFullImportRunning: false,
    },
  ],
  notDeletedWarehouseLocalities: [
    {
      warehouseLocality: {
        id: 3,
        code: '0001',
        city: 'Ostrava',
        countryCode: 'CZ',
        street: 'Street 3',
        isDefault: true,
        isFullImportRunning: false,
      },
      numberOfAssignedProducts: 15,
    },
  ],
};

export const mockGetWarehouseLocality: WarehouseLocality = {
  id: 1,
  code: '0001',
  city: 'Prague',
  countryCode: 'PL',
  street: 'Street 1',
  postcode: '123456',
  company: 'Joe Doe Company',
  province: 'LODZKIE',
  name: 'Joe Doe',
  maxHandlingTime: 1,
  expeditionShiftByDPlus: 1,
  email: 'joedoe@email.com',
  phone: '+123456789',
  isDefault: false,
  isFullImportRunning: false,
};

export const mockGetAssignedProducts: AssignedProducts[] = [
  { warehouseLocalityId: 1, numberOfProducts: 1000 },
  { warehouseLocalityId: 2, numberOfProducts: 2000 },
  { warehouseLocalityId: 3, numberOfProducts: 3000 },
];
