import React, { useEffect, useState } from 'react';
import { Nav, Stack } from 'rsuite';
import { Eshop } from '../../api/eshop/eshop';

interface Props {
  eshops: Eshop[];
  selected?: Eshop;
  onSelection?: (eshop: Eshop) => void;
  isSecondTabVisible?: boolean
}

interface EshopGroup {
  group: string;
  eshops: Eshop[];
}

function mapEshopGroups(eshops: Eshop[]): EshopGroup[] {
  const eshopGroups = new Map<string, Eshop[]>();

  eshops.forEach(eshop => {
    const eshopGroup = eshopGroups.get(eshop.apiGroup);
    if (eshopGroup) {
      eshopGroups.set(eshop.apiGroup, [...eshopGroup, eshop]);
    } else {
      eshopGroups.set(eshop.apiGroup, [eshop]);
    }
  });

  return [...eshopGroups]
    .map(([key, value]) => ({ group: key, eshops: value }));
}

export default function EshopSetupNavigation({
  eshops, selected, onSelection, isSecondTabVisible,
}: Props) {
  const [eshopGroups, setEshopGroups] = useState<EshopGroup[]>([]);
  const [selectedEshop, setSelectedEshop] = useState<Eshop>();
  const selectedEshopGroup = eshopGroups
    .find(eshopGroup => eshopGroup.group === selectedEshop?.apiGroup);

  useEffect(() => {
    setEshopGroups(mapEshopGroups(eshops));
  }, [eshops]);

  useEffect(() => {
    if (!selected) {
      return;
    }
    setSelectedEshop(selected);
  }, [selected]);

  function onEshopSelect(eshop: Eshop): void {
    setSelectedEshop(eshop);
    onSelection?.(eshop);
  }

  function onEshopGroupSelect(eshopGroup: EshopGroup): void {
    const eshop = eshopGroup.eshops[0];
    if (!eshop) {
      return;
    }
    onEshopSelect(eshop);
  }

  return (
    <Stack direction="column" alignItems="flex-start">
      <Nav
        className="nav-large"
        appearance="subtle"
        activeKey={selectedEshop?.apiGroup}
      >
        {eshopGroups.map(
          eshopGroup => (
            <Nav.Item
              key={eshopGroup.group}
              eventKey={eshopGroup.group}
              onSelect={() => onEshopGroupSelect(eshopGroup)}
            >
              {eshopGroup.group}
            </Nav.Item>
          ),
        )}
      </Nav>
      { isSecondTabVisible && (
      <Nav className="nav-large" appearance="subtle" activeKey={selectedEshop?.code}>
        {selectedEshopGroup && selectedEshopGroup.eshops.map(eshop => (
          <Nav.Item
            key={eshop.code}
            eventKey={eshop.code}
            onSelect={() => onEshopSelect(eshop)}
          >
            {eshop.code}
          </Nav.Item>
        ))}
      </Nav>
      )}
    </Stack>
  );
}

EshopSetupNavigation.defaultProps = {
  selected: undefined,
  onSelection: undefined,
  isSecondTabVisible: true,
};
