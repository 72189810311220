import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Modal,
  Nav,
  Panel,
  Row,
  Stack,
} from 'rsuite';
import { Link, useLocation } from 'react-router-dom';
import SearchIcon from '@rsuite/icons/Search';
import { FaFileExport, FaFileImport, FaFilter } from 'react-icons/fa';

import { Icon } from '@rsuite/icons';
import { ORDERS } from '../routes/links';
import { useStoreActions, useStoreState } from '../store/hooks';

import OrdersTable from '../components/orders/OrdersTable';
import OrderFilter from '../components/orders/OrderFilter';
import { exportOrderList } from '../api/orders';
import { downloadData } from '../utils/downloadData';
import { OrderListItem } from '../api/apiTypes';
import OrdersStatesChangeDialog from '../components/orders/OrdersStatesChangeDialog';
import OrderImportUpload from '../components/orders/OrderImportUpload';

export default function Orders() {
  const { search } = useLocation();
  const { setFilter } = useStoreActions(actions => actions.orders);
  const currentFilter = useStoreState(state => state.orders.currentFilter);
  const totalOrders = useStoreState(state => state.orders.total);

  const [globalFilter, setGlobalFilter] = useState(currentFilter.filter.globalFilter);
  const [filterVisible, setFilterVisible] = useState(false);
  const [exportConfirmOpen, setExportConfirmOpen] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [selectedOrders, selectOrders] = useState<OrderListItem[]>([]);
  const [stateDialogOpen, setStateDialogOpen] = useState(false);

  const params = new URLSearchParams(search);
  const view = params.get('view') ?? 'all';

  useEffect(() => {

  }, [view]);

  const globalSearch = () => {
    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        ...currentFilter.filter,
        globalFilter,
      },
    });
  };

  const getFiltersCount = (): number | boolean => {
    const { filter } = currentFilter;
    let count = 0;

    if (filter.eshops && filter.eshops.length > 0) count += 1;
    if (filter.states.length > 0) count += 1;
    if (filter.carriers.length > 0) count += 1;
    if (filter.originalOrderId) count += 1;
    if (filter.partnerOrderId) count += 1;
    if (filter.orderValue && (filter.orderValue?.from || filter.orderValue?.to)) count += 1;
    if (filter.expedition && (filter.expedition?.from || filter.expedition?.to)) count += 1;
    if (filter.created && (filter.created?.from || filter.created?.to)) count += 1;
    if (filter.delivered && (filter.delivered?.from || filter.delivered?.to)) count += 1;
    if (filter.shipping && (filter.shipping?.from || filter.shipping?.to)) count += 1;
    if (filter.returned && (filter.returned?.from || filter.returned?.to)) count += 1;
    if (filter.shipped && (filter.shipped?.from || filter.shipped?.to)) count += 1;
    if (filter.cancelled && (filter.cancelled?.from || filter.cancelled?.to)) count += 1;

    return count === 0 ? false : count;
  };

  const exportOrders = async () => {
    setExporting(true);
    const request = {
      ...currentFilter,
      filter: {
        ...currentFilter.filter,
      },
    };

    try {
      const response = await exportOrderList(request);
      downloadData(response.data, 'text/plain', 'export.csv');
    } finally {
      setExporting(false);
      setExportConfirmOpen(false);
    }
  };

  const handleSelectOrders = (orderIds: OrderListItem[]): void => {
    selectOrders(orderIds);
  };

  const stateDialogClose = () => {
    selectOrders([]);
    setStateDialogOpen(false);
  };

  return (
    <Panel className="h100p">
      <Grid fluid className="h100p">
        <Row>
          <Col xs={24}>
            <Nav className="nav-large" appearance="subtle" activeKey={view}>
              <Nav.Item eventKey="all" as={Link} to={`${ORDERS}?view=all`}>Overview</Nav.Item>
              <Nav.Item eventKey="open" as={Link} to={`${ORDERS}?view=open`}>Open</Nav.Item>
              <Nav.Item eventKey="shipping" as={Link} to={`${ORDERS}?view=shipping`}>Shipping</Nav.Item>
              <Nav.Item eventKey="shipped" as={Link} to={`${ORDERS}?view=shipped`}>Shipped & Delivered</Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" className="mt-20">
              <Stack direction="row" spacing={40}>
                <InputGroup inside>
                  <Input
                    style={{ width: 400 }}
                    placeholder="search by marketplace, status, carrier, order number, payment id"
                    defaultValue={globalFilter}
                    onChange={setGlobalFilter}
                    onKeyUp={(ev) => {
                      if (ev.key === 'Enter') {
                        globalSearch();
                      }
                    }}
                  />
                  <InputGroup.Button>
                    <SearchIcon onClick={globalSearch} />
                  </InputGroup.Button>
                </InputGroup>

                <Badge content={getFiltersCount()} color="green">
                  <IconButton
                    style={{ width: 140 }}
                    appearance="primary"
                    onClick={() => setFilterVisible(!filterVisible)}
                    placement="right"
                    icon={<Icon as={FaFilter} />}
                  >
                    Advance filter
                  </IconButton>
                </Badge>
                <IconButton
                  style={{ width: 140 }}
                  onClick={() => setExportConfirmOpen(true)}
                  appearance="primary"
                  icon={<Icon as={FaFileExport} />}
                  placement="right"
                >
                  Export
                </IconButton>
                <OrderImportUpload>
                  <IconButton
                    style={{ width: 140 }}
                    appearance="primary"
                    icon={<Icon as={FaFileImport} />}
                    placement="right"
                  >
                    Import
                  </IconButton>
                </OrderImportUpload>
              </Stack>
              <Stack>
                <Button
                  style={{ width: 140 }}
                  onClick={() => setStateDialogOpen(true)}
                  appearance="primary"
                  disabled={selectedOrders.length === 0}
                >
                  Change Status
                </Button>
              </Stack>
            </Stack>

            <OrderFilter view={view} visible={filterVisible} />
          </Col>
        </Row>
        <Row className={filterVisible ? 'order-table-container-filter' : 'order-table-container'}>
          <Col xs={24} className="mt-20 h100p">
            <OrdersTable
              view={view}
              selectedOrders={selectedOrders}
              selectOrders={handleSelectOrders}
            />
          </Col>
        </Row>
      </Grid>
      <Modal backdrop="static" role="alertdialog" open={exportConfirmOpen} onClose={() => setExportConfirmOpen(false)} size="xs">
        <Modal.Header closeButton={false}>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to export
          {' '}
          <b>{totalOrders}</b>
          {' '}
          orders?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => exportOrders()} appearance="primary" loading={exporting}>
            Ok
          </Button>
          <Button onClick={() => setExportConfirmOpen(false)} appearance="subtle" disabled={exporting}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <OrdersStatesChangeDialog
        open={stateDialogOpen}
        orders={selectedOrders}
        close={stateDialogClose}
      />
    </Panel>
  );
}
