import React from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { createAndClickLink } from '../utils/downloadData';
import CardActionImportGuide from './CardActionImportGuide';

type DownloadTemplateProps = {
  filename: string;
};

export default function DownloadTemplate({ filename } : DownloadTemplateProps) {
  const downloadTemplateCsv = () => {
    createAndClickLink(filename, filename);
  };

  return (
    <CardActionImportGuide onClick={downloadTemplateCsv}>
      <FaFileCsv />
      <span>Download template CSV</span>
    </CardActionImportGuide>
  );
}
