import React, { useEffect, useReducer } from 'react';
import { Divider, Loader } from 'rsuite';
import _ from 'lodash';
import { Eshop } from '../../../api/eshop/eshop';
import EshopSetupDetail, { Input, Labels } from '../EshopSetupDetail';
import EshopSetupAllegroCredentials from './Credentials/EshopSetupAllegroCredentials';
import EshopSetupBankDetails from '../EshopSetupBankDetails';
import EshopSetupAllegroAddresses from './EshopSetupAllegroAddresses';
import EshopSetupAllegroDefaults from './EshopSetupAllegroDefaults';
import EshopSetupCrossDock from '../EshopSetupCrossDock';
import { getAllegroLinkedInfo } from '../../../api/user';
import { AllegroLinkingInfo } from '../../../api/apiTypes';

interface Props {
  eshop: Eshop;
  onUpdate: () => void
}

const labels: Labels = {
  clientKey: '',
  clientSecret: '',
  stockThreshold: 'Stock threshold',
  expeditionDays: 'Expedition days',
  fixedFee: '',
  dynamicBranchId: '',
  substitutedPhoneNumber: 'Substitute for an empty phone number',
};

interface State {
  status: 'ready' | 'loading';
  isBaseMarketplace: boolean;
  linkingInfos: AllegroLinkingInfo[];
  inputs: Input[];
}

const initialState: State = {
  status: 'ready',
  isBaseMarketplace: true,
  linkingInfos: [],
  inputs: [
    Input.BASE_MARKETPLACE,
    Input.SYNCHRONIZATION,
    Input.STOCK_THRESHOLD,
    Input.EXPEDITION_DAYS,
    Input.SUBSTITUTED_PHONE_NUMBER,
  ],
};

type StatusSetAction = { type: 'status/set', payload: 'ready' | 'loading' };
type IsBaseMarketplaceSetAction = { type: 'isBaseMarketplace/set', payload: boolean };
type LinkingInfosSetAction = { type: 'linkingInfos/set', payload: AllegroLinkingInfo[] };
type Action = StatusSetAction | IsBaseMarketplaceSetAction | LinkingInfosSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set':
      return { ...state, status: action.payload };
    case 'isBaseMarketplace/set':
      return {
        ...state,
        isBaseMarketplace: action.payload,
        inputs: action.payload ? initialState.inputs : [Input.BASE_MARKETPLACE],
      };
    case 'linkingInfos/set': {
      return { ...state, linkingInfos: action.payload, status: 'ready' };
    }
    default:
      return state;
  }
}

function isLinkingInfoBaseMarketplace(
  eshop: Eshop,
  allegroLinkingInfo: AllegroLinkingInfo,
): boolean {
  if (!allegroLinkingInfo?.allegroUser?.baseMarketplace.id) {
    return false;
  }

  const { eshopCode } = allegroLinkingInfo;

  if (eshop.code === eshopCode) {
    return false;
  }

  const { id } = allegroLinkingInfo.allegroUser.baseMarketplace;

  return _.last(id.split('-'))?.toUpperCase() === _.last(eshopCode.split('.'))?.toUpperCase();
}

function mapLinkingInfos(eshop: Eshop, linkingInfos: AllegroLinkingInfo[]): string[] {
  return linkingInfos
    .filter(linkingInfo => isLinkingInfoBaseMarketplace(eshop, linkingInfo))
    .map(linkingInfo => linkingInfo.eshopCode);
}

export default function EshopSetupAllegro({ eshop, onUpdate }: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    status, isBaseMarketplace, linkingInfos, inputs,
  } = state;

  async function fetchAllegroLinkedInfo() {
    dispatch({ type: 'status/set', payload: 'loading' });
    const response = await getAllegroLinkedInfo();

    const { data } = response;
    dispatch({ type: 'linkingInfos/set', payload: data });
  }

  function handleIsBaseMarketplaceUpdate(value: boolean) {
    dispatch({ type: 'isBaseMarketplace/set', payload: value });
  }

  useEffect(() => {
    fetchAllegroLinkedInfo().then();
  }, [eshop]);

  if (status === 'loading') {
    return <Loader />;
  }

  return (
    <>
      <EshopSetupDetail
        credentials={(
          <EshopSetupAllegroCredentials
            eshop={eshop}
            linkingInfos={linkingInfos}
            onUpdate={onUpdate}
          />
        )}
        eshop={eshop}
        inputs={inputs}
        labels={labels}
        baseMarketplaceCodes={mapLinkingInfos(eshop, linkingInfos)}
        onSuccessfulSave={() => {}}
        onIsBaseMarketplaceUpdate={(value) => handleIsBaseMarketplaceUpdate(value)}
      />
      { isBaseMarketplace && (
      <>
        <Divider>Defaults</Divider>
        <EshopSetupAllegroDefaults eshop={eshop} />
        <Divider>Dispatch addresses</Divider>
        <EshopSetupAllegroAddresses eshop={eshop} />
        <Divider>Bank details</Divider>
        <EshopSetupBankDetails eshop={eshop} />
      </>
      )}
      <Divider>Cross dock</Divider>
      <EshopSetupCrossDock eshop={eshop} />
    </>
  );
}
