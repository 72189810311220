import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import { getCurrentUserToken } from '../services/authorization';

export default function ShoptetIntegrationSetup() {
  const currentUserToken = getCurrentUserToken();
  return (
    <>
      <br />
      <IframeResizer
        src={`/shoptet/settings/?user_token=${currentUserToken}`}
        style={{ minWidth: '100%', border: 'none' }}
        heightCalculationMethod="lowestElement"
        inPageLinks
      />
    </>
  );
}
