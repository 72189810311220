import api from './base';
import {
  KauflandCarrier,
  KauflandExternalCarrier,
  KauflandExternalShippingGroup,
  KauflandExternalWarehouse,
  KauflandTransportMapping,
} from './apiTypes';
import {
  mockKauflandCarrier,
  mockKauflandCarriers,
  mockKauflandExternalCarriers,
  mockKauflandExternalShippingGroups,
  mockKauflandExternalWarehouses,
  mockKauflandTransportMappings,
} from './mocks/kauflandMocks';

const BASE_PATH = 'kaufland-api/management';
const KAUFLAND_PARTNERS_PATH = 'kaufland-partners';
const TRANSPORT_MAPPINGS_PATH = 'transport-mappings';
const CARRIERS_PATH = 'carriers';

export async function getKauflandExternalShippingGroups(
  partnerCode: string,
  storefront: string,
): Promise<KauflandExternalShippingGroup[]> {
  return api
    .get<KauflandExternalShippingGroup[]>(`${BASE_PATH}/${KAUFLAND_PARTNERS_PATH}/${partnerCode}/shipping-groups`, {
    params: {
      storefront,
    },
  })
    .then(response => response.data);
}

api.mock
  .onGet(/kaufland-api\/management\/kaufland-partners\/.+\/shipping-groups/)
  .reply(200, mockKauflandExternalShippingGroups);

export async function getKauflandExternalWarehouses(
  partnerCode: string,
): Promise<KauflandExternalWarehouse[]> {
  return api
    .get<KauflandExternalWarehouse[]>(`${BASE_PATH}/${KAUFLAND_PARTNERS_PATH}/${partnerCode}/warehouses`)
    .then(response => response.data);
}

api.mock
  .onGet(/kaufland-api\/management\/kaufland-partners\/.+\/warehouses/)
  .reply(200, mockKauflandExternalWarehouses);

export async function getKauflandExternalCarriers(): Promise<KauflandExternalCarrier[]> {
  return api
    .get<KauflandExternalCarrier[]>(`${BASE_PATH}/${KAUFLAND_PARTNERS_PATH}/carriers`)
    .then(response => response.data)
    .then(carriers => [
      ...carriers,
      { name: 'Other', isHauler: false },
      { name: 'Other Hauler', isHauler: true },
    ]);
}

api.mock
  .onGet('/kaufland-api/management/kaufland-partners/carriers')
  .reply(200, mockKauflandExternalCarriers);

export async function getKauflandTransportMappings(
  partnerCode: string,
): Promise<KauflandTransportMapping[]> {
  return api
    .get<KauflandTransportMapping[]>(`${BASE_PATH}/${TRANSPORT_MAPPINGS_PATH}`, {
    params: {
      partnerCode,
    },
  })
    .then(response => response.data);
}

api.mock
  .onGet('kaufland-api/management/transport-mappings')
  .reply(200, mockKauflandTransportMappings);

export async function saveKauflandTransportMappings(
  transportMappings: KauflandTransportMapping[],
): Promise<null> {
  return api
    .post<null>(`${BASE_PATH}/${TRANSPORT_MAPPINGS_PATH}`, transportMappings)
    .then(response => response.data);
}

api.mock
  .onPost('kaufland-api/management/transport-mappings')
  .reply(204);

export async function getKauflandCarrier(id: number): Promise<KauflandCarrier> {
  return api
    .get<KauflandCarrier>(`${BASE_PATH}/${CARRIERS_PATH}/${id}`)
    .then((response) => response.data);
}

api.mock
  .onGet(/kaufland-api\/management\/carriers\/.+/)
  .reply(200, mockKauflandCarrier);

export async function getKauflandCarriers(): Promise<KauflandCarrier[]> {
  return api
    .get<KauflandCarrier[]>(`${BASE_PATH}/${CARRIERS_PATH}`)
    .then((response) => response.data);
}

api.mock
  .onGet('kaufland-api/management/carriers')
  .reply(200, mockKauflandCarriers);
