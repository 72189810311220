import React, { useEffect, useState } from 'react';
import { Loader, Stack } from 'rsuite';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreState } from '../store/hooks';
import { Eshop, getEshops } from '../api/eshop/eshop';
import EshopSetupNavigation from '../components/EshopSetup/EshopSetupNavigation';
import { ESHOP_SETUP } from '../routes/links';
import EshopSetupKaufland from '../components/EshopSetup/Kaufland/EshopSetupKaufland';
import EshopSetupAllegro from '../components/EshopSetup/Allegro/EshopSetupAllegro';
import { ApiGroup } from '../api/model/ApiGroup';
import EshopSetupAlza from '../components/EshopSetup/Alza/EshopSetupAlza';
import EshopSetupMall from '../components/EshopSetup/Mall/EshopSetupMall';

const SUPPORTED_ESHOP_GROUPS: ApiGroup[] = [
  ApiGroup.ALZA,
  ApiGroup.KAUFLAND,
  ApiGroup.MALL_GROUP,
  ApiGroup.ALLEGRO,
];

interface ContextProps {
  eshop: Eshop;
  onUpdate: () => void;
}

function Context({ eshop, onUpdate }: ContextProps) {
  return (
    <>
      { eshop.apiGroup === ApiGroup.KAUFLAND
          && <EshopSetupKaufland key={eshop.code} eshop={eshop} onUpdate={onUpdate} /> }
      { eshop.apiGroup === ApiGroup.ALLEGRO
          && <EshopSetupAllegro key={eshop.code} eshop={eshop} onUpdate={onUpdate} /> }
      { eshop.apiGroup === ApiGroup.ALZA
          && <EshopSetupAlza key={eshop.code} eshop={eshop} onUpdate={onUpdate} /> }
      {eshop.apiGroup === ApiGroup.MALL_GROUP
          && <EshopSetupMall key={eshop.code} eshop={eshop} onUpdate={onUpdate} />}
    </>
  );
}

function filterSupportedEshops(eshops: Eshop[]): Eshop[] {
  return eshops
    .filter(eshop => SUPPORTED_ESHOP_GROUPS
      .find(supportedEshop => supportedEshop === eshop.apiGroup));
}

function findEshop(
  eshops: Eshop[],
  group?: string,
  code?: string,
): Eshop | undefined {
  if (group && code) {
    return eshops
      .find(eshop => eshop.apiGroup === group && eshop.code === code);
  }

  if (group) {
    return eshops.find(eshop => eshop.apiGroup === group);
  }

  return eshops[0];
}

export default function EshopSetup() {
  const { currentUser } = useStoreState(state => state.user);
  const [eshops, setEshops] = useState<Eshop[]>([]);
  const [eshop, setEshop] = useState<Eshop>();
  const { group, code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getEshops()
      .then(response => response.data)
      .then(filterSupportedEshops)
      .then(supportedEshops => {
        setEshops(supportedEshops);

        const foundEshop = findEshop(supportedEshops, group, code);
        if (!foundEshop) {
          return;
        }
        setEshop(foundEshop);
      });
  }, []);

  useEffect(() => {
    if (!eshop) {
      return;
    }
    navigate(`${ESHOP_SETUP}/${eshop.apiGroup}/${eshop.code}`);
  }, [eshop]);

  return (
    <div className="m-10" style={{ marginBottom: 150 }}>
      <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
        <h4>{`${currentUser.partnerName} (${currentUser.partnerCode}) `}</h4>
        <EshopSetupNavigation
          eshops={eshops}
          selected={eshop}
          onSelection={setEshop}
        />
        { eshop
          ? <Context eshop={eshop} onUpdate={() => setEshop({ ...eshop })} />
          : <Loader />}
      </Stack>
    </div>
  );
}
