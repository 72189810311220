import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from '../store/hooks';

export default function PrivateRoute({ children }: { children:JSX.Element }) {
  const navigate = useNavigate();
  const user = useStoreState(state => state.user.currentUser);

  useEffect(() => {
    if (!user.username) {
      navigate('/', { replace: true });
    }
  }, [user]);

  return children;
}
