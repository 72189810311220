import React, { useEffect, useState } from 'react';
import {
  Input, InputGroup, Panel, Stack, Table,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { RiSettings5Fill } from 'react-icons/ri';
import { Icon } from '@rsuite/icons';
import { getPartners, Partner } from '../api/partner';

interface Props {
  onSelection: (partnerCode: string) => void;
}

export default function PartnersTable({ onSelection }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filteredPartners, setFilteredPartners] = useState<Partner[]>([]);

  useEffect(() => {
    getPartners()
      .then(response => {
        setPartners(response.data);
        setFilteredPartners(response.data);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  function search() {
    const result = partners.filter((user) => {
      const values = [user.id, user.code, user.name, user.integration];
      const filter = (filterValue.toLowerCase());
      return values
        .map(value => String(value).toLowerCase())
        .some(value => value.includes(filter));
    });

    setFilteredPartners(result);
  }

  return (
    <div>
      <Panel className="h100p">
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <InputGroup inside>
            <Input
              style={{ width: 400 }}
              placeholder="search by id, code, name, integration"
              defaultValue={filterValue}
              onChange={setFilterValue}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  search();
                }
              }}
            />
            <InputGroup.Button>
              <SearchIcon onClick={() => search} />
            </InputGroup.Button>
          </InputGroup>
        </Stack>
        <Table
          autoHeight
          bordered
          data={filteredPartners}
          loading={loading}
          className="mt-20"
        >
          <Table.Column width={50} align="center">
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.Cell dataKey="id" />
          </Table.Column>
          <Table.Column width={100} align="center">
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.Cell dataKey="code" />
          </Table.Column>
          <Table.Column width={200} align="center">
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>
          <Table.Column flexGrow={2} align="center">
            <Table.HeaderCell>Integration</Table.HeaderCell>
            <Table.Cell dataKey="integration" />
          </Table.Column>
          <Table.Column flexGrow={3} align="center">
            <Table.HeaderCell>Marketplace count (total/active)</Table.HeaderCell>
            <Table.Cell>
              {(rowData: Partner) => `${rowData.marketplaceCountTotal}/${rowData.marketplaceCountActive}`}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={10} align="left">
            <Table.HeaderCell>Webhook</Table.HeaderCell>
            <Table.Cell dataKey="webhook" />
          </Table.Column>
          <Table.Column align="center">
            <Table.HeaderCell>Set up</Table.HeaderCell>
            <Table.Cell dataKey="code">
              {rowData => (
                <Icon
                  as={RiSettings5Fill}
                  onClick={() => onSelection(rowData.code)}
                  style={{ width: 27, height: 27 }}
                />
              ) }
            </Table.Cell>
          </Table.Column>
        </Table>
      </Panel>
    </div>
  );
}
