import React, { useEffect, useState } from 'react';
import {
  Button,
  CheckPicker,
  Col,
  Divider,
  Grid,
  Input,
  Panel,
  Row,
  SelectPicker,
  Stack,
} from 'rsuite';

import { FormBox, FormBoxRow } from '../FormBox';
import { useStoreActions, useStoreState } from '../../store/hooks';
import {
  EXPORT_LOGISTICS_URL,
  exportProducts,
  IMPORT_LOGISTICS_URL,
  importProducts,
} from '../../api/products';
import { downloadData } from '../../utils/downloadData';
import FileUploadCsv from '../FileUploadCsv';

export default function LogisticData() {
  const { fetchCategories, fetchBrands } = useStoreActions(actions => actions.catalogs);

  const brands = useStoreState(state => state.catalogs.brands);
  const categories = useStoreState(state => state.catalogs.categories);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [taricCode, setTaricCode] = useState('');
  const [taricFilter, setTaricFilter] = useState('');
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    const filters = {
      brands: selectedBrands,
      categories: selectedCategories,
      taricCode,
      hasTaric: !taricFilter ? null : (taricFilter === '1'),
    };

    setLoading(true);
    try {
      const response = await exportProducts(EXPORT_LOGISTICS_URL, filters);
      downloadData(response.data, 'text/plain', 'export.csv');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, [fetchCategories, fetchBrands]);

  async function uploadFile(file: Blob) {
    const data = new FormData();
    data.append('attachment', file);
    const response = await importProducts(IMPORT_LOGISTICS_URL, data);
    return response.data;
  }

  const taricFilterData = [
    {
      label: 'Yes',
      value: '1',
    }, {
      label: 'No',
      value: '2',
    },
  ];

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <h6>export</h6>
            <Stack
              spacing={5}
              alignItems="stretch"
              justifyContent="flex-start"
              className="mt-20"
            >
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <FormBox labelWidth={80}>
                  <FormBoxRow label="brands">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={brands}
                      virtualized
                      labelKey="externalId"
                      valueKey="externalId"
                      value={selectedBrands}
                      onChange={setSelectedBrands}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="categories">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={categories}
                      virtualized
                      labelKey="externalId"
                      valueKey="externalId"
                      value={selectedCategories}
                      onChange={setSelectedCategories}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="taric code">
                    <Input
                      style={{ width: 250 }}
                      onChange={setTaricCode}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="has taric">
                    <SelectPicker
                      style={{ width: 250 }}
                      data={taricFilterData}
                      value={taricFilter}
                      searchable={false}
                      onChange={setTaricFilter}
                    />
                  </FormBoxRow>
                </FormBox>
                <Button
                  className="mt-20"
                  appearance="primary"
                  onClick={() => handleExport()}
                  loading={loading}
                >
                  Export
                </Button>
              </Stack>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <h6>import</h6>
            <FileUploadCsv className="mt-5" getUploadResult={(f) => uploadFile(f)} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
