import React from 'react';
import { Message, toaster } from 'rsuite';
import { TypeAttributes } from 'rsuite/cjs/@types/common';

export function notificationError(error: string) {
  return toaster.push(
    <Message
      type="error"
      header="Error"
      closable
      showIcon
      duration={0}
    >
      {error}
    </Message>,
    { placement: 'topCenter' },
  );
}

export function validationError(error: string) {
  return toaster.push(
    <Message
      type="error"
      header="Validation error"
      closable
      showIcon
      duration={0}
    >
      {error}
    </Message>,
    { placement: 'topCenter' },
  );
}

export function notification(message: string, type: TypeAttributes.Status) {
  return toaster.push(<Message type={type} showIcon closable>{message}</Message>, { placement: 'topCenter' });
}

export function notificationSuccess(success: string, duration: number = 2000) {
  return toaster.push(<Message type="success" showIcon duration={duration} closable>{success}</Message>, { placement: 'topCenter' });
}

export function closeAlert(key: string) {
  toaster.remove(key);
}
