import React, { useEffect, useReducer } from 'react';
import {
  Button, Dropdown, IconButton, Message, Stack, Table, toaster,
} from 'rsuite';
import { BsPlusCircle } from 'react-icons/bs';
import { HiDotsHorizontal } from 'react-icons/hi';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Eshop } from '../../../api/eshop/eshop';
import { WarehouseLocality } from '../../../api/warehouse/warehouseApiTypes';
import { getWarehouseLocalities, setDefaultWarehouseLocality } from '../../../api/warehouse/warehouse';
import DropdownOverlay from '../../DropdownOverlay';
import { WAREHOUSE_LOCALITY_EDIT } from '../../../routes/links';

interface MenuProps {
  onEditClick: () => void;
}

function Menu({ onEditClick }: MenuProps) {
  return (
    <DropdownOverlay
      element={(
        <IconButton
          size="sm"
          appearance="subtle"
          icon={<HiDotsHorizontal size={20} />}
        />
)}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => onEditClick()}
        >
          Edit
        </Dropdown.Item>
      </Dropdown.Menu>
    </DropdownOverlay>
  );
}

interface DefaultProps {
  warehouse: WarehouseLocality;
  isLoading: boolean;
  onClick: () => void;
}

function Default({ warehouse, isLoading, onClick }: DefaultProps) {
  return warehouse.isDefault ? <p>(DEFAULT)</p> : (
    <Button
      size="sm"
      onClick={() => onClick()}
      loading={isLoading}
    >
      Set as default
    </Button>
  );
}

interface Props {
  eshop: Eshop;
}

interface State {
  isLoading: boolean;
  isSettingDefault: boolean;
  warehouses: WarehouseLocality[];
}

const initialState: State = {
  isLoading: false,
  isSettingDefault: false,
  warehouses: [],
};

type LoadingAction = { type: 'loading' };
type LoadingSuccessfulAction = { type: 'loadingSuccessful', payload: WarehouseLocality[] };
type SetDefaultAction = { type: 'setDefault' };
type SetDefaultSuccessfulAction = { type: 'setDefaultSuccessful', payload: WarehouseLocality };
type SetDefaultFailureAction = { type: 'setDefaultFailure' };
type Action = LoadingAction
| LoadingSuccessfulAction
| SetDefaultAction
| SetDefaultSuccessfulAction
| SetDefaultFailureAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'loadingSuccessful':
      return { ...state, isLoading: false, warehouses: action.payload };
    case 'setDefault':
      return { ...state, isSettingDefault: true };
    case 'setDefaultSuccessful':
      return {
        ...state,
        isSettingDefault: false,
        warehouses: state
          .warehouses
          .map((warehouse) => ({ ...warehouse, isDefault: false }))
          .map((warehouse) => (warehouse.id !== action.payload.id ? warehouse : {
            ...action.payload, isDefault: true,
          })),
      };
    case 'setDefaultFailure':
      return { ...state, isSettingDefault: false };
    default:
      return state;
  }
}

export default function EshopSetupAllegroAddresses({ eshop }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  async function fetchWarehouseLocalities(): Promise<WarehouseLocality[]> {
    dispatch({ type: 'loading' });

    try {
      return await getWarehouseLocalities(eshop.code);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 400) {
        throw error;
      }

      return [];
    }
  }

  useEffect(() => {
    fetchWarehouseLocalities()
      .then((response) => dispatch({ type: 'loadingSuccessful', payload: response }));
  }, [eshop]);

  function showSuccessMessage(): void {
    toaster.push(
      <Message
        type="success"
        closable
        showIcon
        duration={2000}
      >
        Warehouse successfully set as default.
      </Message>,
    );
  }

  function handleEdit(warehouse: WarehouseLocality) {
    navigate(`${WAREHOUSE_LOCALITY_EDIT}/${warehouse.id}`);
  }

  function handleSetDefault(warehouse: WarehouseLocality) {
    dispatch({ type: 'setDefault' });
    setDefaultWarehouseLocality(warehouse.id)
      .then(() => {
        showSuccessMessage();
        dispatch({ type: 'setDefaultSuccessful', payload: warehouse });
      })
      .catch((error) => {
        dispatch({ type: 'setDefaultFailure' });
        return Promise.reject(error);
      });
  }

  function handleAdd() {
    navigate(WAREHOUSE_LOCALITY_EDIT);
  }

  return (
    <Stack spacing={15} direction="column" alignItems="stretch" justifyContent="flex-start">
      <Table
        autoHeight
        bordered
        data={state.warehouses}
        loading={state.isLoading}
      >
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Address Code</Table.HeaderCell>
          <Table.Cell dataKey="code" />
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>City</Table.HeaderCell>
          <Table.Cell dataKey="city" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.Cell dataKey="countryCode" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center">
          <Table.HeaderCell>Postcode</Table.HeaderCell>
          <Table.Cell dataKey="postcode" />
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>Street</Table.HeaderCell>
          <Table.Cell dataKey="street" />
        </Table.Column>
        <Table.Column flexGrow={2} align="center">
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.Cell dataKey="company" />
        </Table.Column>
        <Table.Column flexGrow={1} align="center" verticalAlign="middle">
          <Table.HeaderCell>{}</Table.HeaderCell>
          <Table.Cell style={{ padding: '0px' }}>
            {(warehouse: WarehouseLocality) => (
              <Menu
                onEditClick={() => handleEdit(warehouse)}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} align="center" verticalAlign="middle">
          <Table.HeaderCell>{}</Table.HeaderCell>
          <Table.Cell style={{ padding: '0px' }}>
            {(warehouse: WarehouseLocality) => (
              <Default
                warehouse={warehouse}
                onClick={() => handleSetDefault(warehouse)}
                isLoading={state.isSettingDefault}
              />
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
      <IconButton
        size="md"
        appearance="subtle"
        icon={<BsPlusCircle size={20} />}
        onClick={() => handleAdd()}
      />
    </Stack>
  );
}
