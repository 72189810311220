import { action, thunk } from 'easy-peasy';
import { OrderStoreModel } from './types';
import {
  fetchOrderUpdateStatus,
  getOrderPreview,
  getOrdersList,
  updateState,
} from '../../api/orders';
import { OrderStates } from '../../api/apiTypes';

const ordersStore: OrderStoreModel = {
  loading: false,
  orders: [],
  total: 0,
  currentFilter: {
    pageSize: 25,
    page: 1,
    orderBy: 'created',
    orderDirection: 'desc',
    filter: {
      states: [],
      carriers: [],
      eshops: [],
      globalFilter: '',
    },
  },

  fetchOrders: thunk(async (actions, payload) => {
    actions.setLoading(true);
    const { data } = await getOrdersList(payload);

    actions.setOrders({ total: data.total, orders: data.data });
    actions.setLoading(false);
  }),
  updateState: thunk(async (actions, payload, { getState }) => {
    actions.setLoading(true);

    function fetchUpdateStatusChange(orderId: number, interval: any) {
      fetchOrderUpdateStatus(orderId)
        .then((response) => {
          const { data } = response;
          const { orders, total } = getState();
          const newOrders = orders.map(o => {
            if (o.id === orderId) {
              o.updateStatus = data.updateStatus;
              o.updateStatusResponseError = data.updateStatusResponseError;
            }

            return o;
          });
          actions.setOrders({ total, orders: newOrders });
          clearInterval(interval);
        });
    }

    function checkIfStatusChangeWasSyncedWithMarketplace(orderId: number) {
      const interval: any = setInterval(() => fetchUpdateStatusChange(orderId, interval), 5000);
      setTimeout(() => clearInterval(interval), 5000 * 5);
    }

    try {
      const { data } = await updateState(payload);

      const { orders, total } = getState();

      const updated = orders.map(o => {
        if (o.id === data.id) {
          checkIfStatusChangeWasSyncedWithMarketplace(o.id);
          return data;
        }

        return o;
      });

      actions.setOrders({ total, orders: updated });
    } finally {
      actions.setLoading(false);
    }
  }),
  getOrderPreview: thunk(async (actions, payload) => {
    const { data } = await getOrderPreview(payload);
    // just process preview on table itself for now
    return data;
  }),
  setOrders: action((state, payload) => {
    state.total = payload.total;
    state.orders = payload.orders;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setFilter: action((state, payload) => {
    state.currentFilter = payload;
  }),
  updateAfterLabelPrinted: action((state, { orderId, url }) => {
    state.orders = state.orders.map(o => {
      if (o.id === orderId) {
        return {
          ...o,
          shippingLabelUrl: url,
          allowedStates: [...o.allowedStates, OrderStates.SHIPPED],
        };
      }

      return o;
    });
  }),
};

export default ordersStore;
