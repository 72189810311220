import { Carrier, EshopCarrier, MpiDeliveryType } from '../apiTypes';

export const mockEshopCarriers: EshopCarrier[] = [{
  id: 1,
  eshopCode: 'KAUFLAND.CZ',
  eshopCarrierCode: 'GLS',
  carrierId: 1,
  name: 'GLS (GLS)',
  ignoreIban: false,
  identifier: 'GLS',
}, {
  id: 2,
  eshopCode: 'KAUFLAND.CZ',
  eshopCarrierCode: 'DPD',
  carrierId: 2,
  identifier: 'DPD',
  ignoreIban: false,
},
{
  id: 3,
  eshopCode: 'KAUFLAND.CZ',
  eshopCarrierCode: 'UPS',
  carrierId: 3,
  identifier: 'UPS',
  ignoreIban: false,
}];

export const mockEshopCarrier: EshopCarrier = {
  id: 1,
  eshopCode: 'KAUFLAND.CZ',
  eshopCarrierCode: 'GLS',
  secondaryEshopCarrierCode: '937160ed-7c44-4ea6-af78-1e233779e681',
  carrierId: 1,
  name: 'GLS (GLS)',
  ignoreIban: false,
  identifier: 'GLS',
  portalName: 'GLS Carrier',
  defaultMpiDeliveryType: MpiDeliveryType.PARCEL_SHOP,
};

export const mockCarriers: Carrier[] = [
  {
    id: 1,
    identifier: 'GLS',
  },
  {
    id: 2,
    identifier: 'DPD',
  },
  {
    id: 3,
    identifier: 'UPS',
  },
  {
    id: 4,
    identifier: 'PPL',
  },
];
