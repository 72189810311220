import { useSearchParams } from 'react-router-dom';

interface Pagination {
  filter: string;
  limit: number;
  page: number;
}

export default function usePagination(): [Pagination, (value: Pagination) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagination: Pagination = {
    filter: searchParams.get('filter') || '',
    limit: +(searchParams.get('limit') || 25),
    page: +(searchParams.get('page') || 1),
  };

  function setPagination(value: Pagination) {
    setSearchParams({
      filter: value.filter,
      limit: String(value.limit),
      page: String(value.page),
    });
  }

  return [pagination, setPagination];
}
