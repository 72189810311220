export function createAndClickLink(fileName: string, href: string) {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = href;
  link.click();
}

export function downloadData(data: any, fileType: string, fileName: string) {
  const blob = new Blob([data], {
    type: fileType,
  });
  const fileDownloadUrl = URL.createObjectURL(blob);
  createAndClickLink(fileName, fileDownloadUrl);
}
