import React, { useState } from 'react';
import {
  Panel, Nav, Grid, Row, Col, Divider,
} from 'rsuite';

import BrandDescriptions from '../components/ProductsBatchUpdates/BrandDescriptions';
import CategoriesParameters from '../components/ProductsBatchUpdates/CategoriesParameters';
import MediaUpdate from '../components/ProductsBatchUpdates/MediaUpdate';
import LogisticData from '../components/ProductsBatchUpdates/LogisticData';
import DeleteProducts from '../components/ProductsBatchUpdates/DeleteProducts';
import DeliveryDelays from '../components/ProductsBatchUpdates/DeliveryDelays';

function TabContent({ activeKey } : { activeKey:string }) {
  if (activeKey === 'tabMedia') {
    return <MediaUpdate />;
  }
  if (activeKey === 'tabCategories') {
    return <CategoriesParameters />;
  }
  if (activeKey === 'tabBrands') {
    return <BrandDescriptions />;
  }
  if (activeKey === 'tabLogistics') {
    return <LogisticData />;
  }
  if (activeKey === 'tabDelivery') {
    return <DeliveryDelays />;
  }
  if (activeKey === 'tabDelete') {
    return <DeleteProducts />;
  }

  return null;
}

export default function BatchUpdate() {
  const [activeTab, setActiveTab] = useState('tabMedia');

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <Nav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
              <Nav.Item eventKey="tabMedia">Media</Nav.Item>
              <Nav.Item eventKey="tabCategories">Categories and parameters</Nav.Item>
              <Nav.Item eventKey="tabBrands">Brand and descriptions</Nav.Item>
              <Nav.Item eventKey="tabLogistics">Logistics data</Nav.Item>
              <Nav.Item eventKey="tabDelivery">Delivery delays</Nav.Item>
              <Nav.Item disabled><Divider vertical /></Nav.Item>
              <Nav.Item eventKey="tabDelete">Delete products</Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <TabContent activeKey={activeTab} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
