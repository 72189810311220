import api from './base';
import { IntegrationInfo } from './apiTypes';

export async function getIntegrationInfo(): Promise<IntegrationInfo> {
  return api.get<IntegrationInfo>('/integration/info').then(response => response.data);
}

const mockResponse: IntegrationInfo = {
  baseLinkerToken: 'asdfasdfasdf',
};

api.mock.onGet('/integration/info').reply(200, mockResponse);

export async function saveIntegrationInfo(info: IntegrationInfo): Promise<string> {
  return api.post<string>('/integration/info', info).then(response => response.data);
}

api.mock.onPost('/integration/info').reply(202);
