import { Button, Message, Stack } from 'rsuite';
import React from 'react';
import { AllegroLinkingInfo } from '../../../../api/apiTypes';

interface Props {
  linkingInfo: AllegroLinkingInfo;
}

export default function EshopSetupAllegroCredentialsUnlinked({ linkingInfo }: Props) {
  return (
    <Stack spacing={20}>
      <Message type="warning">
        <div style={{ fontWeight: 'bold' }}>{`Your account is not linked to ${linkingInfo.eshopCode}`}</div>
      </Message>
      <Button
        appearance="primary"
        href={linkingInfo.linkUrl ?? undefined}
      >
        Link
      </Button>
    </Stack>
  );
}
