import api from '../base';
import {
  basicInformation, customFields, eshopCodesUsingDefaultConfig, stateMappings,
} from './baselinkerMocks';
import { BasicInformation, CustomFields, StatusMapping } from './apiTypes';

export function getStatusMappings(
  eshopCode :string | undefined = undefined,
): Promise<StatusMapping[]> {
  const url = eshopCode ? `baselinker/status-mappings/${eshopCode}` : 'baselinker/status-mappings';
  return api.get<StatusMapping[]>(url).then((response) => response.data);
}

api.mock.onGet('baselinker/status-mappings').reply(200, stateMappings);
api.mock.onGet('baselinker/status-mappings/MALL.CZ').reply(200, stateMappings);
api.mock.onGet('baselinker/status-mappings/MALL.SK').reply(200, {});

export function saveStatusMappings(
  statusMapping: StatusMapping[],
  eshopCode :string | undefined = undefined,
): Promise<null> {
  const url = eshopCode ? `baselinker/status-mappings/${eshopCode}` : 'baselinker/status-mappings';
  return api.post<null>(url, statusMapping).then((response) => response.data);
}

api.mock.onPost('baselinker/status-mappings').reply(204);
api.mock.onPost('baselinker/status-mappings/MALL.CZ').reply(204);
api.mock.onPost('baselinker/status-mappings/MALL.SK').reply(204);

export function deleteStatusMappings(
  eshopCode :string | undefined = undefined,
): Promise<StatusMapping[]> {
  const url = eshopCode ? `baselinker/status-mappings/${eshopCode}` : 'baselinker/status-mappings';
  return api.delete<StatusMapping[]>(url).then((response) => response.data);
}

api.mock.onDelete('baselinker/status-mappings').reply(200);
api.mock.onDelete('baselinker/status-mappings/MALL.CZ').reply(200);
api.mock.onDelete('baselinker/status-mappings/MALL.SK').reply(200);

export function getBasicInformation(
  eshopCode :string | undefined = undefined,
): Promise<BasicInformation> {
  const url = eshopCode ? `baselinker/basic-information/${eshopCode}` : 'baselinker/basic-information';
  return api.get<BasicInformation>(url).then((response) => response.data);
}

api.mock.onGet('baselinker/basic-information').reply(200, basicInformation);
api.mock.onGet('baselinker/basic-information/MALL.CZ').reply(200, basicInformation);
api.mock.onGet('baselinker/basic-information/MALL.SK').reply(200, {});

export function saveBasicInformation(
  basicInfo: BasicInformation,
  eshopCode :string | undefined = undefined,
): Promise<null> {
  const url = eshopCode ? `baselinker/basic-information/${eshopCode}` : 'baselinker/basic-information';
  return api.post<null>(url, basicInfo).then((response) => response.data);
}

api.mock.onPost('baselinker/basic-information').reply(204);
api.mock.onPost('baselinker/basic-information/MALL.CZ').reply(204);
api.mock.onPost('baselinker/basic-information/MALL.SK').reply(204);

export function getCustomFields(eshopCode :string | undefined = undefined): Promise<CustomFields> {
  const url = eshopCode ? `baselinker/custom-fields/${eshopCode}` : 'baselinker/custom-fields';
  return api.get<CustomFields>(url).then((response) => response.data);
}

api.mock.onGet('baselinker/custom-fields').reply(200, customFields);
api.mock.onGet('baselinker/custom-fields/MALL.CZ').reply(200, customFields);
api.mock.onGet('baselinker/custom-fields/MALL.SK').reply(200, {});

export function deleteCustomFields(
  eshopCode :string | undefined = undefined,
): Promise<CustomFields> {
  const url = eshopCode ? `baselinker/custom-fields/${eshopCode}` : 'baselinker/custom-fields';
  return api.delete<CustomFields>(url).then((response) => response.data);
}

api.mock.onDelete('baselinker/custom-fields').reply(200, customFields);
api.mock.onDelete('baselinker/custom-fields/MALL.CZ').reply(200, customFields);
api.mock.onDelete('baselinker/custom-fields/MALL.SK').reply(200);

export function saveCustomFields(
  customFieldsToSave: CustomFields,
  eshopCode :string | undefined = undefined,
): Promise<null> {
  const url = eshopCode ? `baselinker/custom-fields/${eshopCode}` : 'baselinker/custom-fields';
  return api.post<null>(url, customFieldsToSave).then((response) => response.data);
}

api.mock.onPost('baselinker/custom-fields').reply(204);
api.mock.onPost('baselinker/custom-fields/MALL.CZ').reply(204);
api.mock.onPost('baselinker/custom-fields/MALL.SK').reply(204);

export interface SyncPlatformIdResponse {
  eshopCode: string,
  error?: string,
  productsToSync?: number,
  productsNotFoundInBl?: number,
  csvResponse?: string,
}

export interface SyncPlatformIdRequest {
  partnerCode: string,
  inventoryId: string | null,
  eshopCode: string,
  remapRequired: boolean
}

export async function syncPlatformIdsForEshop(request: SyncPlatformIdRequest) {
  const { eshopCode } = request;
  return api.post<SyncPlatformIdResponse>(
    'webhooks/products/baselinker/ids',
    request,
    {
      transformResponse: (data) => {
        const parsed = JSON.parse(data);
        return { ...parsed, eshopCode };
      },
    },
  );
}

const syncPlatformIdResponseMock: any = {
  httpStatus: 500,
  code: 'GENERAL_HTTP_ERROR',
  codeDescription: 'Error is not probably caused by business logic but by general HTTP 500 (Internal Server Error), etc...',
  details: 'Invalid user token',
};

api.mock.onPost('webhooks/products/baselinker/ids').reply(500, JSON.stringify(syncPlatformIdResponseMock));

export async function getEshopCodesUsingBaselinkerDefaultConfig(): Promise<string[]> {
  return api.get<string[]>('baselinker/eshops/using-default-config')
    .then((response) => response.data);
}

api.mock
  .onGet('baselinker/eshops/using-default-config')
  .reply(200, eshopCodesUsingDefaultConfig);
