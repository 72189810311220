import React, { useEffect, useState } from 'react';
import {
  Button, CheckPicker, Col, Divider, Grid, Panel, Row, Stack,
} from 'rsuite';

import { FormBox, FormBoxRow } from '../FormBox';
import { useStoreActions, useStoreState } from '../../store/hooks';
import {
  EXPORT_MEDIA_URL,
  exportProducts,
  IMPORT_MEDIA_URL,
  importProducts,
} from '../../api/products';
import { downloadData } from '../../utils/downloadData';
import FileUploadCsv from '../FileUploadCsv';

export default function MediaUpdate() {
  const { fetchCategories, fetchBrands } = useStoreActions(actions => actions.catalogs);

  const brands = useStoreState(state => state.catalogs.brands);
  const categories = useStoreState(state => state.catalogs.categories);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    const filters = {
      brands: selectedBrands,
      categories: selectedCategories,
    };

    setLoading(true);
    try {
      const response = await exportProducts(EXPORT_MEDIA_URL, filters);
      downloadData(response.data, 'text/plain', 'export.csv');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, [fetchCategories, fetchBrands]);

  async function uploadFile(fileData:Blob) {
    const data = new FormData();
    data.append('attachment', fileData);
    const response = await importProducts(IMPORT_MEDIA_URL, data);
    return response.data;
  }

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <h6>export</h6>
            <Stack
              spacing={5}
              alignItems="stretch"
              justifyContent="flex-start"
              className="mt-20"
            >
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <FormBox labelWidth={80}>
                  <FormBoxRow label="brands">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={brands}
                      labelKey="externalId"
                      valueKey="externalId"
                      virtualized
                      value={selectedBrands}
                      onChange={setSelectedBrands}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="categories">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={categories}
                      labelKey="externalId"
                      valueKey="externalId"
                      virtualized
                      value={selectedCategories}
                      onChange={setSelectedCategories}
                    />
                  </FormBoxRow>
                </FormBox>
                <Button
                  className="mt-20"
                  appearance="primary"
                  onClick={() => handleExport()}
                  loading={loading}
                >
                  Export
                </Button>
              </Stack>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <h6>import</h6>
            <FileUploadCsv className="mt-5" getUploadResult={(f) => uploadFile(f)} autoZip />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
