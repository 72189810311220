import {
  AllegroDeliveryMethods,
  AllegroDeliveryServices,
  AllegroImpliedWarranties,
  AllegroOrderCarriers,
  AllegroReturnPolicies,
  AllegroShippingRateDetail,
  AllegroShippingRates,
} from '../allegroApiTypes';

export const mockGetAllegroShippingRates: AllegroShippingRates = {
  shippingRates: [
    {
      id: '7470ffe8-8691-4c35-9f2b-a8e527b523bc',
      name: 'Ceska Posta',
    },
    {
      id: 'ae92d50c-5dfb-44b3-abe6-283d386c2542',
      name: 'DPD',
    },
    {
      id: 'a941cfdc-f4f7-4557-8c2b-7ef55327fa4f',
      name: 'DHL',
    },
    {
      id: '74671b1e-f2e1-46a2-a14c-db0229991f8c',
      name: 'PPL',
    },
    {
      id: 'a6df6711-a95f-4437-bb55-eb3aa789d199',
      name: 'UPS',
    },
  ],
};

export const mockGetAllegroShippingRate: AllegroShippingRateDetail = {
  id: 'a941cfdc-f4f7-4557-8c2b-7ef55327fa4f',
  name: 'DHL',
  rates: [{
    deliveryMethod: {
      id: '4bd8a63a-5464-4988-bd34-b7e41d25fbb0',
    },
  },
  {
    deliveryMethod: {
      id: '59dc3285-d9ee-4edc-baed-4183c1c3bc31',
    },
  },
  {
    deliveryMethod: {
      id: 'ceedaec0-cfca-4cb4-8c3a-2045b42c2972',
    },
  }],
  lastModified: '2018-04-05T12:20:23.974Z',
};

export const mockGetAllegroDeliveryMethods: AllegroDeliveryMethods = {
  deliveryMethods: [
    {
      id: '4bd8a63a-5464-4988-bd34-b7e41d25fbb0',
      name: 'DHL PARCEL',
      marketplaces: ['allegro-pl', 'allegro-cz'],
    },
    {
      id: '59dc3285-d9ee-4edc-baed-4183c1c3bc31',
      name: 'DHL HOME',
      marketplaces: ['allegro-pl', 'allegro-cz'],
    },
    {
      id: 'ceedaec0-cfca-4cb4-8c3a-2045b42c2972',
      name: 'DHL BOX',
      marketplaces: ['allegro-pl', 'allegro-cz'],
    },
    {
      id: 'cc9adeaa-66e6-4090-bc8b-981203c73641',
      name: 'UPS PARCEL',
      marketplaces: ['allegro-pl', 'allegro-cz'],
    },
    {
      id: 'cb249e87-5a74-45d5-8d8a-0af6a51a97f8',
      name: 'UPS HOME',
      marketplaces: ['allegro-pl', 'allegro-cz'],
    },
  ],
};

export const mockGetAllegroReturnPolicies: AllegroReturnPolicies = {
  returnPolicies: [
    {
      id: 'd1cbcd9e-6042-4b1b-8e4f-3ba00d94ed32',
      name: 'Return policy 1',
    },
    {
      id: '6f3d8c59-0620-4a61-9e99-c369b761e83e',
      name: 'Return policy 2',
    },
    {
      id: 'a094bcba-b9de-4272-8274-9d91149ebd0e',
      name: 'Return policy 3',
    },
    {
      id: '2950b2f9-6b97-4d9f-8225-aa291e613a45',
      name: 'Return policy 4',
    },
    {
      id: '3612966d-8268-45e2-946e-f088aa86a3c5',
      name: 'Return policy 5',
    },
  ],
};

export const mockGetAllegroImpliedWarranties: AllegroImpliedWarranties = {
  impliedWarranties: [
    {
      id: '9a908d1f-dd30-4c30-87ad-b82ce5d696b8',
      name: 'Warranty 1',
    },
    {
      id: 'f2cc80e9-d7bc-42d0-9d4e-c3f38789b006',
      name: 'Warranty 2',
    },
    {
      id: '637ba234-76a9-4bfe-b5fe-bad3e6389fe3',
      name: 'Warranty 3',
    },
    {
      id: 'd1a9cbe2-c61c-4dba-a107-f88a96575f7e',
      name: 'Warranty 4',
    },
    {
      id: '8a56a90c-d5e1-4c34-96dc-92447b749fa5',
      name: 'Warranty 5',
    },
  ],
};

export const mockGetAllegroOrderCarriers: AllegroOrderCarriers = {
  carriers: [
    {
      id: 'POCZTA_POLSKA',
      name: 'Poczta Polska',
    },
    {
      id: 'DHL',
      name: 'DHL',
    },
    {
      id: 'YUN_EXPRESS',
      name: 'Yun Express',
    },
    {
      id: 'OTHER',
    },
  ],
};

export const mockGetAllegroDeliveryServices: AllegroDeliveryServices = {
  deliveryServices: [
    {
      id: '6179d6a9-c007-4122-aed9-9b32993b1b05',
      name: 'InPost',
    }, {
      id: '937160ed-7c44-4ea6-af78-1e233779e681',
      name: 'DPD',
    },
  ],
};
