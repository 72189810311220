import React, { useEffect } from 'react';
import {
  Pagination, Popover, Table, Tag, Whisper,
} from 'rsuite';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

import { SortType } from 'rsuite-table';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { ProductStates } from '../../api/apiTypes';
import ProductTableStockQuantity from './ProductTableStockQuantity';

function ErrorCell({ message, updateMessage } : { message: string, updateMessage: string }) {
  if (!message && !updateMessage) {
    return null;
  }

  return (
    <Whisper
      trigger="click"
      placement="autoVerticalStart"
      preventOverflow
      speaker={(
        <Popover
          className="error-message-tooltip"
          arrow={false}
        >
          {message && (
          <div>
            <h3 className="rs-popover-title">Message</h3>
            <p>
              {message}
            </p>
          </div>
          )}
          {updateMessage && (
          <div>
            <h3 className="rs-popover-title">Update message</h3>
            <p>
              {updateMessage}
            </p>
          </div>
          )}
        </Popover>
      )}
    >
      <span>
        <FaExclamationTriangle color="red" cursor="pointer" />
      </span>
    </Whisper>
  );
}

function ProductTable() {
  const { fetchProducts, setFilter } = useStoreActions(actions => actions.products);
  const {
    products, loading, currentFilter, total, apiGroup,
  } = useStoreState(state => state.products);

  useEffect(() => {
    const request = {
      ...currentFilter,
      filter: {
        ...currentFilter.filter,
      },
    };
    if (apiGroup) {
      fetchProducts({
        apiGroup,
        request,
      });
    }
  }, [currentFilter, apiGroup]);

  const changePage = (page:number) => {
    setFilter({
      ...currentFilter,
      page,
    });
  };

  const changeLimit = (limit: number) => {
    setFilter({
      ...currentFilter,
      pageSize: limit,
    });
  };

  const handleSortColumn = (column:string, type?:SortType) => {
    setFilter({
      ...currentFilter,
      orderBy: column,
      orderDirection: type ?? 'asc',
    });
  };

  const getUnit = (weight: number, uom: string) => {
    if (!weight) {
      return null;
    }

    let unit;
    switch (uom) {
      case 'GRAM':
        unit = 'g';
        break;
      case 'KILOGRAM':
        unit = 'kg';
        break;
      default:
        unit = '';
        break;
    }

    return (
      <span>
        {weight}
        {' '}
        {unit}
      </span>
    );
  };

  const getState = (state: ProductStates) => {
    let desc = '';
    switch (state) {
      case ProductStates.TO_BE_PUBLISHED:
        desc = 'TO BE LISTED';
        break;
      case ProductStates.DRAFT:
        desc = 'MPI DRAFT';
        break;
      case ProductStates.PUBLISHED_TEST:
        desc = 'LISTED TEST';
        break;
      case ProductStates.TO_BE_ACTIVATED:
        desc = 'TO BE ACTIVATED';
        break;
      case ProductStates.PUBLISHED_LIVE:
        desc = 'LISTED LIVE';
        break;
      case ProductStates.IMPORT_FAILED:
        desc = 'IMPORT FAILED';
        break;
      case ProductStates.UPDATE_PENDING:
        desc = 'TO BE UPDATED';
        break;
      case ProductStates.UPDATE_FAILED:
        desc = 'UPDATE FAILED';
        break;
      case ProductStates.TO_BE_DELETED:
        desc = 'TO BE DELETED';
        break;
      case ProductStates.DELETION_FAILED:
        desc = 'DELETION FAILED';
        break;
      case ProductStates.INTERNAL_ERROR:
        desc = 'MPI ERROR';
        break;
      case ProductStates.MPI_ONLY:
        desc = 'MPI ONLY';
        break;
      case ProductStates.ACTIVATION_FAILED:
        desc = 'ACTIVATION FAILED';
        break;
      default:
        desc = '';
        break;
    }

    return desc;
  };

  return (
    <div className="h100p">
      <Table
        height={400}
        fillHeight
        bordered
        data={products}
        rowKey="id"
        loading={loading}
        onSortColumn={handleSortColumn}
        sortColumn={currentFilter.orderBy}
        sortType={currentFilter.orderDirection}
      >
        <Table.Column width={40} align="center">
          <Table.HeaderCell> </Table.HeaderCell>
          <Table.Cell dataKey="errorMessage">
            {rowData => (
              <ErrorCell
                message={rowData.errorMessage}
                updateMessage={rowData.errorUpdateMessage}
              />
            ) }
          </Table.Cell>
        </Table.Column>
        <Table.Column align="center" sortable>
          <Table.HeaderCell>Async processing</Table.HeaderCell>
          <Table.Cell dataKey="isBeingProcessedByEshop">
            {rowData => (rowData.asyncOperationId != null && <span style={{ color: 'red', fontSize: 20 }}>A</span>)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Marketplace</Table.HeaderCell>
          <Table.Cell dataKey="eshopName" />
        </Table.Column>
        <Table.Column width={200} align="center" sortable>
          <Table.HeaderCell>SKU</Table.HeaderCell>
          <Table.Cell dataKey="partnerProductCode" />
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>EAN</Table.HeaderCell>
          <Table.Cell dataKey="ean" />
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>MP ID</Table.HeaderCell>
          <Table.Cell dataKey="eshopProductCode" />
        </Table.Column>
        <Table.Column width={300} align="center" sortable>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.Cell dataKey="title" />
        </Table.Column>
        <Table.Column width={110} align="center" sortable>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.Cell dataKey="type" />
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell dataKey="status">
            {rowData => (
              <Tag
                className={`product-status-${rowData.status}`}
              >
                {getState(rowData.status)}
              </Tag>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150} align="center" sortable>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.Cell dataKey="category" />
        </Table.Column>
        <Table.Column width={150} align="center" sortable>
          <Table.HeaderCell>Brand</Table.HeaderCell>
          <Table.Cell dataKey="brand" />
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.Cell dataKey="price">
            {rowData => (
              rowData.price > 0 && (
                <span>
                  {rowData.price}
                  {' '}
                  {rowData.currency}
                </span>
              )
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Minimum price</Table.HeaderCell>
          <Table.Cell dataKey="minimumPrice">
            {rowData => (
              rowData.minimumPrice > 0 && (
                <span>
                  {rowData.minimumPrice}
                  {' '}
                  {rowData.currency}
                </span>
              )
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Forced Price</Table.HeaderCell>
          <Table.Cell dataKey="forcedPrice">
            {rowData => (
              rowData.useForcedPrice && rowData.forcedPrice > 0 && (
                <span>
                  {rowData.forcedPrice}
                  {' '}
                  {rowData.currency}
                </span>
              )
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Stock QTY</Table.HeaderCell>
          <Table.Cell dataKey="inStock">
            {rowData => (
              <ProductTableStockQuantity
                stockQuantity={rowData.inStock}
                isOnHold={rowData.temporaryHold}
                marketplace={rowData.eshopCode}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Handling time</Table.HeaderCell>
          <Table.Cell dataKey="handlingTime" />
        </Table.Column>
        <Table.Column width={150} align="center" sortable>
          <Table.HeaderCell>Delivery delay</Table.HeaderCell>
          <Table.Cell dataKey="deliveryDelay" />
        </Table.Column>
        <Table.Column align="center" sortable>
          <Table.HeaderCell>Free delivery</Table.HeaderCell>
          <Table.Cell dataKey="freeDelivery">
            {rowData => (rowData.freeDelivery && <FaCheckCircle color="green" size={20} />)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.Cell dataKey="weight">
            {rowData => (getUnit(rowData.weight, rowData.weightUom))}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Branch code</Table.HeaderCell>
          <Table.Cell dataKey="branchCode" />
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Shipping group</Table.HeaderCell>
          <Table.Cell dataKey="shippingGroup" />
        </Table.Column>
      </Table>

      <div className="mt-10">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={total}
          limitOptions={[25, 50, 100]}
          limit={currentFilter.pageSize}
          activePage={currentFilter.page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>

    </div>
  );
}

export default React.memo(ProductTable);
