import React, { ReactNode } from 'react';
import { Popover, Whisper } from 'rsuite';
import { PositionChildProps } from 'rsuite/Picker';

type OnClose = (delay?: number) => NodeJS.Timeout | void;

interface OnCloseInterface {
  onClose: OnClose;
}

interface SpeakerProps extends PositionChildProps, Pick<React.HTMLAttributes<HTMLElement>, 'id' | 'onMouseEnter' | 'onMouseLeave'>, OnCloseInterface {
  children: ReactNode;
}

function Speaker({
  children, onClose, left, top, className,
}: SpeakerProps, ref: React.RefObject<HTMLDivElement>) {
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full onClick={() => onClose()}>
      {children}
    </Popover>
  );
}

interface Props {
  element: JSX.Element;
  children: ReactNode;
}

export default function DropdownOverlay({ element, children }: Props) {
  return (
    <Whisper
      placement="bottomStart"
      trigger="click"
      speaker={(props, ref) => Speaker({ ...props, children }, ref)}
    >
      {element}
    </Whisper>
  );
}
