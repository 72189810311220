import { createStore } from 'easy-peasy';

import labelStore from './labels/labelStore';
import generalStore from './generalStore';
import { StoreModel } from './storeModel';
import userStore from './user/userStore';
import catalogStore from './catalogs/catalogStore';
import ordersStore from './orders/orderStore';
import productStore from './products/productStore';
import printExistingLabelStore from './labels/printExistingLabelStore';

export const storeModel :StoreModel = {
  labels: labelStore,
  general: generalStore,
  user: userStore,
  catalogs: catalogStore,
  orders: ordersStore,
  products: productStore,
  printExistingLabels: printExistingLabelStore,
};

export default createStore(storeModel);
